export const  formatToTime = (totalHours) => {
    try {
      const hours = Math?.floor(totalHours);
      const minutes = Math?.floor((totalHours - hours) * 60);
      const seconds = Math?.floor(((totalHours - hours) * 60 - minutes) * 60);

      const hoursStr = String(hours)?.padStart(2, '0');
      const minutesStr = String(minutes)?.padStart(2, '0');
      const secondsStr = String(seconds)?.padStart(2, '0');

      return `${hoursStr}:${minutesStr}:${secondsStr}`;
} catch (error) {
    console.log('error on formatToTime');
}
}
