import { Main } from '../../components/Main/Main';
import {
  Option,
  Input,
  InfoForm,
  InputsList,
  InputGroup,
  InputIconContainer,
  ButtonContainer,
  Select,
  Error,
  EyeIconContainer
} from '../../components/Users/CreateUser/CreateUser.styled';
import { MdAlternateEmail } from 'react-icons/md';
import {
  FaUser,
  FaGlobeEurope,
  FaCity,
  FaAddressCard,
  FaPhoneAlt,
  FaTelegramPlane,
  FaSkype,
  FaBirthdayCake,
  FaLanguage,
  FaBriefcase,
} from 'react-icons/fa';
import { FaPeopleGroup, FaCcMastercard } from 'react-icons/fa6';
import { Formik } from 'formik';
import { userText } from '../../services/componentText';
import { useContext, useState } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { LoginFormButton } from '../../components/Buttons/Buttons.styled';
import {  toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { formatDateToInput } from '../../helpers/dateAndTime/formatDate';
import { RiEyeLine, RiEyeOffLine, RiLockPasswordFill } from 'react-icons/ri';
import { fetchOtherInformations, updateUser } from '../../api/users';

export const CurrentUser = () => {
  const { language, currentUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const initialValues = {
    email: currentUser?.email || '',
    password: '',
    full_name: currentUser?.full_name || '',
    id_country: currentUser?.id_country || '',
    city: currentUser?.city || '',
    address: currentUser?.address || '',
    phone_number: currentUser?.phone_number || '',
    telegram: currentUser?.telegram || '',
    skype: currentUser?.skype || '',
    card: currentUser?.card || '',
    birthday: formatDateToInput(currentUser?.birthday) || '',
    id_language: currentUser?.id_language || '',
    id_job_title: currentUser?.id_job_title || '',
    id_groupe: currentUser?.id_groupe || '',
  };

  const {data} = useQuery('information', fetchOtherInformations)

  const handleSubmit = async (values, resetForm) => {
    const response = await updateUser({userId: currentUser.id_employee, data: values})

    if (response.status === 200) {
      toast.success(userText[language][0]?.updateUserSuccess);
    }
  };


  return (
    <Main>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({ isValid }) => (
          <InfoForm autoComplete="off">
            <InputsList>
              <InputGroup>
                <Error name="email" component="div" />
                <InputIconContainer>
                  <MdAlternateEmail size="22" color="green" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.email}
                  type="email"
                  name="email"
                  disabled
                />
              </InputGroup>

              <InputGroup>
                <Error name="password" component="div" />
                <InputIconContainer>
                  <RiLockPasswordFill size="22" fill="#d6b330" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.password}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                />
                <EyeIconContainer onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <RiEyeOffLine size="22px" color="#3098d6" />
                  ) : (
                    <RiEyeLine size="22px" color="#3098d6" />
                  )}
                </EyeIconContainer>
              </InputGroup>

              <InputGroup>
                <Error name="full_name" component="div" />
                <InputIconContainer>
                  <FaUser size="22" color="green" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.fullName}
                  type="text"
                  name="full_name"
                  disabled
                />
              </InputGroup>

              <InputGroup>
                <Error name="id_job_title" component="div" />
                <InputIconContainer>
                  <FaBriefcase size="22" color="brown" />
                </InputIconContainer>
                <Select as="select" name="id_job_title" disabled>
                  <Option>{userText[language][0]?.chooseJobTitle}</Option>
                  {data?.jobTitles?.map(title => (
                    <Option
                      value={title?.id_job_title}
                      key={title?.id_job_title}
                    >
                      {language === 'russian'
                        ? title?.job_title_RU
                        : title?.job_title_UA}
                    </Option>
                  ))}
                </Select>
              </InputGroup>

              <InputGroup>
                <Error name="telegram" component="div" />
                <InputIconContainer>
                  <FaTelegramPlane size="22" color="#0088CC" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.telegram}
                  type="text"
                  name="telegram"
                />
              </InputGroup>

              <InputGroup>
                <Error name="id_groupe" component="div" />
                <InputIconContainer>
                  <FaPeopleGroup size="22" color="green" />
                </InputIconContainer>
                <Select as="select" name="id_groupe" disabled>
                  <Option>{userText[language][0]?.chooseGroupe}</Option>
                  {data?.groups?.map(groupe => (
                    <Option key={groupe?.groupe_id} value={groupe?.groupe_id}>
                      {language === 'russian'
                        ? groupe?.groupe?.groupe_name_RU
                        : groupe?.groupe_name_UA}
                    </Option>
                  ))}
                </Select>
              </InputGroup>

              <InputGroup>
                <Error name="skype" component="div" />
                <InputIconContainer>
                  <FaSkype size="22" color="#00AFF0" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.skype}
                  type="text"
                  name="skype"
                />
              </InputGroup>

              <InputGroup>
                <Error name="card" component="div" />
                <InputIconContainer>
                  <FaCcMastercard size="28" color="#d6b330" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.card}
                  type="text"
                  name="card"
                />
              </InputGroup>

              <InputGroup>
                <Error name="id_country" component="div" />
                <InputIconContainer>
                  <FaGlobeEurope size="22" color="#0077B6" />
                </InputIconContainer>
                <Select as="select" name="id_country">
                  <Option>{userText[language][0]?.chooseCountry}</Option>
                  {data?.countries?.map(country => (
                    <Option
                      key={country?.country_id}
                      value={country?.country_id}
                    >
                      {country?.country_name}
                    </Option>
                  ))}
                </Select>
              </InputGroup>

              <InputGroup>
                <Error name="city" component="div" />
                <InputIconContainer>
                  <FaCity size="22" color="#0077B6" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.city}
                  type="text"
                  name="city"
                />
              </InputGroup>

              <InputGroup>
                <Error name="address" component="div" />
                <InputIconContainer>
                  <FaAddressCard size="22" color="#0077B6" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.address}
                  type="text"
                  name="address"
                />
              </InputGroup>

              <InputGroup>
                <Error name="phone_number" component="div" />
                <InputIconContainer>
                  <FaPhoneAlt size="22" color="green" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.phoneNumber}
                  type="tel"
                  name="phone_number"
                />
              </InputGroup>

              <InputGroup>
                <Error name="birthday" component="div" />
                <InputIconContainer>
                  <FaBirthdayCake size="22" color="green" />
                </InputIconContainer>
                <Input
                  placeholder={userText[language][0]?.birthday}
                  type="date"
                  name="birthday"
                />
              </InputGroup>

              <InputGroup>
                <Error name="id_language" component="div" />
                <InputIconContainer>
                  <FaLanguage size="22" color="#0077B6" />
                </InputIconContainer>
                <Select as="select" name="id_language">
                  <Option>{userText[language][0]?.chooseLanguage}</Option>
                  {data?.languages?.map(language => (
                    <Option key={language?.id} value={language?.id}>
                      {language?.original_name}
                    </Option>
                  ))}
                </Select>
              </InputGroup>
            </InputsList>

            <ButtonContainer
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <LoginFormButton
                type="submit"
                disabled={!isValid}
                style={{ width: '45%' }}
              >
                {userText[language][0]?.saveEdit}
              </LoginFormButton>
            </ButtonContainer>
          </InfoForm>
        )}
      </Formik>
    </Main>
  );
};
