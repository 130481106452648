import { Main } from '../../Main/Main';
import { Modal } from '../../Modal/Modal';
import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { fetchTotalCountEmployeeEfficiencyReport } from '../../../api/report';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  AddedNewReportButton,
  SaveButton,
  ReportTh,
  ReportTd,
  ReportHeadTr,
  ReportTr,
  ReportTable,
  TableContainer,
  Thead,
  NavContainer,
} from './SeeChangesEmployeesEfficiency.styled';
import { employeesEfficiencyText } from '../../../services/componentText';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

export const SeeOfferChangesEmployeesEfficiency = () => {
    const { currentUser, language } = useContext(UserContext);
    const [isModalDateOpen, setIsModalDateOpen] = useState(false);
    const [sortBy, setSortBy] = useState('date');
    const [order, setOrder] = useState('DESC');
    const currentDate = new Date();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const [period, setPeriod] = useState([
      {
        startDate: new Date(
          currentYear,
          currentMonth - 2,
          currentDate.getDate(),
        ),
        endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
        key: 'selection',
      },
    ]);
    const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
    const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
    const formattedStartDateRequest = format(
      period[0]?.startDate,
      'yyyy.MM.dd',
    );
    const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

    const { data, refetch } = useQuery(['employeeReport', sortBy, order], () =>
      fetchTotalCountEmployeeEfficiencyReport({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
    );

    const renderSortIcon = field => {
      if (sortBy === field) {
        return order === 'ASC' ? '▲' : '▼';
      }
      return '';
    };

    const handleSortChange = field => {
      if (sortBy === field) {
        setOrder(order === 'ASC' ? 'DESC' : 'ASC');
        return refetch();
      }

      setSortBy(field);
      return refetch();
    };

    const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const customDefaultInputRanges = defaultInputRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const handleDate = () => {
      refetch();
      setIsModalDateOpen(false);
    };

    return (
      <Main>
        <NavContainer>
          <div
            style={{ marginRight: '10px' }}
          >{`${formattedStartDate} - ${formattedEndDate}`}</div>
          <AddedNewReportButton onClick={() => setIsModalDateOpen(true)}>
            {employeesEfficiencyText[language][0]?.choosePeriod}
          </AddedNewReportButton>
        </NavContainer>
        {isModalDateOpen && (
          <Modal closeModal={() => setIsModalDateOpen(false)}>
            <DateRangePicker
              onChange={item => setPeriod([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              locale={currentUser?.language === 'russian' ? ru : uk}
              staticRanges={customDefaultStaticRanges}
              inputRanges={customDefaultInputRanges}
              months={2}
              ranges={period}
              direction="horizontal"
              style={{ margin: '20px' }}
            />
            <SaveButton onClick={() => handleDate()}>
              {employeesEfficiencyText[language][0]?.update}
            </SaveButton>
          </Modal>
        )}
        <TableContainer>
          <ReportTable>
            <Thead>
              <ReportHeadTr>
                <ReportTh onClick={() => handleSortChange('product_title')}>
                  {employeesEfficiencyText[language][0]?.productTitle}{' '}
                  {renderSortIcon('product_title')}
                </ReportTh>
                <ReportTh
                  onClick={() => handleSortChange('top_best_offer_by_upsells')}
                >
                  {employeesEfficiencyText[language][0]?.topBestOferByUpsells}{' '}
                  {renderSortIcon('top_best_offer_by_upsells')}
                </ReportTh>
                <ReportTh
                  onClick={() => handleSortChange('top_worst_offer_by_upsells')}
                  style={{ backgroundColor: '#e74c3c' }}
                >
                  {employeesEfficiencyText[language][0]?.topWorstOferByUpsells}{' '}
                  {renderSortIcon('top_worst_offer_by_upsells')}
                </ReportTh>
                <ReportTh
                  onClick={() =>
                    handleSortChange('top_best_offer_by_net_income')
                  }
                >
                  {employeesEfficiencyText[language][0]?.topBestOferByNetIncome}{' '}
                  {renderSortIcon('top_best_offer_by_net_income')}
                </ReportTh>
                <ReportTh
                  onClick={() =>
                    handleSortChange('top_worst_offer_by_net_income')
                  }
                  style={{ backgroundColor: '#e74c3c' }}
                >
                  {
                    employeesEfficiencyText[language][0]
                      ?.topWorstOferByNetIncome
                  }{' '}
                  {renderSortIcon('top_worst_offer_by_net_income')}
                </ReportTh>
              </ReportHeadTr>
            </Thead>
            <tbody>
              {data?.length > 0 ? (
                <React.Fragment>
                  {data &&
                    data?.map(
                      item =>
                        item.product_title && (
                          <ReportTr key={item.product_title}>
                            <ReportTd>{item.product_title}</ReportTd>
                            <ReportTd>
                              {item.top_best_offer_by_upsells}
                            </ReportTd>
                            <ReportTd>
                              {item.top_worst_offer_by_upsells}
                            </ReportTd>
                            <ReportTd>
                              {item.top_best_offer_by_net_income}
                            </ReportTd>
                            <ReportTd>
                              {item.top_worst_offer_by_net_income}
                            </ReportTd>
                          </ReportTr>
                        ),
                    )}
                </React.Fragment>
              ) : (
                <ReportTr>
                  <ReportTd
                    colSpan={13}
                    style={{
                      width: '100%',
                      textAlign: 'start',
                      paddingLeft: '50px',
                    }}
                  >
                    {employeesEfficiencyText[language][0]?.notDataOrError}
                  </ReportTd>
                </ReportTr>
              )}
            </tbody>
          </ReportTable>
        </TableContainer>
      </Main>
    );
};
