export const getClaimStatusBackgroundColor = id => {
  if (id === 1 && id !== null) {
      return '#fcb045';
    }
  
    if ((id === 2 && id !== null) || (id === 3 && id !== null) || (id === 9 && id !== null)|| (id === 10 && id !== null)) {
      return '#27ae60';
    }
  
    if ( (id === 4 && id !== null) || (id === 5 && id !== null) || (id === 11 && id !== null)) {
      return '#e74c3c';
    } else {
      return '#ffffff';
    }
}

  