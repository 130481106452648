import styled from "@emotion/styled";

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const Table = styled.table`
  margin: 1em 0 0 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const Tr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const HeadTr = styled(Tr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const Td = styled.td`
  width: 127px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color: ffffff;
  position: relative;

  &:last-of-type {
    border-right: none;
  }
`;

export const Th = styled.th`
  width: 105px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;
`;


export const Select = styled.select`
width: 100%;
height: 40px;
padding: 10px;
margin-bottom: 10px;
background-color: #f5f7fa;
border: 1px solid #000000;
border-radius: 20px;
font-size: 16px;
line-height: 1.17;

cursor: pointer;
`;

export const Option = styled.option`
appearance: none;
width: 100%;
height: 10px;
padding: 5px;
margin-bottom: 10px;
background-color: #f5f7fa;
border: 1px solid #000000;
font-size: 16px;

cursor: pointer;
`;