import { Main } from '../../Main/Main';
import { fetchWiretappingCategoryStatistics } from '../../../api/wiretapping';
import { fetchFinesList } from '../../../api/finesList.js';
import { useQuery } from 'react-query';
import React, { useState, useContext } from 'react';
import {
  Thead,
  TableContainer,
  WiretappingTable,
  WiretappingTr,
  WiretappingHeadTr,
  WiretappingTd,
  WiretappingTh,
  SaveButton,
  AddedNewButton,
  NavContainer,
} from './WiretappingCategoryStatistics.styled.js';
import { wiretappingCheckList } from '../../../services/componentText';
import { UserContext } from '../../Context/UserContext';
import { Modal } from '../../Modal/Modal';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);

export const WiretappingCategoryStatistics = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [order, setOrder] = useState('DESC');
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const { data, refetch } = useQuery(
    ['fetchWiretappingCategoryStatistics'],
    () =>
      fetchWiretappingCategoryStatistics({
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const { data: finesData } = useQuery('finesAmount', fetchFinesList);

  const combinedData = combineData(data || [], finesData || []);

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const compareValues = (key, order = 'ASC') => {
    return function (a, b) {
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }

      return order === 'DESC' ? comparison * -1 : comparison;
    };
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const sortedData = combinedData.slice().sort(compareValues(sortBy, order));

  return (
    <Main>
      {accessLevel ? (
        <div>
          <NavContainer>
            <div
              style={{ marginRight: '10px' }}
            >{`${formattedStartDate} - ${formattedEndDate}`}</div>
            <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
              {wiretappingCheckList[language][0]?.choosePeriod}
            </AddedNewButton>
          </NavContainer>
          {isModalDateOpen && (
            <Modal closeModal={() => setIsModalDateOpen(false)}>
              <DateRangePicker
                onChange={item => setPeriod([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                locale={language === 'russian' ? ru : uk}
                staticRanges={customDefaultStaticRanges}
                inputRanges={customDefaultInputRanges}
                months={2}
                ranges={period}
                direction="horizontal"
                style={{ margin: '20px' }}
              />
              <SaveButton onClick={() => handleDate()}>
                {wiretappingCheckList[language][0]?.update}
              </SaveButton>
            </Modal>
          )}
          <TableContainer>
            <WiretappingTable>
              <Thead>
                <WiretappingHeadTr>
                  <WiretappingTh
                    onClick={() =>
                      handleSortChange(
                        language === 'russian'
                          ? 'fine_title_ru'
                          : 'fine_title_ua',
                      )
                    }
                    style={{
                      width: '400px',
                    }}
                  >
                    {wiretappingCheckList[language][0]?.finesReason}
                    {renderSortIcon(
                      language === 'russian'
                        ? 'fine_title_ru'
                        : 'fine_title_ua',
                    )}
                  </WiretappingTh>

                  <WiretappingTh
                    onClick={() => handleSortChange('total_count')}
                  >
                    {wiretappingCheckList[language][0]?.totalWithoutSymbols}
                    {renderSortIcon('total_count')}
                  </WiretappingTh>

                  <WiretappingTh
                    onClick={() => handleSortChange('total_fines_amount')}
                  >
                    {wiretappingCheckList[language][0]?.finesAmount}
                    {renderSortIcon('total_fines_amount')}
                  </WiretappingTh>

                  <WiretappingTh
                    onClick={() => handleSortChange('error_percent')}
                  >
                    {
                      wiretappingCheckList[language][0]
                        ?.errorPercentWithoutSymbols
                    }
                    {renderSortIcon('error_percent')}
                  </WiretappingTh>
                </WiretappingHeadTr>
              </Thead>
              <tbody>
                {sortedData?.length > 0 ? (
                  <React.Fragment>
                    {sortedData?.map(item => {
                      return (
                        <WiretappingTr key={item.id}>
                          <WiretappingTd
                            style={{
                              width: '422px',
                            }}
                          >
                            {language === 'russian'
                              ? item.fine_title_ru
                              : item.fine_title_ua}
                          </WiretappingTd>
                          <WiretappingTd>{item.total_count}</WiretappingTd>
                          <WiretappingTd>
                            {item.total_fines_amount} грн
                          </WiretappingTd>
                          <WiretappingTd>{item.error_percent} %</WiretappingTd>
                        </WiretappingTr>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <WiretappingTr>
                    <WiretappingTd
                      colSpan={4}
                      style={{
                        width: '790px',
                        textAlign: 'start',
                        paddingLeft: '50px',
                      }}
                    >
                      {wiretappingCheckList[language][0]?.notDataOrError}
                    </WiretappingTd>
                  </WiretappingTr>
                )}
              </tbody>
            </WiretappingTable>
          </TableContainer>
        </div>
      ) : (
        <div>У вас немає доступу для перегляду сторінки</div>
      )}
    </Main>
  );
};

const combineData = (data, finesData) => {
  const categoryCounts = {};

  // Додаємо дані з data
  data?.resultArray?.forEach(item => {
    const categoryId = item.id;

    if (categoryId) {
      if (!categoryCounts[categoryId]) {
        categoryCounts[categoryId] = {
          id: categoryId,
          total_count: item.total_count || 0,
          fine_title_ru: item.fine_title_ru,
          fine_title_ua: item.fine_title_ua,
          fine_amount: 0,
          total_fines_amount: 0,
          error_percent: ((item.total_count / data?.totalCount) * 100).toFixed(
            2,
          ),
        };
      } else {
        categoryCounts[categoryId].total_count += item.total_count || 0;
      }
    }
  });

  finesData?.forEach(fine => {
    const categoryId = fine.id;

    if (categoryCounts[categoryId]) {
      categoryCounts[categoryId].fine_amount = fine.fine_amount || 0;
      categoryCounts[categoryId].total_fines_amount =
        (categoryCounts[categoryId].total_count || 0) * (fine.fine_amount || 0);
    }

    if (!categoryCounts[categoryId]) {
      categoryCounts[categoryId] = {
        id: categoryId,
        total_count: fine.total_count || 0,
        fine_title_ru: fine.fine_title_ru,
        fine_title_ua: fine.fine_title_ua,
        fine_amount: fine.fine_amount,
        total_fines_amount: 0,
        error_percent: 0,
      };
    }
  });

  const resultArray = Object.values(categoryCounts);

  return resultArray;
};
