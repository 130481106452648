import { Main } from '../../Main/Main';
import {
  Thead,
  TableContainer,
  WiretappingTable,
  WiretappingTr,
  WiretappingHeadTr,
  WiretappingTd,
  WiretappingTh,
  SaveButton,
  AddedNewButton,
  NavContainer,
} from './WiretappingAllStatisticsWithoutJSON.styled';
import { fetchWiretappingStatistics } from '../../../api/wiretapping';
import { useQuery } from 'react-query';
import React, { useState, useContext } from 'react';
import { wiretappingCheckList } from '../../../services/componentText';
import { UserContext } from '../../Context/UserContext';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { Modal } from '../../Modal/Modal';
import { fetchUsers } from '../../../api/users';

export const WiretappingFinesStatistics = () => {
  const { language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: 'selection',
    },
  ]);
  const [sorting, setSorting] = useState({
    column: null,
    direction: 'asc',
  });

  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data: wiretappingData, refetch: wiretappingRefetch } = useQuery(
    ['fetchWiretappingStatistics'],
    () =>
      fetchWiretappingStatistics({
        searchBy: 'employee_full_name',
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const { data: usersData } = useQuery(["getAllEmployees"], () =>
  fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0", jobTitle: 3,})
);

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    wiretappingRefetch();
    setIsModalDateOpen(false);
  };

  const combinedData = usersData?.usersData?.map(user => {
    const wiretappingItem = wiretappingData?.find(
      item => item.employee_full_name === user.full_name,
    );

    const errorPercent =
      wiretappingItem && wiretappingItem.order_count
        ? (
            ((wiretappingItem.fines_status_id_count_one +
              wiretappingItem.fines_status_id_count_two) /
              wiretappingItem.order_count) *
            100
          ).toFixed(2)
        : 0;

    return {
      id_employee: user.id_employee,
      full_name: user.full_name,
      total_fine_amount: wiretappingItem?.total_fine_amount || 0,
      order_count: wiretappingItem?.order_count || 0,
      fines_status_id_count_one:
        wiretappingItem?.fines_status_id_count_one || 0,
      fines_status_id_count_two:
        wiretappingItem?.fines_status_id_count_two || 0,
      fines_status_id_count_four:
        wiretappingItem?.fines_status_id_count_four || 0,
      error_percent: errorPercent,
    };
  });

  const handleSort = columnName => {
    setSorting(prevSorting => ({
      column: columnName,
      direction:
        prevSorting.column === columnName && prevSorting.direction === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const sortedCombinedData = (combinedData || []).sort((a, b) => {
    const { column, direction } = sorting || {};

    if (column) {
      const valueA = column === 'error_percent' ? Number(a[column]) : a[column];
      const valueB = column === 'error_percent' ? Number(b[column]) : b[column];

      if (direction === 'asc') {
        return valueA > valueB ? 1 : -1;
      } else {
        return valueA < valueB ? 1 : -1;
      }
    }

    return 0;
  });

  const renderSortIcon = field => {
    if (sorting.column === field) {
      return sorting.direction === 'asc' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <Main>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {wiretappingCheckList[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
          {wiretappingCheckList[language][0]?.choosePeriod}
        </AddedNewButton>
      </NavContainer>

      <TableContainer>
        <WiretappingTable>
          <Thead>
            <WiretappingHeadTr>
              <WiretappingTh onClick={() => handleSort('full_name')}>
                {wiretappingCheckList[language][0]?.fullName}
                {renderSortIcon('full_name')}
              </WiretappingTh>
              <WiretappingTh onClick={() => handleSort('total_fine_amount')}>
                {wiretappingCheckList[language][0]?.finesAmount}
                {renderSortIcon('total_fine_amount')}
              </WiretappingTh>
              <WiretappingTh onClick={() => handleSort('order_count')}>
                {wiretappingCheckList[language][0]?.totalWithoutSymbols}
                {renderSortIcon('order_count')}
              </WiretappingTh>
              <WiretappingTh
                onClick={() => handleSort('fines_status_id_count_one')}
              >
                {wiretappingCheckList[language][0]?.finesWithoutSymbols}
                {renderSortIcon('fines_status_id_count_one')}
              </WiretappingTh>
              <WiretappingTh
                onClick={() => handleSort('fines_status_id_count_two')}
              >
                {wiretappingCheckList[language][0]?.warningWithoutSymbols}
                {renderSortIcon('fines_status_id_count_two')}
              </WiretappingTh>
              <WiretappingTh
                onClick={() => handleSort('fines_status_id_count_four')}
              >
                {wiretappingCheckList[language][0]?.notFinesWithoutSymbols}
                {renderSortIcon('fines_status_id_count_four')}
              </WiretappingTh>
              <WiretappingTh onClick={() => handleSort('error_percent')}>
                {wiretappingCheckList[language][0]?.errorPercentWithoutSymbols}
                {renderSortIcon('error_percent')}
              </WiretappingTh>
            </WiretappingHeadTr>
          </Thead>
          <tbody>
            {sortedCombinedData?.length > 0 ? (
              <React.Fragment>
                {sortedCombinedData.map(item => (
                  <WiretappingTr key={item.id_employee}>
                    <WiretappingTd>{item.full_name}</WiretappingTd>
                    <WiretappingTd>{item.total_fine_amount} грн</WiretappingTd>
                    <WiretappingTd>{item.order_count}</WiretappingTd>
                    <WiretappingTd>
                      {item.fines_status_id_count_one}
                    </WiretappingTd>
                    <WiretappingTd>
                      {item.fines_status_id_count_two}
                    </WiretappingTd>
                    <WiretappingTd>
                      {item.fines_status_id_count_four}
                    </WiretappingTd>
                    <WiretappingTd>{item.error_percent} %</WiretappingTd>
                  </WiretappingTr>
                ))}
              </React.Fragment>
            ) : (
              <WiretappingTr>
                <WiretappingTd
                  colSpan={13}
                  style={{
                    width: '2000px',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {wiretappingCheckList[language][0]?.notDataOrError}
                </WiretappingTd>
              </WiretappingTr>
            )}
          </tbody>
        </WiretappingTable>
      </TableContainer>
    </Main>
  );
};
