import styled from "@emotion/styled";
import { LoginFormButton } from "../Buttons/Buttons.styled";

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const Table = styled.table`
  margin: 1em 0 0 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const Tr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const HeadTr = styled(Tr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const Td = styled.td`
  width: 127px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color: ffffff;
  position: relative;

  &:last-of-type {
    border-right: none;
  }
`;

export const Th = styled.th`
  width: 105px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;
`;

export const IconButton = styled.button`
  transition: background-color 0.2s;
   display: flex;
   align-items: center;
   justify-content: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #27ae60;
  color: #ffffff;
  font-size: 16px;
  width: 50%;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #48e084;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #48e084;
    padding-right: 0.625rem;
    background-color: green;
    cursor: pointer;
    color: #ffffff;
  }
`;

export const TrashButton = styled(IconButton)`
  background-color: #e74c3c;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #ff6659;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #ff6659;
    padding-right: 0.625rem;
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const IconLinkContainer = styled.div`
display: flex;
width: 100%;
`

export const AddedNewButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalContainer = styled.div`
  min-width: 700px;
  min-height: 240px;
`;

export const AddedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  &:first-of-type {
    margin-top: 20px;
  }
`;

export const Input = styled.input`
appearance: none;
width: 370px;
height: 30px;
padding: 5px;
background-color: #f5f7fa;
border: 2px solid #3098d6;
font-size: 18px;
margin-left: 10px;
border-radius: 10px;

`;

export const SaveButton = styled(LoginFormButton)`
  min-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const DeleteButton = styled(SaveButton)`
  margin-left: 10px;
  background-color: #e74c3c;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;