import React, { useRef, useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import {
  BarChartMainContainer,
  BarChartContainer,
  BarChartTitle,
} from './BarChart.styled';
import { NavContext } from '../Context/NavContext';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

export const BarChart = ({ title, data }) => {
  const chartRef = useRef(null);
  const { isNavVisible } = useContext(NavContext);

  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    setChartData(data);
  }, [data]);

  const options = {
    scales: {
      x: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        callbacks: {
          label: context => {
            const label = context.raw.x;
            const value = context.parsed.y;
            return `${label}: ${value}`;
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 8,
      },
    },
    onHover: (event, elements) => {
      const ctx = chartRef.current?.ctx;

      if (ctx) {
        ctx.clearRect(0, 0, 0, 0);

        if (elements.length) {
          const element = elements[0];
          const x = element?.element?.x;

          const y = element?.element?.y;
          const label = chartData?.labels[element?.index];
          const value = chartData?.datasets[0]?.data[element?.index];

          ctx.save();
          ctx.textAlign = 'center';
          ctx.fillText(`Результат ${value} за дату ${label}`, x, y - 10);
          ctx.restore();
        }
      }
    },
  };

  return (
    <BarChartMainContainer isNavVisible={isNavVisible}>
      <BarChartTitle>{title}</BarChartTitle>
      <BarChartContainer isNavVisible={isNavVisible}>
        <Line ref={chartRef} data={chartData} options={options} />
      </BarChartContainer>
    </BarChartMainContainer>
  );
};
