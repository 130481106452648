import { Modal } from '../../Modal/Modal';
import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { toast } from 'react-toastify';
import {
  ModalContainer,
  Thead,
  TableContainer,
  FinesListTable,
  FinesListTr,
  FinesListHeadTr,
  FinesListTd,
  FinesListTh,
  SaveButton,
  ButtonContainer,
  DeleteFinesListButton,
  AddedFinesListContainer,
  FinesListTextArea,
  AddedFinesListInput,
  FinesNavigationButton,
} from './FinesListManager.styled';
import { useQuery } from 'react-query';
import {
  fetchFinesList,
  createFinesAmount,
  updateFinesAmount,
  deleteFinesAmount,
} from '../../../api/finesList';
import { finesListText } from '../../../services/componentText';

export const FinesListManager = () => {
  const { language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [finesTitleRu, setFinesTitleRu] = useState('');
  const [finesTitleUa, setFinesTitleUa] = useState('');
  const [finesAmount, setFinesAmount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const maxLength = 250;

  const { data, refetch } = useQuery('finesAmount', fetchFinesList);

  const handleTitleRuChange = e => {
    const inputValue = e.target.value;
    if (inputValue?.length <= maxLength) {
      setFinesTitleRu(inputValue);
    }
  };

  const handleTitleUaChange = e => {
    const inputValue = e.target.value;
    if (inputValue?.length <= maxLength) {
      setFinesTitleUa(inputValue);
    }
  };

  const handleSave = async () => {
    const data = {
      fine_title_ru: finesTitleRu,
      fine_title_ua: finesTitleUa,
      fine_amount: finesAmount,
    };

    const response = await createFinesAmount(data);

    if (response?.status === 204) {
      setIsModalOpen(false);
      toast.success(finesListText[language][0]?.successSave);
      refetch();
      resetValue();
    }
  };

  const handleClick = async value => {
    setIsUpdate(true);
    setIsModalOpen(true);
    setFinesTitleRu(value.fine_title_ru);
    setFinesTitleUa(value.fine_title_ua);
    setFinesAmount(value.fine_amount);
    setSelectedId(value.id);
  };

  const resetValue = () => {
    setFinesTitleRu('');
    setFinesTitleUa('');
    setFinesAmount(0);
    setSelectedId('');
  };

  const handleDecline = () => {
    resetValue();
    setIsModalOpen(false);
  };

  const handleCreateNewFines = async () => {
    resetValue();
    setIsModalOpen(true);
    setIsUpdate(false);
  };

  const handleUpdate = async () => {
    const data = {
      id: selectedId,
      fine_title_ru: finesTitleRu,
      fine_title_ua: finesTitleUa,
      fine_amount: finesAmount,
    };

    const response = await updateFinesAmount(data);

    if (response?.status === 204) {
      setIsModalOpen(false);
      toast.success(finesListText[language][0]?.successUpdate);
      refetch();
      resetValue();
      setIsUpdate(false);
    }
  };

  const handleDelete = async () => {
    const response = await deleteFinesAmount(selectedId);

    if (response?.status === 204) {
      setIsModalOpen(false);
      toast.success(finesListText[language][0]?.successRemoved);
      refetch();
      resetValue();
      setIsUpdate(false);
    }
  };

  return (
    <div>
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainer>
            <AddedFinesListContainer>
              <label>{finesListText[language][0]?.enterRuTitle}</label>
              <div>
                <FinesListTextArea
                  type="text"
                  value={finesTitleRu}
                  placeholder={finesListText[language][0]?.enterRuTitle}
                  onChange={handleTitleRuChange}
                  maxLength={maxLength}
                />
                <p>
                  {finesListText[language][0]?.leftSymbols}{' '}
                  {maxLength - finesTitleRu?.length}/{maxLength}
                </p>
              </div>
            </AddedFinesListContainer>
            <AddedFinesListContainer>
              <label>{finesListText[language][0]?.enterUaTitle} </label>
              <div>
                <FinesListTextArea
                  type="text"
                  value={finesTitleUa}
                  placeholder={finesListText[language][0]?.enterUaTitle}
                  onChange={handleTitleUaChange}
                  maxLength={maxLength}
                />
                <p>
                  {finesListText[language][0]?.leftSymbols}{' '}
                  {maxLength - finesTitleUa?.length}/{maxLength}
                </p>
              </div>
            </AddedFinesListContainer>
            <AddedFinesListContainer>
              <label>{finesListText[language][0]?.finesAmount}</label>
              <AddedFinesListInput
                type="number"
                value={finesAmount}
                placeholder={finesListText[language][0]?.finesAmount}
                onChange={e => setFinesAmount(e.target.value)}
              />
            </AddedFinesListContainer>
            {isUpdate ? (
              <ButtonContainer>
                <SaveButton onClick={() => handleUpdate()}>
                  {finesListText[language][0]?.update}
                </SaveButton>
                <DeleteFinesListButton onClick={() => handleDelete()}>
                  {finesListText[language][0]?.delete}
                </DeleteFinesListButton>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <SaveButton onClick={handleSave}>
                  {finesListText[language][0]?.save}
                </SaveButton>
                <DeleteFinesListButton onClick={() => handleDecline()}>
                  {finesListText[language][0]?.cancel}
                </DeleteFinesListButton>
              </ButtonContainer>
            )}
          </ModalContainer>
        </Modal>
      )}
      <FinesNavigationButton onClick={() => handleCreateNewFines()}>
        {finesListText[language][0]?.createNewFines}
      </FinesNavigationButton>
      <TableContainer>
        <FinesListTable>
          <Thead>
            <FinesListHeadTr>
              <FinesListTh>
                {finesListText[language][0]?.finesReason}
              </FinesListTh>
              <FinesListTh>
                {finesListText[language][0]?.fineAmount}
              </FinesListTh>
            </FinesListHeadTr>
          </Thead>
          <tbody>
            {data?.map(item => (
              <FinesListTr key={item.id}  onClick={() => handleClick(item)}>
                {language === 'russian' ? (
                  <FinesListTd >{item?.fine_title_ru}</FinesListTd>
                ) : (
                  <FinesListTd>{item?.fine_title_ua}</FinesListTd>
                )}
                <FinesListTd>{item?.fine_amount} грн</FinesListTd>
              </FinesListTr>
            ))}
          </tbody>
        </FinesListTable>
      </TableContainer>
    </div>
  );
};
