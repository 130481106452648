import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchSalaryByEmail = async () => {
    try {
      const response = await makeRequestSQL.get(`/salary/me`);
      const decryptedData = await  decryptMiddleware(response.data);

      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchAllSalaries = async ({date, sortBy, order }) => {
    try {
      const response = await makeRequestSQL.get(
        date ? `/salary` : "/salary",
        {
          params: {
            sortBy,
            order,
            date,
          },
        }
      );
      
      const decryptedData = await  decryptMiddleware(response.data);

      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

export const onDeleteByDate = async (date) => {
    try {
      const response = await makeRequestSQL.delete(
        `/salary?date=${date}`
      );

      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

export const updateSalary = async (data) => {
    try {
      const response = await makeRequestSQL.put(`/salary`, data);

      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

export const uploadFile = async (data) => {
    try {
      const response = await makeRequestSQL.post("/salary", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };