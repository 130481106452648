import styled from "@emotion/styled";
import { Link } from 'react-router-dom';

export const AddedNewButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border: none;
  background-color: ${({ active }) => (active ? '#0b6aa2' : '#3098d6')};
  border-left: ${({ active }) => (active ? '0.375rem solid #5cbcf6' : 'none')};
  border-right: ${({ active }) => (active ? '0.375rem solid #5cbcf6' : 'none')};
  padding-right: ${({ active }) => (active ? '0.625rem' : '1rem')};
  padding-left: ${({ active }) => (active ? '0.625rem' : '1rem')};
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconButton = styled(Link)`
  transition: background-color 0.2s;
   display: flex;
   align-items: center;
   justify-content: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #27ae60;
  color: #ffffff;
  font-size: 16px;
  width: 50%;
  margin-left: 10px;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #48e084;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #48e084;
    padding-right: 0.625rem;
    background-color: green;
    cursor: pointer;
    color: #ffffff;
  }

`;

export const TrashButton = styled(IconButton)`
  background-color: #e74c3c;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #ff6659;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #ff6659;
    padding-right: 0.625rem;
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const IconLinkContainer = styled.div`
display: flex;
width: 20%;
height: 34px;
`

export const Option = styled.option`
appearance: none;
width: 100%;
height: 10px;
padding: 5px;
margin-bottom: 10px;
background-color: #f5f7fa;
border: 1px solid #000000;
font-size: 16px;

cursor: pointer;
`;

export const Title = styled.h2`
&:hover,
&:focus,
&.active {
  color: #3098d6;
}

cursor: pointer;
`

export const ProductTitle = styled.div`
&:hover,
&:focus,
&.active {
  color: #3098d6;
}

cursor: pointer;
`