export const getBackgroundColorForStatus = status => {
  let color = '';

  switch (status) {
    case 2:
      color = '#27ae60';
      break;
    case 3:
      color = '#e74c3c';
      break;
    case 4:
      color = '#fcb045';
      break;
    case 5:
      color = '#e74c3c';
      break;
    default:
      color = '#fcb045';
  }

  return color;
};
