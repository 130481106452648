import { UserContext } from '../../Context/UserContext';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { productsText } from '../../../services/componentText';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  SaveButton,
  ButtonContainer,
  DeleteButton,
  ProductInput,
  AddedNewButton,
  NavContainer,
} from './ProductsChangeItem.styled';
import { MyEditor } from '../../MyEditor/MyEditor';
import { toast } from 'react-toastify';
import { Main } from '../../Main/Main';
import {
  createProduct,
  updateProduct,
  fetchProduct,
  deleteProduct,
} from '../../../api/product';

export const ProductsChangeItem = () => {
  const { language } = useContext(UserContext);
  const [product, setProduct] = useState({
    id: '',
    title_ua: '',
    title_ru: '',
    description_ua: '',
    description_ru: '',
  });
  const [isPageRussian, setIsPageRussian] = useState(
    language === 'russian' ? true : false,
  );
  const location = useLocation();
  const navigate = useNavigate();

  const description_ru = useRef('');
  const description_ua = useRef('');
  const isCreateNewProduct = location?.pathname === '/products/new-product';

  useEffect(() => {
    const fetchProductData = async () => {
      if (isCreateNewProduct) {
        return;
      }

      const productIdFromPath = location?.pathname?.replace(
        /^\/products\/change-product\//,
        '',
      );

      const response = await fetchProduct({ productId: productIdFromPath });

      if (response?.data?.length > 0) {
        const fetchedProduct = response?.data;

        setProduct({
            ...fetchedProduct[0]
        });
        description_ru.current = fetchedProduct[0]?.description_ru || '';
        description_ua.current = fetchedProduct[0]?.description_ua || '';
      }
    };

    fetchProductData();
  }, [location, isCreateNewProduct]);

  const maxLength = 250;

  const handlePreSubmit = () => {
    const newProduct = {
      id: product?.id || '',
      title_ua: product?.title_ua || '',
      title_ru: product?.title_ru || '',
      description_ua: description_ua.current || '',
      description_ru: description_ru.current || '',
    };

    handleSubmit(newProduct);
  };

  const handleSubmit = async formData => {
    if (isCreateNewProduct) {
      const response = await createProduct(formData);

      if (response?.status === 201) {
        toast.success(productsText[language][0]?.successSave);
        return navigate('/products/product-list');
      }
    }

    if (!isCreateNewProduct) {
      const response = await updateProduct(formData);

      if (response?.status === 201) {
        toast.success(productsText[language][0]?.successUpdate);
        return navigate(`/products/product-list/${product?.id}`);
      }
    }
  };

  const handleCancelClick = () => {
    return navigate('/products/product-list');
  };

  const handleDelete = async id => {
    const response = await deleteProduct(id);

    if (response?.status === 200) {
      toast.success(productsText[language][0]?.successRemoved);
      return navigate('/products/product-list');
    }
  };

  return (
    <div>
      <Main>
        <NavContainer>
          <AddedNewButton
            onClick={() => setIsPageRussian(false)}
            active={!isPageRussian}
            disabled={!isPageRussian}
          >
            {productsText[language][0]?.ukraineVersion}
          </AddedNewButton>
          <AddedNewButton
            onClick={() => setIsPageRussian(true)}
            active={isPageRussian}
            disabled={isPageRussian}
          >
            {productsText[language][0]?.russianVersion}
          </AddedNewButton>
        </NavContainer>

        {isPageRussian && (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ProductInput
                style={{ width: '70%', marginRight: '10px' }}
                value={product?.title_ru}
                onChange={e => {
                  const updatedProduct = {
                    ...product,
                    title_ru: e.target.value,
                  };
                  if (updatedProduct?.title_ru?.length <= maxLength) {
                    setProduct(updatedProduct);
                  }
                }}
                placeholder={productsText[language][0]?.enterProductTitleRU}
                maxLength={maxLength}
                required
              />
              <p style={{ margin: '0' }}>
                {productsText[language][0]?.leftSymbols}{' '}
                {maxLength - product?.title_ru?.length}/{maxLength}
              </p>
            </div>

            <div style={{ width: '100%', minHeight: '300px' }}>
              <MyEditor
                value={description_ru.current || ''}
                onChange={newContent => (description_ru.current = newContent)}
              />
            </div>
          </div>
        )}

        {!isPageRussian && (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ProductInput
                style={{ width: '70%', marginRight: '10px' }}
                value={product?.title_ua}
                onChange={e => {
                  const updatedProduct = {
                    ...product,
                    title_ua: e.target.value,
                  };
                  if (updatedProduct?.title_ua?.length <= maxLength) {
                    setProduct(updatedProduct);
                  }
                }}
                placeholder={productsText[language][0]?.enterProductTitleUA}
                maxLength={maxLength}
                required
              />
              <p style={{ margin: '0' }}>
                {productsText[language][0]?.leftSymbols}{' '}
                {maxLength - product?.title_ua?.length}/{maxLength}
              </p>
            </div>

            <div style={{ width: '100%', minHeight: '300px' }}>
              <MyEditor
                value={description_ua.current || ''}
                onChange={newContent => (description_ua.current = newContent)}
              />
            </div>
          </div>
        )}

        {isCreateNewProduct && (
          <ButtonContainer>
            <SaveButton onClick={() => handlePreSubmit()}>
              {productsText[language][0]?.save}
            </SaveButton>
            <DeleteButton onClick={() => handleCancelClick()}>
              {productsText[language][0]?.cancel}
            </DeleteButton>
          </ButtonContainer>
        )}
        {!isCreateNewProduct && (
          <ButtonContainer>
            <SaveButton onClick={() => handlePreSubmit()}>
              {productsText[language][0]?.update}
            </SaveButton>
            <DeleteButton
              onClick={() => {
                handleDelete(product.id);
              }}
            >
              {productsText[language][0]?.delete}
            </DeleteButton>
          </ButtonContainer>
        )}
      </Main>
    </div>
  );
};
