import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { useContext, useState } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { Outlet } from 'react-router-dom';
import { LinkOnPageNav } from '../../components/OnPageNav/LinkOnPageNav/LinkOnPageNav';
import { OnPageNavContainer } from '../../components/OnPageNav/OnPageNavContainer/OnPageNavContainer';
import { reportsText } from '../../services/componentText';

export const OrderReportPage = () => {
  const { currentUser, language } = useContext(UserContext);

  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const [activeTab, setActiveTab] = useState('reports');

  return (
    <div>
      <SharedLayout />
      {accessLevel && (
        <OnPageNavContainer>
          <LinkOnPageNav
            to="reports"
            onClick={() => setActiveTab('reports')}
            className={activeTab === 'reports' ? 'active' : ''}
          >
            {reportsText[language][0]?.reportData}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="changes"
            onClick={() => setActiveTab('changes')}
            className={activeTab === 'changes' ? 'active' : ''}
          >
            {reportsText[language][0]?.seeChanges}
          </LinkOnPageNav>
        </OnPageNavContainer>
      )}
      <Outlet />
    </div>
  );
};
