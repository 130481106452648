import styled from '@emotion/styled';

export const NavigationBar = styled.div`
  background-color: #3098d6;
  color: #ffffff;
  min-width: 250px;
  width: 250px;
  transition: all 0.2s ease-in-out;
  display: block;
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  margin-left: 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px; /* Ширина вертикальной полосы прокрутки */
  }
  &::-webkit-scrollbar-track {
    background: #3098d6; /* Цвет дорожки (фона) полосы прокрутки */
  }
  &::-webkit-scrollbar-thumb {
    background: #00d3ff; /* Цвет самой полосы прокрутки */
  }
`;


export const TopBarPrimaryContainer = styled.div`
  z-index: 1020;
  position: sticky;
  top: 0;
  background-color: #fff;
  flex: none;
  padding: 0.75em;
  z-index: 1;
  box-shadow: 2px 4px 4px #eef0f3;
  display: flex;
  flex-direction: row;
  height: 2.6 rem;
`;

export const TopBarLogoutContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  border-left: 1px solid #bdbdbd;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  background-color: #ffffff;
  margin-left: auto;
`;

export const TopBarDropDown = styled.div`
  max-width: 100%;
  position: relative;
`;

export const TopBarLogoutButton = styled.button`
  cursor: pointer;
  box-shadow: none;
  width: 50px;
  height: 50px;
  color: #3098d6;
  border: none;
  background-color: inherit;
`;