import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Home } from "./pages/Home/Home";
import { Break } from "./pages/Break/Break";
import { Schedule } from "./pages/Schedule/Schedule";
import { Users } from "./pages/Users/Users";
import LoginPage from "./pages/Login/Login";
import { CreateUser } from "./components//Users/CreateUser/CreateUser";
import { UserList } from "./components//Users/UserList/UserList";
import { UserContext } from "./components/Context/UserContext";
import { useContext } from "react";
import { CurrentUser } from "./pages/Users/CurrentUser.jsx";
import { ScheduleMe } from "./components/Schedule/ScheduleMe/ScheduleMe";
import { NumberOfWorkingHours } from "./components/Schedule/NumberOfWorkingHours/NumberOfWorkingHours";
import { HourlySchedule } from "./components/Schedule/HourlySchedule/HourlySchedule";
import { GeneralSchedule } from "./components/Schedule/GeneralSchedule/GeneralSchedule";
import { HoursStatisic } from "./components/Schedule/HoursStatistic/HoursStatistic.jsx";
import { Salary } from "./pages/Salary/Salary";
import { ForceMajeure } from "./pages/ForceMajeure/ForceMajeure";
import { Fines } from "./pages/Fines/Fines";
import { Product } from "./pages/Product/Product.jsx";
import { DutySchedule } from "./components/Schedule/DutySchedule/DutySchedule.jsx";
import { ProductAvailability } from "./pages/ProductAvailability/ProductAvailability";
import { FindFail } from "./pages/FindFail/FindFail.jsx";
import { EverydayStatistics } from "./pages/EverydayStatistics/EverydayStatistics.jsx";
import { AllEmployeesEverydayStatistics } from "./components/EverydayStatistics/AllEmployeesEverydayStatistics/AllEmployeesEverydayStatistics.jsx";
import { OneEmployeeEverydayStatistics } from "./components/EverydayStatistics/UserEverydayStatistics/OneEmployeeEverydayStatistics.jsx";
import { SeeChangesEverydayStatistics } from "./components/EverydayStatistics/SeeChangesEverydayStatistics/SeeChangesEverydayStatistics.jsx";
import { Rating } from "./components/EverydayStatistics/Rating/Rating.jsx";
import { FinesList } from "./pages/FinesList/FinesList.jsx";
import { CallReportPage } from "./pages/Reports/CallReportPage.jsx";
import { SeeChangesCallReport } from "./components/Reports/CallReport/SeeChangesCallReport.jsx";
import { CallReport } from "./components/Reports/CallReport/CallReport.jsx";
import { AllDepartmentsReport } from "./pages/Reports/AllDepartmentsReport.jsx";
import { SeeChangesAllDepartments } from "./components/Reports/AllDepartments/SeeChangesAllDepartments.jsx";
import { AllDepartments } from "./components/Reports/AllDepartments/AllDepartments.jsx";
import { EmployeeReportPage } from "./pages/Reports/EmployeeReportPage.jsx";
import { SeeChangesEmployeeReport } from "./components/Reports/EmployeeReport/SeeChangesEmployeeReport.jsx";
import { EmployeeReport } from "./components/Reports/EmployeeReport/EmployeeReport.jsx";
import { EmployeesEfficiencyPage } from "./pages/Reports/EmployeesEfficiencyPage.jsx";
import { SeeEmployeeChangesEmployeesEfficiency } from "./components/Reports/EmployeesEfficiency/SeeEmployeeChangesEmployeesEfficiency.jsx";
import { SeeOfferChangesEmployeesEfficiency } from "./components/Reports/EmployeesEfficiency/SeeOfferChangesEmployeesEfficiency.jsx";
import { EmployeesEfficiency } from "./components/Reports/EmployeesEfficiency/EmployeesEfficiency.jsx";
import { OrderReportPage } from "./pages/Reports/OrderReportPage.jsx";
import { SeeChangesOrderReport } from "./components/Reports/OrderReport/SeeChangesOrderReport.jsx";
import { OrderReport } from "./components/Reports/OrderReport/OrderReport.jsx";
import { TotalCountByEmployeeReport } from "./components/Reports/EmployeeReport/TotalCountByEmployeeReport.jsx";
import { Wiretapping } from "./pages/Wiretapping/Wiretapping.jsx";
import { CheckList } from "./components/Wiretapping/CheckList/CheckList.jsx";
import { WiretappingStatistics } from "./pages/WiretappingStatistics/WiretappingStatistics.jsx";
import { WiretappingAllStatisticsWithoutJSON } from "./components/Wiretapping/WiretappingAllStatisticsWithoutJSON/WiretappingAllStatisticsWithoutJSON.jsx";
import { WiretappingFinesStatistics } from "./components/Wiretapping/WiretappingAllStatisticsWithoutJSON/WiretappingFinesStatistics.jsx";
import { WiretappingEverydayStatistics } from "./components/Wiretapping/WiretappingEverydayStatistics/WiretappingEverydayStatistics.jsx";
import { SeeChangesWiretappingEverydayStatistics } from "./components/Wiretapping/WiretappingEverydayStatistics/SeeChangesWiretappingEverydayStatistics.jsx";
import { WiretappingCategoryStatistics } from "./components/Wiretapping/WiretappingCategoryStatistics/WiretappingCategoryStatistics.jsx";
import { ProductsChangeItem } from "./components/Products/ProductsModalItem/ProductsChangeItem.jsx";
import { ProductList } from "./components/Products/ProductsList/ProductsList.jsx";
import { ProductDetails } from "./components/Products/ProductDetails/ProductDetails.jsx";
import { CheckListItem } from "./components/Wiretapping/CheckList/CheckListItem.jsx";
import { StatisticsFM } from "./components/FM/FMstatistics/StatisticsFM.jsx";
import { ClaimsControlPanel } from "./pages/ClaimsControlPanel/ClaimsControlPanel.jsx";
import { ClaimsCategory } from "./components/ClaimsControlPanel/ClaimsCategory/ClaimsCategory.jsx";
import { ClaimsStatus } from "./components/ClaimsControlPanel/ClaimsStatus/ClaimsStatus.jsx";
import { ClientConfigured } from "./components/ClaimsControlPanel/ClientConfigured/ClientConfigured.jsx";
import { RefundStatus } from "./components/ClaimsControlPanel/RefundStatus/RefundStatus.jsx";
import { WarehouseCity } from "./components/ClaimsControlPanel/WarehouseCity/WarehouseCity.jsx";
import { Claims } from "./pages/Claims/Claims.jsx";
import { ClaimsItem } from "./components/Claims/ClaimsItem/ClaimsItem.jsx";
import { ClaimsList } from "./components/Claims/ClaimsList/ClaimsList.jsx";
import { ReasonReturn } from "./components/ClaimsControlPanel/ReasonReturn/ReasonReturn.jsx";
import { ClaimsReport } from "./pages/ClaimsReport/ClaimsReport.jsx";
import { ClaimsReportItem } from "./components/ClaimsReport/ClaimsReportItem/ClaimsReportItem.jsx";
import { ClaimsReportList } from "./components/ClaimsReport/ClaimsReportList/ClaimsReportList.jsx";
import { SeeChangesClaimsReport } from "./components/ClaimsReport/SeeChangesClaimsReport/SeeChangesClaimsReport.jsx";
import { ClaimStatistics } from "./pages/ClaimStatistics/ClaimStatistics.jsx";
import { CategoryStatistics } from "./components/ClaimStatistics/CategoryStatistics.jsx";
import { ReasonReturnStatistics } from "./components/ClaimStatistics/ReasonReturnStatistics.jsx";
import { RefundStatusStatistics } from "./components/ClaimStatistics/RefundStatusStatistics.jsx";
import { WarehouseCityStatistic } from "./components/ClaimStatistics/WarehouseCityStatistic.jsx";
import { VerificationStatusStatistic } from "./components/ClaimStatistics/VerificationStatusStatistic.jsx";
import { ClaimStatusStatistic } from "./components/ClaimStatistics/ClaimStatusStatistic.jsx";
import { ClientConfiguredStatistic } from "./components/ClaimStatistics/ClientConfiguredStatistic.jsx";
import { ProductStatistic } from "./components/ClaimStatistics/ProductStatistic.jsx";
import { NotFound } from "./pages/NotFound/NotFound.jsx";
import { NotFoundWithoutMenu } from "./pages/NotFound/NotFoundWithoutMenu.jsx";
import { KnowledgeBase } from "./pages/KnowledgeBase/KnowledgeBase.jsx";
import { KnowledgeBaseDetails } from "./components/KnowledgeBase/KnowledgeBaseDetails/KnowledgeBaseDetails.jsx";
import { KnowledgeBaseItem } from "./components/KnowledgeBase/KnowledgeBaseItem/KnowledgeBaseItem.jsx";
import { KnowledgeBaseList } from "./components/KnowledgeBase/KnowledgeBaseList/KnowledgeBaseList.jsx";
import { Script } from "./pages/Script/Script.jsx";
import { ScriptsTemplate } from "./pages/ScriptsTemplate/ScriptsTemplate.jsx";
import { ScriptTemplateItem } from "./components/ScriptTemplate/ScriptTemplateItem/ScriptTemplateItem.jsx";
import { ScriptTemplateList } from "./components/ScriptTemplate/ScriptTemplateList/ScriptTemplateList.jsx";
import { ScriptItem } from "./components/Script/ScriptItem/ScriptItem.jsx";
import { ScriptList } from "./components/Script/ScriptList/ScriptList.jsx";
import { ScriptDetails } from "./components/Script/ScriptDetails/ScriptDetails.jsx";
import { DisplayScriptCategory } from "./components/ScriptTemplate/ScriptCategory/DisplayScriptCategory.jsx";
import { OnlineUsers } from "./pages/OnlineUsers/OnlineUsers.jsx";
import { RatingByPeriod } from "./components/EverydayStatistics/Rating/RatingByPeriod.jsx";

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
const access_level_a = Number(process.env.REACT_APP_A_LEVEL_ACCESS);
const access_level_k = Number(process.env.REACT_APP_K_LEVEL_ACCESS);
const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);
const access_level_h = Number(process.env.REACT_APP_H_LEVEL_ACCESS);
const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);
const access_level_j = Number(process.env.REACT_APP_J_LEVEL_ACCESS);

function App() {
  const { currentUser, isUserLoading } = useContext(UserContext);

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const ProtectedRoute = ({ children }) => {
    if (
      (!currentUser && !isUserLoading) ||
      (currentUser?.isBlocked && !isUserLoading)
    ) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
    },
    // {
    //   path: "/break",
    //   element:
    //     accessLevel || currentUser?.access_level === access_level_c ? (
    //       <ProtectedRoute>
    //         <Break />
    //       </ProtectedRoute>
    //     ) : (
    //       <ProtectedRoute>
    //         <NotFoundWithoutMenu />
    //       </ProtectedRoute>
    //     ),
    // },
    {
      path: "/schedule",
      element:
        accessLevel ||
        currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_d ||
        currentUser?.access_level === access_level_i ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <Schedule />
          </ProtectedRoute>
        ) : (
          <ProtectedRoute>
            <NotFound />
          </ProtectedRoute>
        ),
      children: [
        { path: "me", element: <ScheduleMe /> },
        {
          path: "number-of-working-hours",
          element: accessLevel ? (
            <NumberOfWorkingHours />
          ) : (
            <NotFoundWithoutMenu />
          ),
        },
        {
          path: "hourly",
          element: accessLevel ? <HourlySchedule /> : <NotFoundWithoutMenu />,
        },
        {
          path: "genereal",
          element: accessLevel ? <GeneralSchedule /> : <NotFoundWithoutMenu />,
        },
        {
          path: "duty",
          element:
            accessLevel || currentUser?.access_level === access_level_c  ? (
              <DutySchedule />
            ) : (
              <NotFoundWithoutMenu />
            ),
        },
        {
          path: "hours-statistic",
          element: accessLevel ? <HoursStatisic /> : <NotFoundWithoutMenu />,
        },
      ],
    },
    {
      path: "/users",
      element:
        accessLevel ||
        currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_d ||
        currentUser?.access_level === access_level_i || currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
      children: [
        {
          path: "users-list",
          element: accessLevel ? <UserList /> : <NotFoundWithoutMenu />,
        },
        {
          path: "create-user",
          element: accessLevel ? <CreateUser /> : <NotFoundWithoutMenu />,
        },
        {
          path: "current-user",
          element:
            accessLevel ||
            currentUser?.access_level === access_level_c ||
            currentUser?.access_level === access_level_d ||
            currentUser?.access_level === access_level_i || currentUser?.access_level === access_level_k ? (
              <CurrentUser />
            ) : (
              <NotFoundWithoutMenu />
            ),
        },
      ],
    },
    {
      path: "/users-online",
      element:
        accessLevel? (
          <ProtectedRoute>
            <OnlineUsers />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },
    {
      path: "/salary",
      element:
        accessLevel || currentUser?.access_level === access_level_c ? (
          <ProtectedRoute>
            <Salary />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },
    {
      path: "/everyday-statistics",
      element:
        accessLevel || currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <EverydayStatistics />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
      children: [
        {
          path: "me",
          element: <OneEmployeeEverydayStatistics />,
        },
        {
          path: "users",
          element: <AllEmployeesEverydayStatistics />,
        },
        {
          path: "changes",
          element: <SeeChangesEverydayStatistics />,
        },
      ],
    },
    {
      path: "/rating",
      element:
        accessLevel ||
        currentUser?.access_level === access_level_c  ? (
          <ProtectedRoute>
            <Rating />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },
    {
      path: "/rating-by-period",
      element:
        accessLevel ||
        currentUser?.access_level === access_level_c  ? (
          <ProtectedRoute>
            <RatingByPeriod />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },
    {
      path: "/fines-list",
      element:
        accessLevel ||
        currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_d ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <FinesList />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },
    {
      path: "/fm",
      element:
        accessLevel || currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <ForceMajeure />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },
    {
      path: "/fm/statistics",
      element: accessLevel ? (
        <ProtectedRoute>
          <StatisticsFM />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
    },
    {
      path: "/find-fail",
      element:
        accessLevel || currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <FindFail />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },
    {
      path: "/fines",
      element:
        accessLevel || currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <Fines />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },
    {
      path: "/products",
      element:
        accessLevel || currentUser?.access_level === access_level_c || currentUser?.access_level === access_level_a || currentUser?.access_level === access_level_j || currentUser?.access_level === access_level_i ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <Product />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
      children: [
        {
          path: "new-product",
          element: accessLevel || currentUser?.access_level === access_level_a? (
            <ProtectedRoute>
              <ProductsChangeItem />
            </ProtectedRoute>
          ) : (
            <NotFoundWithoutMenu />
          ),
        },
        {
          path: "change-product/:productId",
          element: accessLevel || currentUser?.access_level === access_level_a ? (
            <ProtectedRoute>
              <ProductsChangeItem />
            </ProtectedRoute>
          ) : (
            <NotFoundWithoutMenu />
          ),
        },
        {
          path: "product-list",
          element: (
            <ProtectedRoute>
              <ProductList />
            </ProtectedRoute>
          ),
        },
        {
          path: "product-list/:productId",
          element: (
            <ProtectedRoute>
              <ProductDetails />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/knowledge-base",
      element: (accessLevel || currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_k) && ![86, 94, 127, 128]?.includes(Number(currentUser?.id_employee)) ? (
        <ProtectedRoute>
          <KnowledgeBase />
        </ProtectedRoute>
      ) : (
          <ProtectedRoute>
        <NotFound />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "list",
          element:
            (accessLevel || currentUser?.access_level === access_level_c ||
              currentUser?.access_level === access_level_k) ? (
              <ProtectedRoute>
                <KnowledgeBaseList />
              </ProtectedRoute>
            ) : (
              <ProtectedRoute>
              <NotFound />
              </ProtectedRoute>
            ),
        },
        {
          path: "list/:id",
          element: (accessLevel || currentUser?.access_level === access_level_c ||
            currentUser?.access_level === access_level_k) ? (
            <ProtectedRoute>
              <KnowledgeBaseDetails />
            </ProtectedRoute>
          )  : (
            <ProtectedRoute>
            <NotFound />
            </ProtectedRoute>
          ),
        },
        {
          path: "new",
          element: accessLevel  ? (
            <ProtectedRoute>
              <KnowledgeBaseItem />
            </ProtectedRoute>
          ) : (
            <ProtectedRoute>
            <NotFound />
            </ProtectedRoute>
          ),
        },
        {
          path: "change/:id",
          element: accessLevel ? (
            <ProtectedRoute>
              <KnowledgeBaseItem />
            </ProtectedRoute>
          ) : (
            <ProtectedRoute>
            <NotFound />
            </ProtectedRoute>
          ),
        },
      ],
    },

    {
      path: "/products-availability",
      element:
        accessLevel ||
        currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_d ||
        currentUser?.access_level === access_level_a ||
        currentUser?.access_level === access_level_h ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <ProductAvailability />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
    },

    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/wiretapping",
      element:
        accessLevel || currentUser?.access_level === access_level_d ? (
          <ProtectedRoute>
            <Wiretapping />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
      children: [
        {
          path: "new-check-list",
          element: (
            <ProtectedRoute>
              <CheckListItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "change-check-list/:Id",
          element: (
            <ProtectedRoute>
              <CheckListItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "check-list",
          element: (
            <ProtectedRoute>
              <CheckList />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/claims",
      element:
        accessLevel ||
        currentUser?.access_level === access_level_a ||
        currentUser?.access_level === access_level_i ? (
          <ProtectedRoute>
            <Claims />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
      children: [
        {
          path: "new-claim",
          element: (
            <ProtectedRoute>
              <ClaimsItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "change-claim/:Id",
          element: (
            <ProtectedRoute>
              <ClaimsItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "claim-list",
          element: (
            <ProtectedRoute>
              <ClaimsList />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/script",
      element:
        accessLevel ||
        currentUser?.access_level === access_level_c || currentUser?.access_level === access_level_j || currentUser?.access_level === access_level_i ||
        currentUser?.access_level === access_level_k ? (
          <ProtectedRoute>
            <Script />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
      children: [
        {
          path: "new",
          element: accessLevel ?(
            <ProtectedRoute>
              <ScriptItem />
            </ProtectedRoute>
          ) : (
            <NotFoundWithoutMenu />
          ),
        },
        {
          path: "change/:Id",
          element: accessLevel ? (
            <ProtectedRoute>
              <ScriptItem />
            </ProtectedRoute>
          ) : (
            <NotFoundWithoutMenu />
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedRoute>
              <ScriptList />
            </ProtectedRoute>
          ),
        },
        {
          path: "list/:id",
          element: (
            <ProtectedRoute>
              <ScriptDetails />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/script-control-panel",
      element: accessLevel ? (
        <ProtectedRoute>
          <ScriptsTemplate />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
      children: [
        {
          path: "new",
          element: (
            <ProtectedRoute>
              <ScriptTemplateItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "change/:id",
          element: (
            <ProtectedRoute>
              <ScriptTemplateItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "change-category",
          element: (
            <ProtectedRoute>
              <DisplayScriptCategory />
            </ProtectedRoute>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedRoute>
              <ScriptTemplateList />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/claims-report",
      element:
        accessLevel || currentUser?.access_level === access_level_i ? (
          <ProtectedRoute>
            <ClaimsReport />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
      children: [
        {
          path: "new-report",
          element: (
            <ProtectedRoute>
              <ClaimsReportItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "change-report/:Id",
          element: (
            <ProtectedRoute>
              <ClaimsReportItem />
            </ProtectedRoute>
          ),
        },
        {
          path: "report-list",
          element: (
            <ProtectedRoute>
              <ClaimsReportList />
            </ProtectedRoute>
          ),
        },
        {
          path: "see-changes",
          element: (
            <ProtectedRoute>
              <SeeChangesClaimsReport />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/claims-control-panel",
      element: accessLevel ? (
        <ProtectedRoute>
          <ClaimsControlPanel />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
      children: [
        {
          path: "category",
          element: (
            <ProtectedRoute>
              <ClaimsCategory />
            </ProtectedRoute>
          ),
        },
        {
          path: "reason-return",
          element: (
            <ProtectedRoute>
              <ReasonReturn />
            </ProtectedRoute>
          ),
        },
        {
          path: "refund-status",
          element: (
            <ProtectedRoute>
              <RefundStatus />
            </ProtectedRoute>
          ),
        },
        {
          path: "warehouse-city",
          element: (
            <ProtectedRoute>
              <WarehouseCity />
            </ProtectedRoute>
          ),
        },
        {
          path: "status",
          element: (
            <ProtectedRoute>
              <ClaimsStatus />
            </ProtectedRoute>
          ),
        },
        {
          path: "client-configured",
          element: (
            <ProtectedRoute>
              <ClientConfigured />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/claim-statistics",
      element: accessLevel ? (
        <ProtectedRoute>
          <ClaimStatistics />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
      children: [
        {
          path: "category",
          element: (
            <ProtectedRoute>
              <CategoryStatistics />
            </ProtectedRoute>
          ),
        },
        {
          path: "reason-return",
          element: (
            <ProtectedRoute>
              <ReasonReturnStatistics />
            </ProtectedRoute>
          ),
        },
        {
          path: "refund-status",
          element: (
            <ProtectedRoute>
              <RefundStatusStatistics />
            </ProtectedRoute>
          ),
        },
        {
          path: "warehouse-city",
          element: (
            <ProtectedRoute>
              <WarehouseCityStatistic />
            </ProtectedRoute>
          ),
        },
        {
          path: "verification-status",
          element: (
            <ProtectedRoute>
              <VerificationStatusStatistic />
            </ProtectedRoute>
          ),
        },
        {
          path: "status",
          element: (
            <ProtectedRoute>
              <ClaimStatusStatistic />
            </ProtectedRoute>
          ),
        },
        {
          path: "client-configured",
          element: (
            <ProtectedRoute>
              <ClientConfiguredStatistic />
            </ProtectedRoute>
          ),
        },
        {
          path: "product",
          element: (
            <ProtectedRoute>
              <ProductStatistic />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/wiretapping-statistics/",
      element:
        accessLevel || currentUser?.access_level === access_level_d ? (
          <ProtectedRoute>
            <WiretappingStatistics />
          </ProtectedRoute>
        ) : (
          <NotFound />
        ),
      children: [
        {
          path: "all",
          element: accessLevel ? (
            <WiretappingAllStatisticsWithoutJSON />
          ) : (
            <NotFoundWithoutMenu />
          ),
        },
        {
          path: "fines",
          element: <WiretappingFinesStatistics />,
        },
        {
          path: "category-statistics",
          element: accessLevel ? (
            <WiretappingCategoryStatistics />
          ) : (
            <NotFoundWithoutMenu />
          ),
        },
        {
          path: "everyday-statistics",
          element: <WiretappingEverydayStatistics />,
        },
        {
          path: "changes",
          element: <SeeChangesWiretappingEverydayStatistics />,
        },
      ],
    },
    {
      path: "/call-report",
      element: accessLevel ? (
        <ProtectedRoute>
          <CallReportPage />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
      children: [
        {
          path: "reports",
          element: <CallReport />,
        },
        {
          path: "changes",
          element: <SeeChangesCallReport />,
        },
      ],
    },
    {
      path: "/all-departments-report",
      element: accessLevel ? (
        <ProtectedRoute>
          <AllDepartmentsReport />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
      children: [
        {
          path: "reports",
          element: <AllDepartments />,
        },
        {
          path: "changes",
          element: <SeeChangesAllDepartments />,
        },
      ],
    },
    {
      path: "/employee-report",
      element: accessLevel ? (
        <ProtectedRoute>
          <EmployeeReportPage />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
      children: [
        {
          path: "reports",
          element: <EmployeeReport />,
        },
        {
          path: "changes",
          element: <SeeChangesEmployeeReport />,
        },
        {
          path: "total-count",
          element: <TotalCountByEmployeeReport />,
        },
      ],
    },
    {
      path: "/employee-efficiency-report",
      element: accessLevel ? (
        <ProtectedRoute>
          <EmployeesEfficiencyPage />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
      children: [
        {
          path: "reports",
          element: <EmployeesEfficiency />,
        },
        {
          path: "employee-changes",
          element: <SeeEmployeeChangesEmployeesEfficiency />,
        },
        {
          path: "offer-changes",
          element: <SeeOfferChangesEmployeesEfficiency />,
        },
      ],
    },
    {
      path: "/order-report",
      element: accessLevel ? (
        <ProtectedRoute>
          <OrderReportPage />
        </ProtectedRoute>
      ) : (
        <NotFound />
      ),
      children: [
        {
          path: "reports",
          element: <OrderReport />,
        },
        {
          path: "changes",
          element: <SeeChangesOrderReport />,
        },
      ],
    },
    {
      path: "*",
      element: (
        <ProtectedRoute>
          <NotFound />
        </ProtectedRoute>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
