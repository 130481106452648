import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Outlet } from 'react-router-dom';

export const Wiretapping = () => {
  return (
    <div>
      <SharedLayout />
      <Outlet />
    </div>
  );
};
