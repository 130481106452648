import { Main } from "../../components/Main/Main";
import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import { useContext } from "react";
import { UserContext } from "../../components/Context/UserContext";
import { FinesListManager } from "../../components/FinesList/FinesListManager/FinesListManager";
import { FinesListUsers } from "../../components/FinesList/FinesListUsers/FinesListUsers";
import { Outlet } from "react-router-dom";

export const FinesList = () => {
  const { currentUser } = useContext(UserContext);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
  const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);
  const access_level_k = Number(process.env.REACT_APP_K_LEVEL_ACCESS);

  const primaryAccess =
    currentUser?.access_level === access_level_e ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_g;
  const secondaryAccess =
    currentUser?.access_level === access_level_c ||
    currentUser?.access_level === access_level_d ||
    currentUser?.access_level === access_level_k;

  return (
    <div>
      <SharedLayout />
      <Main>
        {primaryAccess && <FinesListManager />}
        {secondaryAccess && <FinesListUsers />}
      </Main>
      <Outlet />
    </div>
  );
};
