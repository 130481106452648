import { Main } from "../../components/Main/Main";
import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import { useContext } from "react";
import { UserContext } from "../../components/Context/UserContext";
import { ManagerFM } from "../../components/FM/ManagerFM/ManagerFM";
import { UserFM } from "../../components/FM/UserFM/UserFM";
import { Outlet } from "react-router-dom";

export const ForceMajeure = () => {
  const { currentUser } = useContext(UserContext);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
  const access_level_k = Number(process.env.REACT_APP_K_LEVEL_ACCESS);

  return (
    <div>
      <SharedLayout />
      <Main>
        {(currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g) && <ManagerFM />}
        {(currentUser?.access_level === access_level_c ||
          currentUser?.access_level === access_level_k) && <UserFM />}
      </Main>
      <Outlet />
    </div>
  );
};
