export const getGradientColorForApprove = percentage => {
  let red, green, blue;

  // 0-60%: Червоний до Помаранчевого
  if (percentage <= 60) {
    red = 255;
    green = Math.round(50 * (percentage / 60));
    blue = 0;
  }
  // 60%-70%: Помаранчевий до Жовтого
  else if (percentage <= 74) {
    red = 255;
    green = Math.round(165 + 90 * ((percentage - 60) / 14));
    blue = 0;
  }
  else {
    percentage = Math.min(percentage, 100) - 90;
    red = 0;
    green = Math.round(255 * (100 - percentage));
    blue = Math.round(255 * percentage);
  }

  return `rgb(${red}, ${green}, ${blue})`;
};

export const getGradientColorForOtherCategorie = percentage => {
  let red, green, blue;

  if (percentage === 0) {
    red = 0;
    green = 235;
    blue = 235;
  }

  // 0-5%: green
  if (percentage > 0 && percentage <= 5) {
    red = Math.round(255 * (percentage / 5));
    green = 255;
    blue = 0;
  }
  // 5%-10%: yellow
  else if (percentage > 0 && percentage <= 10) {
    red = 255;
    green = Math.round(255 * (100 - (percentage - 5) / 5));
    blue = 0;
  }
  // 10%-15%: orange
  else if (percentage > 0 && percentage <= 15) {
    red = 255;
    green = Math.round(165 + 90 * ((percentage - 10) / 5));
    blue = 0;
  }
  // 15%-100%: red
  else if (percentage > 0 && percentage <= 100) {
    red = Math.round(255 * (100 - (percentage - 15) / 85));
    green = 20;
    blue = 0;
  }

  return `rgb(${red}, ${green}, ${blue})`;
};

export const getGradientColorForNettoIncome = value => {
  let red, green, blue;

  // 0-60%: Червоний до Помаранчевого
  if (value <= 180) {
    red = 255;
    green = Math.round(50 * (value / 180));
    blue = 0;
  }
  // 60%-70%: Помаранчевий до Жовтого
  else if (value <= 200) {
    red = 255;
    green = Math.round(165 + 90 * ((value - 180) / 20));
    blue = 0;
  }
  // 70%-100%: Жовтий до Зеленого
 else if (value <= 300) {
    red = Math.round(30 + 30 * ((value - 200) / 100));
    green = 255;
    blue = 0;
  } else {
    value = Math.min(value, 500) - 300;
    red = 0;
    green = Math.round(255 * (500 - value));
    blue = Math.round(255 * value);
  }

  return `rgb(${red}, ${green}, ${blue})`;
};

export const getGradientColorForAdditionalApprovalPercentage = percentage => {
  let red, green, blue;

  // 0-60%: Червоний до Помаранчевого
  if (percentage <= 100) {
    red = 255;
    green = Math.round(50 * (percentage / 100));
    blue = 0;
  }
  // 60%-70%: Помаранчевий до Жовтого
  else if (percentage <= 120) {
    red = 255;
    green = Math.round(165 + 90 * ((percentage - 100) / 20));
    blue = 0;
  }
  // 70%-100%: Жовтий до Зеленого
  else if (percentage <= 180) {
    red = Math.round(30 + 30 * ((percentage - 120) / 60));
    green = 255;
    blue = 0;
  } else {
    percentage = Math.min(percentage, 250) - 180;
    red = 0;
    green = Math.round(255 * (250 - percentage));
    blue = Math.round(255 * percentage);
  }

  return `rgb(${red}, ${green}, ${blue})`;
};