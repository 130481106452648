import styled from '@emotion/styled';

export const MainContainer = styled.main`
  background-color: #ffffff;
  margin-left: auto;
  padding: 30px;
  transform: ${props =>
    props.isNavVisible ? 'translateX(0%)' : 'translateX(-250px)'};
  transition: transform 0.3s;
  width: ${props =>
    props.isNavVisible ? 'calc(100% - 310px)' : 'calc(110% - 310px)'};
  margin-left: ${props => (props.isNavVisible ? 'auto' : '250px')};
`;
