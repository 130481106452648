import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';

export const AddedFMSelect = styled.select`
  appearance: none;
  width: 330px;
  height: 30px;
  padding: 5px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 16px;
  margin-left: 10px;

  cursor: pointer;
`;

export const AddedFMOption = styled.option`
  appearance: none;
  width: 100%;
  height: 10px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 16px;

  cursor: pointer;
`;

export const AddedFMContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SaveButton = styled(LoginFormButton)`
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const AddedNewFMButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;
