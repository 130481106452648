import { Main } from '../../Main/Main';
import {
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  MonthButton,
  ScheduleMeNavContainer,
  DataSelect,
  DataOption,
  Thead,
  TableContainer,
  ChooseDataContainer,
  CopyNavContainer,
  EmployeeSelect,
} from './NumberOfWorkingHours.styled';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { calculateHoursDifference } from '../../../helpers/calculate/calculateHoursDifference';
import { useQuery } from 'react-query';
import { weekDays, scheduleText } from '../../../services/componentText';
import { formatToTime } from '../../../helpers/dateAndTime/formatToTime';
import { getColorByHoursWeek } from '../../../helpers/gradient/getColorByHoursWeek';
import { getColorByHoursMonth } from '../../../helpers/gradient/getColorByHoursMonth';
import { getWeekRanges } from '../../../helpers/dateAndTime/getWeekRanges';
import { fetchAllSchedule, fetchInformations } from '../../../api/schedule';
import { fetchUsers } from '../../../api/users';

export const NumberOfWorkingHours = () => {
  const { language } = useContext(UserContext);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1)?.getDay();
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(1);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [filteredDays, setFilteredDays] = useState(daysInMonth);
  const [allJobTitles, setAllJobTitles] = useState(null);
  const [selectedJobTitles, setSelectedJobTitles] = useState(3);
  let combinedData = null;

  const fetchAllSchedules = async () => {
    try {
      const response = await fetchAllSchedule({
          job_titles: selectedJobTitles,
          year: currentYear,
          month: currentMonth,
          id_status: 2,
      });

      const formattedDataByEmployee = {};

      for (const [employeeId, employeeData] of Object.entries(
        response || {},
      )) {
        const formattedData = employeeData?.schedules?.reduce((acc, item) => {
          const date = new Date(item.day);
          const dayNumber = date.getDate();

          if (!acc[dayNumber]) {
            acc[dayNumber] = {
              timeSlots: [],
            };
          }

          if (item.start_time !== null && item.end_time !== null) {
            acc[dayNumber].timeSlots.push({
              start: item.start_time?.slice(0, 5),
              end: item.end_time?.slice(0, 5),
            });
          }
          return acc;
        }, {});

        formattedDataByEmployee[employeeId] = {
          schedules: formattedData,
          name: employeeData?.employeeName,
          group:
            language === 'russian'
              ? employeeData?.employeeGroup?.groupe_name_RU
              : employeeData?.employeeGroup?.groupe_name_UA,
        };
      }

      return formattedDataByEmployee;
    } catch (error) {
      combinedData = null;
    }
  };

  const { data: users } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "id_employee", order: "ASC", isBlocked: "0" })
  );

  const { data: informationData } = useQuery(
    'informationData',
    fetchInformations,
  );

  const { data: schedule, refetch: refetchSchedule } = useQuery(
    ['scheduleData', currentYear, currentMonth],
    () => fetchAllSchedules(currentYear, currentMonth),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (informationData) {
      setAllJobTitles(informationData.jobTitles);
    }
  }, [informationData]);

  useEffect(() => {
    const days = new Date(currentYear, currentMonth, 0).getDate();
    const daysArray = [...Array(days)?.keys()]?.map(day => day + 1);
    setDaysInMonth(daysArray);
    setFilteredDays(daysArray);

    refetchSchedule();
  }, [currentMonth, currentYear, refetchSchedule, selectedJobTitles]);

  combinedData = { ...schedule };

  users?.usersData?.forEach(user => {
    if (user.id_job_title !== selectedJobTitles) {
      return;
    }

    if (!combinedData[user.id_employee] && user.isBlocked !== 1) {
      combinedData[user.id_employee] = {
        name: user.full_name,
        group:
          language === 'russian' ? user.groupe_name_ru : user.groupe_name_ua,
        schedules: {},
      };
    }
  });

  const changeMonth = direction => {
    if (direction === 1 && currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(prev => prev + 1);
    } else if (direction === -1 && currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(prev => prev - 1);
    } else {
      setCurrentMonth(prev => prev + direction);
    }
  };

  const handleApplyCustomSchedule = () => {
    if (selectedStartDate && selectedEndDate) {
      const newFilteredDays = daysInMonth.filter(
        day => day >= selectedStartDate && day <= selectedEndDate,
      );
      setFilteredDays(newFilteredDays);
    } else {
      setFilteredDays(daysInMonth);
    }
  };

  const weekRanges = getWeekRanges(currentYear, currentMonth);

  const getCurrentWeekRange = day => {
    return weekRanges.find(range => day >= range.start && day <= range.end);
  };

  const isFirstWeekLastDay = (year, month, day) => {
    const firstWeek = getWeekRanges(year, month)[0];
    return firstWeek && day === firstWeek.end;
  };

  const calculateHoursForWeek = (employeeDetails, day, weekRange) => {
    let hoursForTheWeek = 0;
    if (
      new Date(currentYear, currentMonth - 1, day).getDay() === 0 ||
      isFirstWeekLastDay(currentYear, currentMonth, day)
    ) {
      for (let i = weekRange?.start; i <= weekRange?.end; i++) {
        const schedule = employeeDetails.schedules[i];
        if (schedule && schedule.timeSlots) {
          hoursForTheWeek += schedule.timeSlots.reduce(
            (acc, slot) =>
              acc + calculateHoursDifference(slot?.start, slot?.end),
            0,
          );
        }
      }
    }

    return hoursForTheWeek;
  };

  const calculateHoursForDay = (employeeDetails, day) => {
    const schedule = employeeDetails.schedules[day];
    let totalHoursForDay = 0;
    if (schedule && schedule.timeSlots) {
      totalHoursForDay = schedule.timeSlots.reduce(
        (acc, slot) => acc + calculateHoursDifference(slot.start, slot.end),
        0,
      );
    }
    return totalHoursForDay;
  };

  const calculateTotalHoursForMonth = employeeDetails => {
    let totalHoursForMonth = 0;
    for (let day = 1; day <= daysInMonth.length; day++) {
      const totalHoursForDay = calculateHoursForDay(employeeDetails, day);
      totalHoursForMonth += totalHoursForDay;
    }
    return totalHoursForMonth;
  };

  let hoursForTheWeek = null;
  let totalHoursForMonth = null;

  return (
    <Main>
      <ScheduleMeNavContainer>
        <MonthButton onClick={() => changeMonth(-1)}>
          {scheduleText[language][0]?.prevMonth}
        </MonthButton>
        <MonthButton onClick={() => changeMonth(1)}>
          {scheduleText[language][0]?.nextMonth}
        </MonthButton>
      </ScheduleMeNavContainer>

      <CopyNavContainer>
        <ChooseDataContainer>
          <span>{scheduleText[language][0]?.dateStartPeriod} </span>
          <DataSelect
            value={selectedStartDate || ''}
            onChange={e => setSelectedStartDate(Number(e.target.value))}
          >
            <DataOption value="">
              {scheduleText[language][0]?.chooseDate}
            </DataOption>
            {daysInMonth?.map(day => (
              <DataOption key={day} value={day}>
                {day >= 10 ? day : `0${day}`}.
                {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
              </DataOption>
            ))}
          </DataSelect>
        </ChooseDataContainer>

        <ChooseDataContainer>
          <span>{scheduleText[language][0]?.dateEndPeriod} </span>
          <DataSelect
            value={selectedEndDate || ''}
            onChange={e => setSelectedEndDate(Number(e.target.value))}
          >
            <DataOption value="">
              {scheduleText[language][0]?.chooseDate}
            </DataOption>
            {daysInMonth?.map(day => (
              <DataOption key={day} value={day}>
                {day >= 10 ? day : `0${day}`}.
                {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
              </DataOption>
            ))}
          </DataSelect>
        </ChooseDataContainer>

        <MonthButton
          onClick={handleApplyCustomSchedule}
          style={{ marginLeft: '10px' }}
        >
          {scheduleText[language][0]?.changeSchedule}
        </MonthButton>

        <ChooseDataContainer>
          <span>{scheduleText[language][0]?.chooseJobTitle} </span>
          <EmployeeSelect
            value={selectedJobTitles}
            onChange={e => setSelectedJobTitles(Number(e.target.value))}
          >
            {allJobTitles?.map(job => (
              <DataOption key={job.id_job_title} value={job.id_job_title}>
                {language === 'russian' ? job.job_title_RU : job.job_title_UA}
              </DataOption>
            ))}
          </EmployeeSelect>
        </ChooseDataContainer>
      </CopyNavContainer>

      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh>#</ScheduleTh>
              <ScheduleTh>{scheduleText[language][0]?.fullName}</ScheduleTh>
              <ScheduleTh>{scheduleText[language][0]?.groupe}</ScheduleTh>
              <ScheduleTh>{scheduleText[language][0]?.hoursOnMonth}</ScheduleTh>
              {filteredDays?.map((day, idx) => {
                const adjustedIdx = (idx + firstDayOfMonth - 1) % 7;
                return (
                  <React.Fragment key={day}>
                    <ScheduleTh key={idx}>
                      {
                        weekDays[language][0][
                          Object.keys(weekDays[language][0])[adjustedIdx]
                        ]
                      }
                      <br />
                      {day >= 10 ? day : `0${day}`}.
                      {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                    </ScheduleTh>
                    {(new Date(currentYear, currentMonth - 1, day).getDay() ===
                      0 ||
                      isFirstWeekLastDay(currentYear, currentMonth, day)) && (
                      <ScheduleTh>
                        {scheduleText[language][0]?.hoursOnWeek}
                      </ScheduleTh>
                    )}
                  </React.Fragment>
                );
              })}
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {Object?.entries(combinedData)?.map(
              ([employeeId, employeeDetails], rowIndex) => {
                totalHoursForMonth =
                  calculateTotalHoursForMonth(employeeDetails);

                return (
                  <ScheduleTr key={employeeId}>
                    <ScheduleTd>{rowIndex + 1}</ScheduleTd>
                    <ScheduleTd>{employeeDetails.name}</ScheduleTd>
                    <ScheduleTd>{employeeDetails.group}</ScheduleTd>
                    <ScheduleTd
                      backgroundColor={getColorByHoursMonth(totalHoursForMonth)}
                    >
                      {formatToTime(totalHoursForMonth)}
                    </ScheduleTd>
                    {filteredDays?.map(day => {
                      const weekRange = getCurrentWeekRange(day);
                      const totalHoursForDay = calculateHoursForDay(
                        employeeDetails,
                        day,
                      );
                      hoursForTheWeek = calculateHoursForWeek(
                        employeeDetails,
                        day,
                        weekRange,
                      );

                      return (
                        <React.Fragment key={day}>
                          <ScheduleTd>
                            {formatToTime(totalHoursForDay)}
                          </ScheduleTd>
                          {(new Date(
                            currentYear,
                            currentMonth - 1,
                            day,
                          ).getDay() === 0 ||
                            isFirstWeekLastDay(
                              currentYear,
                              currentMonth,
                              day,
                            )) && (
                            <ScheduleTd
                              backgroundColor={getColorByHoursWeek(
                                hoursForTheWeek,
                              )}
                            >
                              {formatToTime(hoursForTheWeek)}
                            </ScheduleTd>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </ScheduleTr>
                );
              },
            )}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </Main>
  );
};
