import React, { useState, useContext,  } from "react";
import { useQuery } from "react-query";
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  AddedFMContainer,
  SaveButton,
  Thead,
  AddedNewButton
} from "./Rating.style";
import { UserContext } from "../../Context/UserContext";
import { Modal } from "../../Modal/Modal";
import { everydayStatisticsText, wiretappingCheckList } from "../../../services/componentText";
import {
  getGradientColorForApprove,
  getGradientColorForOtherCategorie,
  getGradientColorForNettoIncome,
  getGradientColorForAdditionalApprovalPercentage,
} from "../../../helpers/gradient/getGradientColorForEverydayStatistics";
import { Main } from "../../Main/Main";
import {
  fetchRating
} from "../../../api/everydayStatistics";
import { SharedLayout } from "../../SharedLayout/SharedLayout";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

export const Rating = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState("totalScore");
  const [order, setOrder] = useState("DESC");
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    const [period, setPeriod] = useState([
      {
        startDate:
          new Date().getDate() < 16
            ? new Date(`${currentMonth}.01.${currentYear}`)
            : new Date(`${currentMonth}.16.${currentYear}`),
        endDate:
          new Date().getDate() < 16
            ? new Date(`${currentMonth}.15.${currentYear}`)
            : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
        key: 'selection',
      },
    ]);
  
    const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
    const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
    const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy-MM-dd');
    const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy-MM-dd');

  const { data, refetch } = useQuery(
    ["fetchRating", sortBy, order],
    () =>
      fetchRating({
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        sortBy,
        order,
      })
  );



  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };


  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <div>
      <SharedLayout />
      <Main>
      {isModalDateOpen && (
          <Modal closeModal={() => setIsModalDateOpen(false)}>
            <DateRangePicker
              onChange={item => setPeriod([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              locale={currentUser?.language === 'russian' ? ru : uk}
              staticRanges={customDefaultStaticRanges}
              inputRanges={customDefaultInputRanges}
              months={2}
              ranges={period}
              direction="horizontal"
              style={{ margin: '20px' }}
            />
            <SaveButton onClick={() => handleDate()}>
              {wiretappingCheckList[language][0]?.update}
            </SaveButton>
          </Modal>
        )}
        <div>
          <AddedFMContainer>
          <div
            style={{ marginRight: '10px' }}
          >{`${formattedStartDate} - ${formattedEndDate}`}</div>
          <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
            {wiretappingCheckList[language][0]?.choosePeriod}
          </AddedNewButton>
          </AddedFMContainer>
        </div>

        <TableContainer>
          <ScheduleTable>
            <Thead>
              <ScheduleHeadTr>
              <ScheduleTh style={{width: '30px'}}>
                  №
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("full_name")}>
                  {everydayStatisticsText[language][0]?.fullName}{" "}
                  {renderSortIcon("full_name")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("totalScore")}>
                  {everydayStatisticsText[language][0]?.totalScore}{" "}
                  {renderSortIcon("totalScore")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("total_order")}>
                  {everydayStatisticsText[language][0]?.totalOrder}{" "}
                  {renderSortIcon("total_order")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("total_order_score")}>
                  {everydayStatisticsText[language][0]?.totalOrderScore}{" "}
                  {renderSortIcon("total_order_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("approval_percentage")}
                >
                  {everydayStatisticsText[language][0]?.approve}{" "}
                  {renderSortIcon("approval_percentage")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("approval_percentage_score")}
                >
                  {everydayStatisticsText[language][0]?.approvalPercentageScore}{" "}
                  {renderSortIcon("approval_percentage_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("in_processing_percentage")}
                >
                  {everydayStatisticsText[language][0]?.inProcessing}{" "}
                  {renderSortIcon("in_processing_percentage")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("in_processing_percentage_score")}
                >
                  {everydayStatisticsText[language][0]?.inProcessingScore}{" "}
                  {renderSortIcon("in_processing_percentage_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("rejected_percentage")}
                >
                  {everydayStatisticsText[language][0]?.rejected}{" "}
                  {renderSortIcon("rejected_percentage")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("rejected_percentage_score")}
                >
                  {everydayStatisticsText[language][0]?.rejectedScore}{" "}
                  {renderSortIcon("rejected_percentage_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("trash_percentage")}
                >
                  {everydayStatisticsText[language][0]?.trash}{" "}
                  {renderSortIcon("trash_percentage")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("trash_percentage_score")}
                >
                  {everydayStatisticsText[language][0]?.trashScore}{" "}
                  {renderSortIcon("trash_percentage_score")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("netto_income")}>
                  {everydayStatisticsText[language][0]?.nettoIncome}{" "}
                  {renderSortIcon("netto_income")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("netto_income_score")}>
                  {everydayStatisticsText[language][0]?.nettoIncomeScore}{" "}
                  {renderSortIcon("netto_income_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() =>
                    handleSortChange("additional_approval_percentage")
                  }
                >
                  {
                    everydayStatisticsText[language][0]
                      ?.additionalApprovalPercentage
                  }{" "}
                  {renderSortIcon("additional_approval_percentage")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() =>
                    handleSortChange("additional_approval_percentage_score")
                  }
                >
                  {
                    everydayStatisticsText[language][0]
                      ?.additionalApprovalPercentage
                  }
                  {renderSortIcon("additional_approval_percentage_score")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("check_by_approv")}>
                  {everydayStatisticsText[language][0]?.checkByApprov}{" "}
                  {renderSortIcon("check_by_approv")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("check_by_approv_score")}>
                  {everydayStatisticsText[language][0]?.checkByApprov}{" "}
                  {renderSortIcon("check_by_approv_score")}
                </ScheduleTh>

              </ScheduleHeadTr>
            </Thead>
            <tbody>
              {data?.length > 0 ? (
                <React.Fragment>
                  {data?.map((item, index) => {
                    const approvalBgColor = getGradientColorForApprove(
                      item.approval_percentage
                    );

                    const rejectedBgColor = getGradientColorForOtherCategorie(
                      item.rejected_percentage
                    );
                    const inProcessingBgColor =
                      getGradientColorForOtherCategorie(
                        item.in_processing_percentage
                      );
                    const trashBgColor = getGradientColorForOtherCategorie(
                      item.trash_percentage
                    );

                    const nettoIncomeBgColor = getGradientColorForNettoIncome(
                      item.netto_income
                    );

                    const additionalApprovalPercentageBgColor =
                      getGradientColorForAdditionalApprovalPercentage(
                        item.additional_approval_percentage
                      );

                    return (
                      <ScheduleTr key={item.full_name}>
                        <ScheduleTd style={{width: '52px'}}>{index + 1}</ScheduleTd>
                        <ScheduleTd>{item.full_name}</ScheduleTd>
                        <ScheduleTd>{item.totalScore}</ScheduleTd>
                        <ScheduleTd>{item.total_order}</ScheduleTd>
                        <ScheduleTd>{item.total_order_score}</ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: approvalBgColor,
                          }}
                        >
                          {item.approve} <br />({item.approval_percentage}
                          %)
                        </ScheduleTd>
                        <ScheduleTd>{item.approval_percentage_score}</ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: inProcessingBgColor,
                          }}
                        >
                          {item.in_processing} <br />(
                          {item.in_processing_percentage}
                          %)
                        </ScheduleTd>
                        <ScheduleTd>{item.in_processing_percentage_score}</ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: rejectedBgColor,
                          }}
                        >
                          {item.rejected} <br />({item.rejected_percentage}
                          %)
                        </ScheduleTd>
                        <ScheduleTd>{item.rejected_percentage_score}</ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: trashBgColor,
                          }}
                        >
                          {item.trash}
                          <br />({item.trash_percentage}
                          %)
                        </ScheduleTd>
                        <ScheduleTd>{item.trash_percentage_score}</ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: nettoIncomeBgColor,
                          }}
                        >
                          {item.netto_income} грн
                        </ScheduleTd>
                        <ScheduleTd>{item.netto_income_score}</ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor:
                              additionalApprovalPercentageBgColor,
                          }}
                        >
                          {item.additional_approval_percentage}%
                        </ScheduleTd>
                        <ScheduleTd>{item.additional_approval_percentage_score}</ScheduleTd>
                        <ScheduleTd>{item.check_by_approv} грн</ScheduleTd>
                        <ScheduleTd>{item.check_by_approv_score}</ScheduleTd>
                      </ScheduleTr>
                    );
                  })}
                </React.Fragment>
              ) : (
                <ScheduleTr>
                  <ScheduleTd colSpan={9} style={{ width: "600px" }}>
                    {everydayStatisticsText[language][0]?.notDataOrError}
                  </ScheduleTd>
                </ScheduleTr>
              )}
            </tbody>
          </ScheduleTable>
        </TableContainer>
      </Main>
    </div>
  );
};
