import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const createWarehouseCity = async data => {
    try {
      const response = await makeRequestSQL.post('/claims/warehouse-city', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchWarehouseCity = async () => {
    try {
      const response = await makeRequestSQL.get('/claims/warehouse-city');
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const deleteWarehouseCity = async id => {
    try {
      const response = await makeRequestSQL.delete(`/claims/warehouse-city`, {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateWarehouseCity = async data => {
    try {
      const response = await makeRequestSQL.put('/claims/warehouse-city', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const createClaimsStatus = async data => {
    try {
      const response = await makeRequestSQL.post('/claims/claim-status', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchClaimsStatus = async () => {
    try {
      const response = await makeRequestSQL.get('/claims/claim-status');
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const deleteClaimsStatus = async id => {
    try {
      const response = await makeRequestSQL.delete(`/claims/claim-status`, {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateClaimsStatus = async data => {
    try {
      const response = await makeRequestSQL.put('/claims/claim-status', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const createClientConfigured = async data => {
    try {
      const response = await makeRequestSQL.post('/claims/client-configured', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchClientConfigured = async () => {
    try {
      const response = await makeRequestSQL.get('/claims/client-configured');
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const deleteClientConfigured = async id => {
    try {
      const response = await makeRequestSQL.delete(`/claims/client-configured`, {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateClientConfigured = async data => {
    try {
      const response = await makeRequestSQL.put('/claims/client-configured', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const createRefundStatus = async data => {
    try {
      const response = await makeRequestSQL.post('/claims/refund-status', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchRefundStatus = async () => {
    try {
      const response = await makeRequestSQL.get('/claims/refund-status');
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const deleteRefundStatus = async id => {
    try {
      const response = await makeRequestSQL.delete(`/claims/refund-status`, {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateRefundStatus = async data => {
    try {
      const response = await makeRequestSQL.put('/claims/refund-status', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const createClaimsCategory = async data => {
    try {
      const response = await makeRequestSQL.post('/claims/claims-category', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchClaimsCategory = async () => {
    try {
      const response = await makeRequestSQL.get('/claims/claims-category');
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const deleteClaimsCategory = async id => {
    try {
      const response = await makeRequestSQL.delete(`/claims/claims-category`, {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateClaimsCategory = async data => {
    try {
      const response = await makeRequestSQL.put('/claims/claims-category', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const createReasonReturn = async data => {
    try {
      const response = await makeRequestSQL.post('/claims/reason-return', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchReasonReturn = async () => {
    try {
      const response = await makeRequestSQL.get('/claims/reason-return');
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const deleteReasonReturn = async id => {
    try {
      const response = await makeRequestSQL.delete(`/claims/reason-return`, {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateReasonReturn = async data => {
    try {
      const response = await makeRequestSQL.put('/claims/reason-return', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };