import styled from '@emotion/styled';

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const ScheduleTable = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const ScheduleTr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ScheduleHeadTr = styled(ScheduleTr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const ScheduleTd = styled.td`
  width: 107px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color: ${props => props.backgroundColor || '#ffffff'};

  &:last-of-type {
    border-right: none;
  }
`;

export const ScheduleTh = styled.th`
  width: 85px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

`;

export const AddedFMSelect = styled.select`
  appearance: none;
  width: 330px;
  height: 30px;
  padding: 5px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 16px;
  margin-left: 10px;

  cursor: pointer;
`;

export const AddedFMOption = styled.option`
  appearance: none;
  width: 100%;
  height: 10px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 16px;

  cursor: pointer;
`;

export const AddedFMContainer = styled.div`
  display: flex;
  align-items: flex-start;

`;