import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const NavigationLeftBarContainer = styled.div`
  height: calc(100% - 4.5rem);
  overflow-y: auto;
  font-size: 0.8203125rem;
  background-color: inherit;
  padding-bottom: 1.5rem;

  &::-webkit-scrollbar {
    width: 5px; /* Ширина вертикальной полосы прокрутки */
  }
  &::-webkit-scrollbar-track {
    background: #3098d6; /* Цвет дорожки (фона) полосы прокрутки */
  }
  &::-webkit-scrollbar-thumb {
    background: #00d3ff; /* Цвет самой полосы прокрутки */
  }
`;

export const NavigationLeftBarPrimaryList = styled.ul`
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: inherit;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
`;

export const NavigationLeftBarPrimaryItem = styled.li`
  margin-bottom: 20px;
  transition: margin 0.2s;
  background-color: inherit;
`;

export const NavigationLeftBarCategoryTitleContainer = styled(Link)`
  pointer-events: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-size: 20px;
  color: #ffffff;
  transition: background-color 0.2s;
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  background-color: transparent;
  display: flex;
  border-bottom: 1px solid #ffffff;
  align-items: center;

`;

export const NavigationLeftBarCategoryImage = styled.span`
  margin-right: 0.75rem;
  line-height: 1;
  display: inline-block;
  color: #ffffff;
`;

export const NavigationLeftBarCategoryTitle = styled.span`
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  pointer-events: none;
`;
