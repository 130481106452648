import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import { Main } from "../../components/Main/Main";
import { Outlet, useLocation } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../components/Context/UserContext";
import { NavContainer, AddedNewButton } from "./Product.styled";
import { productsText } from "../../services/componentText";

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_a = Number(process.env.REACT_APP_A_LEVEL_ACCESS);

export const Product = () => {
  const { currentUser, language } = useContext(UserContext);
  const location = useLocation();

  const isAddNewProductPage = location.pathname === "/products/new-product";

  const isMainProducts =
    location.pathname === "/products" ||
    location.pathname === "/products/product-list";

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e ||
    currentUser?.access_level === access_level_a;

  return (
    <div>
      <SharedLayout />
      {isMainProducts && (
        <Main>
          {accessLevel && (
            <NavContainer>
              {isAddNewProductPage ? null : (
                <AddedNewButton to="/products/new-product">
                  {productsText[language][0]?.addNewProduct}
                </AddedNewButton>
              )}
            </NavContainer>
          )}
        </Main>
      )}
      <Outlet />
    </div>
  );
};
