export const formattedDateToDayMonthYear = date => {
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;
  return formattedDate;
};
