import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  Thead,
  AddedFMSelect,
  AddedFMOption,
  AddedFMContainer,
} from './OneEmployeeEverydayStatistics.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { Main } from '../../Main/Main';
import {
  getGradientColorForApprove,
  getGradientColorForOtherCategorie,
  getGradientColorForNettoIncome,
  getGradientColorForAdditionalApprovalPercentage,
} from '../../../helpers/gradient/getGradientColorForEverydayStatistics';
import {
  fetchAllEverydayStatistics,
} from '../../../api/everydayStatistics';
import { everydayStatisticsText } from '../../../services/componentText';
import { fetchUsers } from '../../../api/users';

export const OneEmployeeEverydayStatistics = () => {
  const { currentUser, language } = useContext(UserContext);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;
  const sortBy = 'date';
  const order = 'DESC';

const { data: users } = useQuery(["getAllEmployees"], () =>
fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0" })
);

  const { data: everydayStatisticsData } = useQuery(
    ['everydayStatistics', selectedEmployee],
    () =>
      fetchAllEverydayStatistics({
        email: accessLevel ? selectedEmployee : currentUser?.email,
        sortBy,
        order,
      }),
  );

  return (
    <Main>
      {accessLevel && (
        <AddedFMContainer>
          <label>
            {everydayStatisticsText[language][0]?.chooseEmployeeOne}
          </label>
          <AddedFMSelect
            name="selectedUser"
            value={selectedEmployee?.full_name}
            onChange={e => setSelectedEmployee(e.target.value)}
          >
            <AddedFMOption value="">
              {everydayStatisticsText[language][0]?.chooseEmployeeTwo}
            </AddedFMOption>
            {users?.usersData?.map(user => (
              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
              <AddedFMOption key={user.id_employee} value={user?.email}>
                {user.full_name}
              </AddedFMOption>
              )
            ))}
          </AddedFMSelect>
        </AddedFMContainer>
      )}
      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh>Дата</ScheduleTh>
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.totalOrder}{' '}
              </ScheduleTh>
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.approve}{' '}
              </ScheduleTh>
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.inProcessing}{' '}
              </ScheduleTh>
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.rejected}{' '}
              </ScheduleTh>
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.trash}{' '}
              </ScheduleTh>
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.nettoIncome}{' '}
              </ScheduleTh>
              {/* <ScheduleTh>
                {everydayStatisticsText[language][0]?.approvalPercentage}
              </ScheduleTh> */}
              <ScheduleTh>
                {
                  everydayStatisticsText[language][0]
                    ?.additionalApprovalPercentage
                }{' '}
              </ScheduleTh>
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.checkByApprov}{' '}
              </ScheduleTh>
              {/* <ScheduleTh>
                {everydayStatisticsText[language][0]?.timeInConversation}
              </ScheduleTh> */}
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.timeInSystem}
              </ScheduleTh>
              <ScheduleTh>
                {everydayStatisticsText[language][0]?.approvalForTime}
              </ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {everydayStatisticsData?.statisticsData?.length > 0 ? (
              <React.Fragment>
                {everydayStatisticsData?.statisticsData?.map(item => {
                  const approvalBgColor = getGradientColorForApprove(
                    item.approval_percentage,
                  );

                  const rejectedBgColor = getGradientColorForOtherCategorie(
                    item.rejected_percentage,
                  );
                  const inProcessingBgColor = getGradientColorForOtherCategorie(
                    item.in_processing_percentage,
                  );
                  const trashBgColor = getGradientColorForOtherCategorie(
                    item.trash_percentage,
                  );

                  const nettoIncomeBgColor = getGradientColorForNettoIncome(
                    item.netto_income,
                  );

                  const additionalApprovalPercentageBgColor =
                    getGradientColorForAdditionalApprovalPercentage(
                      item.additional_approval_percentage,
                    );

                  return (
                    <ScheduleTr key={item.id}>
                      <ScheduleTd>
                        {formattedDateToDayMonthYear(item.date)}
                      </ScheduleTd>
                      <ScheduleTd>{item.total_order}</ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: approvalBgColor,
                        }}
                      >
                        {item.approve} <br />({item.approval_percentage}
                        %)
                      </ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: inProcessingBgColor,
                        }}
                      >
                        {item.in_processing} <br />(
                        {item.in_processing_percentage}
                        %)
                      </ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: rejectedBgColor,
                        }}
                      >
                        {item.rejected} <br />({item.rejected_percentage}
                        %)
                      </ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: trashBgColor,
                        }}
                      >
                        {item.trash}
                        <br />({item.trash_percentage}
                        %)
                      </ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: nettoIncomeBgColor,
                        }}
                      >
                        {item.netto_income} грн
                      </ScheduleTd>
                      {/* <ScheduleTd>{item.approval_percentage}%</ScheduleTd> */}
                      <ScheduleTd
                        style={{
                          backgroundColor: additionalApprovalPercentageBgColor,
                        }}
                      >
                        {item.additional_approval_percentage}%
                      </ScheduleTd>
                      <ScheduleTd>{item.check_by_approv} грн</ScheduleTd>
                      {/* <ScheduleTd>{item.time_in_conversation}</ScheduleTd> */}
                      <ScheduleTd>{item.time_in_system}</ScheduleTd>
                      <ScheduleTd>{item.approval_for_time}</ScheduleTd>
                    </ScheduleTr>
                  );
                })}
              </React.Fragment>
            ) : (
              <ScheduleTr>
                <ScheduleTd colSpan={9} style={{ width: '600px' }}>
                  {everydayStatisticsText[language][0]?.notDataOrError}
                </ScheduleTd>
              </ScheduleTr>
            )}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </Main>
  );
};
