import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const SubMenuList = styled.ul`
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: inherit;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
`;

export const SubMenuItem = styled.li`
  background-color: inherit;
`;

export const NavigationLink = styled(NavLink)`
  color: #ffffff;
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: inherit;
  font-size: 16px;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }
`;
