import { Main } from '../../Main/Main';
import { Modal } from '../../Modal/Modal';
import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import {
  createEmployeeReport,
  fetchEmployeeReport,
  updateEmployeeReport,
  deleteEmployeeReport,
} from '../../../api/report';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  ModalContainer,
  InfoForm,
  Input,
  ReportTextArea,
  AddedReportContainer,
  DeleteReportButton,
  AddedNewReportButton,
  ButtonContainer,
  SaveButton,
  ReportTh,
  ReportTd,
  ReportHeadTr,
  ReportTr,
  ReportTable,
  TableContainer,
  Thead,
  ReportLabel,
  ReportCommentContainer,
  AddedFMSelect,
  AddedFMOption,
  ReportTextContainerForLabel,
  ReportText,
  NavContainer,
} from './EmployeeReport.styled';
import { employeeReportText } from '../../../services/componentText';
import { Formik } from 'formik';
import { fetchUsers } from '../../../api/users';
import { formatDateToInput } from '../../../helpers/dateAndTime/formatDate';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { v4 as uuidv4 } from 'uuid';

export const EmployeeReport = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [sortBy, setSortBy] = useState('date');
  const [order, setOrder] = useState('DESC');
  const [formData, setFormData] = useState({
    id: '',
    date: '',
    net_income_by_approve: '',
    employees_with_highest_error_in_crm: [],
    employees_with_highest_interruptions: [],
    number_of_interview: '',
    number_of_new_employee: '',
    employees_in_training: [],
    scripts_have_beent_written: [],
    required_calls: [],
    number_of_corrected_orders: '',
    number_of_claims_drawn_up: '',
    worst_employees_based_on_daily_statistics: [],
    number_of_order_out_of_stock: '',
    number_of_wrong_number: '',
    report_comment: '',
  });
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data, refetch } = useQuery(['employeeReport', sortBy, order], () =>
    fetchEmployeeReport({
      sortBy,
      order,
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    }),
  );

  const { data: users } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0" })
  );

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddItem = (arrayName, defaultItem) => {
    setFormData({
      ...formData,
      [arrayName]: [...formData[arrayName], { ...defaultItem }],
    });
  };

  const handleRemoveLastItem = arrayName => {
    const updatedArray = [...formData[arrayName]];

    if (updatedArray.length > 0) {
      updatedArray.pop();
      setFormData({
        ...formData,
        [arrayName]: updatedArray,
      });
    }
  };

  const handleArrayInputChange = (arrayName, index, field, value) => {
    const updatedArray = [...formData[arrayName]];
    updatedArray[index][field] = value;

    setFormData({
      ...formData,
      [arrayName]: updatedArray,
    });
  };

  const handleSubmit = async e => {
    if (isUpdate) {
      const response = await updateEmployeeReport(formData);
      if (response?.status === 204) {
        setIsModalOpen(false);
        setIsUpdate(false);
        resetValue();
        refetch();
        return toast.success(employeeReportText[language][0]?.successUpdate);
      }
    }
    const response = await createEmployeeReport(formData);

    if (response?.status === 204) {
      setIsModalOpen(false);
      resetValue();
      refetch();
      return toast.success(employeeReportText[language][0]?.successSave);
    }
  };

  const resetValue = () => {
    setFormData({
      id: '',
      date: '',
      net_income_by_approve: '',
      employees_with_highest_error_in_crm: [],
      employees_with_highest_interruptions: [],
      number_of_interview: '',
      number_of_new_employee: '',
      employees_in_training: [],
      scripts_have_beent_written: [],
      required_calls: [],
      number_of_corrected_orders: '',
      number_of_claims_drawn_up: '',
      worst_employees_based_on_daily_statistics: [],
      number_of_order_out_of_stock: '',
      number_of_wrong_number: '',
      report_comment: '',
    });
  };

  const handleNewReport = () => {
    setIsModalOpen(true);
    resetValue();
    setIsUpdate(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetValue();
  };

  const handleDelete = async () => {
    const response = await deleteEmployeeReport(selectedId);
    if (response.status === 200) {
      setIsUpdate(false);
      resetValue();
      setIsModalOpen(false);
      refetch();
      return toast.success(employeeReportText[language][0]?.successRemoved);
    }
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const handleClick = item => {
    const errorData = JSON?.parse(item.employees_with_highest_error_in_crm);
    const interruptionsData = JSON?.parse(
      item.employees_with_highest_interruptions,
    );
    const trainingData = JSON?.parse(item.employees_in_training);
    const scriptsData = JSON?.parse(item.scripts_have_beent_written);
    const callsData = JSON?.parse(item.required_calls);
    const worstEmployeesData = JSON?.parse(
      item.worst_employees_based_on_daily_statistics,
    );
    setIsUpdate(true);
    setIsModalOpen(true);
    setSelectedId(item?.id);
    setFormData({
      ...item,
      date: formatDateToInput(item.date),
      employees_with_highest_error_in_crm: [...errorData],
      employees_with_highest_interruptions: [...interruptionsData],
      employees_in_training: [...trainingData],
      scripts_have_beent_written: [...scriptsData],
      required_calls: [...callsData],
      worst_employees_based_on_daily_statistics: [...worstEmployeesData],
    });
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewReportButton onClick={() => setIsModalDateOpen(true)}>
          {employeeReportText[language][0]?.choosePeriod}
        </AddedNewReportButton>
        <AddedNewReportButton onClick={() => handleNewReport()}>
          {employeeReportText[language][0]?.addNewReport}
        </AddedNewReportButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {employeeReportText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainer>
            <Formik onSubmit={handleSubmit} initialValues={formData}>
              <InfoForm autoComplete="off">
                <AddedReportContainer>
                  <ReportLabel>
                    <ReportTextContainerForLabel>
                      {employeeReportText[language][0]?.chooseDate}
                    </ReportTextContainerForLabel>
                    <Input
                      type="date"
                      name="date"
                      value={formData.date}
                      placeholder={employeeReportText[language][0]?.chooseDate}
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    <ReportTextContainerForLabel>
                      {
                        employeeReportText[language][0]
                          ?.enterNetIncomeByApproveBy
                      }
                    </ReportTextContainerForLabel>
                    <Input
                      type="number"
                      name="net_income_by_approve"
                      value={formData.net_income_by_approve}
                      placeholder={
                        employeeReportText[language][0]
                          ?.enterNetIncomeByApproveBy
                      }
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {employeeReportText[language][0]?.enterNumberOfInterview}
                    <Input
                      type="number"
                      name="number_of_interview"
                      value={formData.number_of_interview}
                      placeholder={
                        employeeReportText[language][0]?.enterNumberOfInterview
                      }
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    <ReportTextContainerForLabel>
                      {
                        employeeReportText[language][0]
                          ?.enterNumberOfNewEmployee
                      }
                    </ReportTextContainerForLabel>
                    <Input
                      type="number"
                      name="number_of_new_employee"
                      value={formData.number_of_new_employee}
                      placeholder={
                        employeeReportText[language][0]
                          ?.enterNumberOfNewEmployee
                      }
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    <ReportTextContainerForLabel>
                      {
                        employeeReportText[language][0]
                          ?.enterNumberOfCorrectedOrders
                      }
                    </ReportTextContainerForLabel>
                    <Input
                      type="number"
                      name="number_of_corrected_orders"
                      value={formData.number_of_corrected_orders}
                      placeholder={
                        employeeReportText[language][0]
                          ?.enterNumberOfCorrectedOrders
                      }
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    <ReportTextContainerForLabel>
                      {
                        employeeReportText[language][0]
                          ?.enterNumberOfClaimsDrawnUp
                      }
                    </ReportTextContainerForLabel>
                    <Input
                      type="number"
                      name="number_of_claims_drawn_up"
                      value={formData.number_of_claims_drawn_up}
                      placeholder={
                        employeeReportText[language][0]
                          ?.enterNumberOfClaimsDrawnUp
                      }
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    <ReportTextContainerForLabel>
                      {
                        employeeReportText[language][0]
                          ?.enterNumberOfOrderOutOfStock
                      }
                    </ReportTextContainerForLabel>
                    <Input
                      type="number"
                      name="number_of_order_out_of_stock"
                      value={formData.number_of_order_out_of_stock}
                      placeholder={
                        employeeReportText[language][0]
                          ?.enterNumberOfOrderOutOfStock
                      }
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    <ReportTextContainerForLabel>
                      {
                        employeeReportText[language][0]
                          ?.enterNumberOfWrongNumber
                      }
                    </ReportTextContainerForLabel>
                    <Input
                      type="number"
                      name="number_of_wrong_number"
                      value={formData.number_of_wrong_number}
                      placeholder={
                        employeeReportText[language][0]
                          ?.enterNumberOfWrongNumber
                      }
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </AddedReportContainer>

                {!formData.employees_with_highest_error_in_crm ||
                  (formData.employees_with_highest_error_in_crm.length === 0 &&
                    handleAddItem('employees_with_highest_error_in_crm', {
                      full_name: '',
                      error_count: 0,
                    }))}

                {formData?.employees_with_highest_error_in_crm?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeeReportText[language][0]
                              ?.chooseEmployeeWithHighestNumberOfErrors
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'employees_with_highest_error_in_crm',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {employeeReportText[language][0]?.chooseEmployeeTwo}
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {employeeReportText[language][0]?.enterNumberOfErrors}
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`error_count_${index}`}
                          value={employee.error_count}
                          onChange={e =>
                            handleArrayInputChange(
                              'employees_with_highest_error_in_crm',
                              index,
                              'error_count',
                              e.target.value,
                            )
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}
                <ButtonContainer>
                  <SaveButton
                    type="button"
                    onClick={() =>
                      handleAddItem('employees_with_highest_error_in_crm', {
                        full_name: '',
                        error_count: 0,
                      })
                    }
                  >
                    {employeeReportText[language][0]?.addEmployee}
                  </SaveButton>
                  <DeleteReportButton
                    type="button"
                    onClick={() =>
                      handleRemoveLastItem(
                        'employees_with_highest_error_in_crm',
                      )
                    }
                  >
                    {employeeReportText[language][0]?.deleteEmployee}
                  </DeleteReportButton>
                </ButtonContainer>

                {!formData.employees_with_highest_interruptions ||
                  (formData.employees_with_highest_interruptions.length === 0 &&
                    handleAddItem('employees_with_highest_interruptions', {
                      full_name: '',
                      interruptions_count: 0,
                    }))}

                {formData?.employees_with_highest_interruptions?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeeReportText[language][0]
                              ?.chooseEmployeeWithHighestNumberOfInterruptions
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'employees_with_highest_interruptions',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {employeeReportText[language][0]?.chooseEmployeeTwo}
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeeReportText[language][0]
                              ?.enterNumberOfInterruptions
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`interruptions_count_${index}`}
                          value={employee.interruptions_count}
                          onChange={e =>
                            handleArrayInputChange(
                              'employees_with_highest_interruptions',
                              index,
                              'interruptions_count',
                              e.target.value,
                            )
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}
                <ButtonContainer>
                  <SaveButton
                    type="button"
                    onClick={() =>
                      handleAddItem('employees_with_highest_interruptions', {
                        full_name: '',
                        interruptions_count: 0,
                      })
                    }
                  >
                    {employeeReportText[language][0]?.addEmployee}
                  </SaveButton>
                  <DeleteReportButton
                    type="button"
                    onClick={() =>
                      handleRemoveLastItem(
                        'employees_with_highest_interruptions',
                      )
                    }
                  >
                    {employeeReportText[language][0]?.deleteEmployee}
                  </DeleteReportButton>
                </ButtonContainer>

                {!formData.employees_in_training ||
                  (formData.employees_in_training.length === 0 &&
                    handleAddItem('employees_in_training', {
                      full_name: '',
                    }))}

                {formData?.employees_in_training?.map((employee, index) => (
                  <AddedReportContainer key={index}>
                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {
                          employeeReportText[language][0]
                            ?.chooseNumberOfEmployeesInTraining
                        }
                      </ReportTextContainerForLabel>
                      <AddedFMSelect
                        name={`full_name_${index}`}
                        value={employee?.full_name}
                        onChange={e =>
                          handleArrayInputChange(
                            'employees_in_training',
                            index,
                            'full_name',
                            e.target.value,
                          )
                        }
                      >
                        <AddedFMOption value="">
                          {employeeReportText[language][0]?.chooseEmployeeTwo}
                        </AddedFMOption>
                        {users?.usersData?.map(
                          user =>
                            (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                              <AddedFMOption
                                key={user.id_employee}
                                value={user.full_name}
                              >
                                {user.full_name}
                              </AddedFMOption>
                            ),
                        )}
                      </AddedFMSelect>
                    </ReportLabel>
                  </AddedReportContainer>
                ))}
                <ButtonContainer>
                  <SaveButton
                    type="button"
                    onClick={() =>
                      handleAddItem('employees_in_training', {
                        full_name: '',
                      })
                    }
                  >
                    {employeeReportText[language][0]?.addEmployee}
                  </SaveButton>
                  <DeleteReportButton
                    type="button"
                    onClick={() =>
                      handleRemoveLastItem('employees_in_training')
                    }
                  >
                    {employeeReportText[language][0]?.deleteEmployee}
                  </DeleteReportButton>
                </ButtonContainer>

                {!formData.scripts_have_beent_written ||
                  (formData.scripts_have_beent_written.length === 0 &&
                    handleAddItem('scripts_have_beent_written', {
                      product_title: '',
                    }))}

                {formData?.scripts_have_beent_written?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {employeeReportText[language][0]?.enterProductTitle}
                        </ReportTextContainerForLabel>
                        <Input
                          type="text"
                          name={`product_title_${index}`}
                          value={employee.product_title}
                          onChange={e =>
                            handleArrayInputChange(
                              'scripts_have_beent_written',
                              index,
                              'product_title',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeeReportText[language][0]?.enterProductTitle
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}
                <ButtonContainer>
                  <SaveButton
                    type="button"
                    onClick={() =>
                      handleAddItem('scripts_have_beent_written', {
                        product_title: '',
                      })
                    }
                  >
                    {employeeReportText[language][0]?.addNew}
                  </SaveButton>
                  <DeleteReportButton
                    type="button"
                    onClick={() =>
                      handleRemoveLastItem('scripts_have_beent_written')
                    }
                  >
                    {employeeReportText[language][0]?.deleteLast}
                  </DeleteReportButton>
                </ButtonContainer>

                {!formData.required_calls ||
                  (formData.required_calls.length === 0 &&
                    handleAddItem('required_calls', {
                      department: '',
                      day: '',
                      time: '',
                    }))}

                {formData?.required_calls?.map((employee, index) => (
                  <AddedReportContainer key={index}>
                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {employeeReportText[language][0]?.enterDeparment}
                      </ReportTextContainerForLabel>
                      <Input
                        type="text"
                        name={`department_${index}`}
                        value={employee.department}
                        onChange={e =>
                          handleArrayInputChange(
                            'required_calls',
                            index,
                            'department',
                            e.target.value,
                          )
                        }
                        placeholder={
                          employeeReportText[language][0]?.enterDeparment
                        }
                      />
                    </ReportLabel>

                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {employeeReportText[language][0]?.chooseDay}
                      </ReportTextContainerForLabel>
                      <Input
                        type="text"
                        name={`day_${index}`}
                        value={employee.day}
                        onChange={e =>
                          handleArrayInputChange(
                            'required_calls',
                            index,
                            'day',
                            e.target.value,
                          )
                        }
                        placeholder={employeeReportText[language][0]?.chooseDay}
                      />
                    </ReportLabel>

                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {employeeReportText[language][0]?.chooseTime}
                      </ReportTextContainerForLabel>
                      <Input
                        type="time"
                        name={`time_${index}`}
                        value={employee.time}
                        onChange={e =>
                          handleArrayInputChange(
                            'required_calls',
                            index,
                            'time',
                            e.target.value,
                          )
                        }
                      />
                    </ReportLabel>
                  </AddedReportContainer>
                ))}
                <ButtonContainer>
                  <SaveButton
                    type="button"
                    onClick={() =>
                      handleAddItem('required_calls', {
                        department: '',
                        day: '',
                        time: '',
                      })
                    }
                  >
                    {employeeReportText[language][0]?.addNew}
                  </SaveButton>
                  <DeleteReportButton
                    type="button"
                    onClick={() => handleRemoveLastItem('required_calls')}
                  >
                    {employeeReportText[language][0]?.deleteLast}
                  </DeleteReportButton>
                </ButtonContainer>

                {!formData.worst_employees_based_on_daily_statistics ||
                  (formData.worst_employees_based_on_daily_statistics.length ===
                    0 &&
                    handleAddItem('worst_employees_based_on_daily_statistics', {
                      full_name: '',
                    }))}

                {formData?.worst_employees_based_on_daily_statistics?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeeReportText[language][0]
                              ?.chooseWorstEmployeesBasedOnDailyStatistics
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'worst_employees_based_on_daily_statistics',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {employeeReportText[language][0]?.chooseEmployeeTwo}
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}
                <ButtonContainer>
                  <SaveButton
                    type="button"
                    onClick={() =>
                      handleAddItem(
                        'worst_employees_based_on_daily_statistics',
                        {
                          full_name: '',
                        },
                      )
                    }
                  >
                    {employeeReportText[language][0]?.addEmployee}
                  </SaveButton>
                  <DeleteReportButton
                    type="button"
                    onClick={() =>
                      handleRemoveLastItem(
                        'worst_employees_based_on_daily_statistics',
                      )
                    }
                  >
                    {employeeReportText[language][0]?.deleteEmployee}
                  </DeleteReportButton>
                </ButtonContainer>

                <ReportCommentContainer>
                  <ReportLabel>
                    {employeeReportText[language][0]?.enterReportComment}
                  </ReportLabel>
                  <ReportTextArea
                    type="number"
                    name="report_comment"
                    as="textarea"
                    value={formData.report_comment}
                    placeholder={
                      employeeReportText[language][0]?.enterReportComment
                    }
                    onChange={e => handleInputChange(e)}
                  />
                </ReportCommentContainer>

                {!isUpdate && (
                  <ButtonContainer>
                    <SaveButton type="submit">
                      {employeeReportText[language][0]?.save}
                    </SaveButton>
                    <DeleteReportButton
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {employeeReportText[language][0]?.cancel}
                    </DeleteReportButton>
                  </ButtonContainer>
                )}

                {isUpdate && (
                  <ButtonContainer>
                    <SaveButton type="submit">
                      {employeeReportText[language][0]?.update}
                    </SaveButton>
                    <DeleteReportButton
                      type="button"
                      onClick={() => handleDelete()}
                    >
                      {employeeReportText[language][0]?.delete}
                    </DeleteReportButton>
                  </ButtonContainer>
                )}
              </InfoForm>
            </Formik>
          </ModalContainer>
        </Modal>
      )}

      <TableContainer>
        <ReportTable>
          <Thead>
            <ReportHeadTr>
              <ReportTh
                onClick={() => handleSortChange('date')}
                style={{ width: '68px' }}
              >
                {employeeReportText[language][0]?.date} {renderSortIcon('date')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('net_income_by_approve')}
                style={{ width: '128px' }}
              >
                {employeeReportText[language][0]?.netIncomeByApproveBy}{' '}
                {renderSortIcon('net_income_by_approve')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('employees_with_highest_error_in_crm')
                }
              >
                {
                  employeeReportText[language][0]
                    ?.employeeWithHighestNumberOfErrors
                }{' '}
                {renderSortIcon('employees_with_highest_error_in_crm')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('employees_with_highest_interruptions')
                }
              >
                {
                  employeeReportText[language][0]
                    ?.employeeWithHighestNumberOfInterruptions
                }{' '}
                {renderSortIcon('employees_with_highest_interruptions')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_interview')}
                style={{ width: '69px' }}
              >
                {employeeReportText[language][0]?.numberOfInterview}{' '}
                {renderSortIcon('number_of_interview')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_new_employee')}
                style={{ width: '128px' }}
              >
                {employeeReportText[language][0]?.numberOfNewEmployee}{' '}
                {renderSortIcon('number_of_new_employee')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('employees_in_training')}
              >
                {employeeReportText[language][0]?.numberOfEmployeesInTraining}{' '}
                {renderSortIcon('employees_in_training')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('scripts_have_beent_written')}
              >
                {employeeReportText[language][0]?.howManyScriptsHaveBeenWritten}{' '}
                {renderSortIcon('scripts_have_beent_written')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('required_calls')}>
                {employeeReportText[language][0]?.requiredCalls}{' '}
                {renderSortIcon('required_calls')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_corrected_orders')}
                style={{ width: '128px' }}
              >
                {employeeReportText[language][0]?.numberOfCorrectedOrders}{' '}
                {renderSortIcon('number_of_corrected_orders')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_claims_drawn_up')}
                style={{ width: '128px' }}
              >
                {employeeReportText[language][0]?.numberOfClaimsDrawnUp}{' '}
                {renderSortIcon('number_of_claims_drawn_up')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('worst_employees_based_on_daily_statistics')
                }
              >
                {
                  employeeReportText[language][0]
                    ?.worstEmployeesBasedOnDailyStatistics
                }{' '}
                {renderSortIcon('worst_employees_based_on_daily_statistics')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_order_out_of_stock')}
              >
                {employeeReportText[language][0]?.numberOfOrderOutOfStock}{' '}
                {renderSortIcon('number_of_order_out_of_stock')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_wrong_number')}
              >
                {employeeReportText[language][0]?.numberOfWrongNumber}{' '}
                {renderSortIcon('number_of_wrong_number')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('report_comment')}>
                {employeeReportText[language][0]?.reportComment}{' '}
                {renderSortIcon('report_comment')}
              </ReportTh>
            </ReportHeadTr>
          </Thead>
          <tbody>
            {data?.length > 0 ? (
              <React.Fragment>
                {data &&
                  data?.map(item => {
                    const errorData = JSON?.parse(
                      item.employees_with_highest_error_in_crm || '[]',
                    );
                    const interruptionsData = JSON?.parse(
                      item.employees_with_highest_interruptions || '[]',
                    );
                    const trainingData = JSON?.parse(
                      item.employees_in_training || '[]',
                    );
                    const scriptsData = JSON?.parse(
                      item.scripts_have_beent_written || '[]',
                    );
                    const callsData = JSON?.parse(item.required_calls || '[]');
                    const worstEmployeesData = JSON?.parse(
                      item.worst_employees_based_on_daily_statistics || '[]',
                    );

                    return (
                      <ReportTr key={item.id} onClick={() => handleClick(item)}>
                        <ReportTd style={{ width: '90px' }}>
                          {formattedDateToDayMonthYear(item.date)}
                        </ReportTd>
                        <ReportTd style={{ width: '150px' }}>
                          {item.net_income_by_approve}
                        </ReportTd>
                        <ReportTd>
                          {errorData?.map(value => {
                            const key = uuidv4();
                            return (
                              <div key={key}>
                                <ReportText>
                                  {value?.full_name} - {value?.error_count}
                                </ReportText>
                              </div>
                            );
                          })}
                        </ReportTd>
                        <ReportTd>
                          {interruptionsData.map(value => {
                            const key = uuidv4();
                            return (
                              <div key={key}>
                                <ReportText>
                                  {value?.full_name} -{' '}
                                  {value?.interruptions_count}
                                </ReportText>
                              </div>
                            )
                          })}
                        </ReportTd>
                        <ReportTd style={{ width: '90px' }}>
                          {item.number_of_interview}
                        </ReportTd>
                        <ReportTd style={{ width: '150px' }}>
                          {item.number_of_new_employee}
                        </ReportTd>
                        <ReportTd>
                          {employeeReportText[language][0]?.total}{' '}
                          {trainingData?.length}
                          {trainingData?.map(value => {
                          const key = uuidv4();
                            return (
                              <div key={key}>
                                <ReportText>{value?.full_name}</ReportText>
                              </div>
                            );
                          })}
                        </ReportTd>
                        <ReportTd>
                          <ReportText>
                            {employeeReportText[language][0]?.total}{' '}
                            {scriptsData?.length}
                          </ReportText>
                          {scriptsData.map(value => {
                            const key = uuidv4();
                            return (
                              <div key={key}>
                                <ReportText>{value?.product_title}</ReportText>
                              </div>
                            );
                          })}
                        </ReportTd>
                        <ReportTd>
                          <ReportText>
                            {employeeReportText[language][0]?.total}{' '}
                            {callsData?.length}
                          </ReportText>
                          {callsData.map(value => {
                            const key = uuidv4();
                            return (
                              <div key={key}>
                                <ReportText>
                                  {value?.department} - {value?.day} -{' '}
                                  {value?.time}
                                </ReportText>
                              </div>
                            );
                          })}
                        </ReportTd>
                        <ReportTd style={{ width: '150px' }}>
                          {item.number_of_corrected_orders}
                        </ReportTd>
                        <ReportTd style={{ width: '150px' }}>
                          {item.number_of_claims_drawn_up}
                        </ReportTd>
                        <ReportTd>
                          {worstEmployeesData.map(value => {
                            return (
                              <div key={value?.full_name}>
                                <ReportText>{value?.full_name}</ReportText>
                              </div>
                            );
                          })}
                        </ReportTd>
                        <ReportTd>{item.number_of_order_out_of_stock}</ReportTd>
                        <ReportTd>{item.number_of_wrong_number}</ReportTd>
                        <ReportTd>{item.report_comment}</ReportTd>
                      </ReportTr>
                    );
                  })}
              </React.Fragment>
            ) : (
              <ReportTr>
                <ReportTd
                  colSpan={13}
                  style={{
                    width: '2000px',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {employeeReportText[language][0]?.notDataOrError}
                </ReportTd>
              </ReportTr>
            )}
          </tbody>
        </ReportTable>
      </TableContainer>
    </Main>
  );
};
