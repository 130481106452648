import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchProductStatus = async () => {
  try {
    const response = await makeRequestSQL.get(
      '/product-availability/product-status',
    );
    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createMutationProductAvailability = async data => {
  try {
    const response = await makeRequestSQL.post('/product-availability', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchProductAvailability = async (search, page, limit, sortBy, order) => {
  try {
    const response = await makeRequestSQL.get('/product-availability', {
      params: { search, page, limit, sortBy, order },
    });

    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateMutationProductAvailability = async data => {
  try {
    const response = await makeRequestSQL.put('/product-availability', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteMutationOneVariation = async ids => {
  try {
    const response = await makeRequestSQL.delete(`/product-availability`, {
      params: {
        ids,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};