import { Main } from '../../Main/Main';
import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import {
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  MonthButton,
  ScheduleMeNavContainer,
  DataSelect,
  DataOption,
  Thead,
  TableContainer,
  ChooseDataContainer,
  CopyNavContainer,
  EmployeeScheduleTd,
  EmployeeScheduleTh,
  AddScheduleContainerModal,
  AddedTimeInput,
  AddedTimeContainer,
  SaveButton,
  CheckSchedule,
  DeclineSchedule,
  ApproveSchedule,
  CategoryContainer,
  CategorySelect,
} from './HourlySchedule,styled';
import { AiOutlineFieldTime } from 'react-icons/ai';
import {
  FaExclamationCircle,
  FaEye,
  FaEyeSlash,
  FaCheck,
  FaTimes,
} from 'react-icons/fa';
import { weekDays, scheduleText } from '../../../services/componentText';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getGradientColor } from '../../../helpers/gradient/getGradientColor';
import { processScheduleDataSingle } from '../../../helpers/changedData/processScheduleDataSingle';
import { processScheduleData } from '../../../helpers/changedData/processScheduleData';
import { mergeData } from '../../../helpers/changedData/mergeData';
import { Modal } from '../../Modal/Modal';
import { IconButton } from '../../Buttons/IconButton.styled';
import {
  formatDateSchedule,
  formatDateToInput,
} from '../../../helpers/dateAndTime/formatDate';
import { MdPublishedWithChanges } from 'react-icons/md';
import { fetchAllSchedule, fetchCategories, bulkUpdateSchedule, createScedule, updateSchedule, deleteScheduleByDay } from '../../../api/schedule';

export const HourlySchedule = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { language } = useContext(UserContext);
  const [selectedStartDate, setSelectedStartDate] = useState(1);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1)?.getDay();
  const [filteredDays, setFilteredDays] = useState(daysInMonth);
  const [statusOneData, setStatusOneData] = useState({});
  const [statusTwoData, setStatusTwoData] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modifiedData, setModifiedData] = useState({});
  const [isModifiedView, setIsModifiedView] = useState(false);
  const [timeSlots, setTimeSlots] = useState([{ start: '', end: '' }]);
  const [time, setTime] = useState('');

  const requiredEmployees = [
    2, 0, 0, 0, 4, 7, 10, 12, 15, 22, 25, 25, 28, 28, 28, 27, 26, 26, 25, 25,
    22, 18, 12, 8,
  ];

  const fetchWorkSchedule = async status => {
      const responseAll = await fetchAllSchedule({
          job_titles: "3, 11",
          year: currentYear,
          month: currentMonth,
          id_status: status,
      });

        if (status === 1 && responseAll) {
          setStatusOneData(responseAll);
        } else if (status === 2 && responseAll) {
          setStatusTwoData(processScheduleData(responseAll));
        }
  };

  const { data: categories } = useQuery('categoriesData', fetchCategories);

  useEffect(() => {
    fetchWorkSchedule(1);
    fetchWorkSchedule(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth, currentYear]);

  useEffect(() => {
    const days = new Date(currentYear, currentMonth, 0).getDate();
    const daysArray = [...Array(days)?.keys()]?.map(day => day + 1);
    setDaysInMonth(daysArray);
    setFilteredDays(daysArray);
  }, [currentMonth, currentYear]);

  const changeMonth = direction => {
    if (direction === 1 && currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(prev => prev + 1);
    } else if (direction === -1 && currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(prev => prev - 1);
    } else {
      setCurrentMonth(prev => prev + direction);
    }
  };

  const handleApplyCustomSchedule = () => {
    if (selectedStartDate && selectedEndDate) {
      const newFilteredDays = daysInMonth.filter(
        day => day >= selectedStartDate && day <= selectedEndDate,
      );
      setFilteredDays(newFilteredDays);
    } else {
      setFilteredDays(daysInMonth);
    }
  };

  const handleDayClick = (day, employeeId) => {
    setTimeSlots(null);
    const dayFromat =
      formatDateSchedule(currentYear, currentMonth, day - 1) + 'T22:00:00.000Z';
    setSelectedDay(day);
    setSelectedEmployeeId(employeeId);

    const employeeSchedulesForDay = statusOneData[employeeId].schedules.filter(
      schedule => schedule.day === dayFromat,
    );

    const slots = employeeSchedulesForDay.map(schedule => ({
      id: schedule.id_schedule,
      start: schedule.start_time || '',
      end: schedule.end_time || '',
    }));

    setTimeSlots(slots);
    setSelectedCategory(employeeSchedulesForDay[0]?.id_categorie || '');

    setModalOpen(true);
  };

  const handleToggleChanges = employeeId => {
    setIsModifiedView(prevState => !prevState);

    if (isModifiedView) {
      return setModifiedData({});
    }

    let newData = processScheduleDataSingle(statusOneData[employeeId]);
    newData = mergeData(newData, statusTwoData);

    return setModifiedData(newData);
  };

  const updateStatusForMultipleSlots = async (
    slotsArray,
    status,
    employeeId,
  ) => {
    const dataForServer = prepareDataForMultipleSlots(
      slotsArray,
      status,
      employeeId,
    );

    try {
      const response = await bulkUpdateSchedule(dataForServer);

      if (response.status === 200) {
        fetchWorkSchedule(1);
        fetchWorkSchedule(2);
        toast.success(scheduleText[language][0]?.successSlot);
      } else {
        fetchWorkSchedule(1);
        fetchWorkSchedule(2);
        toast.error('Деякі оновленя данних не пройшли модерацію');
      }
    } catch (error) {
      fetchWorkSchedule(1);
      fetchWorkSchedule(2);
      toast.error(error.message);
    }
  };

  const prepareDataForMultipleSlots = (slotsArray, status, employeeId) => {
    return slotsArray?.schedules.map(slot => ({
      id_schedule: slot.id_schedule,
      start_time: slot.start_time,
      end_time: slot.end_time,
      id_categorie: slot.id_categorie,
      id_status: status,
      day: slot.day,
      employee_id: employeeId,
    }));
  };

  const updateTimeSlot = (index, field, value) => {
    const updatedSlots = [...timeSlots];
    if (field === 'end' && value < updatedSlots[index].start) {
      toast.warning(scheduleText[language][0]?.warningEndSlot);
      return;
    }

    if (
      field === 'start' &&
      updatedSlots[index].end &&
      value > updatedSlots[index].end
    ) {
      toast.warning(scheduleText[language][0]?.warningStartSlot);
      return;
    }

    if (selectedCategory?.schedule_category_id >= 11) {
      const dataToSend = prepareDataForServer({
        [selectedDay]: timeSlots,
      });

      saveSchedule(dataToSend)
        .then(() => {
          toast.success(scheduleText[language][0]?.successResponseDataBase);
        })
        .catch(() => {
          toast.error(scheduleText[language][0]?.errorResponseDataBase);
        });
    }

    updatedSlots[index][field] = value;
    setTimeSlots(updatedSlots);
  };

  const prepareDataForServer = () => {
    const results = [];
    const categoryId = selectedCategory?.schedule_category_id;

    if (!categoryId) {
      toast.error(
        `${scheduleText[language][0]?.categoryIdMissing} ${selectedDay}`,
      );
      return results;
    }

    timeSlots?.forEach(slot => {
      const startTime = slot?.start?.trim() || null;
      const endTime = slot?.end?.trim() || null;

      if (categoryId <= 10 && (!startTime || !endTime)) {
        toast.error(
          language === 'russian'
            ? `Для дня ${selectedDay} с идентификатором категории ${categoryId} необходимы поля start_time и end_time.`
            : `Для дня ${selectedDay} з ідентифікатором категорії ${categoryId} необхідні поля start_time та end_time.`,
        );
        return;
      }

      results.push({
        employee_id: selectedEmployeeId,
        day: formatDateSchedule(currentYear, currentMonth, selectedDay),
        start_time: startTime,
        end_time: endTime,
        id_categorie: categoryId,
        id_status: 1,
      });
    });

    return results;
  };

  const saveSchedule = async scheduleData => {
      const response = await createScedule(scheduleData);
      if (response.status === 204) {
        fetchWorkSchedule(1);
        fetchWorkSchedule(2);
      }
  };

  const updateSingleSlotToServer = async (slot, index) => {
    const dataForServer = prepareDataForSingleSlot(slot);

      if (dataForServer?.id_schedule) {
    const response = await updateSchedule({id_schedule: dataForServer.id_schedule, data: dataForServer})
        if (response.status === 200) {
          fetchWorkSchedule(1);
          fetchWorkSchedule(2);
        }
        toast.success(scheduleText[language][0]?.successSlot);
        setModalOpen(false);
      }
  };

  const prepareDataForSingleSlot = slot => {
    return {
      employee_id: selectedEmployeeId,
      id_schedule: slot.id || null,
      start_time:
        selectedCategory?.schedule_category_id <= 10
          ? slot?.start.trim()
          : null,
      end_time:
        selectedCategory?.schedule_category_id <= 10 ? slot?.end.trim() : null,
      id_categorie: slot.category || selectedCategory?.schedule_category_id,
      id_status: 1,
      day: selectedDay,
    };
  };

  const addTimeSlot = () => {
    setTimeSlots(prev => [...prev, { start: '', end: '' }]);
  };

  const handleSave = () => {
    const fullScheduleData = prepareDataForServer();
    mutation.mutate(fullScheduleData);
  };
  const areTimeSlotsValid = () => {
    if (selectedCategory?.schedule_category_id >= 11) {
      return true;
    }

    return timeSlots.every(slot => slot.start < slot.end);
  };

  const handleConfirmDelete = async () => {
    const fullDate = `${currentYear}-${currentMonth}-${selectedDay}`;
      const response = await deleteScheduleByDay({
        employee_id: selectedEmployeeId,
        day: await formatDateToInput(fullDate),
        status: 1,
      });
      if (response.status === 200) {
        fetchWorkSchedule(1);
        fetchWorkSchedule(2);
        toast.success(scheduleText[language][0]?.successDelete);
        setModalOpen(false);
      }
  };

  const mutation = useMutation(saveSchedule, {
    onSuccess: () => {
      setModalOpen(false);
      toast.success(scheduleText[language][0]?.successSchedule);
    },
    onError: error => {
      toast.error(scheduleText[language][0]?.errorSchedule, error);
    },
  });

  useEffect(() => {
    setTime(`02:00`);
  }, []);

  return (
    <Main>
      {isModalOpen && (
        <Modal closeModal={() => setModalOpen(false)}>
          <AddScheduleContainerModal>
            <h2>
              День: {selectedDay >= 10 ? selectedDay : `0${selectedDay}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
            </h2>
            <CategorySelect
              value={
                selectedCategory ? selectedCategory?.schedule_category_id : ''
              }
              onChange={e => {
                const categoryId = Number(e.target.value);
                const categoryObj = categories?.find(
                  cat => cat.schedule_category_id === categoryId,
                );
                setSelectedCategory(categoryObj);
              }}
              style={{
                marginBottom: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <DataOption>
                {scheduleText[language][0]?.chooseCategory}
              </DataOption>
              {categories?.map(category => (
                <DataOption
                  key={category?.schedule_category_id}
                  value={category?.schedule_category_id}
                >
                  {language === 'russian'
                    ? category?.schedule_categorie_name_RU
                    : category?.schedule_categorie_name_UA}
                </DataOption>
              ))}
            </CategorySelect>

            {selectedCategory &&
              (selectedCategory?.schedule_category_id <= 10 ||
                selectedCategory?.schedule_category_id >= 18) &&
              timeSlots?.map((slot, index) => (
                <AddedTimeContainer key={index}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AddedTimeInput
                      type="time"
                      value={slot.start ? slot.start : time}
                      onChange={e =>
                        updateTimeSlot(index, 'start', e.target.value)
                      }
                    />
                    -
                    <AddedTimeInput
                      type="time"
                      value={slot.end ? slot.end : time}
                      onChange={e =>
                        updateTimeSlot(index, 'end', e.target.value)
                      }
                    />
                    {slot?.start && slot?.end && (
                      <div>
                        <IconButton
                          onClick={() => updateSingleSlotToServer(slot, index)}
                          style={{ marginBottom: '10px' }}
                        >
                          <MdPublishedWithChanges size="28px" />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </AddedTimeContainer>
              ))}

            <SaveButton
              onClick={addTimeSlot}
              disabled={timeSlots?.some(slot => !slot.start || !slot.end)}
            >
              {scheduleText[language][0]?.addSlot}
            </SaveButton>

            <SaveButton
              onClick={handleSave}
              disabled={!areTimeSlotsValid()}
              style={{ marginTop: '10px' }}
            >
              {scheduleText[language][0]?.save}
            </SaveButton>

            <SaveButton
              onClick={() => handleConfirmDelete()}
              style={{ marginTop: '10px' }}
            >
              {scheduleText[language][0]?.deleteSchedule}
            </SaveButton>
          </AddScheduleContainerModal>
        </Modal>
      )}
      <ScheduleMeNavContainer>
        <MonthButton onClick={() => changeMonth(-1)}>
          {scheduleText[language][0]?.prevMonth}
        </MonthButton>
        <MonthButton onClick={() => changeMonth(1)}>
          {scheduleText[language][0]?.nextMonth}
        </MonthButton>
      </ScheduleMeNavContainer>
      <CopyNavContainer>
        <ChooseDataContainer>
          <span>{scheduleText[language][0]?.dateStartPeriod} </span>
          <DataSelect
            value={selectedStartDate || ''}
            onChange={e => setSelectedStartDate(Number(e.target.value))}
          >
            <DataOption value="">
              {scheduleText[language][0]?.chooseDate}
            </DataOption>
            {daysInMonth?.map(day => (
              <DataOption key={day} value={day}>
                  {day >= 10 ? day : `0${day}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
              </DataOption>
            ))}
          </DataSelect>
        </ChooseDataContainer>

        <ChooseDataContainer>
          <span>{scheduleText[language][0]?.dateEndPeriod} </span>
          <DataSelect
            value={selectedEndDate || ''}
            onChange={e => setSelectedEndDate(Number(e.target.value))}
          >
            <DataOption value="">
              {scheduleText[language][0]?.chooseDate}
            </DataOption>
            {daysInMonth?.map(day => (
              <DataOption key={day} value={day}>
                  {day >= 10 ? day : `0${day}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
              </DataOption>
            ))}
          </DataSelect>
        </ChooseDataContainer>

        <MonthButton
          onClick={handleApplyCustomSchedule}
          style={{ marginLeft: '10px' }}
        >
          {scheduleText[language][0]?.changeSchedule}
        </MonthButton>
      </CopyNavContainer>

      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh>
                <AiOutlineFieldTime size="24px" />
              </ScheduleTh>
              {filteredDays?.map(day => (
                <ScheduleTh key={day}>
                  {day >= 10 ? day : `0${day}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                </ScheduleTh>
              ))}
              <ScheduleTh>
                <FaExclamationCircle size="24px" />
              </ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {Array.from({ length: 24 }).map((_, idx) => {
              const hour = Math.floor(idx);
              return (
                <ScheduleTr key={idx}>
                  <ScheduleTd>{`${idx}:00 - ${idx + 1}:00`}</ScheduleTd>
                  {filteredDays?.map(day => {
                    const modifiedEmployees = Math.round(
                      modifiedData[day]?.[hour] ?? 0,
                    );
                    const statusTwoEmployees = Math.round(
                      statusTwoData[day]?.[hour] ?? 0,
                    );
                    const currentEmployees = isModifiedView
                      ? modifiedEmployees
                      : statusTwoEmployees;
                    const bgColor = getGradientColor(
                      currentEmployees,
                      requiredEmployees[idx],
                    );
                    return (
                      <ScheduleTd
                        key={day}
                        style={{
                          backgroundColor: bgColor,
                          color: bgColor ? '#000000' : '#ffffff',
                          fontWeight: '700',
                          fontSize: '18px',
                        }}
                      >
                        {currentEmployees}
                      </ScheduleTd>
                    );
                  })}

                  <ScheduleTd
                    style={{
                      backgroundColor: 'green',
                      color: '#ffffff',
                      fontWeight: '700',
                      fontSize: '18px',
                    }}
                  >
                    {requiredEmployees[idx]}
                  </ScheduleTd>
                </ScheduleTr>
              );
            })}

            <ScheduleTr>
              <ScheduleTd>{scheduleText[language][0]?.allOnTheDay}</ScheduleTd>
              {filteredDays?.map(day => {
                let totalHoursForDay = 0;

                for (let hour = 0; hour < 24; hour++) {
                  const currentEmployees = isModifiedView
                    ? modifiedData[day] && modifiedData[day][hour]
                      ? modifiedData[day][hour]
                      : 0
                    : statusTwoData[day] && statusTwoData[day][hour]
                    ? statusTwoData[day][hour]
                    : 0;

                  totalHoursForDay += currentEmployees;
                }
                const bgColorForTotalHours = getGradientColor(
                  totalHoursForDay,
                  355,
                );
                return (
                  <ScheduleTd
                    key={day}
                    style={{
                      backgroundColor: bgColorForTotalHours,
                      color: bgColorForTotalHours ? '#000000' : '#ffffff',
                      fontWeight: '700',
                      fontSize: '18px',
                    }}
                  >
                    {totalHoursForDay}
                  </ScheduleTd>
                );
              })}
              <ScheduleTd
                style={{
                  backgroundColor: 'green',
                  color: '#ffffff',
                  fontWeight: '700',
                  fontSize: '18px',
                }}
              >
                355
              </ScheduleTd>
            </ScheduleTr>
          </tbody>
        </ScheduleTable>

        {Object?.keys(statusOneData)?.length > 0 && (
          <ScheduleTable>
            <Thead>
              <ScheduleHeadTr>
                <ScheduleTh>{scheduleText[language][0]?.fullName}</ScheduleTh>
                <EmployeeScheduleTh>
                  {scheduleText[language][0]?.actions}
                </EmployeeScheduleTh>
                {filteredDays?.map((day, idx) => {
                  const adjustedIdx = (idx + firstDayOfMonth - 1) % 7;
                  return (
                    <EmployeeScheduleTh key={idx}>
                      {
                        weekDays[language][0][
                          Object.keys(weekDays[language][0])[adjustedIdx]
                        ]
                      }
                      <br />
                      {day >= 10 ? day : `0${day}`}.
                      {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                    </EmployeeScheduleTh>
                  );
                })}
              </ScheduleHeadTr>
            </Thead>
            <tbody>
              {Object?.entries(statusOneData)?.map(
                ([employeeId, employeeDetails], rowIndex) => {
                  return (
                    <ScheduleTr key={employeeId}>
                      <ScheduleTd>{employeeDetails.employeeName}</ScheduleTd>
                      <EmployeeScheduleTd>
                        <CheckSchedule
                          onClick={() => handleToggleChanges(employeeId)}
                        >
                          {isModifiedView ? <FaEyeSlash /> : <FaEye />}
                        </CheckSchedule>

                        <ApproveSchedule
                          onClick={() =>
                            updateStatusForMultipleSlots(
                              statusOneData[employeeId],
                              2,
                              employeeId,
                            )
                          }
                        >
                          <FaCheck />
                        </ApproveSchedule>
                        <DeclineSchedule
                          onClick={() =>
                            updateStatusForMultipleSlots(
                              statusOneData[employeeId],
                              3,
                              employeeId,
                            )
                          }
                        >
                          <FaTimes />
                        </DeclineSchedule>
                      </EmployeeScheduleTd>
                      {filteredDays?.map(day => {
                        const schedulesForDay =
                          employeeDetails.schedules.filter(sched => {
                            const scheduleDate = new Date(sched.day);
                            scheduleDate.setUTCDate(
                              scheduleDate.getUTCDate() + 1,
                            );
                            return scheduleDate.getUTCDate() === day;
                          });

                        return (
                          <EmployeeScheduleTd
                            key={day}
                            onClick={
                              day ? () => handleDayClick(day, employeeId) : null
                            }
                            categoryId={schedulesForDay[0]?.id_categorie}
                          >
                            {schedulesForDay?.map((schedule, idx) => (
                              <div key={schedule.id_status + idx}>
                                {schedule?.id_categorie > 6 ? (
                                  <>
                                    <CategoryContainer
                                      style={{ marginTop: '8px' }}
                                    >
                                      {language === 'russian'
                                        ? schedule?.schedule_categorie_name_RU
                                        : schedule?.schedule_categorie_name_UA}
                                    </CategoryContainer>
                                    <div>
                                      {schedule?.start_time
                                        ? `${schedule?.start_time?.slice(
                                            0,
                                            5,
                                          )}-${schedule?.end_time?.slice(0, 5)}`
                                        : ''}
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    {schedule?.start_time
                                      ? `${schedule?.start_time?.slice(
                                          0,
                                          5,
                                        )}-${schedule?.end_time?.slice(0, 5)}`
                                      : ''}
                                  </div>
                                )}
                              </div>
                            ))}
                          </EmployeeScheduleTd>
                        );
                      })}
                    </ScheduleTr>
                  );
                },
              )}
            </tbody>
          </ScheduleTable>
        )}
      </TableContainer>
    </Main>
  );
};
