import React from 'react';
import { useState } from 'react';

export const NavContext = React.createContext();

export const NavContextProvider = ({ children }) => {
    const [isNavVisible, setNavVisible] = useState(true);

          const toggleNav = () => {
            setNavVisible(!isNavVisible);
    };
    
    return (
      <NavContext.Provider value={{ isNavVisible, toggleNav }}>
        {children}
      </NavContext.Provider>
    );
}
