import { toast } from 'react-toastify';

export const processScheduleDataSingle = scheduleData => {
  try {
    const transformedData = {};
    const schedules = scheduleData?.schedules;

    if (!schedules) return {};

    for (let entry of schedules) {
      const startTimeSplit = entry.start_time?.split(':');
      const endTimeSplit = entry.end_time?.split(':');
      const startHour = parseInt(startTimeSplit?.[0], 10);
      const startMinutes = parseInt(startTimeSplit?.[1], 10);
      const endHour = parseInt(endTimeSplit?.[0], 10);
      const endMinutes = parseInt(endTimeSplit?.[1], 10);

      if (
        isNaN(startHour) ||
        isNaN(startMinutes) ||
        isNaN(endHour) ||
        isNaN(endMinutes)
      )
        continue;

      const date = new Date(entry.day);
      date.setUTCDate(date.getUTCDate() + 1);
      const day = date.getUTCDate();

      for (let hour = startHour; hour <= endHour; hour++) {
        if (hour === endHour && endMinutes === 0) break; 

        let workHours = 1;
        if (hour === startHour && startMinutes === 30) {
          workHours = 0.5;
        } else if (hour === endHour && endMinutes === 30) {
          workHours = 0.5;
        }

        if (!transformedData[day]) transformedData[day] = {};
        if (!transformedData[day][hour]) transformedData[day][hour] = 0;

        transformedData[day][hour] += workHours;
      }
    }

    return transformedData;
  } catch (error) {
    toast.error('Error on processScheduleDataSingle');
    console.error(error);
  }
};
