import React from 'react';
import {
  PaginationItem,
  PaginationList,
  PaginationArrowLeft,
  PaginationArrowRight,
} from './Pagination.styled';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pageNumbers = [];

    if (currentPage === 1 || totalPages <= 0) {
      for (let i = currentPage + 1; i <= currentPage + 2; i++) {
        if (i <= totalPages && i >= 1) {
          pageNumbers.push(i);
        }
      }
    } else if (currentPage === totalPages) {
      for (let i = currentPage - 2; i <= currentPage - 1; i++) {
        if (i >= 1) {
          pageNumbers.push(i);
        }
      }
    } else {
      pageNumbers.push(currentPage - 1);
      pageNumbers.push(currentPage);
      pageNumbers.push(Number(currentPage) + Number(1));
    }

    return pageNumbers;
  };

  const handlePageChange = page => {
    onPageChange(page);
  };

  return (
    <PaginationList>
      {currentPage === 1 && totalPages > 0 && (
        <PaginationItem
          onClick={() => handlePageChange(1)}
          className={1 === currentPage ? 'active' : ''}
        >
          1
        </PaginationItem>
      )}

      {currentPage > 1 && (
        <>
          <PaginationArrowLeft onClick={() => onPageChange(currentPage - 1)}>
            <FaArrowLeft />
          </PaginationArrowLeft>
        </>
      )}

      {currentPage > 2 && totalPages > 0 && (
        <>
          <PaginationItem
            onClick={() => handlePageChange(1)}
            className={1 === currentPage ? 'active' : ''}
          >
            1
          </PaginationItem>
          <PaginationItem
            onClick={() => onPageChange(Math.max(1, currentPage - 3))}
          >
            ...
          </PaginationItem>
        </>
      )}

      {getPageNumbers().map(pageNumber => (
        <PaginationItem
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          className={pageNumber === currentPage ? 'active' : ''}
        >
          {pageNumber}
        </PaginationItem>
      ))}

      {currentPage === totalPages && totalPages > 0 && (
        <PaginationItem
          onClick={() => handlePageChange(totalPages)}
          className={totalPages === currentPage ? 'active' : ''}
        >
          {totalPages}
        </PaginationItem>
      )}

      {currentPage < totalPages - 3 && totalPages > 0 && (
        <>
          <PaginationItem
            onClick={() =>
              onPageChange(
                Math.min(totalPages, Number(currentPage) + Number(3)),
              )
            }
          >
            ...
          </PaginationItem>
        </>
      )}

      {currentPage < totalPages - 1 && totalPages > 0 && (
        <>
          <PaginationItem
            onClick={() => handlePageChange(totalPages)}
            className={totalPages === currentPage ? 'active' : ''}
          >
            {totalPages}
          </PaginationItem>
        </>
      )}

      {currentPage < totalPages && (
        <>
          <PaginationArrowRight onClick={() => onPageChange(currentPage + 1)}>
            <FaArrowRight />
          </PaginationArrowRight>
        </>
      )}
    </PaginationList>
  );
};
