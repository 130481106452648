import { Main } from '../../Main/Main';
import { UserContext } from '../../Context/UserContext';
import React, { useContext, useState, useEffect } from 'react';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from '../../Modal/Modal';
import {
  IconButton,
  TrashButton,
  IconLinkContainer,
  AddedSelect,
  AddedOption,
  SearchContainer,
  SearchInput,
  SearchButton,
  SaveButton,
  NavContainer,
  AddedNewButton,
  Thead,
  TableContainer,
  Table,
  Tr,
  HeadTr,
  Td,
  Th,
  ClaimFaCheckCircle,
  ClaimFaMinusCircle,
  ClientConfiguredTd,
  VarificationTd,
  RefundTd,
  ClaimTd,
} from './ClaimsList.styled';
import { claimsControlPanelText } from '../../../services/componentText';
import { fetchUsers } from '../../../api/users';
import { PageLimitSelector } from '../../PageLimitSelector/PageLimitSelector';
import { AiOutlineSearch } from 'react-icons/ai';
import { Pagination } from '../../Pagination/Pagination';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { updateClaims, fetchClaims } from '../../../api/claims';
import {
  fetchClaimsCategory,
  fetchWarehouseCity,
  fetchClaimsStatus,
  fetchClientConfigured,
  fetchRefundStatus,
  fetchReasonReturn,
} from '../../../api/claimsControlPanel';
import { getClientConfiguredBackgroundColor } from '../../../helpers/backgroundColor/getClientConfiguredBackgroundColor';
import { getVarificationBackgroundColor } from '../../../helpers/backgroundColor/getVarificationBackgroundColor';
import { getRefundStatusBackgroundColor } from '../../../helpers/backgroundColor/getRefundStatusBackgroundColor';
import { getClaimStatusBackgroundColor } from '../../../helpers/backgroundColor/getClaimStatusBackgroundColor';

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const ClaimsList = () => {
  const { currentUser, language } = useContext(UserContext);
  const navigate = useNavigate();
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedClaimCategory, setSelectedClaimCategory] = useState('');
  const [
    selectedClaimCategoryAfterReCall,
    setSelectedClaimCategoryAfterReCall,
  ] = useState('');
  const [selectedClientType, setSelectedClientType] = useState('');
  const [selectedRefundStatus, setSelectedRefundStatus] = useState('');
  const [selectedRefundStatusFromCompany, setSelectedRefundStatusFromCompany] =
    useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [selectedReasonReturn, setSelectedReasonReturn] = useState('');
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const [sortBy, setSortBy] = useState('createdAt');
  const [order, setOrder] = useState('DESC');
  const [searchQuery, setSearchQuery] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const { data: claimsData, refetch } = useQuery(
    [
      'claimsData',
      formattedStartDateRequest,
      formattedEndDateRequest,
      selectedEmployee,
      selectedWarehouse,
      selectedClaimCategory,
      selectedClientType,
      selectedClaimCategoryAfterReCall,
      selectedRefundStatus,
      selectedRefundStatusFromCompany,
      selectedReasonReturn,
      currentPage,
      pageLimit,
    ],
    () =>
      fetchClaims({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        employee_id: selectedEmployee,
        warehouse_city_id: selectedWarehouse,
        claims_category_id: selectedClaimCategory,
        client_configured_id: selectedClientType,
        verification_status_id: selectedClaimCategoryAfterReCall,
        claims_status_id: selectedRefundStatusFromCompany,
        refund_status_id: selectedRefundStatus,
        reason_return_id: selectedReasonReturn,
        search: searchQuery,
        page: currentPage,
        limit: pageLimit,
      }),
  );

  const { data: claimsCategoryData } = useQuery(
    'fetchClaimsCategory',
    fetchClaimsCategory,
  );
  const { data: warehouseData } = useQuery(
    'fetchWarehouseCity',
    fetchWarehouseCity,
  );
  const { data: claimStatusData } = useQuery(
    'fetchClaimsStatus',
    fetchClaimsStatus,
  );
  const { data: clientConfiguredData } = useQuery(
    'fetchClientConfigured',
    fetchClientConfigured,
  );
  const { data: refundStatusData } = useQuery(
    'fetchRefundStatus',
    fetchRefundStatus,
  );

  const { data: reasonReturnData } = useQuery(
    'reasonReturnData',
    fetchReasonReturn,
  );

  const { data: usersData } = useQuery('users', fetchUsers);

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const handleSearch = event => {
    event?.preventDefault();
    setSearchQuery(inputValue);
    refetch();
  };

  const handlePageLimitChange = newPageLimit => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (claimsData?.data) {
      setTotalPages(claimsData?.data?.totalPages);
    }
  }, [claimsData?.data, pageLimit]);

  const handleClearValue = () => {
    setSelectedEmployee('');
    setSelectedClaimCategory('');
    setSelectedClaimCategoryAfterReCall('');
    setSelectedClientType('');
    setSelectedRefundStatus('');
    setSelectedRefundStatusFromCompany('');
    setSelectedWarehouse('');
    setSelectedReasonReturn('');
    setFiltersLoaded(false);
    setInputValue('');
    setSearchQuery('');

    const defaultFilters = {
      selectedEmployee: '',
      selectedClaimCategory: '',
      selectedClaimCategoryAfterReCall: '',
      selectedClientType: '',
      selectedRefundStatus: '',
      selectedRefundStatusFromCompany: '',
      selectedWarehouse: '',
      selectedReasonReturn: '',
    };
    localStorage.setItem('claimsFilters', JSON.stringify(defaultFilters));
  };
  

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSubmit = async item => {
    const response = await updateClaims(item);

    if (response?.status === 201) {
      toast.success(claimsControlPanelText[language][0]?.successUpdate);
      return refetch();
    }
  };

  const handleClick = id => {
    return navigate(`/claims/change-claim/${id}`);
  };

  useEffect(() => {
    let savedFilters = localStorage.getItem('claimsFilters');
    if (!savedFilters) {
      const defaultFilters = {
        selectedEmployee: '',
        selectedClaimCategory: '',
        selectedClaimCategoryAfterReCall: '',
        selectedClientType: '',
        selectedRefundStatus: '',
        selectedRefundStatusFromCompany: '',
        selectedWarehouse: '',
        selectedReasonReturn: '',
      };
      localStorage.setItem('claimsFilters', JSON.stringify(defaultFilters));
      savedFilters = JSON.stringify(defaultFilters);
    }
    const parsedFilters = JSON.parse(savedFilters);
    setSelectedEmployee(parsedFilters.selectedEmployee);
    setSelectedClaimCategory(parsedFilters.selectedClaimCategory);
    setSelectedClaimCategoryAfterReCall(parsedFilters.selectedClaimCategoryAfterReCall);
    setSelectedClientType(parsedFilters.selectedClientType);
    setSelectedRefundStatus(parsedFilters.selectedRefundStatus);
    setSelectedRefundStatusFromCompany(parsedFilters.selectedRefundStatusFromCompany);
    setSelectedWarehouse(parsedFilters.selectedWarehouse);
    setSelectedReasonReturn(parsedFilters.selectedReasonReturn);
    setFiltersLoaded(true);
  }, []);
  
  

  useEffect(() => {
    if (filtersLoaded) {
      const filtersToSave = {
        selectedEmployee,
        selectedClaimCategory,
        selectedClaimCategoryAfterReCall,
        selectedClientType,
        selectedRefundStatus,
        selectedRefundStatusFromCompany,
        selectedWarehouse,
        selectedReasonReturn,
      };
      localStorage.setItem('claimsFilters', JSON.stringify(filtersToSave));
    }
  }, [
    filtersLoaded,
    selectedEmployee,
    selectedClaimCategory,
    selectedClaimCategoryAfterReCall,
    selectedClientType,
    selectedRefundStatus,
    selectedRefundStatusFromCompany,
    selectedWarehouse,
    selectedReasonReturn,
  ]);

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
          {claimsControlPanelText[language][0]?.choosePeriod}
        </AddedNewButton>

        <AddedNewButton onClick={() => handleClearValue()}>
          {claimsControlPanelText[language][0]?.clearAllSearchValue}
        </AddedNewButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {claimsControlPanelText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <SearchContainer onSubmit={e => handleSearch(e)}>
          <SearchInput
            type="text"
            placeholder={claimsControlPanelText[language][0]?.enterSearchQuery}
            value={inputValue}
            onChange={e => setInputValue(e?.target?.value)}
            onKeyDown={handleKeyPress}
          />
          <SearchButton type="submit" onClick={e => handleSearch(e)}>
            <AiOutlineSearch size="24px" />
          </SearchButton>
        </SearchContainer>
        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </div>

      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {accessLevel && (
          <div>
            <label>
              {claimsControlPanelText[language][0]?.chooseManagerOne}
            </label>
            <AddedSelect
              value={selectedEmployee || ''}
              onChange={e => setSelectedEmployee(e.target.value)}
              style={{ backgroundColor: '#f5f7fa' }}
            >
              <AddedOption value="">
                {claimsControlPanelText[language][0]?.chooseManagerTwo}
              </AddedOption>
              {usersData?.usersData?.map(
                user =>
                  Number(user?.id_job_title) === 9 && (
                    <AddedOption value={user?.id_employee} key={user?.id_employee}>
                      {user?.full_name}
                    </AddedOption>
                  ),
              )}
            </AddedSelect>
          </div>
        )}
        <div style={{ marginLeft: '10px' }}>
          <label>
            {claimsControlPanelText[language][0]?.chooseCallResultOne}
          </label>
          <AddedSelect
            value={selectedClaimCategory || ''}
            onChange={e => setSelectedClaimCategory(e.target.value)}
            style={{ backgroundColor: '#f5f7fa' }}
          >
            <AddedOption value="">
              {claimsControlPanelText[language][0]?.chooseCallResultTwo}
            </AddedOption>
            {claimsCategoryData?.map(item => (
              <AddedOption value={item?.id} key={item?.id}>
                {language === 'russian'
                  ? item?.category_title_ru
                  : item?.category_title_ua}
              </AddedOption>
            ))}
          </AddedSelect>
        </div>

        <div style={{ marginLeft: '10px' }}>
          <label>
            {claimsControlPanelText[language][0]?.chooseClientTypeOne}
          </label>
          <AddedSelect
            value={selectedClientType || ''}
            onChange={e => setSelectedClientType(e.target.value)}
            style={{ backgroundColor: '#f5f7fa' }}
          >
            <AddedOption value="">
              {claimsControlPanelText[language][0]?.chooseClientTypeTwo}
            </AddedOption>
            {clientConfiguredData?.map(item => (
              <AddedOption value={item?.id} key={item?.id}>
                {language === 'russian'
                  ? item?.configured_title_ru
                  : item?.configured_title_ua}
              </AddedOption>
            ))}
          </AddedSelect>
        </div>
      </div>

      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <label>
            {
              claimsControlPanelText[language][0]
                ?.chooseCallResultOneAfterReCallOne
            }
          </label>
          <AddedSelect
            value={selectedClaimCategoryAfterReCall || ''}
            onChange={e => setSelectedClaimCategoryAfterReCall(e.target.value)}
            style={{ backgroundColor: '#f5f7fa' }}
          >
            <AddedOption value="">
              {
                claimsControlPanelText[language][0]
                  ?.chooseCallResultOneAfterReCallTwo
              }
            </AddedOption>
            {claimsCategoryData?.map(item => (
              <AddedOption value={item?.id} key={item?.id}>
                {language === 'russian'
                  ? item?.category_title_ru
                  : item?.category_title_ua}
              </AddedOption>
            ))}
          </AddedSelect>
        </div>

        <div style={{ marginLeft: '10px' }}>
          <label>
            {claimsControlPanelText[language][0]?.chooseRefundFromCompanyOne}
          </label>
          <AddedSelect
            value={selectedRefundStatusFromCompany || ''}
            onChange={e => setSelectedRefundStatusFromCompany(e.target.value)}
            style={{ backgroundColor: '#f5f7fa' }}
          >
            <AddedOption value="">
              {claimsControlPanelText[language][0]?.chooseRefundFromCompanyTwo}
            </AddedOption>
            {claimStatusData?.map(item => (
              <AddedOption value={item?.id} key={item?.id}>
                {language === 'russian'
                  ? item?.status_title_ru
                  : item?.status_title_ua}
              </AddedOption>
            ))}
          </AddedSelect>
        </div>
      </div>

      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <label>
            {claimsControlPanelText[language][0]?.chooseWarehouseOne}
          </label>
          <AddedSelect
            value={selectedWarehouse || ''}
            onChange={e => setSelectedWarehouse(e.target.value)}
            style={{ backgroundColor: '#f5f7fa' }}
          >
            <AddedOption value="">
              {claimsControlPanelText[language][0]?.chooseWarehouseTwo}
            </AddedOption>
            {warehouseData?.map(item => (
              <AddedOption value={item?.id} key={item?.id}>
                {language === 'russian'
                  ? item?.city_name_ru
                  : item?.city_name_ua}
              </AddedOption>
            ))}
          </AddedSelect>
        </div>

        <div style={{ marginLeft: '10px' }}>
          <label>
            {claimsControlPanelText[language][0]?.chooseRefundStatusOne}
          </label>
          <AddedSelect
            value={selectedRefundStatus || ''}
            onChange={e => setSelectedRefundStatus(e.target.value)}
            style={{ backgroundColor: '#f5f7fa' }}
          >
            <AddedOption value="">
              {claimsControlPanelText[language][0]?.chooseRefundStatusTwo}
            </AddedOption>
            {refundStatusData?.map(item => (
              <AddedOption value={item?.id} key={item?.id}>
                {language === 'russian'
                  ? item?.refund_status_ru
                  : item?.refund_status_ua}
              </AddedOption>
            ))}
          </AddedSelect>
        </div>
      </div>

      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <label>
            {claimsControlPanelText[language][0]?.chooseReturnReasom}
          </label>
          <AddedSelect
            value={selectedReasonReturn || ''}
            onChange={e => setSelectedReasonReturn(e.target.value)}
            style={{ backgroundColor: '#f5f7fa' }}
          >
            <AddedOption value="">
              {claimsControlPanelText[language][0]?.chooseReturnReasom}
            </AddedOption>
            {reasonReturnData?.map(item => (
              <AddedOption value={item?.id} key={item?.id}>
                {language === 'russian'
                  ? item?.reason_title_ru
                  : item?.reason_title_ua}
              </AddedOption>
            ))}
          </AddedSelect>
        </div>
      </div>

      <TableContainer>
        <Table>
          <Thead>
            <HeadTr>
              <Th onClick={() => handleSortChange('createdAt')}>
                {claimsControlPanelText[language][0]?.createdDate}
                {renderSortIcon('createdAt')}
              </Th>
              <Th onClick={() => handleSortChange('full_name')}>
                {claimsControlPanelText[language][0]?.employeeFullName}
                {renderSortIcon('full_name')}
              </Th>
              <Th>{claimsControlPanelText[language][0]?.orderId}</Th>
              <Th>{claimsControlPanelText[language][0]?.productTitle}</Th>
              <Th>{claimsControlPanelText[language][0]?.claimsDescribe}</Th>
              <Th>{claimsControlPanelText[language][0]?.ifClientAddress}</Th>
              <Th>{claimsControlPanelText[language][0]?.clientPhoneNumber}</Th>
              <Th>{claimsControlPanelText[language][0]?.clientFullname}</Th>
              <Th>{claimsControlPanelText[language][0]?.ifCashRefund}</Th>
              <Th>{claimsControlPanelText[language][0]?.callStatus}</Th>
              <Th>{claimsControlPanelText[language][0]?.clientType}</Th>
              <Th>{claimsControlPanelText[language][0]?.checkClientTrue}</Th>
              <Th>{claimsControlPanelText[language][0]?.refundOurAddress}</Th>
              <Th>{claimsControlPanelText[language][0]?.comment}</Th>
              <Th>{claimsControlPanelText[language][0]?.inspectionResults}</Th>
              <Th>{claimsControlPanelText[language][0]?.sendSentOrMoney}</Th>
              <Th>{claimsControlPanelText[language][0]?.ttnClient}</Th>
              <Th>{claimsControlPanelText[language][0]?.redundStatus}</Th>
              <Th>{claimsControlPanelText[language][0]?.ttnWarehouse}</Th>
              <Th>{claimsControlPanelText[language][0]?.controlPanel}</Th>
            </HeadTr>
          </Thead>
          <tbody>
            {claimsData?.data?.length > 0 ? (
              <React.Fragment>
                {claimsData?.data?.map(item => {
                  const findVarificationCategory = claimsCategoryData?.filter(
                    category => category?.id === item?.verification_status_id,
                  );
                  const clientConfiguredBackgroundColor =
                    getClientConfiguredBackgroundColor(
                      item?.client_configured_id,
                    );
                  const varificationBackgroundColor =
                    getVarificationBackgroundColor(item.verification_status_id);
                  const refundStatusBackgroundColor =
                    getRefundStatusBackgroundColor(item.refund_status_id);
                  const claimStatusBackgroundColor =
                    getClaimStatusBackgroundColor(item.claims_status_id);

                  return (
                    <Tr key={item.id}>
                      <Td onClick={() => handleClick(item.id)}>
                        {formattedDateToDayMonthYear(item.createdAt)}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.employee_full_name}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.order_id}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.product_title}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {language === 'russian'
                          ? item.reason_title_ru
                          : item.reason_title_ua}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.client_address}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.phone_number}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.full_name}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.client_detail}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {language === 'russian'
                          ? item.category_title_ru
                          : item.category_title_ua}
                      </Td>
                      <ClientConfiguredTd
                        backgroundcolor={clientConfiguredBackgroundColor}
                        onClick={() => handleClick(item.id)}
                      >
                        {language === 'russian'
                          ? item.configured_title_ru
                          : item.configured_title_ua}
                      </ClientConfiguredTd>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.is_client_lie === 1 && (
                          <ClaimFaCheckCircle fill="green" />
                        )}
                        {item.is_client_lie === 2 && (
                          <ClaimFaMinusCircle fill="red" />
                        )}
                        {item.is_client_lie !== 2 &&
                          item.is_client_lie !== 1 && (
                            <ClaimFaMinusCircle fill="gray" />
                          )}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.address_for_refund}
                      </Td>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.employee_describe}
                      </Td>
                      <VarificationTd
                        backgroundcolor={varificationBackgroundColor}
                        onClick={() => handleClick(item.id)}
                      >
                        {language === 'russian' &&
                        findVarificationCategory &&
                        findVarificationCategory.length > 0
                          ? findVarificationCategory[0]?.category_title_ru
                          : findVarificationCategory &&
                            findVarificationCategory.length > 0
                          ? findVarificationCategory[0]?.category_title_ua
                          : ''}
                      </VarificationTd>

                      <ClaimTd
                        backgroundcolor={claimStatusBackgroundColor}
                        onClick={() => handleClick(item.id)}
                      >
                        {language === 'russian'
                          ? item.status_title_ru
                          : item.status_title_ua}
                      </ClaimTd>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.ttn_client_number}
                      </Td>
                      <RefundTd
                        backgroundcolor={refundStatusBackgroundColor}
                        onClick={() => handleClick(item.id)}
                      >
                        {language === 'russian'
                          ? item.refund_status_ru
                          : item.refund_status_ua}
                      </RefundTd>
                      <Td onClick={() => handleClick(item.id)}>
                        {item.ttn_warehouse_number}
                      </Td>
                      <Td>
                        <IconLinkContainer>
                          <IconButton to={`/claims/change-claim/${item.id}`}>
                            <FaEdit />
                          </IconButton>
                          <TrashButton
                            onClick={() => {
                              const updatedClaim = {
                                ...item,
                                status_message_id: 4,
                              };
                              handleSubmit(updatedClaim);
                            }}
                          >
                            <FaTrash />
                          </TrashButton>
                        </IconLinkContainer>
                      </Td>
                    </Tr>
                  );
                })}
              </React.Fragment>
            ) : (
              <Tr>
                <Td
                  colSpan={13}
                  style={{
                    width: '2000px',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {claimsControlPanelText[language][0]?.notDataOrError}
                </Td>
              </Tr>
            )}
          </tbody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
