import { Main } from '../../Main/Main';
import { Modal } from '../../Modal/Modal';
import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { fetchTotalCountEmployeeReport } from '../../../api/report';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  AddedNewReportButton,
  SaveButton,
  ReportTh,
  ReportTd,
  ReportHeadTr,
  ReportTr,
  ReportTable,
  TableContainer,
  Thead,
  NavContainer,
} from './TotalCountByEmployeeReport.styled';
import { employeeReportText } from '../../../services/componentText';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

export const TotalCountByEmployeeReport = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState('date');
  const [order, setOrder] = useState('DESC');
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data, refetch } = useQuery(['employeeReport', sortBy, order], () =>
    fetchTotalCountEmployeeReport({
      sortBy,
      order,
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    }),
  );

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewReportButton onClick={() => setIsModalDateOpen(true)}>
          {employeeReportText[language][0]?.choosePeriod}
        </AddedNewReportButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {employeeReportText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <TableContainer>
        <ReportTable>
          <Thead>
            <ReportHeadTr>
              <ReportTh onClick={() => handleSortChange('full_name')}>
                {employeeReportText[language][0]?.fullName}{' '}
                {renderSortIcon('full_name')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('total_errors')}>
                {employeeReportText[language][0]?.totalErrorCounts}{' '}
                {renderSortIcon('total_errors')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('total_interruptions')}>
                {employeeReportText[language][0]?.totalErrorInterruptions}{' '}
                {renderSortIcon('total_interruptions')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('total_training')}>
                {employeeReportText[language][0]?.totalTraining}{' '}
                {renderSortIcon('total_training')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('total_worst_employee')}
              >
                {employeeReportText[language][0]?.totalWorstEmployee}{' '}
                {renderSortIcon('total_worst_employee')}
              </ReportTh>
            </ReportHeadTr>
          </Thead>
          <tbody>
            {data?.length > 0 ? (
              <React.Fragment>
                {data &&
                  data?.map(item => (
                    <ReportTr key={item.full_name}>
                      <ReportTd>{item.full_name}</ReportTd>
                      <ReportTd>{item.total_errors}</ReportTd>
                      <ReportTd>{item.total_interruptions}</ReportTd>
                      <ReportTd>{item.total_training}</ReportTd>
                      <ReportTd>{item.total_worst_employee}</ReportTd>
                    </ReportTr>
                  ))}
              </React.Fragment>
            ) : (
              <ReportTr>
                <ReportTd
                  colSpan={13}
                  style={{
                    width: '100%',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {employeeReportText[language][0]?.notDataOrError}
                </ReportTd>
              </ReportTr>
            )}
          </tbody>
        </ReportTable>
      </TableContainer>
    </Main>
  );
};
