import { UserContext } from "../../Context/UserContext";
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { knowledgeBaseText } from "../../../services/componentText";
import { Main } from "../../Main/Main";
import {
  fetchKnowledgeBase,
  deleteKnowledgeBase,
} from "../../../api/knowledgeBase";
import {
  AddedNewButton,
  NavContainer,
  IconButton,
  TrashButton,
  IconLinkContainer,
} from "./KnowledgeBaseDetails.styled";
import { BackLink } from "../../BackLink/BackLink";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import css from "./KnowledgeBaseDetails.module.css";

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const KnowledgeBaseDetails = ({ data }) => {
  const { language, currentUser } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState({
    id: data?.id || "",
    title_ua: data?.title_ua || "",
    title_ru: data?.title_ru || "",
    description_ua: data?.description_ua || "",
    description_ru: data?.description_ru || "",
  });
  const [isPageRussian, setIsPageRussian] = useState(
    language === "russian" ? true : false
  );

  const isProductList = location?.pathname?.startsWith("/knowledge-base/list");

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  useEffect(() => {
    if (!isProductList) {
      return;
    }

    const fetchProductData = async () => {
      const idFromPath = location?.pathname?.replace(
        /^\/knowledge-base\/list\//,
        ""
      );

      const response = await fetchKnowledgeBase({ id: idFromPath });
      
      if (response?.data?.length > 0) {
        const fetchedKnowledge = response?.data;

        setInitialValue({
          id: fetchedKnowledge[0]?.id,
          title_ua: fetchedKnowledge[0]?.title_ua,
          title_ru: fetchedKnowledge[0]?.title_ru,
          description_ua: fetchedKnowledge[0]?.description_ua,
          description_ru: fetchedKnowledge[0]?.description_ru,
        });
      }
    };

    fetchProductData();
  }, [location, isProductList]);

  const handleDelete = async (id) => {
    const response = await deleteKnowledgeBase(id);

    if (response?.status === 200) {
      toast.success(knowledgeBaseText[language][0]?.successRemoved);
      return navigate("/knowledge-base/list");
    }
  };

  return (
    <div>
      <Main>
        <NavContainer>
          {isProductList && <BackLink to="/knowledge-base/list" />}
          <AddedNewButton
            onClick={() => setIsPageRussian(false)}
            active={!isPageRussian}
            disabled={!isPageRussian}
            style={{ marginLeft: "10px" }}
          >
            {knowledgeBaseText[language][0]?.ukraineVersion}
          </AddedNewButton>
          <AddedNewButton
            onClick={() => setIsPageRussian(true)}
            active={isPageRussian}
            disabled={isPageRussian}
          >
            {knowledgeBaseText[language][0]?.russianVersion}
          </AddedNewButton>
          {accessLevel && (
            <IconLinkContainer>
              <IconButton to={`/knowledge-base/change/${initialValue?.id}`}>
                <FaEdit />
              </IconButton>
              <TrashButton onClick={() => handleDelete(initialValue?.id)}>
                <FaTrash />
              </TrashButton>
            </IconLinkContainer>
          )}
        </NavContainer>
        {initialValue && (
          <div style={{ userSelect: "none" }}>
            <h2>
              {isPageRussian ? initialValue.title_ru : initialValue.title_ua}
            </h2>
            <div
              className={css.knowledgeContent}
              dangerouslySetInnerHTML={{
                __html: isPageRussian
                  ? initialValue?.description_ru
                  : initialValue?.description_ua,
              }}
            />
          </div>
        )}
      </Main>
    </div>
  );
};
