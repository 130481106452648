import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchUsers = async ({ jobTitle, sortBy, order, isBlocked, search, page, limit }) => {
  try {
    const response = await makeRequestSQL.get(
      '/users/',
      {
        params: {
          jobTitle,
          sortBy,
          order,
          search,
          isBlocked
        },
      },
    );
    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOnlineUsers = async () => {
  try {
    const response = await makeRequestSQL.get(`/users/online-users`);
    const decryptedData = await  decryptMiddleware(response.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
}

export const loginUser = async credentials => {
  try {
    const response = await makeRequestSQL.post('/auth/login', credentials);
    const decryptedData = await  decryptMiddleware(response.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const logoutUser = async credentials => {
  try {
    const response = await makeRequestSQL.post('/auth/logout', credentials);
    const decryptedData = await  decryptMiddleware(response.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOtherInformations = async () => {
  try {
    const response = await makeRequestSQL.get('/information');
    const decryptedData = await  decryptMiddleware(response.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
}

export const updateUser = async ({userId, data}) => {
  try {
    const response = await makeRequestSQL.put(
      `/users/${userId}`,
      data,
    );
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchCurrentUser = async () => {
  try {
    const response = await makeRequestSQL.get('/users/me');
    const decryptedData = await  decryptMiddleware(response.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
}