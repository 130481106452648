import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';

export const ScheduleTable = styled.table`
  margin: 1em 0;
  max-height: 100%;
  overflow: auto;
  border-collapse: collapse;
  width: 100%;
`;

export const ScheduleTr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ScheduleHeadTr = styled(ScheduleTr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const ScheduleTd = styled.td`
  flex: 1;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 112px;
  border-bottom: 1px solid #d9e4e6;
  border-right: 1px solid #d9e4e6;

  &:last-child {
    border-right: none;
  }
`;

export const ScheduleTh = styled.th`
  flex: 1;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;
  height: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SaveButton = styled(LoginFormButton)`
  width: 45%;
  margin-left: auto;
  margin-right: auto;
`;

export const CancelButton = styled(SaveButton)`
  background-color: #e74c3c;

  &:hover,
  &:focus,
  &.active {
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const AddedTimeContainer = styled.div`
  width: 500px;
  height: 60px;
`;

export const AddedTimeInput = styled.input`
appearance: none;
width: 120px;
height: 30px;
padding: 5px;
background-color: #f5f7fa;
border: 2px solid #3098d6;
font-size: 18px;
margin-left: 10px;
border-radius: 10px;

cursor: pointer;
`;

export const ScheduleMeNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MonthButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const StatusSelect = styled.select`
  appearance: none;
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 0.5rem 1rem;
  margin-left: 10px;
  background-color: ${props =>
    props.value === '1'
      ? '#d6c930'
      : props.value === '2'
      ? '#30d68b'
      : props.value === '3'
      ? '#d63030'
      : '#ffffff'};
  border: 1px solid #000000;
  cursor: pointer;
`;

export const StatusOption = styled.option`
  width: 20%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
`;

export const ChooseDataContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 20px;
  }
`;

export const CopyNavContainer = styled(ScheduleMeNavContainer)`
  margin-top: 20px;
`;

export const DataSelect = styled.select`
appearance: none;
width: 120px;
height: 30px;
padding: 5px;
background-color: #f5f7fa;
border: 2px solid #3098d6;
font-size: 16px;
margin-left: 10px;
border-radius: 10px;

cursor: pointer;
`;

export const CategorySelect = styled(DataSelect)`
width: 200px;
`

export const DataOption = styled(StatusOption)``;

export const AddScheduleContainerModal = styled.div`
  width: 500px;
  min-height: 300px;
  text-align: center;
`;

export const CategoryContainer = styled.div`
  width: 100%;
  padding: 3px;
  background-color: #94bbe9;
`;