import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { UserContextProvider } from './components/Context/UserContext';
import {NavContextProvider} from './components/Context/NavContext';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <NavContextProvider>
          <App />
        </NavContextProvider>
        <ReactQueryDevtools />
      </UserContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
