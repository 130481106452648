import React, { useState, useContext, useEffect } from 'react';
import { makeRequestSQL } from '../../../api/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from 'react-query';
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  AddedNewFMButton,
  AddedFMContainer,
  SaveButton,
  AddedFMInput,
  AddedFMSelect,
  AddedFMOption,
  ButtonContainer,
  DeleteFMButton,
  Thead,
  FileContainer,
  ModalContainer,
  NavigationButton,
} from './AllEmployeesEverydayStatistics.styled';
import { UserContext } from '../../Context/UserContext';
import { Modal } from '../../Modal/Modal';
import { everydayStatisticsText } from '../../../services/componentText';
import { AiOutlinePlus, AiFillFileExcel } from 'react-icons/ai';
import {
  getGradientColorForApprove,
  getGradientColorForOtherCategorie,
  getGradientColorForNettoIncome,
  getGradientColorForAdditionalApprovalPercentage,
} from '../../../helpers/gradient/getGradientColorForEverydayStatistics';
import { Main } from '../../Main/Main';
import {
  fetchAllEverydayStatistics,
  onDeleteByDate,
} from '../../../api/everydayStatistics';
import { ClipLoader } from 'react-spinners';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';

export const AllEmployeesEverydayStatistics = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [order, setOrder] = useState('DESC');
  const [isUploadLoading, setIsUploadLoading] = useState(false)
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const onFileChange = e => {
    setFile(e?.target?.files[0]);
    setFileName(e.target.files[0]?.name);
  };

  const { data: everydayStatisticsData, refetch } = useQuery(
    ['everydayStatistics', selectedDate, sortBy, order],
    () =>
      fetchAllEverydayStatistics({
        selectedDate,
        sortBy,
        order,
      }),
  );

  const onUpload = async () => {
    const formData = new FormData();
    formData.append('everydayStatistics', file);

    try {
      setIsUploadLoading(true);
      const response = await makeRequestSQL.post(
        '/everyday-statistics',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (response.status === 204) {
        setIsModalOpen(false);
        toast.success(`${everydayStatisticsText[language][0]?.successUpload}`);
        setIsUploadLoading(false);
        refetch();
      }
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
      setIsUploadLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedDate && everydayStatisticsData?.uniqueDates[0] !== undefined) {
      setSelectedDate(everydayStatisticsData?.uniqueDates[0]);
    }
  }, [selectedDate, everydayStatisticsData]);

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const handleDelete = async () => {
    const response = await onDeleteByDate(selectedDate);

    if (response?.status === 204) {
      toast.success(`${everydayStatisticsText[language][0]?.successDelete}`);
      setSelectedDate('');
      refetch();
    }
  };

  return (
    <Main>
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainer>
            {!fileName && !isUploadLoading && (
              <AiOutlinePlus
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                size="100"
              />
            )}
            {fileName && !isUploadLoading && (
              <FileContainer>
                <AiFillFileExcel
                  size="100"
                  color="green"
                  style={{ marginRight: '10px' }}
                />
                {fileName}
              </FileContainer>
            )}
            {isUploadLoading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'top',
                  height: '5vh',
                }}
              >
                <ClipLoader
                  color="#3098d6"
                  loading={isUploadLoading}
                  size={30}
                />
              </div>
            )}
            <AddedFMInput
              type="file"
              accept=".xlsx, .xls"
              onChange={onFileChange}
            />
          </ModalContainer>
          {!isUploadLoading && (
            <ButtonContainer>
              <SaveButton onClick={onUpload}>
                {everydayStatisticsText[language][0]?.upload}
              </SaveButton>
              {fileName && (
                <DeleteFMButton
                  onClick={() => {
                    setFile(null);
                    setFileName(null);
                  }}
                >
                  {everydayStatisticsText[language][0]?.cancel}
                </DeleteFMButton>
              )}
            </ButtonContainer>
          )}
        </Modal>
      )}
      <div>
        {accessLevel && (
          <AddedNewFMButton onClick={() => setIsModalOpen(true)}>
            {everydayStatisticsText[language][0]?.addNewTable}
          </AddedNewFMButton>
        )}
        <AddedFMContainer>
          <NavigationButton>
            <AddedFMSelect
              value={selectedDate}
              onChange={e => setSelectedDate(e.target.value)}
            >
              <AddedFMOption value="" disabled>
                {everydayStatisticsText[language][0]?.chooseDate}
              </AddedFMOption>
              {everydayStatisticsData !== undefined &&
                everydayStatisticsData?.uniqueDates?.map(date => (
                  <AddedFMOption key={date} value={date}>
                    {formattedDateToDayMonthYear(date)}
                  </AddedFMOption>
                ))}
            </AddedFMSelect>

            <ButtonContainer style={{ marginLeft: '20px' }}>
              {/* <SaveButton onClick={fetchAllEverydayStatistics}>
               {everydayStatisticsText[language][0]?.chooseThisDate}
              </SaveButton> */}

              {accessLevel && (
                <DeleteFMButton
                  onClick={handleDelete}
                  style={{ width: '350px' }}
                >
                  {everydayStatisticsText[language][0]?.chooseRemoveThisDate}
                </DeleteFMButton>
              )}
            </ButtonContainer>
          </NavigationButton>
        </AddedFMContainer>
      </div>

      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh onClick={() => handleSortChange('full_name')}>
                {everydayStatisticsText[language][0]?.fullName}{' '}
                {renderSortIcon('full_name')}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange('total_order')}>
                {everydayStatisticsText[language][0]?.totalOrder}{' '}
                {renderSortIcon('total_order')}
              </ScheduleTh>
              <ScheduleTh
                onClick={() => handleSortChange('approval_percentage')}
              >
                {everydayStatisticsText[language][0]?.approve}{' '}
                {renderSortIcon('approval_percentage')}
              </ScheduleTh>
              <ScheduleTh
                onClick={() => handleSortChange('in_processing_percentage')}
              >
                {everydayStatisticsText[language][0]?.inProcessing}{' '}
                {renderSortIcon('in_processing_percentage')}
              </ScheduleTh>
              <ScheduleTh
                onClick={() => handleSortChange('rejected_percentage')}
              >
                {everydayStatisticsText[language][0]?.rejected}{' '}
                {renderSortIcon('rejected_percentage')}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange('trash_percentage')}>
                {everydayStatisticsText[language][0]?.trash}{' '}
                {renderSortIcon('trash_percentage')}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange('netto_income')}>
                {everydayStatisticsText[language][0]?.nettoIncome}{' '}
                {renderSortIcon('netto_income')}
              </ScheduleTh>
              {/* <ScheduleTh>
                {everydayStatisticsText[language][0]?.approvalPercentage}
              </ScheduleTh> */}
              <ScheduleTh
                onClick={() =>
                  handleSortChange('additional_approval_percentage')
                }
              >
                {
                  everydayStatisticsText[language][0]
                    ?.additionalApprovalPercentage
                }{' '}
                {renderSortIcon('additional_approval_percentage')}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange('check_by_approv')}>
                {everydayStatisticsText[language][0]?.checkByApprov}{' '}
                {renderSortIcon('check_by_approv')}
              </ScheduleTh>
              {/* <ScheduleTh>
                {everydayStatisticsText[language][0]?.timeInConversation}
              </ScheduleTh> */}
              <ScheduleTh onClick={() => handleSortChange('time_in_system')}>
                {everydayStatisticsText[language][0]?.timeInSystem}
                {renderSortIcon('time_in_system')}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange('approval_for_time')}>
                {everydayStatisticsText[language][0]?.approvalForTime}
                {renderSortIcon('approval_for_time')}
              </ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {everydayStatisticsData?.statisticsData?.length > 0 ? (
              <React.Fragment>
                {everydayStatisticsData?.statisticsData?.map(item => {
                  const approvalBgColor = getGradientColorForApprove(
                    item.approval_percentage,
                  );

                  const rejectedBgColor = getGradientColorForOtherCategorie(
                    item.rejected_percentage,
                  );
                  const inProcessingBgColor = getGradientColorForOtherCategorie(
                    item.in_processing_percentage,
                  );
                  const trashBgColor = getGradientColorForOtherCategorie(
                    item.trash_percentage,
                  );

                  const nettoIncomeBgColor = getGradientColorForNettoIncome(
                    item.netto_income,
                  );

                  const additionalApprovalPercentageBgColor =
                    getGradientColorForAdditionalApprovalPercentage(
                      item.additional_approval_percentage,
                    );

                  return (
                    <ScheduleTr key={item.id}>
                      <ScheduleTd>{item.full_name}</ScheduleTd>
                      <ScheduleTd>{item.total_order}</ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: approvalBgColor,
                        }}
                      >
                        {item.approve} <br />({item.approval_percentage}
                        %)
                      </ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: inProcessingBgColor,
                        }}
                      >
                        {item.in_processing} <br />(
                        {item.in_processing_percentage}
                        %)
                      </ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: rejectedBgColor,
                        }}
                      >
                        {item.rejected} <br />({item.rejected_percentage}
                        %)
                      </ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: trashBgColor,
                        }}
                      >
                        {item.trash}
                        <br />({item.trash_percentage}
                        %)
                      </ScheduleTd>
                      <ScheduleTd
                        style={{
                          backgroundColor: nettoIncomeBgColor,
                        }}
                      >
                        {item.netto_income} грн
                      </ScheduleTd>
                      {/* <ScheduleTd>{item.approval_percentage}%</ScheduleTd> */}
                      <ScheduleTd
                        style={{
                          backgroundColor: additionalApprovalPercentageBgColor,
                        }}
                      >
                        {item.additional_approval_percentage}%
                      </ScheduleTd>
                      <ScheduleTd>{item.check_by_approv} грн</ScheduleTd>
                      {/* <ScheduleTd>{item.time_in_conversation}</ScheduleTd> */}
                      <ScheduleTd>{item.time_in_system}</ScheduleTd>
                      <ScheduleTd>{item.approval_for_time}</ScheduleTd>
                    </ScheduleTr>
                  );
                })}
              </React.Fragment>
            ) : (
              <ScheduleTr>
                <ScheduleTd colSpan={9} style={{ width: '600px' }}>
                  {everydayStatisticsText[language][0]?.notDataOrError}
                </ScheduleTd>
              </ScheduleTr>
            )}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </Main>
  );
};
