import React, { useContext } from 'react';
import { UserContext } from '../Context/UserContext';
import {
  PageLimitSelect,
  PageLimitOption,
  PageLimitContainer,
} from './PageLimitSelector.styled';

export const PageLimitSelector = ({ pageLimit, onChange }) => {
  const { language } = useContext(UserContext);
  const options = [10, 20, 50, 100, 500, 1000];

  return (
    <PageLimitContainer>
      <label htmlFor="pageLimit">
        {' '}
        {language === 'russian'
          ? 'Показывать на странице:'
          : 'Показувати на сторінці:'}{' '}
      </label>
      <PageLimitSelect
        id="pageLimit"
        value={pageLimit}
        onChange={e => onChange(e.target.value)}
      >
        {options.map(option => (
          <PageLimitOption key={option} value={option}>
            {option}
          </PageLimitOption>
        ))}
      </PageLimitSelect>
    </PageLimitContainer>
  );
};
