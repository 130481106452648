import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';

export const SaveButton = styled(LoginFormButton)`
  min-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

export const DeleteButton = styled(SaveButton)`
  margin-left: 10px;
  background-color: #e74c3c;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const Input = styled.input`
  width: 40%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.17;
`;



export const AddedNewButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border: none;
  background-color: ${({ active }) => (active ? '#0b6aa2' : '#3098d6')};
  border-left: ${({ active }) => (active ? '0.375rem solid #5cbcf6' : 'none')};
  border-right: ${({ active }) => (active ? '0.375rem solid #5cbcf6' : 'none')};
  padding-right: ${({ active }) => (active ? '0.625rem' : '1rem')};
  padding-left: ${({ active }) => (active ? '0.625rem' : '1rem')};
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const SelectTemplate = styled.select`
width: 40%;
height: 40px;
padding: 10px;
margin-bottom: 10px;
background-color: #f5f7fa;
border: 1px solid #000000;
border-radius: 20px;
font-size: 16px;
line-height: 1.17;

cursor: pointer;
`;

export const Option = styled.option`
appearance: none;
width: 100%;
height: 10px;
padding: 5px;
margin-bottom: 10px;
background-color: #f5f7fa;
border: 1px solid #000000;
font-size: 16px;

cursor: pointer;
`;