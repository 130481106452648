import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchFineStatuses = async () => {
  try {
    const response = await makeRequestSQL.get('/fines/fines-status');
    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchAllFines = async ({employee_id, startDate, endDate}) => {
  try {
    const response = await makeRequestSQL.get('/fines/', {
      params: {
        employee_id, startDate, endDate
      },
    });

    const decryptedData = await  decryptMiddleware(response?.data);

    return employee_id ? decryptedData[0]?.fines_list : decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createMutationFines = async finesData => {
  try {
    const response = await makeRequestSQL.post('/fines', finesData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteMutationFines = async finesId => {
  try {
    const response = await makeRequestSQL.delete('/fines/', {
      params: {
        id: finesId,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateMutationFines = async ({id, finesData}) => {
  try {
    const response = await makeRequestSQL.put(
      `/fines/${id}`,
      finesData,
    );
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};