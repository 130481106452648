import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { useContext, useState } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { Outlet } from 'react-router-dom';
import { LinkOnPageNav } from '../../components/OnPageNav/LinkOnPageNav/LinkOnPageNav';
import { OnPageNavContainer } from '../../components/OnPageNav/OnPageNavContainer/OnPageNavContainer';
import { wiretappingCheckList } from '../../services/componentText';

export const WiretappingStatistics = () => {
  const { currentUser, language } = useContext(UserContext);

  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const [activeTab, setActiveTab] = useState('fines');

  return (
    <div>
      <SharedLayout />
      {accessLevel && (
        <OnPageNavContainer>
          <LinkOnPageNav
            to="all"
            onClick={() => setActiveTab('all')}
            className={activeTab === 'all' ? 'active' : ''}
          >
            {wiretappingCheckList[language][0]?.statistic}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="fines"
            onClick={() => setActiveTab('fines')}
            className={activeTab === 'fines' ? 'active' : ''}
          >
            {wiretappingCheckList[language][0]?.finesStatistic}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="category-statistics"
            onClick={() => setActiveTab('category-statistics')}
            className={activeTab === 'category-statistics' ? 'active' : ''}
          >
            {wiretappingCheckList[language][0]?.categoryStatistics}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="everyday-statistics"
            onClick={() => setActiveTab('everyday-statistics')}
            className={activeTab === 'everyday-statistics' ? 'active' : ''}
          >
            {wiretappingCheckList[language][0]?.everydayStatistics}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="changes"
            onClick={() => setActiveTab('changes')}
            className={activeTab === 'changes' ? 'active' : ''}
          >
            {wiretappingCheckList[language][0]?.seeChanges}
          </LinkOnPageNav>
        </OnPageNavContainer>
      )}

      {access_level_d === currentUser?.access_level && (
        <OnPageNavContainer>
          <LinkOnPageNav
            to="fines"
            onClick={() => setActiveTab('fines')}
            className={activeTab === 'fines' ? 'active' : ''}
          >
            {wiretappingCheckList[language][0]?.finesStatistic}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="everyday-statistics"
            onClick={() => setActiveTab('everyday-statistics')}
            className={activeTab === 'everyday-statistics' ? 'active' : ''}
          >
            {wiretappingCheckList[language][0]?.everydayStatistics}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="changes"
            onClick={() => setActiveTab('changes')}
            className={activeTab === 'changes' ? 'active' : ''}
          >
            {wiretappingCheckList[language][0]?.seeChanges}
          </LinkOnPageNav>
        </OnPageNavContainer>
      )}
      <Outlet />
    </div>
  );
};
