import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const getScheduleHourByCategorie = async ({
  startDate,
  endDate,
  id_status,
  job_titles,
  order,
}) => {
  try {
    const response = await makeRequestSQL.get("/schedule/hours", {
      params: {
        startDate,
        endDate,
        id_status,
        job_titles,
        order,
      },
    });

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchCategories = async () => {
  try {
    const response = await makeRequestSQL.get("/schedule/categories");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchStatuses = async () => {
  try {
    const response = await makeRequestSQL.get("/schedule/statuses");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchInformations = async () => {
  try {
    const response = await makeRequestSQL.get("/information");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteScheduleByDay = async ({ employee_id, day, status }) => {
  try {
    const response = await makeRequestSQL.delete(`/schedule/`, {
      params: {
        employee_id,
        day,
        status,
      },
    });

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchAllSchedule = async ({
  year,
  month,
  id_status,
  job_titles,
}) => {
  try {
    const response = await makeRequestSQL.get(`/schedule/`, {
      params: {
        year,
        month,
        id_status,
        job_titles,
      },
    });

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateSchedule = async ({ id_schedule, data }) => {
  try {
    const response = await makeRequestSQL.put(`/schedule/${id_schedule}`, data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createScedule = async (scheduleData) => {
  try {
    const response = await makeRequestSQL.post("/schedule", scheduleData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const bulkUpdateSchedule = async (data) => {
  try {
    const response = await makeRequestSQL.put(`/schedule/bulk-update`, data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchMySchedule = async ({ year, month, id_status }) => {
  try {
    const response = await makeRequestSQL.get("/schedule/me", {
      params: {
        year,
        month,
        id_status,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
