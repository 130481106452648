import {
  NavigationBar,
    TopBarPrimaryContainer,
  TopBarLogoutContainer,
  TopBarLogoutButton,
} from './SharedLayout.styled';
import { TopLeftBarNavigation } from './NavigationTopLeftBar';
import { GlobalMenu } from './GlobalMenu';
import { SlLogout } from 'react-icons/sl';
import { SubMenuClaims, SubMenuKnowledges, SubMenuOrganizational, SubMenuProducts, SubMenuWiretapping } from './SubMenu';
import { makeRequestSQL } from '../../api/api';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../Context/UserContext';
import { NavContext } from '../Context/NavContext';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logoutUser } from '../../api/users';

export const SharedLayout = () => {
  const navigate = useNavigate();
  const { handleLogin, language, currentUser } = useContext(UserContext);
  const {isNavVisible} = useContext(NavContext)
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
  const access_level_a = Number(process.env.REACT_APP_A_LEVEL_ACCESS);
  const access_level_b = Number(process.env.REACT_APP_B_LEVEL_ACCESS);
  const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);
  const access_level_h = Number(process.env.REACT_APP_H_LEVEL_ACCESS);
  const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);
  const access_level_j = Number(process.env.REACT_APP_J_LEVEL_ACCESS);
  const access_level_k = Number(process.env.REACT_APP_K_LEVEL_ACCESS);
  

    const mutation = useMutation(logoutUser, {
      onSuccess: data => {
        handleLogin(null);
        navigate('/login');
      },
      onError: error => {
        const errorMessage = error.response?.data?.message || error.message;
        toast.error(errorMessage);
      },
    });
  
    const handleLogout = async values => {
      await mutation.mutateAsync(null);
  };

 return (
   <div>
     <NavigationBar
       style={{
         transform: isNavVisible ? 'translateX(0)' : 'translateX(-100%)',
         transition: 'transform 0.3s',
       }}
     >
       {(currentUser?.access_level === access_level_e ||
         currentUser?.access_level === access_level_f ||
         currentUser?.access_level === access_level_g) && (
         <GlobalMenu language={language} />
       )}
       {(currentUser?.access_level === access_level_a ||
         currentUser?.access_level === access_level_b ||
         currentUser?.access_level === access_level_c ||
         currentUser?.access_level === access_level_d ||
         currentUser?.access_level === access_level_h || 
         currentUser?.access_level === access_level_i|| 
         currentUser?.access_level === access_level_j || currentUser?.access_level === access_level_k) && (
         <>
           <SubMenuOrganizational />
           <SubMenuProducts />
           <SubMenuWiretapping />
           <SubMenuClaims />
           <SubMenuKnowledges />
         </>
       )}
     </NavigationBar>
     <TopBarPrimaryContainer>
       <TopLeftBarNavigation />
       <TopBarLogoutContainer>
         <TopBarLogoutButton onClick={handleLogout}>
           <SlLogout size="24px" />
         </TopBarLogoutButton>
       </TopBarLogoutContainer>
     </TopBarPrimaryContainer>
     <ToastContainer autoClose={4000} />
   </div>
 );

}