export  const getColorByHoursWeek = hours => {
  if (hours <= 30) {
    // Інтерполяція між червоним і жовтим
    const ratio = hours / 35;
    const red = 255;
    const green = Math.floor(255 * ratio);
    return `rgb(${red}, ${green}, 0)`;
  } else if (hours <= 45) {
    // Інтерполяція між жовтим і зеленим
    const ratio = (hours - 35) / 10;
    const red = 255 * (1 - ratio);
    const green = 255;
    return `rgb(${Math.floor(red)}, ${green}, 0)`;
  } else {
    // Інтерполяція між зеленим і синім
    const ratio = (hours - 45) / 55; // до 300 годин
    const blue = Math.floor(255 * ratio);
    return `rgb(0, ${255 - blue}, ${blue})`;
  }
};