import { Main } from '../../Main/Main';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { SharedLayout } from '../../SharedLayout/SharedLayout';
import { UserContext } from '../../Context/UserContext';
import { Modal } from '../../Modal/Modal';
import { fetchCountFM } from '../../../api/fm';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  AddedNewFMButton,
  SaveButton,
  Thead,
  NavContainer,
  IsBlockedSelect,
  IsBlockedOption,
  LinkButton,
} from './StatisticsFM.styled';
import { fmText, userText } from '../../../services/componentText';
import { v4 as uuidv4 } from 'uuid';

export const StatisticsFM = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState('fm_count');
  const [order, setOrder] = useState('DESC');
  const [isBlocked, setIsBlocked] = useState(0);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data, refetch } = useQuery(
    ['fmStatisticsData', sortBy, order, isBlocked],
    () =>
      fetchCountFM({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        isBlocked,
      }),
  );

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <div>
      <SharedLayout />
      <Main>
        {isModalDateOpen && (
          <Modal closeModal={() => setIsModalDateOpen(false)}>
            <DateRangePicker
              onChange={item => setPeriod([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              locale={currentUser?.language === 'russian' ? ru : uk}
              staticRanges={customDefaultStaticRanges}
              inputRanges={customDefaultInputRanges}
              months={2}
              ranges={period}
              direction="horizontal"
              style={{ margin: '20px' }}
            />
            <SaveButton onClick={() => handleDate()}>
              {fmText[language][0]?.update}
            </SaveButton>
          </Modal>
        )}
        <NavContainer>
          <div
            style={{ marginRight: '10px' }}
          >{`${formattedStartDate} - ${formattedEndDate}`}</div>
          <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
            {fmText[language][0]?.choosePeriod}
          </AddedNewFMButton>
          <LinkButton to={`/fm`}>{fmText[language][0]?.toFmList}</LinkButton>
          <IsBlockedSelect
            value={isBlocked}
            onChange={e => setIsBlocked(e.target.value)}
          >
            <IsBlockedOption key={0} value="0">
              {userText[language][0]?.notBlocked}
            </IsBlockedOption>
            <IsBlockedOption key={1} value="1">
              {userText[language][0]?.blocked}
            </IsBlockedOption>
          </IsBlockedSelect>
        </NavContainer>
        <TableContainer>
          <ScheduleTable>
            <Thead>
              <ScheduleHeadTr>
                <ScheduleTh onClick={() => handleSortChange('full_name')}>
                  {fmText[language][0]?.fullName}
                  {renderSortIcon('full_name')}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange('fm_count')}>
                  {fmText[language][0]?.fmCountText}
                  {renderSortIcon('fm_count')}
                </ScheduleTh>
              </ScheduleHeadTr>
            </Thead>
            <tbody>
              {data?.map(item => {
                const key = uuidv4();
                return (
                  <ScheduleTr key={key}>
                    <ScheduleTd>{item.full_name}</ScheduleTd>
                    <ScheduleTd>{item.fm_count}</ScheduleTd>
                  </ScheduleTr>
                );
              })}
            </tbody>
          </ScheduleTable>
        </TableContainer>
      </Main>
    </div>
  );
};
