import { Main } from '../../Main/Main';
import { Modal } from '../../Modal/Modal';
import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from 'react-query';
import {
  ModalContainer,
  InfoForm,
  Input,
  ReportTextArea,
  AddedReportContainer,
  DeleteReportButton,
  AddedNewReportButton,
  ButtonContainer,
  SaveButton,
  ReportTh,
  ReportTd,
  ReportHeadTr,
  ReportTr,
  ReportTable,
  TableContainer,
  Thead,
  ReportLabel,
  ReportCommentContainer,
  NavContainer,
} from './CallReport.styled';
import {
  createCallReport,
  updateCallReport,
  fetchCallReport,
  deleteCallReport,
} from '../../../api/report';
import { callReportsText } from '../../../services/componentText';
import { Formik } from 'formik';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { formatDateToInput } from '../../../helpers/dateAndTime/formatDate';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

export const CallReport = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState('date');
  const [order, setOrder] = useState('DESC');
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedId, setSelectedId] = useState();
    const [isModalDateOpen, setIsModalDateOpen] = useState(false);
    const currentDate = new Date();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const [period, setPeriod] = useState([
      {
        startDate: new Date(
          currentYear,
          currentMonth - 2,
          currentDate.getDate(),
        ),
        endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
        key: 'selection',
      },
    ]);
    const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
    const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
    const formattedStartDateRequest = format(
      period[0]?.startDate,
      'yyyy.MM.dd',
    );
    const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');
  const [callReportInitialState, setCallReportInitialState] = useState({
    id: '',
    date: '',
    time: '',
    new_auto_call_status_number: '',
    attempt_called_operators: '',
    total_call: '',
    success_call: '',
    unsuccess_call: '',
    lost_call: '',
    loged_in_time: '',
    talking_time: '',
    not_ready_time: '',
    ready_time: '',
    break_time: '',
    approved_order_time: '',
    technical_break_time: '',
    planning_meeting_time: '',
    real_not_ready_time: '',
    real_time_at_work: '',
    percent_not_ready_time: '',
    received_per_hour_without_not_ready_time: '',
    percent_break_time: '',
    percent_approve_time: '',
    report_comment: '',
    created_by: !isUpdate ? currentUser?.id_employee : null,
    updated_by: currentUser?.id_employee,
  });

  const { data, refetch } = useQuery(['callReport', sortBy, order], () =>
    fetchCallReport({
      sortBy,
      order,
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    }),
  );

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const handleSubmit = async values => {
    if (isUpdate) {
      const response = await updateCallReport(values);

      if (response?.status === 204) {
        setIsModalOpen(false);
        setIsUpdate(false);
        resetValue();
        refetch();
        return toast.success(callReportsText[language][0]?.successUpdate);
      }
    }

    const response = await createCallReport(values);

    if (response?.status === 204) {
      setIsModalOpen(false);
      resetValue();
      refetch();
      return toast.success(callReportsText[language][0]?.successSave);
    }
  };

  const resetValue = () => {
    setCallReportInitialState({
      date: '',
      time: '',
      new_auto_call_status_number: '',
      attempt_called_operators: '',
      total_call: '',
      success_call: '',
      unsuccess_call: '',
      lost_call: '',
      loged_in_time: '',
      talking_time: '',
      not_ready_time: '',
      ready_time: '',
      break_time: '',
      approved_order_time: '',
      technical_break_time: '',
      planning_meeting_time: '',
      real_not_ready_time: '',
      real_time_at_work: '',
      percent_not_ready_time: '',
      received_per_hour_without_not_ready_time: '',
      percent_break_time: '',
      percent_approve_time: '',
      report_comment: '',
      created_by: !isUpdate ? currentUser?.id_employee : null,
      updated_by: currentUser?.id_employee,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetValue();
  };

  const handleDelete = async () => {
    const response = await deleteCallReport(selectedId);

    if (response.status === 200) {
      setIsUpdate(false);
      resetValue();
      setIsModalOpen(false);
      refetch()
      return toast.success(callReportsText[language][0]?.successRemoved);
    }
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const handleClick = (item) => {
    setIsUpdate(true);
    setIsModalOpen(true);
    setSelectedId(item?.id);
    setCallReportInitialState({ ...item, date: formatDateToInput(item.date) });
  }

  const handleNewReport = () => {
    setIsModalOpen(true);
    resetValue();
    setIsUpdate(false);
  }

    const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const customDefaultInputRanges = defaultInputRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const handleDate = () => {
      refetch();
      setIsModalDateOpen(false);
    };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewReportButton onClick={() => setIsModalDateOpen(true)}>
          {callReportsText[language][0]?.choosePeriod}
        </AddedNewReportButton>
        <AddedNewReportButton onClick={() => handleNewReport()}>
          {callReportsText[language][0]?.addNewReport}
        </AddedNewReportButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {callReportsText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainer>
            <Formik
              initialValues={callReportInitialState}
              onSubmit={handleSubmit}
            >
              <InfoForm autoComplete="off">
                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.chooseDate}
                  </ReportLabel>
                  <Input
                    type="date"
                    name="date"
                    placeholder={callReportsText[language][0]?.chooseDate}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterTime}
                  </ReportLabel>
                  <Input
                    type="time"
                    name="time"
                    placeholder={callReportsText[language][0]?.enterTime}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterTotalOrderInNewStatus}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="new_auto_call_status_number"
                    placeholder={
                      callReportsText[language][0]?.enterTotalOrderInNewStatus
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterAttemptCalledOperators}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="attempt_called_operators"
                    placeholder={
                      callReportsText[language][0]?.enterAttemptCalledOperators
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterTotalOrder}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="total_call"
                    placeholder={callReportsText[language][0]?.enterTotalOrder}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterTotalSuccessOrder}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="success_call"
                    placeholder={
                      callReportsText[language][0]?.enterTotalSuccessOrder
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterTotalUnsuccessOrder}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="unsuccess_call"
                    placeholder={
                      callReportsText[language][0]?.enterTotalUnsuccessOrder
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterTotalLostOrder}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="lost_call"
                    placeholder={
                      callReportsText[language][0]?.enterTotalLostOrder
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterLogedInTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="loged_in_time"
                    placeholder={callReportsText[language][0]?.enterLogedInTime}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterTalkingTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="talking_time"
                    placeholder={callReportsText[language][0]?.enterTalkingTime}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterNotReadyTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="not_ready_time"
                    placeholder={
                      callReportsText[language][0]?.enterNotReadyTime
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterReadyTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="ready_time"
                    placeholder={callReportsText[language][0]?.enterReadyTime}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterBreakTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="break_time"
                    placeholder={callReportsText[language][0]?.enterBreakTime}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterApproveTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="approved_order_time"
                    placeholder={callReportsText[language][0]?.enterApproveTime}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterTechnicalBreakTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="technical_break_time"
                    placeholder={
                      callReportsText[language][0]?.enterTechnicalBreakTime
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterPlanningMeetingTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="planning_meeting_time"
                    placeholder={
                      callReportsText[language][0]?.enterPlanningMeetingTime
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterRealNotReadyTime}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="real_not_ready_time"
                    placeholder={
                      callReportsText[language][0]?.enterRealNotReadyTime
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterRealTimeAtWork}
                  </ReportLabel>
                  <Input
                    type="text"
                    name="real_time_at_work"
                    placeholder={
                      callReportsText[language][0]?.enterRealTimeAtWork
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      callReportsText[language][0]
                        ?.enterReceivedPerHourWithoutNotReadyTime
                    }
                  </ReportLabel>
                  <Input
                    type="text"
                    name="received_per_hour_without_not_ready_time"
                    placeholder={
                      callReportsText[language][0]
                        ?.enterReceivedPerHourWithoutNotReadyTime
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterPercentNotReadyTime}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_not_ready_time"
                    placeholder={
                      callReportsText[language][0]?.enterPercentNotReadyTime
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterPercentBreakTime}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_break_time"
                    placeholder={
                      callReportsText[language][0]?.enterPercentBreakTime
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterPercentApproveTime}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_approve_time"
                    placeholder={
                      callReportsText[language][0]?.enterPercentApproveTime
                    }
                  />
                </AddedReportContainer>

                <ReportCommentContainer>
                  <ReportLabel>
                    {callReportsText[language][0]?.enterReportComment}
                  </ReportLabel>
                  <ReportTextArea
                    type="text"
                    name="report_comment"
                    as="textarea"
                    placeholder={
                      callReportsText[language][0]?.enterReportComment
                    }
                  />
                </ReportCommentContainer>
                {!isUpdate && (
                  <ButtonContainer>
                    <SaveButton type="submit">
                      {callReportsText[language][0]?.save}
                    </SaveButton>
                    <DeleteReportButton
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {callReportsText[language][0]?.cancel}
                    </DeleteReportButton>
                  </ButtonContainer>
                )}
                {isUpdate && (
                  <ButtonContainer>
                    <SaveButton type="submit">
                      {callReportsText[language][0]?.update}
                    </SaveButton>
                    <DeleteReportButton
                      type="button"
                      onClick={() => handleDelete()}
                    >
                      {callReportsText[language][0]?.delete}
                    </DeleteReportButton>
                  </ButtonContainer>
                )}
              </InfoForm>
            </Formik>
          </ModalContainer>
        </Modal>
      )}
      <TableContainer>
        <ReportTable>
          <Thead>
            <ReportHeadTr>
              <ReportTh onClick={() => handleSortChange('date')}>
                {callReportsText[language][0]?.date} {renderSortIcon('date')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('time')}>
                {callReportsText[language][0]?.time} {renderSortIcon('time')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('new_auto_call_status_number')}
              >
                {callReportsText[language][0]?.totalOrderInNewStatus}{' '}
                {renderSortIcon('new_auto_call_status_number')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('attempt_called_operators')}
              >
                {callReportsText[language][0]?.attemptCalledOperators}{' '}
                {renderSortIcon('attempt_called_operators')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('total_call')}>
                {callReportsText[language][0]?.totalOrder}{' '}
                {renderSortIcon('total_call')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('success_call')}>
                {callReportsText[language][0]?.totalSuccessOrder}{' '}
                {renderSortIcon('success_call')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('unsuccess_call')}>
                {callReportsText[language][0]?.totalUnsuccessOrder}{' '}
                {renderSortIcon('unsuccess_call')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('lost_call')}>
                {callReportsText[language][0]?.totalLostOrder}{' '}
                {renderSortIcon('lost_call')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('loged_in_time')}>
                {callReportsText[language][0]?.logedInTime}{' '}
                {renderSortIcon('loged_in_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('talking_time')}>
                {callReportsText[language][0]?.talkingTime}{' '}
                {renderSortIcon('talking_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('not_ready_time')}>
                {callReportsText[language][0]?.notReadyTime}{' '}
                {renderSortIcon('not_ready_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('ready_time')}>
                {callReportsText[language][0]?.readyTime}{' '}
                {renderSortIcon('ready_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('break_time')}>
                {callReportsText[language][0]?.breakTime}{' '}
                {renderSortIcon('break_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('approved_order_time')}>
                {callReportsText[language][0]?.approveTime}{' '}
                {renderSortIcon('approved_order_time')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('technical_break_time')}
              >
                {callReportsText[language][0]?.technicalBreakTime}{' '}
                {renderSortIcon('technical_break_time')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('planning_meeting_time')}
              >
                {callReportsText[language][0]?.planningMeetingTime}{' '}
                {renderSortIcon('planning_meeting_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('real_not_ready_time')}>
                {callReportsText[language][0]?.realNotReadyTime}{' '}
                {renderSortIcon('real_not_ready_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('real_time_at_work')}>
                {callReportsText[language][0]?.realTimeAtWork}{' '}
                {renderSortIcon('real_time_at_work')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('received_per_hour_without_not_ready_time')
                }
              >
                {
                  callReportsText[language][0]
                    ?.receivedPerHourWithoutNotReadyTime
                }{' '}
                {renderSortIcon('received_per_hour_without_not_ready_time')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('percent_not_ready_time')}
              >
                {callReportsText[language][0]?.percentNotReadyTime}{' '}
                {renderSortIcon('percent_not_ready_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('percent_break_time')}>
                {callReportsText[language][0]?.percentBreakTime}{' '}
                {renderSortIcon('percent_break_time')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('percent_approve_time')}
              >
                {callReportsText[language][0]?.percentApproveTime}{' '}
                {renderSortIcon('percent_approve_time')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('report_comment')}>
                {callReportsText[language][0]?.reportComment}{' '}
                {renderSortIcon('report_comment')}
              </ReportTh>
            </ReportHeadTr>
          </Thead>
          <tbody>
            {data?.length > 0 ? (
              <React.Fragment>
                {data?.map(item => {
                  return (
                    <ReportTr key={item.id} onClick={() => handleClick(item)}>
                      <ReportTd>
                        {formattedDateToDayMonthYear(item.date)}
                      </ReportTd>
                      <ReportTd>{item.time}</ReportTd>
                      <ReportTd>{item.new_auto_call_status_number}</ReportTd>
                      <ReportTd>{item.attempt_called_operators}</ReportTd>
                      <ReportTd>{item.total_call}</ReportTd>
                      <ReportTd>{item.success_call}</ReportTd>
                      <ReportTd>{item.unsuccess_call}</ReportTd>
                      <ReportTd>{item.lost_call}</ReportTd>
                      <ReportTd>{item.loged_in_time}</ReportTd>
                      <ReportTd>{item.talking_time}</ReportTd>
                      <ReportTd>{item.not_ready_time}</ReportTd>
                      <ReportTd>{item.ready_time}</ReportTd>
                      <ReportTd>{item.break_time}</ReportTd>
                      <ReportTd>{item.approved_order_time}</ReportTd>
                      <ReportTd>{item.technical_break_time}</ReportTd>
                      <ReportTd>{item.planning_meeting_time}</ReportTd>
                      <ReportTd>{item.real_not_ready_time}</ReportTd>
                      <ReportTd>{item.real_time_at_work}</ReportTd>
                      <ReportTd>
                        {item.received_per_hour_without_not_ready_time}
                      </ReportTd>
                      <ReportTd>{item.percent_not_ready_time} %</ReportTd>
                      <ReportTd>{item.percent_break_time} %</ReportTd>
                      <ReportTd>{item.percent_approve_time} %</ReportTd>
                      <ReportTd>{item.report_comment}</ReportTd>
                    </ReportTr>
                  );
                })}
              </React.Fragment>
            ) : (
              <ReportTr>
                <ReportTd
                  colSpan={13}
                  style={{
                    width: '2000px',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {callReportsText[language][0]?.notDataOrError}
                </ReportTd>
              </ReportTr>
            )}
          </tbody>
        </ReportTable>
      </TableContainer>
    </Main>
  );
};
