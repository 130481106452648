import {
  Option,
  Input,
  InfoForm,
  InputsList,
  InputGroup,
  InputIconContainer,
  ButtonContainer,
  Select,
  Error,
  EyeIconContainer,
} from './CreateUser.styled';
import { Main } from '../../Main/Main';
import { useState } from 'react';
import { MdAlternateEmail } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import {
  FaUser,
  FaGlobeEurope,
  FaCity,
  FaAddressCard,
  FaPhoneAlt,
  FaTelegramPlane,
  FaSkype,
  FaBirthdayCake,
  FaLanguage,
  FaBriefcase,
} from 'react-icons/fa';
import { FaPeopleGroup, FaCcMastercard } from 'react-icons/fa6';
import { Formik } from 'formik';
import { makeRequestSQL } from '../../../api/api';
import { LoginFormButton } from '../../Buttons/Buttons.styled';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { CreateUserSchema } from '../../../validations/CreateUserValidation';
import { userText } from '../../../services/componentText';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { fetchOtherInformations } from '../../../api/users';

export const CreateUser = () => {
  const { language, currentUser } = useContext(UserContext);
    const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
    const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
    const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const accessLevel = currentUser?.access_level;
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };
  const initialValues = {
    email: '',
    password: '',
    full_name: '',
    id_country: '',
    city: '',
    address: '',
    phone_number: '',
    telegram: '',
    skype: '',
    birthday: '',
    id_language: '',
    id_job_title: '',
    id_groupe: '',
  };

  const {data} = useQuery('information', fetchOtherInformations)

    const handleSubmit = async (value, resetForm) => {
      try {
        setLoading(true);
        await makeRequestSQL.post('/users/register', value);
        toast.success(userText[language][0]?.successUserCreate);
        resetForm();
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

  return (
    <div>
      {(currentUser?.access_level === access_level_e ||
        currentUser?.access_level === access_level_f ||
        currentUser?.access_level === access_level_g) && (
        <Main>
          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'top',
                height: '5vh',
              }}
            >
              <ClipLoader color="#3098d6" loading={loading} size={30} />
            </div>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={CreateUserSchema(language)}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ isValid }) => (
              <InfoForm autoComplete="off">
                <InputsList>
                  <InputGroup>
                    <Error name="email" component="div" />
                    <InputIconContainer>
                      <MdAlternateEmail size="22" color="green" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.email}
                      type="email"
                      name="email"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="password" component="div" />
                    <InputIconContainer>
                      <RiLockPasswordFill size="22" fill="#d6b330" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.password}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                    />
                    <EyeIconContainer onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <RiEyeOffLine size="22px" color="#3098d6" />
                      ) : (
                        <RiEyeLine size="22px" color="#3098d6" />
                      )}
                    </EyeIconContainer>
                  </InputGroup>

                  <InputGroup>
                    <Error name="full_name" component="div" />
                    <InputIconContainer>
                      <FaUser size="22" color="green" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.fullName}
                      type="text"
                      name="full_name"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="id_job_title" component="div" />
                    <InputIconContainer>
                      <FaBriefcase size="22" color="brown" />
                    </InputIconContainer>
                    <Select as="select" name="id_job_title">
                      <Option>{userText[language][0]?.chooseJobTitle}</Option>
                      {data?.jobTitles
                        ?.filter(title => accessLevel > title?.access_level)
                        ?.map(title => (
                          <Option
                            value={title?.id_job_title}
                            key={title?.id_job_title}
                          >
                            {language === 'russian'
                              ? title?.job_title_RU
                              : title?.job_title_UA}
                          </Option>
                        ))}
                    </Select>
                  </InputGroup>

                  <InputGroup>
                    <Error name="telegram" component="div" />
                    <InputIconContainer>
                      <FaTelegramPlane size="22" color="#0088CC" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.telegram}
                      type="text"
                      name="telegram"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="id_groupe" component="div" />
                    <InputIconContainer>
                      <FaPeopleGroup size="22" color="green" />
                    </InputIconContainer>
                    <Select as="select" name="id_groupe">
                      <Option>{userText[language][0]?.chooseGroupe}</Option>
                      {data?.groups?.map(groupe => (
                        <Option
                          key={groupe?.groupe_id}
                          value={groupe?.groupe_id}
                        >
                          {language === 'russian'
                            ? groupe?.groupe?.groupe_name_RU
                            : groupe?.groupe_name_UA}
                        </Option>
                      ))}
                    </Select>
                  </InputGroup>

                  <InputGroup>
                    <Error name="skype" component="div" />
                    <InputIconContainer>
                      <FaSkype size="22" color="#00AFF0" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.skype}
                      type="text"
                      name="skype"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="card" component="div" />
                    <InputIconContainer>
                      <FaCcMastercard size="28" color="#d6b330" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.card}
                      type="text"
                      name="card"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="id_country" component="div" />
                    <InputIconContainer>
                      <FaGlobeEurope size="22" color="#0077B6" />
                    </InputIconContainer>
                    <Select as="select" name="id_country">
                      <Option>{userText[language][0]?.chooseCountry}</Option>
                      {data?.countries?.map(country => (
                        <Option
                          key={country?.country_id}
                          value={country?.country_id}
                        >
                          {country?.country_name}
                        </Option>
                      ))}
                    </Select>
                  </InputGroup>

                  <InputGroup>
                    <Error name="city" component="div" />
                    <InputIconContainer>
                      <FaCity size="22" color="#0077B6" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.city}
                      type="text"
                      name="city"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="address" component="div" />
                    <InputIconContainer>
                      <FaAddressCard size="22" color="#0077B6" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.address}
                      type="text"
                      name="address"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="phone_number" component="div" />
                    <InputIconContainer>
                      <FaPhoneAlt size="22" color="green" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.phoneNumber}
                      type="tel"
                      name="phone_number"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="birthday" component="div" />
                    <InputIconContainer>
                      <FaBirthdayCake size="22" color="green" />
                    </InputIconContainer>
                    <Input
                      placeholder={userText[language][0]?.birthday}
                      type="date"
                      name="birthday"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="id_language" component="div" />
                    <InputIconContainer>
                      <FaLanguage size="22" color="#0077B6" />
                    </InputIconContainer>
                    <Select as="select" name="id_language">
                      <Option>{userText[language][0]?.chooseLanguage}</Option>
                      {data?.languages?.map(language => (
                        <Option key={language?.id} value={language?.id}>
                          {language?.original_name}
                        </Option>
                      ))}
                    </Select>
                  </InputGroup>
                </InputsList>

                <ButtonContainer>
                  <LoginFormButton type="submit" disabled={!isValid}>
                    {userText[language][0]?.createUser}
                  </LoginFormButton>
                </ButtonContainer>
              </InfoForm>
            )}
          </Formik>
        </Main>
      )}
      {currentUser?.access_level < 5 && (
        <Main>{userText[language][0]?.notFound}</Main>
      )}
    </div>
  );
};
