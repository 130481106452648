import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { useContext, useState } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { Outlet } from 'react-router-dom';
import { LinkOnPageNav } from '../../components/OnPageNav/LinkOnPageNav/LinkOnPageNav';
import { OnPageNavContainer } from '../../components/OnPageNav/OnPageNavContainer/OnPageNavContainer';
import { everydayStatisticsText } from '../../services/componentText';

export const EverydayStatistics = () => {
  const { currentUser, language } = useContext(UserContext);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
  const access_level_k = Number(process.env.REACT_APP_K_LEVEL_ACCESS);
  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;
  const getActiveTab = accessLevel
    ? 'users'
    : 'me';
  const [activeTab, setActiveTab] = useState(getActiveTab);

  return (
    <div>
      <SharedLayout />
      {(accessLevel || currentUser?.access_level === access_level_c) && (
        <OnPageNavContainer>
          <LinkOnPageNav
            to="me"
            onClick={() => setActiveTab('me')}
            className={activeTab === 'me' ? 'active' : ''}
          >
            {everydayStatisticsText[language][0]?.myStatistics}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="users"
            onClick={() => setActiveTab('users')}
            className={activeTab === 'users' ? 'active' : ''}
          >
            {everydayStatisticsText[language][0]?.allEmployeeStatistics}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="changes"
            onClick={() => setActiveTab('changes')}
            className={activeTab === 'changes' ? 'active' : ''}
          >
            {everydayStatisticsText[language][0]?.seeChanges}
          </LinkOnPageNav>
        </OnPageNavContainer>
      )}
            {(currentUser?.access_level === access_level_k) && (
        <OnPageNavContainer>
          <LinkOnPageNav
            to="me"
            onClick={() => setActiveTab('me')}
            className={activeTab === 'me' ? 'active' : ''}
          >
            {everydayStatisticsText[language][0]?.myStatistics}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="changes"
            onClick={() => setActiveTab('changes')}
            className={activeTab === 'changes' ? 'active' : ''}
          >
            {everydayStatisticsText[language][0]?.seeChanges}
          </LinkOnPageNav>
        </OnPageNavContainer>
      )}
      <Outlet />
    </div>
  );
};