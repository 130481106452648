import { Main } from '../../Main/Main';
import {
  Table,
  Thead,
  TableContainer,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  IconLinkContainer,
  TrashButton,
  AddedNewButton,
  NavContainer,
  ModalContainer,
  AddedContainer,
  Input,
  ButtonContainer,
  SaveButton,
  DeleteButton,
} from '../ClaimsControlPanel.styled';
import { Modal } from '../../Modal/Modal';
import {
  fetchReasonReturn,
    createReasonReturn,
    deleteReasonReturn,
    updateReasonReturn,
} from '../../../api/claimsControlPanel';
import { useQuery } from 'react-query';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { claimsControlPanelText } from '../../../services/componentText';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const ReasonReturn = () => {
    const { currentUser, language } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const { data, refetch } = useQuery(['reasonReturn'], () =>
    fetchReasonReturn(),
    );
    const [initialValues, setInitialValues] = useState({
      id: '',
      reason_title_ua: '',
      reason_title_ru: '',
    });

    const accessLevel =
      currentUser?.access_level === access_level_g ||
      currentUser?.access_level === access_level_f ||
      currentUser?.access_level === access_level_e;
  
    const handleDelete = async id => {
      const response = await deleteReasonReturn(id);
  
      if (response.status === 201) {
        resetValue();
        toast.success(claimsControlPanelText[language][0]?.successRemoved);
        return refetch();
      }
    };
  
    const handleSubmit = async () => {
      if (isUpdate) {
        const response = await updateReasonReturn(initialValues);
  
        if (response.status === 201) {
          resetValue();
          toast.success(claimsControlPanelText[language][0]?.successUpdate);
          return refetch();
        }
      }
  
      if (!isUpdate) {
        const response = await createReasonReturn(initialValues);
  
        if (response.status === 201) {
          resetValue();
          toast.success(claimsControlPanelText[language][0]?.successAdded);
          return refetch();
        }
      }
    };
  
    const resetValue = () => {
      setIsModalOpen(false);
      setIsUpdate(false);
      const initialValue = {
        id: '',
        reason_title_ua: '',
        reason_title_ru: '',
      };
      setInitialValues(initialValue);
    };
  
    const handleAddedNewClick = () => {
      resetValue();
      setIsModalOpen(true);
    };
  
    const handleEdit = item => {
      setInitialValues(item);
      setIsUpdate(true);
      setIsModalOpen(true);
    };


    return (
      <div>
        {accessLevel && (
          <Main>
            {isModalOpen && (
              <Modal closeModal={() => setIsModalOpen(false)}>
                <ModalContainer>
                  <AddedContainer>
                    <label>{claimsControlPanelText[language][0]?.titleUA}</label>
                    <Input
                      type="text"
                      value={initialValues.reason_title_ua}
                      placeholder={claimsControlPanelText[language][0]?.titleUA}
                      onChange={e => {
                        const updatedInitialValues = {
                          ...initialValues,
                          reason_title_ua: e.target.value,
                        };
                        setInitialValues(updatedInitialValues);
                      }}
                    />
                  </AddedContainer>
                  <AddedContainer>
                    <label>{claimsControlPanelText[language][0]?.titleRU}</label>
                    <Input
                      type="text"
                      value={initialValues.reason_title_ru}
                      placeholder={claimsControlPanelText[language][0]?.titleRU}
                      onChange={e => {
                        const updatedInitialValues = {
                          ...initialValues,
                          reason_title_ru: e.target.value,
                        };
                        setInitialValues(updatedInitialValues);
                      }}
                    />
                  </AddedContainer>
                  {isUpdate && (
                    <ButtonContainer>
                      <SaveButton onClick={handleSubmit}>
                        {claimsControlPanelText[language][0]?.update}
                      </SaveButton>
                      <DeleteButton
                        onClick={() => handleDelete(initialValues.id)}
                      >
                        {claimsControlPanelText[language][0]?.delete}
                      </DeleteButton>
                    </ButtonContainer>
                  )}
                  {!isUpdate && (
                    <ButtonContainer>
                      <SaveButton onClick={handleSubmit}>
                        {claimsControlPanelText[language][0]?.save}
                      </SaveButton>
                      <DeleteButton onClick={() => resetValue()}>
                        {claimsControlPanelText[language][0]?.cancel}
                      </DeleteButton>
                    </ButtonContainer>
                  )}
                </ModalContainer>
              </Modal>
            )}
            <NavContainer>
              <AddedNewButton onClick={() => handleAddedNewClick()}>
                {claimsControlPanelText[language][0]?.addNewStatus}
              </AddedNewButton>
            </NavContainer>
            <TableContainer>
              <Table>
                <Thead>
                  <HeadTr>
                    <Th> {claimsControlPanelText[language][0]?.number}</Th>
                    <Th style={{ width: '300px' }}>
                      {claimsControlPanelText[language][0]?.status}
                    </Th>
                    <Th>{claimsControlPanelText[language][0]?.controlPanel}</Th>
                  </HeadTr>
                </Thead>
                <tbody>
                  {data ? (
                    <React.Fragment>
                      {data?.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td style={{ width: '322px' }}>
                              {language === 'russian'
                                ? item.reason_title_ru
                                : item.reason_title_ua}
                            </Td>
                            <Td>
                              {' '}
                              <IconLinkContainer>
                                <IconButton onClick={() => handleEdit(item)}>
                                  <FaEdit size="20px" />
                                </IconButton>
                                <TrashButton
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <FaTrash />
                                </TrashButton>
                              </IconLinkContainer>
                            </Td>
                          </Tr>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <Tr>
                      <Td
                        colSpan={2}
                        style={{
                          width: '400px',
                          textAlign: 'start',
                          paddingLeft: '50px',
                        }}
                      >
                        {claimsControlPanelText[language][0]?.notDataOrError}
                      </Td>
                    </Tr>
                  )}
                </tbody>
              </Table>
            </TableContainer>
          </Main>
        )}
      </div>
    );
}