import { Main } from '../../Main/Main';
import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { UserContext } from '../../Context/UserContext';
import {
  fetchClaimsCategory,
  fetchWarehouseCity,
  fetchClaimsStatus,
  fetchClientConfigured,
  fetchRefundStatus,
  fetchReasonReturn,
} from '../../../api/claimsControlPanel';
import {
  fetchClaims,
  updateClaims,
  createClaims,
} from '../../../api/claims';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  ListContainer,
  ItemContainer,
  ClaimInput,
  ClaimLabel,
  AddedClaimSelect,
  AddedClaimtOption,
  ClaimCommentContainer,
  ClaimTextArea,
  SaveButton,
  ButtonContainer,
  DeleteButton,
} from './ClaimsItem.styled';
import { claimsControlPanelText } from '../../../services/componentText';

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);
const access_level_a = Number(process.env.REACT_APP_A_LEVEL_ACCESS);

export const ClaimsItem = () => {
  const { currentUser, language } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    id: '',
    employee_id: currentUser?.id_employee,
    order_id: '',
    product_title: '',
    reason_return_id: '',
    client_address: '',
    phone_number: '',
    full_name: '',
    client_detail: '',
    warehouse_city_id: '',
    claims_category_id: '',
    client_configured_id: '',
    is_client_lie: '',
    address_for_refund: '',
    verification_status_id: '',
    claims_status_id: '',
    ttn_client_number: '',
    refund_status_id: '',
    ttn_warehouse_number: '',
    status_message_id: 1,
    employee_describe: '',
  });

  const isCreateNewClaim = location?.pathname === '/claims/new-claim';
  const maxLength = 250;

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const warehouseAccessLevelWithManager =
    accessLevel || currentUser?.access_level === access_level_a;
  const operatorWithManagerAccessLevel =
    accessLevel || currentUser?.access_level === access_level_i;

  const { data: claimsCategoryData } = useQuery(
    'fetchClaimsCategory',
    fetchClaimsCategory,
  );

  const { data: warehouseData } = useQuery(
    'fetchWarehouseCity',
    fetchWarehouseCity,
  );

  const { data: claimStatusData } = useQuery(
    'fetchClaimsStatus',
    fetchClaimsStatus,
  );

  const { data: clientConfiguredData } = useQuery(
    'fetchClientConfigured',
    fetchClientConfigured,
  );

  const { data: refundStatusData } = useQuery(
    'fetchRefundStatus',
    fetchRefundStatus,
  );

  const { data: reasonReturnData } = useQuery(
    'reasonReturnData',
    fetchReasonReturn,
  );

  useEffect(() => {
    const fetchClaim = async () => {
      if (isCreateNewClaim) {
        return;
      }

      const claimIdFromPath = location?.pathname?.replace(
        /^\/claims\/change-claim\//,
        '',
      );

      const response = await fetchClaims({ id: claimIdFromPath });

      if (response?.data?.length > 0) {
        const fetchData = response?.data;
        const updatedInitialValue = {
          ...fetchData[0],
          status_message_id: 1,
        };
        setInitialValues(updatedInitialValue);
      }
    };
    fetchClaim();
  }, [location, isCreateNewClaim]);

  const handleSubmit = async () => {
    if (isCreateNewClaim) {
      const response = await createClaims(initialValues);

      if (response?.status === 201) {
        toast.success(claimsControlPanelText[language][0]?.successSave);
        return navigate('/claims/claim-list');
      }
    }

    if (!isCreateNewClaim) {
      const response = await updateClaims(initialValues);

      if (response?.status === 201) {
        toast.success(claimsControlPanelText[language][0]?.successUpdate);
        return navigate('/claims/claim-list');
      }
    }
  };

  const handleCancelClick = () => {
    return navigate('/claims/claim-list');
  };

  return (
    <Main>
      <ListContainer>
        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.enterOrderNumber}

            <ClaimInput
              type="number"
              placeholder={
                claimsControlPanelText[language][0]?.enterOrderNumber
              }
              value={initialValues?.order_id || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  order_id: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.enterProductTitle}

            <div style={{ width: '60%' }}>
              <ClaimInput
                style={{ width: '95%' }}
                value={initialValues?.product_title || ''}
                onChange={e => {
                  const updatedInitialValue = {
                    ...initialValues,
                    product_title: e.target.value,
                  };
                  if (updatedInitialValue?.product_title?.length <= maxLength) {
                    setInitialValues(updatedInitialValue);
                  }
                }}
                placeholder={
                  claimsControlPanelText[language][0]?.enterProductTitle
                }
                maxLength={maxLength}
                required
              />
              <p style={{ margin: '0' }}>
                {claimsControlPanelText[language][0]?.leftSymbols}{' '}
                {maxLength - initialValues?.product_title?.length
                  ? maxLength - initialValues?.product_title?.length
                  : 250}
                /{maxLength}
              </p>
            </div>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.chooseReturnReasom}

            <AddedClaimSelect
              value={initialValues?.reason_return_id || ''}
              onChange={e => {
                const updatedCheckList = {
                  ...initialValues,
                  reason_return_id: e.target.value,
                };
                setInitialValues(updatedCheckList);
              }}
              style={{ backgroundColor: '#f5f7fa' }}
            >
              <AddedClaimtOption value={null} key={0}>
                {claimsControlPanelText[language][0]?.chooseReturnReasom}
              </AddedClaimtOption>
              {reasonReturnData?.map(item => (
                <AddedClaimtOption value={item?.id} key={item?.id}>
                  {language === 'russian'
                    ? item.reason_title_ru
                    : item.reason_title_ua}
                </AddedClaimtOption>
              ))}
            </AddedClaimSelect>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.clientPhone}

            <ClaimInput
              type="tel"
              placeholder={claimsControlPanelText[language][0]?.clientPhone}
              value={initialValues?.phone_number || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  phone_number: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.clientFullName}

            <div style={{ width: '60%' }}>
              <ClaimInput
                style={{ width: '95%' }}
                value={initialValues?.full_name || ''}
                onChange={e => {
                  const updatedInitialValue = {
                    ...initialValues,
                    full_name: e.target.value,
                  };
                  if (updatedInitialValue?.full_name?.length <= maxLength) {
                    setInitialValues(updatedInitialValue);
                  }
                }}
                placeholder={
                  claimsControlPanelText[language][0]?.clientFullName
                }
                maxLength={maxLength}
                required
              />
              <p style={{ margin: '0' }}>
                {claimsControlPanelText[language][0]?.leftSymbols}{' '}
                {maxLength - initialValues?.full_name?.length
                  ? maxLength - initialValues?.full_name?.length
                  : 250}
                /{maxLength}
              </p>
            </div>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.clientCard}

            <ClaimInput
              type="tel"
              placeholder={claimsControlPanelText[language][0]?.clientCard}
              value={initialValues?.client_detail || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  client_detail: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.claimsWarehouse}

            <AddedClaimSelect
              value={initialValues?.warehouse_city_id || ''}
              onChange={e => {
                const updatedCheckList = {
                  ...initialValues,
                  warehouse_city_id: e.target.value,
                };
                setInitialValues(updatedCheckList);
              }}
              style={{ backgroundColor: '#f5f7fa' }}
            >
              <AddedClaimtOption value={null} key={0}>
                {claimsControlPanelText[language][0]?.claimsWarehouse}
              </AddedClaimtOption>
              {warehouseData?.map(item => (
                <AddedClaimtOption value={item?.id} key={item?.id}>
                  {language === 'russian'
                    ? item.city_name_ru
                    : item.city_name_ua}
                </AddedClaimtOption>
              ))}
            </AddedClaimSelect>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.selecteClaimCategory}

            <AddedClaimSelect
              value={initialValues?.claims_category_id || ''}
              onChange={e => {
                const updatedCheckList = {
                  ...initialValues,
                  claims_category_id: e.target.value,
                  verification_status_id: 12,
                };
                setInitialValues(updatedCheckList);
              }}
              style={{ backgroundColor: '#f5f7fa' }}
            >
              <AddedClaimtOption value={''} key={0}>
                {claimsControlPanelText[language][0]?.selecteClaimCategory}
              </AddedClaimtOption>
              {claimsCategoryData?.map(item => (
                <AddedClaimtOption value={item?.id} key={item?.id}>
                  {language === 'russian'
                    ? item.category_title_ru
                    : item.category_title_ua}
                </AddedClaimtOption>
              ))}
            </AddedClaimSelect>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.selecteClientConfigured}

            <AddedClaimSelect
              value={initialValues?.client_configured_id || ''}
              onChange={e => {
                const updatedCheckList = {
                  ...initialValues,
                  client_configured_id: e.target.value,
                };
                setInitialValues(updatedCheckList);
              }}
              style={{ backgroundColor: '#f5f7fa' }}
            >
              <AddedClaimtOption value={null} key={0}>
                {claimsControlPanelText[language][0]?.selecteClientConfigured}
              </AddedClaimtOption>
              {clientConfiguredData?.map(item => (
                <AddedClaimtOption value={item?.id} key={item?.id}>
                  {language === 'russian'
                    ? item.configured_title_ru
                    : item.configured_title_ua}
                </AddedClaimtOption>
              ))}
            </AddedClaimSelect>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.checkClient}

            <AddedClaimSelect
              value={initialValues?.is_client_lie || '0'}
              onChange={e => {
                const updatedCheckList = {
                  ...initialValues,
                  is_client_lie: e.target.value,
                };
                setInitialValues(updatedCheckList);
              }}
              style={{ backgroundColor: '#f5f7fa' }}
            >
              <AddedClaimtOption value={0} key={0}>
                {claimsControlPanelText[language][0]?.checkClient}
              </AddedClaimtOption>
              <AddedClaimtOption value="1" key="1">
                {language === 'russian' ? 'Да' : 'Так'}
              </AddedClaimtOption>
              <AddedClaimtOption value="2" key="2">
                {language === 'russian' ? 'Нет' : 'Ні'}
              </AddedClaimtOption>
            </AddedClaimSelect>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.clientAddress}

            <div style={{ width: '60%' }}>
              <ClaimTextArea
                style={{ width: '95%' }}
                value={initialValues?.client_address || ''}
                onChange={e => {
                  const updatedInitialValue = {
                    ...initialValues,
                    client_address: e.target.value,
                  };
                  if (
                    updatedInitialValue?.client_address?.length <= maxLength
                  ) {
                    setInitialValues(updatedInitialValue);
                  }
                }}
                placeholder={claimsControlPanelText[language][0]?.clientAddress}
                maxLength={maxLength}
                required
              />
              <p style={{ margin: '0' }}>
                {claimsControlPanelText[language][0]?.leftSymbols}{' '}
                {maxLength - initialValues?.client_address?.length
                  ? maxLength - initialValues?.client_address?.length
                  : 250}
                /{maxLength}
              </p>
            </div>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.refundAddress}

            <div style={{ width: '60%' }}>
              <ClaimTextArea
                style={{ width: '95%' }}
                value={initialValues?.address_for_refund || ''}
                onChange={e => {
                  const updatedInitialValue = {
                    ...initialValues,
                    address_for_refund: e.target.value,
                  };
                  if (
                    updatedInitialValue?.address_for_refund?.length <= maxLength
                  ) {
                    setInitialValues(updatedInitialValue);
                  }
                }}
                placeholder={claimsControlPanelText[language][0]?.refundAddress}
                maxLength={maxLength}
                required
              />
              <p style={{ margin: '0' }}>
                {claimsControlPanelText[language][0]?.leftSymbols}{' '}
                {maxLength - initialValues?.address_for_refund?.length
                  ? maxLength - initialValues?.address_for_refund?.length
                  : 250}
                /{maxLength}
              </p>
            </div>
          </ClaimLabel>
        </ItemContainer>

        <ClaimCommentContainer>
        <ClaimLabel>
            {claimsControlPanelText[language][0]?.comment}

            <div style={{ width: '80%' }}>
              <ClaimTextArea
                style={{ width: '95%' }}
                value={initialValues?.employee_describe || ''}
                onChange={e => {
                  const updatedInitialValue = {
                    ...initialValues,
                    employee_describe: e.target.value,
                  };
                  if (
                    updatedInitialValue?.employee_describe?.length <= maxLength
                  ) {
                    setInitialValues(updatedInitialValue);
                  }
                }}
                placeholder={claimsControlPanelText[language][0]?.comment}
                required
              />
            </div>
          </ClaimLabel>
        </ClaimCommentContainer>

        <ClaimCommentContainer style={{ borderColor: '#f28d07' }}>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.resultCheck}

            <AddedClaimSelect
              value={initialValues?.verification_status_id || ''}
              onChange={e => {
                const updatedCheckList = {
                  ...initialValues,
                  verification_status_id: e.target.value,
                };
                setInitialValues(updatedCheckList);
              }}
              style={{ backgroundColor: '#f5f7fa' }}
              disabled={!warehouseAccessLevelWithManager}
            >
              <AddedClaimtOption value={null} key={0}>
                {claimsControlPanelText[language][0]?.resultCheck}
              </AddedClaimtOption>
              {claimsCategoryData?.map(item => (
                <AddedClaimtOption value={item?.id} key={item?.id}>
                  {language === 'russian'
                    ? item.category_title_ru
                    : item.category_title_ua}
                </AddedClaimtOption>
              ))}
            </AddedClaimSelect>
          </ClaimLabel>
        </ClaimCommentContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.clientTTN}

            <ClaimInput
              type="tel"
              placeholder={claimsControlPanelText[language][0]?.clientTTN}
              value={initialValues?.ttn_client_number || ''}
              onChange={e => {
                const updatedInitialValue = {
                    ...initialValues,
                    ttn_client_number: e.target.value,
                    refund_status_id: e.target.value !== '' ? 1 : '',
                };
                setInitialValues(updatedInitialValue);
            }}            
            />
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer style={{ borderColor: 'green' }}>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.refundWasTaken}

            <AddedClaimSelect
              value={initialValues?.refund_status_id || ''}
              onChange={e => {
                const updatedCheckList = {
                  ...initialValues,
                  refund_status_id: e.target.value,
                  claims_status_id: Number(e.target.value) === 2 ? 1 : '',
                };
                setInitialValues(updatedCheckList);
              }}
              style={{ backgroundColor: '#f5f7fa' }}
              disabled={!warehouseAccessLevelWithManager}
            >
              <AddedClaimtOption value={null} key={0}>
                {claimsControlPanelText[language][0]?.refundWasTaken}
              </AddedClaimtOption>
              {refundStatusData?.map(item => (
                <AddedClaimtOption value={item?.id} key={item?.id}>
                  {language === 'russian'
                    ? item.refund_status_ru
                    : item.refund_status_ua}
                </AddedClaimtOption>
              ))}
            </AddedClaimSelect>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer style={{ borderColor: 'green' }}>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.sendMoneyOrSent}

            <AddedClaimSelect
              value={initialValues?.claims_status_id || ''}
              onChange={e => {
                const updatedCheckList = {
                  ...initialValues,
                  claims_status_id: e.target.value,
                };
                setInitialValues(updatedCheckList);
              }}
              style={{ backgroundColor: '#f5f7fa' }}
              disabled={!warehouseAccessLevelWithManager}
            >
              <AddedClaimtOption value={null} key={0}>
                {claimsControlPanelText[language][0]?.sendMoneyOrSent}
              </AddedClaimtOption>
              {claimStatusData?.map(item => (
                <AddedClaimtOption value={item?.id} key={item?.id}>
                  {language === 'russian'
                    ? item.status_title_ru
                    : item.status_title_ua}
                </AddedClaimtOption>
              ))}
            </AddedClaimSelect>
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer style={{ borderColor: 'green' }}>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.ourTTN}

            <ClaimInput
              type="tel"
              placeholder={claimsControlPanelText[language][0]?.ourTTN}
              value={initialValues?.ttn_warehouse_number || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  ttn_warehouse_number: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
              disabled={!warehouseAccessLevelWithManager}
            />
          </ClaimLabel>
        </ItemContainer>
      </ListContainer>
      {isCreateNewClaim && (
        <ButtonContainer>
          <SaveButton onClick={() => handleSubmit(initialValues)}>
            {claimsControlPanelText[language][0]?.save}
          </SaveButton>
          <DeleteButton onClick={() => handleCancelClick()}>
            {claimsControlPanelText[language][0]?.cancel}
          </DeleteButton>
        </ButtonContainer>
      )}
      {!isCreateNewClaim && (
        <ButtonContainer>
          <SaveButton onClick={() => handleSubmit(initialValues)}>
            {claimsControlPanelText[language][0]?.update}
          </SaveButton>
          <DeleteButton onClick={() => {
              const updatedInitialValue = {
                ...initialValues,
                status_message_id: 4,
              };
              handleSubmit(updatedInitialValue);
            }}>
            {claimsControlPanelText[language][0]?.delete}
          </DeleteButton>
        </ButtonContainer>
      )}
    </Main>
  );
};
