import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Outlet } from 'react-router-dom';
import { LinkButton, NavContainer } from './ClaimsControlPanel.styled';
import { UserContext } from '../../components/Context/UserContext';
import React, { useContext} from 'react';
import { claimsControlPanelText } from '../../services/componentText';
import { Main } from '../../components/Main/Main';
import { useLocation } from 'react-router-dom';

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const ClaimsControlPanel = () => {
  const { currentUser, language } = useContext(UserContext);
  const location = useLocation();
  const activeTab = location?.pathname;

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  return (
    <div>
      <SharedLayout />
      {accessLevel && (
        <Main>
          <NavContainer>
            <LinkButton
              to={`/claims-control-panel/category`}
              className={
                activeTab === '/claims-control-panel/category' ? 'active' : ''
              }
            >
              {claimsControlPanelText[language][0]?.claimsCategory}
            </LinkButton>
            <LinkButton
              to={`/claims-control-panel/reason-return`}
              className={
                activeTab === '/claims-control-panel/reason-return' ? 'active' : ''
              }
            >
              {claimsControlPanelText[language][0]?.reasonReturn}
            </LinkButton>
            <LinkButton
              to={`/claims-control-panel/refund-status`}
              className={
                activeTab === '/claims-control-panel/refund-status'
                  ? 'active'
                  : ''
              }
            >
              {claimsControlPanelText[language][0]?.refundStatus}
            </LinkButton>
            <LinkButton
              to={`/claims-control-panel/warehouse-city`}
              className={
                activeTab === '/claims-control-panel/warehouse-city'
                  ? 'active'
                  : ''
              }
            >
              {claimsControlPanelText[language][0]?.wirehouse}
            </LinkButton>
            <LinkButton
              to={`/claims-control-panel/status`}
              className={
                activeTab === '/claims-control-panel/status' ? 'active' : ''
              }
            >
              {claimsControlPanelText[language][0]?.statusFromWarehouse}
            </LinkButton>
            <LinkButton
              to={`/claims-control-panel/client-configured`}
              className={
                activeTab === '/claims-control-panel/client-configured'
                  ? 'active'
                  : ''
              }
            >
              {claimsControlPanelText[language][0]?.clientsType}
            </LinkButton>
          </NavContainer>
        </Main>
      )}
      {!accessLevel && (
        <Main>Такої сторінки не знайдено</Main>
      )}
      <Outlet />
    </div>
  );
};
