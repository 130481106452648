import { createContext, useState, useEffect } from "react";
import { logoutUser, fetchCurrentUser } from "../../api/users";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [language, setLanguage] = useState(
    currentUser?.language || localStorage.getItem("language") || "ukraine"
  );
  const [prevUser, setPrevUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);

  const handleLogin = (userData) => {
    setCurrentUser(userData);
  };

  useEffect(() => {
    if (currentUser?.language) {
      setLanguage(currentUser.language);
    }
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchCurrentUser();

        if (response) {
          setCurrentUser(response);
          setPrevUser(response);
        }
      } catch (error) {
        console.error("Ошибка при загрузке данных о пользователе:", error);
      } finally {
        setIsUserLoading(false);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (currentUser?.id_employee) {
        const response = await fetchCurrentUser();

        if (
          !prevUser ||
          JSON.stringify(response) !== JSON.stringify(prevUser)
        ) {
          setPrevUser(response);
          setCurrentUser(response);
        }

        if (response?.isBlocked) {
          logoutUser();
          setCurrentUser(null);
        }
      }
    };

    const intervalId = setInterval(checkUserStatus, 3 * 60 * 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        handleLogin,
        language,
        isUserLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
