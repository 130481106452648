import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const createMutationDutyGroupe = async groupeData => {
  try {
    const response = await makeRequestSQL.post('/duty/groupe', groupeData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchDutyGroupe = async () => {
  try {
    const response = await makeRequestSQL.get('/duty/groupe');
    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchUsers = async () => {
  try {
    const response = await makeRequestSQL.get(
      '/users?search=&sortBy=id_employee&order=ASC&isBlocked=0',
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchDutyEmployee = async () => {
  try {
    const response = await makeRequestSQL.get('/duty/employee');
    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createMutationDutyEmployee = async employeeData => {
  try {
    const response = await makeRequestSQL.post('/duty/employee', employeeData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteMutationDutyEmployee = async (
  employee_id,
  refetchDutyEmployee,
) => {
  try {
    const response = await makeRequestSQL.delete(`/duty/employee`, {
      params: {
        employee_id,
      },
    });
    refetchDutyEmployee();
    toast.success('Оператора успішно видалено з групи чергувань');
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateDutyGroupe = async groupeData => {
  try {
    const response = await makeRequestSQL.put('/duty/groupe', groupeData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteMutationDutyGroupe = async id_groupe => {
  try {
    const response = await makeRequestSQL.delete(`/duty/groupe`, {
      params: {
        id_groupe,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchDutySchedule = async (year, month) => {

  try {
    const response = await makeRequestSQL.get('/duty/schedule', {
      params: {
        year,
        month,
        id_status: 2,
      },
    });
    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const saveSchedule = async scheduleData => {
  try {
    const response = await makeRequestSQL.post('/schedule', scheduleData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateSchedule = async (dataForServer) => {
try {
  if (dataForServer?.id_schedule) {
    await makeRequestSQL.put(
      `/schedule/${dataForServer.id_schedule}`,
      dataForServer,
    );
  } else {
    await makeRequestSQL.post('/schedule', dataForServer);
  }
} catch (error) {
  const errorMessage = error.response?.data?.message || error.message;
  toast.error(errorMessage);
}
}

export const deleteSchedule = async (selectedEmployeeId, day, ) => {
  try {
    const response = await makeRequestSQL.delete(`/schedule/`, {
      params: {
        employee_id: selectedEmployeeId,
        day,
        status: 2,
        id_categorie: 9
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};