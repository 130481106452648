import styled from '@emotion/styled';

export const LanguageSelect = styled.select`
  appearance: none;
  width: 62px;
  height: 25px;
  padding: 1px;
  padding-left: 5px;
  background-color: #f5f7fa;
  border: 2px solid #3098d6;
  font-size: 18px;
  margin-left: 10px;
  border-radius: 10px;

  cursor: pointer;
`;

export const LanguageOption = styled.option`
  appearance: none;
  width: 100%;
  height: 10px;
  padding-left: 5px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 18px;

  cursor: pointer;
`;

export const LanguageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: inherit;
  margin-left: 10px;
`;
