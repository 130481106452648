import { Main } from '../../Main/Main';
import { useQuery } from 'react-query';
import React, { useState, useContext } from 'react';
import {
  getScheduleHourByCategorie,
  fetchCategories,
  fetchInformations,
} from '../../../api/schedule';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { UserContext } from '../../Context/UserContext';
import {
  AddedNewReportButton,
  SaveButton,
  ReportTh,
  ReportTd,
  ReportHeadTr,
  ReportTr,
  ReportTable,
  TableContainer,
  Thead,
  NavContainer,
  DataSelect,
  DataOption,
} from './HoursStatistic.styled';
import { Modal } from '../../Modal/Modal';
import { hoursStatisticText } from '../../../services/componentText';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';

export const HoursStatisic = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: 'selection',
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');
  const statusId = 2;
  const [jobTitle, setJobTitle] = useState(3);
  const [order, setOrder] = useState('DESC');
  const [sortBy, setSortBy] = useState('day');

  const { data, refetch } = useQuery(
    ['hoursStatisticsData', order, jobTitle],
    () =>
      getScheduleHourByCategorie({
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        id_status: statusId,
        job_titles: jobTitle,
        order,
      }),
  );

  const { data: categories } = useQuery('categoriesData', fetchCategories);
  const { data: informationData } = useQuery(
    'informationData',
    fetchInformations,
  );

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewReportButton onClick={() => setIsModalDateOpen(true)}>
          {hoursStatisticText[language][0]?.choosePeriod}
        </AddedNewReportButton>
        <DataSelect
          value={jobTitle}
          onChange={e => {
            const idJobTitle = Number(e.target.value);
            setJobTitle(idJobTitle);
          }}
        >
          <DataOption key="0" value={'0'}>
            {language === 'russian' ? 'Все отделы' : 'Всі відділи'}
          </DataOption>
          {informationData &&
            informationData?.jobTitles?.map(title => (
              <DataOption key={title.id_job_title} value={title.id_job_title}>
                {language === 'russian'
                  ? title.job_title_RU
                  : title.job_title_UA}
              </DataOption>
            ))}
        </DataSelect>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {hoursStatisticText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <TableContainer>
        <ReportTable>
          <Thead>
            <ReportHeadTr>
              <ReportTh onClick={() => handleSortChange('day')}>
                {hoursStatisticText[language][0]?.date} {renderSortIcon('day')}
              </ReportTh>
              {categories &&
                categories?.map(categorie => {
                  return (
                    <ReportTh key={categorie?.schedule_category_id}>
                      {language === 'russian'
                        ? categorie?.schedule_categorie_name_RU
                        : categorie?.schedule_categorie_name_UA}
                    </ReportTh>
                  );
                })}
            </ReportHeadTr>
          </Thead>
          <tbody>
            {data ? (
              Object.keys(data)?.map(date => {
                const items = data[date];
                const totalHoursByCategory = {};
                const totalRecordCountByCategory = {};

                items?.forEach(item => {
                  const { id_categorie, total_hours, record_count } = item;

                  if (!totalHoursByCategory[id_categorie]) {
                    totalHoursByCategory[id_categorie] = total_hours;
                  } else if (total_hours) {
                    totalHoursByCategory[id_categorie] += total_hours;
                  }

                  if (!totalRecordCountByCategory[id_categorie]) {
                    totalRecordCountByCategory[id_categorie] = record_count;
                  } else if (record_count) {
                    totalRecordCountByCategory[id_categorie] += record_count;
                  }
                });

                return (
                  <ReportTr key={date}>
                    <ReportTd>{formattedDateToDayMonthYear(date)}</ReportTd>
                    {categories?.map(categorie => (
                      <React.Fragment key={categorie?.schedule_category_id}>
                        <ReportTd>
                          {totalHoursByCategory[
                            categorie?.schedule_category_id
                          ] ||
                            totalRecordCountByCategory[
                              categorie?.schedule_category_id
                            ] ||
                            0}
                        </ReportTd>
                      </React.Fragment>
                    ))}
                  </ReportTr>
                );
              })
            ) : (
              <ReportTr>
                <ReportTd
                  colSpan={13}
                  style={{
                    width: '100%',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {hoursStatisticText[language][0]?.notDataOrError}
                </ReportTd>
              </ReportTr>
            )}
          </tbody>
        </ReportTable>
      </TableContainer>
    </Main>
  );
};
