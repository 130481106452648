import { Main } from '../../Main/Main';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  SaveButton,
  AddedNewFMButton,
  NavContainer,
  AddedSelect,
  AddedOption,
} from './SeeChangesClaimsReport.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { claimsControlPanelText } from '../../../services/componentText';
import { BarChart } from '../../BarChart/BarChart';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { Modal } from '../../Modal/Modal';
import { fetchUsers } from '../../../api/users';
import { fetchClaimsReport } from '../../../api/claims';

const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);

export const SeeChangesClaimsReport = () => {
  const { currentUser, language } = useContext(UserContext);
  const sortBy = 'createdAt';
  const order = 'ASC';
  const [selectedEmployee, setSelectedEmployee] = useState(
    currentUser?.id_job_title === 9 ? currentUser?.id_employee : '',
  );
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const { data: claimsReportData, refetch } = useQuery(
    ['claimsReportData', selectedEmployee, sortBy, order],
    () =>
      fetchClaimsReport({
        employee_id: selectedEmployee,
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const { data: users } = useQuery('allEmployees', fetchUsers);

  const createNewDateForChart = value => {
    const label = 'Дані для діаграми';
    const data = {
      labels: claimsReportData?.map(item =>
        formattedDateToDayMonthYear(item.createdAt),
      ),
      datasets: [
        {
          label: 'Дані для діаграми',
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: 'rgba(75,192,192,1)',
          data: [],
          hoveredLabel: label,
        },
      ],
    };

    switch (value) {
      case 'order_processed':
        data.datasets[0].data = claimsReportData?.map(
          item => item.order_processed,
        );
        break;
      case 'total_order':
        data.datasets[0].data = claimsReportData?.map(item => item.total_order);
        break;
      case 'consultations':
        data.datasets[0].data = claimsReportData?.map(
          item => item.consultations,
        );
        break;
      case 'claim_accepted':
        data.datasets[0].data = claimsReportData?.map(
          item => item.claim_accepted,
        );
        break;
      case 'return_issued':
        data.datasets[0].data = claimsReportData?.map(
          item => item.return_issued,
        );
        break;
      case 'problem_customer':
        data.datasets[0].data = claimsReportData?.map(
          item => item.problem_customer,
        );
        break;
      case 'consultations_percent':
        data.datasets[0].data = claimsReportData?.map(
          item => Number(item.consultations_percent),
        );
        break;
      case 'claim_accepted_percent':
        data.datasets[0].data = claimsReportData?.map(
          item => Number(item.claim_accepted_percent),
        );
        break;
      case 'return_issued_percent':
        data.datasets[0].data = claimsReportData?.map(
          item => Number(item.return_issued_percent),
        );
        break;
      case 'completed_exchanges_percent':
        data.datasets[0].data = claimsReportData?.map(
          item => Number(item.completed_exchanges_percent),
        );
        break;
      case 'problem_customer_percent':
        data.datasets[0].data = claimsReportData?.map(
          item => Number(item.problem_customer_percent),
        );
        break;

      default:
        data.datasets[0].data = claimsReportData?.map(
          item => item.completed_exchanges,
        );
    }

    return data;
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };
  return (
    <Main>
      {accessLevel || currentUser?.access_level === access_level_i ? (
        <div>
          <NavContainer>
            <div
              style={{ marginRight: '10px' }}
            >{`${formattedStartDate} - ${formattedEndDate}`}</div>
            <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
              {claimsControlPanelText[language][0]?.choosePeriod}
            </AddedNewFMButton>
            {accessLevel && (
              <div style={{ marginLeft: '10px' }}>
                <label>
                  {claimsControlPanelText[language][0]?.chooseManagerOne}
                  <AddedSelect
                    value={selectedEmployee || ''}
                    onChange={e => setSelectedEmployee(e.target.value)}
                    style={{ backgroundColor: '#f5f7fa' }}
                  >
                    <AddedOption value="">
                      {claimsControlPanelText[language][0]?.chooseManagerTwo}
                    </AddedOption>
                    {users?.usersData?.map(
                      user =>
                        Number(user?.id_job_title) === 9 && (
                          <AddedOption
                            value={user?.id_employee}
                            key={user?.id_employee}
                          >
                            {user?.full_name}
                          </AddedOption>
                        ),
                    )}
                  </AddedSelect>
                </label>
              </div>
            )}
          </NavContainer>
          {isModalDateOpen && (
            <Modal closeModal={() => setIsModalDateOpen(false)}>
              <DateRangePicker
                onChange={item => setPeriod([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                locale={currentUser?.language === 'russian' ? ru : uk}
                staticRanges={customDefaultStaticRanges}
                inputRanges={customDefaultInputRanges}
                months={2}
                ranges={period}
                direction="horizontal"
                style={{ margin: '20px' }}
              />
              <SaveButton onClick={() => handleDate()}>
                {claimsControlPanelText[language][0]?.update}
              </SaveButton>
            </Modal>
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <BarChart
              title={claimsControlPanelText[language][0]?.orderProcessed}
              data={createNewDateForChart('order_processed')}
            />
            <BarChart
              title={claimsControlPanelText[language][0]?.consultations}
              data={createNewDateForChart('consultations')}
            />
            <BarChart
              title={claimsControlPanelText[language][0]?.claimAccepted}
              data={createNewDateForChart('claim_accepted')}
            />
            <BarChart
              title={claimsControlPanelText[language][0]?.returnIssued}
              data={createNewDateForChart('return_issued')}
            />
            <BarChart
              title={claimsControlPanelText[language][0]?.completedExchanges}
              data={createNewDateForChart('completed_exchanges')}
            />
            <BarChart
              title={claimsControlPanelText[language][0]?.problemCustomer}
              data={createNewDateForChart('problem_customer')}
            />
            <BarChart
              title={claimsControlPanelText[language][0]?.percentConsultations}
              data={createNewDateForChart('consultations_percent')}
            />
            <BarChart
              title={claimsControlPanelText[language][0]?.percentReturnIssued}
              data={createNewDateForChart('return_issued_percent')}
            />
            <BarChart
              title={claimsControlPanelText[language][0]?.percentClaimAccepted}
              data={createNewDateForChart('claim_accepted_percent')}
            />
            <BarChart
              title={
                claimsControlPanelText[language][0]?.percentCompletedExchanges
              }
              data={createNewDateForChart('completed_exchanges_percent')}
            />
            <BarChart
              title={
                claimsControlPanelText[language][0]?.percentProblemCustomer
              }
              data={createNewDateForChart('problem_customer_percent')}
            />
          </div>
        </div>
      ) : (
        <div>У вас немає доступу для перегляду сторінки</div>
      )}
    </Main>
  );
};
