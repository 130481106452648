import {
  NavigationTopLeftBar,
  NavigationLogoContainer,
  NavigationTopLeftButtonContainer,
  NavigationLeftHidden,
} from './NavigationTopLeftBar.styled';
import logo from '../../images/logo.jpg';
import { FaAnglesLeft, FaAnglesRight } from 'react-icons/fa6';
import { useContext } from 'react';
import { NavContext } from '../Context/NavContext';

export const TopLeftBarNavigation = () => {
  const { isNavVisible, toggleNav } = useContext(NavContext);
  return (
    <NavigationTopLeftBar
      style={{
        transform: isNavVisible ? 'translateX(150%)' : 'translateX(0%)',
        transition: 'transform 0.3s',
      }}
    >
      <NavigationLogoContainer>
        <a href="https://www.crm-ogomart.com.ua/">
          <img src={logo} width="150px" height="100px" alt="OGO MART" />
        </a>
      </NavigationLogoContainer>
      <NavigationTopLeftButtonContainer
        onClick={toggleNav}
        style={{ position: 'absolute', top: '10px', right: '-50px' }}
      >
        <NavigationLeftHidden>
        {isNavVisible ? <FaAnglesLeft /> : <FaAnglesRight />}
        </NavigationLeftHidden>
      </NavigationTopLeftButtonContainer>
    </NavigationTopLeftBar>
  );
};
