import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
  width: 120%;
`;

export const ScheduleTable = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const ScheduleTr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const ScheduleHeadTr = styled(ScheduleTr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const ScheduleTh = styled.th`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &:first-of-type {
    width: 80px;
    position: sticky;
    left: 0;
    z-index: 1;
  }
`;

export const ScheduleTd = styled.td`
  width: 42px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color: ${props => props.backgroundColor || '#ffffff'};

  color: ${props => (props?.backgroundColor ? '#ffffff' : '#000000')};

  &:last-of-type {
    border-right: none;
  }

  &:first-of-type {
    width: 100px;
    position: sticky;
    left: 0;
    z-index: 1;
  }
`;

export const SaveButton = styled(LoginFormButton)`
  width: 45%;
  margin-left: auto;
  margin-right: auto;
`;

export const AddedTimeContainer = styled.div`
  width: 500px;
  height: 60px;
`;

export const AddedTimeInput = styled.input`
appearance: none;
width: 120px;
height: 30px;
padding: 5px;
background-color: #f5f7fa;
border: 2px solid #3098d6;
font-size: 18px;
margin-left: 10px;
border-radius: 10px;

cursor: pointer;
`;

export const MonthButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const ScheduleMeNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DataSelect = styled.select`
appearance: none;
width: 120px;
height: 30px;
padding: 5px;
background-color: #f5f7fa;
border: 2px solid #3098d6;
font-size: 16px;
margin-left: 10px;
border-radius: 10px;

cursor: pointer;
`;

export const CategorySelect = styled(DataSelect)`
width: 200px;
`

export const DataOption = styled.option`
  width: 20%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
`;

export const AddScheduleContainerModal = styled.div`
  width: 500px;
  min-height: 300px;
  text-align: center;
`;

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const ChooseDataContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 20px;
  }
`;

export const CopyNavContainer = styled(ScheduleMeNavContainer)`
  margin-top: 20px;
`;

export const StatusSelect = styled.select`
  appearance: none;
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 0.5rem 1rem;
  margin-left: 10px;
  background-color: ${props =>
    props.value === '1'
      ? '#d6c930'
      : props.value === '2'
      ? '#30d68b'
      : props.value === '3'
      ? '#d63030'
      : '#ffffff'};
  border: 1px solid #000000;
  cursor: pointer;
`;

export const StatusOption = styled.option`
  width: 20%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
`;

export const EmployeeScheduleTd = styled(ScheduleTd)`
  width: 100px;
`;

export const EmployeeScheduleTh = styled(ScheduleTh)`
  width: 78px;
`;

export const CheckSchedule = styled.button`
background-color: rgb(214, 211, 48);
padding: 5px;
margin-left: 3px;
cursor: pointer;
transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);

&:hover,
&:focus,
&.active {
  transform: scale(1.2); 
}
`

export const ApproveSchedule = styled(CheckSchedule)`
background-color: rgb(48, 214, 109);
`

export const DeclineSchedule = styled(CheckSchedule)`
background-color: rgb(214, 48, 60);
`

export const CategoryContainer = styled.div`
  width: 100%;
  padding: 3px;
  background-color: #94bbe9;
`;