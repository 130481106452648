import { Main } from '../../Main/Main';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  SaveButton,
  AddedNewFMButton,
  NavContainer,
} from './SeeChangesCallReport.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import {
  fetchCallReport,
} from '../../../api/report';
import { callReportsText } from '../../../services/componentText';
import { BarChart } from '../../BarChart/BarChart';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { Modal } from '../../Modal/Modal';

const convertTimeToDecimalHours = timeString => {
  // Check if timeString is defined and is a string
  if (typeof timeString !== 'string') {
    return 0; 
  }

  const timeArray = timeString?.split(':')?.map(Number);

  if (!Array.isArray(timeArray) || timeArray.length < 3) {

    return 0; 
  }

  const [hours, minutes, seconds] = timeArray;

  const totalMinutes = hours * 60 + minutes + seconds / 60;
  return totalMinutes / 60; 
};


export const SeeChangesCallReport = () => {
    const { currentUser, language } = useContext(UserContext);
    const sortBy = 'date';
    const order = 'ASC';
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentDate = new Date();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const [period, setPeriod] = useState([
      {
        startDate: new Date(
          currentYear,
          currentMonth - 2,
          currentDate.getDate(),
        ),
        endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
        key: 'selection',
      },
    ]);
    const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
    const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
    const formattedStartDateRequest = format(
      period[0]?.startDate,
      'yyyy.MM.dd',
    );
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');
  
    const { data: callReportData, refetch } = useQuery(
      [
        'callReport',
        sortBy,
        order,
        formattedStartDateRequest,
        formattedEndDateRequest,
      ],
      () =>
        fetchCallReport({
          sortBy,
          order,
          startDate: formattedStartDateRequest,
          endDate: formattedEndDateRequest,
        }),
    );

    const createNewDateForChart = value => {
      const label = 'Дані для діаграми';
      const data = {
        labels: callReportData?.map(item =>
          formattedDateToDayMonthYear(item.date),
        ),
        datasets: [
          {
            label: 'Дані для діаграми',
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)',
            data: [],
            hoveredLabel: label,
          },
        ],
      };

      switch (value) {
        case 'time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.time),
          );
          break;
        case 'new_auto_call_status_number':
          data.datasets[0].data = callReportData?.map(
            item => item.new_auto_call_status_number,
          );
          break;
        case 'attempt_called_operators':
          data.datasets[0].data = callReportData?.map(
            item => item.attempt_called_operators,
          );
          break;
        case 'total_call':
          data.datasets[0].data = callReportData?.map(item => item.total_call);
          break;
        case 'success_call':
          data.datasets[0].data = callReportData?.map(
            item => item.success_call,
          );
          break;
        case 'unsuccess_call':
          data.datasets[0].data = callReportData?.map(
            item => item.unsuccess_call,
          );
          break;
        case 'lost_call':
          data.datasets[0].data = callReportData?.map(item => item.lost_call);
          break;
        case 'loged_in_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.loged_in_time),
          );
          break;
        case 'talking_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.talking_time),
          );
          break;
        case 'not_ready_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.not_ready_time),
          );
          break;
        case 'ready_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.ready_time),
          );
          break;
        case 'break_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.break_time),
          );
          break;
        case 'approved_order_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.approved_order_time),
          );
          break;
        case 'technical_break_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.technical_break_time),
          );
          break;
        case 'planning_meeting_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.planning_meeting_time),
          );
          break;
        case 'real_not_ready_time':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.real_not_ready_time),
          );
          break;
        case 'real_time_at_work':
          data.datasets[0].data = callReportData?.map(item =>
            convertTimeToDecimalHours(item.real_time_at_work),
          );
          break;
        case 'received_per_hour_without_not_ready_time':
          data.datasets[0].data = callReportData?.map(
            item => item.received_per_hour_without_not_ready_time,
          );
          break;
        case 'percent_not_ready_time':
          data.datasets[0].data = callReportData?.map(
            item => item.percent_not_ready_time,
          );
          break;
        case 'percent_break_time':
          data.datasets[0].data = callReportData?.map(
            item => item.percent_break_time,
          );
          break;
        default:
          data.datasets[0].data = callReportData?.map(
            item => item.percent_approve_time,
          );
      }

      return data;
    };

    const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const customDefaultInputRanges = defaultInputRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const handleDate = () => {
      refetch();
      setIsModalDateOpen(false);
    };

    return (
      <Main>
        <NavContainer>
          <div
            style={{ marginRight: '10px' }}
          >{`${formattedStartDate} - ${formattedEndDate}`}</div>
          <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
            {callReportsText[language][0]?.choosePeriod}
          </AddedNewFMButton>
        </NavContainer>
        {isModalDateOpen && (
          <Modal closeModal={() => setIsModalDateOpen(false)}>
            <DateRangePicker
              onChange={item => setPeriod([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              locale={currentUser?.language === 'russian' ? ru : uk}
              staticRanges={customDefaultStaticRanges}
              inputRanges={customDefaultInputRanges}
              months={2}
              ranges={period}
              direction="horizontal"
              style={{ margin: '20px' }}
            />
            <SaveButton onClick={() => handleDate()}>
              {callReportsText[language][0]?.update}
            </SaveButton>
          </Modal>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <BarChart
            title={callReportsText[language][0]?.time}
            data={createNewDateForChart('time')}
          />
          <BarChart
            title={callReportsText[language][0]?.totalOrderInNewStatus}
            data={createNewDateForChart('new_auto_call_status_number')}
          />
          <BarChart
            title={callReportsText[language][0]?.attemptCalledOperators}
            data={createNewDateForChart('attempt_called_operators')}
          />
          <BarChart
            title={callReportsText[language][0]?.totalOrder}
            data={createNewDateForChart('total_call')}
          />
          <BarChart
            title={callReportsText[language][0]?.totalSuccessOrder}
            data={createNewDateForChart('success_call')}
          />
          <BarChart
            title={callReportsText[language][0]?.totalUnsuccessOrder}
            data={createNewDateForChart('unsuccess_call')}
          />
          <BarChart
            title={callReportsText[language][0]?.totalLostOrder}
            data={createNewDateForChart('lost_call')}
          />
          <BarChart
            title={callReportsText[language][0]?.logedInTime}
            data={createNewDateForChart('loged_in_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.talkingTime}
            data={createNewDateForChart('talking_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.notReadyTime}
            data={createNewDateForChart('not_ready_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.readyTime}
            data={createNewDateForChart('ready_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.breakTime}
            data={createNewDateForChart('break_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.approveTime}
            data={createNewDateForChart('approved_order_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.technicalBreakTime}
            data={createNewDateForChart('technical_break_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.planningMeetingTime}
            data={createNewDateForChart('planning_meeting_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.realNotReadyTime}
            data={createNewDateForChart('real_not_ready_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.realTimeAtWork}
            data={createNewDateForChart('real_time_at_work')}
          />
          <BarChart
            title={
              callReportsText[language][0]?.receivedPerHourWithoutNotReadyTime
            }
            data={createNewDateForChart(
              'received_per_hour_without_not_ready_time',
            )}
          />
          <BarChart
            title={callReportsText[language][0]?.percentNotReadyTime}
            data={createNewDateForChart('percent_not_ready_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.percentBreakTime}
            data={createNewDateForChart('percent_break_time')}
          />
          <BarChart
            title={callReportsText[language][0]?.percentApproveTime}
            data={createNewDateForChart('percent_approve_time')}
          />
        </div>
      </Main>
    );
};
