import { Main } from '../../Main/Main';
import { fetchClaimsReport, deleteClaimsReport } from '../../../api/claims';
import { useQuery } from 'react-query';
import React, { useState, useContext } from 'react';
import {
  AddedSelect,
  AddedOption,
  Thead,
  TableContainer,
  Table,
  Tr,
  HeadTr,
  Td,
  Th,
  SaveButton,
  AddedNewButton,
  NavContainer,
  IconLinkContainer,
  IconButton,
  TrashButton,
} from './ClaimsReportList.styled';
import { claimsControlPanelText } from '../../../services/componentText';
import { UserContext } from '../../Context/UserContext';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from '../../Modal/Modal';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { fetchUsers } from '../../../api/users';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { FaTrash, FaEdit } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);

export const ClaimsReportList = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [order, setOrder] = useState('DESC');
  const [selectedEmployee, setSelectedEmployee] = useState(
    currentUser?.id_job_title === 4 ? currentUser?.full_name : '',
  );
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data, refetch } = useQuery(
    ['fetchClaimsReport', selectedEmployee, sortBy, order],
    () =>
      fetchClaimsReport({
        employee_id: selectedEmployee,
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const { data: usersData } = useQuery('users', fetchUsers);

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleDelete = async id => {
    const response = await deleteClaimsReport(id);

    if (response.status === 200) {
      toast.success(claimsControlPanelText[language][0]?.successRemoved);
      return refetch();
    }
  };

  return (
    <Main>
      {accessLevel || currentUser?.access_level === access_level_i ? (
        <div>
          <NavContainer>
            <div
              style={{ marginRight: '10px' }}
            >{`${formattedStartDate} - ${formattedEndDate}`}</div>
            <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
              {claimsControlPanelText[language][0]?.choosePeriod}
            </AddedNewButton>
            {accessLevel && (
              <div style={{ marginLeft: '10px' }}>
                <label>
                  {claimsControlPanelText[language][0]?.chooseManagerOne}
                  <AddedSelect
                    value={selectedEmployee || ''}
                    onChange={e => setSelectedEmployee(e.target.value)}
                    style={{ backgroundColor: '#f5f7fa' }}
                  >
                    <AddedOption value="">
                      {claimsControlPanelText[language][0]?.chooseManagerTwo}
                    </AddedOption>
                    {usersData?.usersData?.map(
                      user =>
                        Number(user?.id_job_title) === 9 && (
                          <AddedOption
                            value={user?.id_employee}
                            key={user?.id_employee}
                          >
                            {user?.full_name}
                          </AddedOption>
                        ),
                    )}
                  </AddedSelect>
                </label>
              </div>
            )}
          </NavContainer>
          {isModalDateOpen && (
            <Modal closeModal={() => setIsModalDateOpen(false)}>
              <DateRangePicker
                onChange={item => setPeriod([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                locale={language === 'russian' ? ru : uk}
                staticRanges={customDefaultStaticRanges}
                inputRanges={customDefaultInputRanges}
                months={2}
                ranges={period}
                direction="horizontal"
                style={{ margin: '20px' }}
              />
              <SaveButton onClick={() => handleDate()}>
                {claimsControlPanelText[language][0]?.update}
              </SaveButton>
            </Modal>
          )}
          <TableContainer>
            <Table>
              <Thead>
                <HeadTr>
                  <Th onClick={() => handleSortChange('createdAt')}>
                    {claimsControlPanelText[language][0]?.createdDate}
                    {renderSortIcon('createdAt')}
                  </Th>
                  <Th onClick={() => handleSortChange('full_name')}>
                    {claimsControlPanelText[language][0]?.employeeFullName}
                    {renderSortIcon('full_name')}
                  </Th>
                  <Th onClick={() => handleSortChange('order_processed')}>
                    {claimsControlPanelText[language][0]?.orderProcessed}
                    {renderSortIcon('order_processed')}
                  </Th>
                  <Th onClick={() => handleSortChange('consultations')}>
                    {claimsControlPanelText[language][0]?.consultations}
                    {renderSortIcon('consultations')}
                  </Th>
                  <Th onClick={() => handleSortChange('claim_accepted')}>
                    {claimsControlPanelText[language][0]?.claimAccepted}
                    {renderSortIcon('claim_accepted')}
                  </Th>
                  <Th onClick={() => handleSortChange('return_issued')}>
                    {claimsControlPanelText[language][0]?.returnIssued}
                    {renderSortIcon('return_issued')}
                  </Th>
                  <Th onClick={() => handleSortChange('completed_exchanges')}>
                    {claimsControlPanelText[language][0]?.completedExchanges}
                    {renderSortIcon('completed_exchanges')}
                  </Th>
                  <Th onClick={() => handleSortChange('problem_customer')}>
                    {claimsControlPanelText[language][0]?.problemCustomer}
                    {renderSortIcon('problem_customer')}
                  </Th>
                  <Th onClick={() => handleSortChange('consultations_percent')}>
                    {claimsControlPanelText[language][0]?.percentConsultations}
                    {renderSortIcon('consultations_percent')}
                  </Th>
                  <Th
                    onClick={() => handleSortChange('claim_accepted_percent')}
                  >
                    {claimsControlPanelText[language][0]?.percentClaimAccepted}
                    {renderSortIcon('claim_accepted_percent')}
                  </Th>
                  <Th onClick={() => handleSortChange('return_issued_percent')}>
                    {claimsControlPanelText[language][0]?.percentReturnIssued}
                    {renderSortIcon('return_issued_percent')}
                  </Th>
                  <Th
                    onClick={() =>
                      handleSortChange('completed_exchanges_percent')
                    }
                  >
                    {
                      claimsControlPanelText[language][0]
                        ?.percentCompletedExchanges
                    }
                    {renderSortIcon('completed_exchanges_percent')}
                  </Th>
                  <Th
                    onClick={() => handleSortChange('problem_customer_percent')}
                  >
                    {
                      claimsControlPanelText[language][0]
                        ?.percentProblemCustomer
                    }
                    {renderSortIcon('problem_customer_percent')}
                  </Th>
                  <Th onClick={() => handleSortChange('work_duration')}>
                    {claimsControlPanelText[language][0]?.oneOrderWorkDuration}
                    {renderSortIcon('work_duration')}
                  </Th>
                  {currentUser?.access_level === access_level_i && (
                    <Th>{claimsControlPanelText[language][0]?.controlPanel}</Th>
                  )}
                </HeadTr>
              </Thead>
              <tbody>
                {data?.length > 0 ? (
                  <React.Fragment>
                    {data?.map(item => {
                      const key = uuidv4();

                      return (
                        <Tr key={key}>
                          <Td>{formattedDateToDayMonthYear(item.createdAt)}</Td>
                          <Td>{item.full_name}</Td>
                          <Td>{item.order_processed}</Td>
                          <Td>{item.consultations}</Td>
                          <Td>{item.claim_accepted}</Td>
                          <Td>{item.return_issued}</Td>
                          <Td>{item.completed_exchanges}</Td>
                          <Td>{item.problem_customer}</Td>
                          <Td>{item.consultations_percent}%</Td>
                          <Td>{item.claim_accepted_percent}%</Td>
                          <Td>{item.return_issued_percent}%</Td>
                          <Td>{item.completed_exchanges_percent}%</Td>
                          <Td>{item.problem_customer_percent}%</Td>
                          <Td>{item.work_duration}</Td>
                          {currentUser?.access_level === access_level_i && (
                            <Td>
                              <IconLinkContainer>
                                <IconButton
                                  to={`/claims-report/change-report/${item.id}`}
                                >
                                  <FaEdit />
                                </IconButton>
                                <TrashButton
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <FaTrash />
                                </TrashButton>
                              </IconLinkContainer>
                            </Td>
                          )}
                        </Tr>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <Tr>
                    <Td
                      colSpan={13}
                      style={{
                        width: '2000px',
                        textAlign: 'start',
                        paddingLeft: '50px',
                      }}
                    >
                      {claimsControlPanelText[language][0]?.notDataOrError}
                    </Td>
                  </Tr>
                )}
              </tbody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div>У вас немає доступу для перегляду сторінки</div>
      )}
    </Main>
  );
};
