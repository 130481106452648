import {
  NavigationLeftBarContainer,
  NavigationLeftBarPrimaryList,
  NavigationLeftBarPrimaryItem,
  NavigationLeftBarCategoryTitleContainer,
  NavigationLeftBarCategoryImage,
  NavigationLeftBarCategoryTitle,
} from './GlobalMenu.styled';
import {
  SubMenuOrganizational,
  SubMenuProducts,
  SubMenuKnowledges,
  SubMenuWiretapping,
  SubMenuReport,
  SubMenuClaims
} from './SubMenu';
import { AiOutlineSchedule } from 'react-icons/ai';
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { GiGiftOfKnowledge } from 'react-icons/gi';
import { LuBaggageClaim } from "react-icons/lu";
import { ImHeadphones } from 'react-icons/im';
import { FcStatistics } from 'react-icons/fc';
import { navbarText } from '../../services/componentText';
import { useContext } from 'react';
import { UserContext } from '../Context/UserContext';
import { TbReport } from 'react-icons/tb';


export const GlobalMenu = () => {
  const { language } =
    useContext(UserContext);
  return (
    <div>
      <NavigationLeftBarContainer>
        <NavigationLeftBarPrimaryList>
          <NavigationLeftBarPrimaryItem>
            <NavigationLeftBarCategoryTitleContainer href="#">
              <NavigationLeftBarCategoryImage>
                <AiOutlineSchedule size="24px" />
              </NavigationLeftBarCategoryImage>
              <NavigationLeftBarCategoryTitle>
                {navbarText[language][0]?.organizational}
              </NavigationLeftBarCategoryTitle>
            </NavigationLeftBarCategoryTitleContainer>
            <SubMenuOrganizational />
          </NavigationLeftBarPrimaryItem>

          <NavigationLeftBarPrimaryItem>
            <NavigationLeftBarCategoryTitleContainer href="#">
              <NavigationLeftBarCategoryImage>
                <MdOutlineProductionQuantityLimits size="24px" />
              </NavigationLeftBarCategoryImage>
              <NavigationLeftBarCategoryTitle>
                {navbarText[language][0]?.workwithproducts}
              </NavigationLeftBarCategoryTitle>
            </NavigationLeftBarCategoryTitleContainer>
            <SubMenuProducts />
          </NavigationLeftBarPrimaryItem>

          <NavigationLeftBarPrimaryItem>
            <NavigationLeftBarCategoryTitleContainer href="#">
              <NavigationLeftBarCategoryImage>
                <ImHeadphones size="24px" />
              </NavigationLeftBarCategoryImage>
              <NavigationLeftBarCategoryTitle>
                {navbarText[language][0]?.wiretapping}
              </NavigationLeftBarCategoryTitle>
            </NavigationLeftBarCategoryTitleContainer>
            <SubMenuWiretapping />
          </NavigationLeftBarPrimaryItem>

          <NavigationLeftBarPrimaryItem>
            <NavigationLeftBarCategoryTitleContainer href="#">
              <NavigationLeftBarCategoryImage>
                <LuBaggageClaim  size="24px" />
              </NavigationLeftBarCategoryImage>
              <NavigationLeftBarCategoryTitle>
                {navbarText[language][0]?.claims}
              </NavigationLeftBarCategoryTitle>
            </NavigationLeftBarCategoryTitleContainer>
            <SubMenuClaims />
          </NavigationLeftBarPrimaryItem>

          {/* <NavigationLeftBarPrimaryItem>
            <NavigationLeftBarCategoryTitleContainer href="#">
              <NavigationLeftBarCategoryImage>
                <FcStatistics size="24px" />
              </NavigationLeftBarCategoryImage>
              <NavigationLeftBarCategoryTitle>
                {navbarText[language][0]?.statistics}
              </NavigationLeftBarCategoryTitle>
            </NavigationLeftBarCategoryTitleContainer>
            <SubMenuOrganizational language={language} />
          </NavigationLeftBarPrimaryItem> */}

          <NavigationLeftBarPrimaryItem>
            <NavigationLeftBarCategoryTitleContainer href="#">
              <NavigationLeftBarCategoryImage>
                <TbReport size="26px" />
              </NavigationLeftBarCategoryImage>
              <NavigationLeftBarCategoryTitle>
                {navbarText[language][0]?.report}
              </NavigationLeftBarCategoryTitle>
            </NavigationLeftBarCategoryTitleContainer>
            <SubMenuReport />
          </NavigationLeftBarPrimaryItem>

          <NavigationLeftBarPrimaryItem>
            <NavigationLeftBarCategoryTitleContainer href="#">
              <NavigationLeftBarCategoryImage>
                <GiGiftOfKnowledge size="24px" />
              </NavigationLeftBarCategoryImage>
              <NavigationLeftBarCategoryTitle>
                {navbarText[language][0]?.knowledgebase}
              </NavigationLeftBarCategoryTitle>
            </NavigationLeftBarCategoryTitleContainer>
            <SubMenuKnowledges language={language} />
          </NavigationLeftBarPrimaryItem>
        </NavigationLeftBarPrimaryList>
      </NavigationLeftBarContainer>
    </div>
  );
};
