import { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import { fmText } from '../../../services/componentText';
import 'react-toastify/dist/ReactToastify.css';
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  Thead,
} from './UserFM.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { fetchAllFM } from '../../../api/fm';

export const UserFM = () => {
  const { currentUser, language } = useContext(UserContext);


  const { data: fm } = useQuery(
    ['fmData'],
    () =>
      fetchAllFM({
        sortBy: 'fm_date',
        order: 'DESC',
        id: currentUser?.id_employee,
      }),
  );


  return (
    <div>
      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh>{fmText[language][0]?.date}</ScheduleTh>
              <ScheduleTh>{fmText[language][0]?.reasonFm}</ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {fm?.map(fm => {
              return (
                <ScheduleTr key={fm.fm_id}>
                  <ScheduleTd>
                    {formattedDateToDayMonthYear(fm.fm_date)}
                  </ScheduleTd>
                  <ScheduleTd>{fm.fm_describe}</ScheduleTd>
                </ScheduleTr>
              );
            })}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </div>
  );
};
