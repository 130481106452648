import { Main } from '../Main/Main';
import {
  Thead,
  TableContainer,
  Table,
  Tr,
  HeadTr,
  Td,
  Th,
  NavContainer,
  AddedNewButton,
  SaveButton,
} from './ClaimStatistic.styled';
import { fetchClaimsStatistics } from '../../api/claims';
import React, { useContext, useState, useEffect } from 'react';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../helpers/reactDateRange/translateLabel';
import { Modal } from '../Modal/Modal';
import { useQuery } from 'react-query';
import { claimsControlPanelText } from '../../services/componentText';
import {
  fetchReasonReturn,
} from '../../api/claimsControlPanel';
import { UserContext } from '../Context/UserContext';

export const ReasonReturnStatistics = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortedCategoryData, setSortedCategoryData] = useState([]);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');
  const [sortBy, setSortBy] = useState('createdAt');
  const [order, setOrder] = useState('DESC');

  const { data: claimsStatisticsData, refetch } = useQuery(
    ['claimsStatisticsData', sortBy, order],
    () =>
      fetchClaimsStatistics({
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const { data: reasonReturnData } = useQuery(
    'reasonReturnData',
    fetchReasonReturn,
  );

  useEffect(() => {
    if (
      reasonReturnData?.length > 0 &&
      claimsStatisticsData?.reasonReturnResults?.length > 0
    ) {
      const sortedData = reasonReturnData
        .map(item => {
          const totalCategoryCount =
            claimsStatisticsData.reasonReturnResults.find(
              category => category.id === item.id,
            )?.totalCount || 0;
          return {
            ...item,
            totalCount: totalCategoryCount,
          };
        })
        .sort((a, b) => {
          if (sortBy === 'reason_title_ru' || sortBy === 'reason_title_ua') {
            return order === 'ASC'
              ? (a.reason_title_ua || '').localeCompare(b.reason_title_ua || '')
              : (b.reason_title_ua || '').localeCompare(a.reason_title_ua || '');
          } else {
            return order === 'ASC'
              ? a.totalCount - b.totalCount
              : b.totalCount - a.totalCount;
          }
        });
      setSortedCategoryData(sortedData);
    }
  }, [reasonReturnData, claimsStatisticsData, sortBy, order]);

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortBy(field);
      setOrder('ASC');
    }
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
          {claimsControlPanelText[language][0]?.choosePeriod}
        </AddedNewButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {claimsControlPanelText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <TableContainer>
        <Table>
          <Thead>
            <HeadTr>
              <Th
                style={{ width: '500px' }}
                onClick={() =>
                  handleSortChange(
                    language === 'russian'
                      ? 'reason_title_ru'
                      : 'reason_title_ua',
                  )
                }
              >
                {claimsControlPanelText[language][0]?.status}{' '}
                {renderSortIcon(
                  language === 'russian'
                    ? 'reason_title_ru'
                    : 'reason_title_ua',
                )}
              </Th>
              <Th onClick={() => handleSortChange('count')}>
                {claimsControlPanelText[language][0]?.count}{' '}
                {renderSortIcon('count')}
              </Th>
            </HeadTr>
          </Thead>

          <tbody>
            {sortedCategoryData?.length > 0 ? (
              <React.Fragment>
                {sortedCategoryData?.map(item => (
                  <Tr key={item.id}>
                    <Td style={{ width: '522px' }}>
                      {language === 'russian'
                        ? item.reason_title_ru
                        : item.reason_title_ua}
                    </Td>
                    <Td>{item.totalCount}</Td>
                  </Tr>
                ))}
              </React.Fragment>
            ) : (
              <Tr>
                <Td
                  colSpan={3}
                  style={{
                    width: '590px',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {claimsControlPanelText[language][0]?.notDataOrError}
                </Td>
              </Tr>
            )}
          </tbody>
        </Table>
      </TableContainer>
    </Main>
  );
};
