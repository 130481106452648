import { OnPageNavLink } from "./LinkOnPageNav.styled";
import PropTypes from 'prop-types';

export const LinkOnPageNav = ({ children, onClick, ...allProps }) => {
    return (
      <OnPageNavLink onClick={onClick} {...allProps}>
        {children}
      </OnPageNavLink>
    );
};

LinkOnPageNav.defaultProps = {
   onClick: () => null,
   children: null,
};
 
 LinkOnPageNav.propTypes = {
   onClick: PropTypes.func,
   children: PropTypes.node,
 };