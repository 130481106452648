import { UserContext } from '../../Context/UserContext';
import { useEffect, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { finesText } from '../../../services/componentText';
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  Thead,
  FinesLink,
  NavContainer,
  AddedNewButton,
  SaveButton,
} from './FinesUser.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { Modal } from '../../Modal/Modal';
import { fetchAllFines } from '../../../api/fines';
import { fetchCheckList } from '../../../api/wiretapping';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { merge, orderBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ClipLoader } from 'react-spinners';
import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const createImageURL = imageData => {
  const binaryData = new Uint8Array(imageData?.data);
  const blob = new Blob([binaryData], { type: `image/${imageData?.type}` });
  return URL.createObjectURL(blob);
};

export const FinesUser = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isImageModalOpen, setIsImageOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [newData, setNewData] = useState([]);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data: fines, refetch: refetchFines } = useQuery(['finesData'], () =>
    fetchAllFines({
      employee_id: currentUser?.id_employee,
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    }),
  );

  const {
    data: wiretappingData,
    refetch: wiretappingRefetch,
    isLoading,
  } = useQuery(['wiretappingCheckList'], () => {
    if (
      currentUser?.access_level !== null &&
      currentUser?.access_level !== undefined
    ) {
      return fetchCheckList({
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        employee_full_name: currentUser?.full_name,
        only_fines_for_employee: true
      });
    }
  });

  const handleImageClick = image => {
    setIsImageOpenModal(true);
    setSelectedImage(image);
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetchFines();
    wiretappingRefetch();
    setIsModalDateOpen(false);
  };

  useEffect(() => {
    const createNewData = () => {
      const mergedData = merge(
        fines?.reduce((acc, item) => {
          acc[item.fines_id] = item;
          return acc;
        }, {}),
        wiretappingData?.data?.data?.reduce((acc, item) => {
          const finesId = item.id;
          if (acc[finesId]) {
            acc[finesId].wiretappingData = item;
          } else {
            acc[finesId] = item;
          }
          return acc;
        }, {}),
      );

      const dataArray = Object.values(mergedData);

      const sortedDataArray = orderBy(
        dataArray,
        [item => (item?.fines_date ? item?.fines_date : item?.createdAt)],
        ['desc'],
      );

      return setNewData([...sortedDataArray]);
    };

    createNewData();
  }, [fines, wiretappingData?.data]);

  const createAudioURL = (audioData, audioType) => {
    const binaryData = new Uint8Array(audioData);
    const blob = new Blob([binaryData], { type: `audio/${audioType}` });
    return URL.createObjectURL(blob);
  };

  return (
    <div>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
          {finesText[language][0]?.choosePeriod}
        </AddedNewButton>
      </NavContainer>
      {isImageModalOpen && (
        <Modal closeModal={() => setIsImageOpenModal(false)}>
          <div style={{ width: '95%' }}>
            <img
              src={createImageURL(selectedImage)}
              alt="Скріншот"
              width="100%"
              height="100%"
            />
          </div>
        </Modal>
      )}
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {finesText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh>{finesText[language][0]?.date}</ScheduleTh>
              <ScheduleTh>{finesText[language][0]?.reasonFines}</ScheduleTh>
              <ScheduleTh>{finesText[language][0]?.finesPrice}</ScheduleTh>
              <ScheduleTh>
                {finesText[language][0]?.additionalInformation}
              </ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
          {isLoading && (
                <ScheduleTr>
                  <ScheduleTd
                    colSpan={13}
                    style={{
                      width: '100%',
                      textAlign: 'start',
                      paddingLeft: '8%',
                    }}
                  >
                    <ClipLoader color={'#123abc'} size={50} />
                  </ScheduleTd>
                </ScheduleTr>
              )}
            {!isLoading && newData?.map(item => {
              const finesCategoryDataString = item?.fines_category || '[]';
              const finesCategoryData = JSON.parse(finesCategoryDataString);
              const key = uuidv4();

              return (
                <ScheduleTr key={key}>
                  <ScheduleTd>
                    {formattedDateToDayMonthYear(
                      item.fines_date ? item.fines_date : item.createdAt,
                    )}
                  </ScheduleTd>
                  {item.fines_describe && (
                    <ScheduleTd style={{ width: '420px' }}>
                      {item.fines_describe}
                    </ScheduleTd>
                  )}
                  {item.employee_full_name && (
                    <ScheduleTd style={{ width: '420px' }}>
                      {Array.isArray(finesCategoryData) ? (
                        finesCategoryData.map(value => {
                          const key = uuidv4();
                          return (
                            <div key={key} style={{ marginBottom: '10px' }}>
                              <span>
                                {language === 'russian'
                                  ? value?.fine_title_ru
                                  : value?.fine_title_ua}
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <div>-</div>
                      )}
                    </ScheduleTd>
                  )}
                  <ScheduleTd>{item.fines_price ? item.fines_price : item.fine_amount} грн</ScheduleTd>

                  <ScheduleTd
                    onClick={() => handleImageClick(item?.fines_url_image_1 || item?.url_image_1)}
                  >
                    {item?.fines_url_image_1 || item?.url_image_1  ? (
                      <img
                        src={createImageURL(item?.fines_url_image_1 || item?.url_image_1)}
                        alt="Скріншот"
                        width="100px"
                        height="100px"
                      />
                    ) : (
                      <div>-</div>
                    )}
                  </ScheduleTd>

                  <ScheduleTd style={{width: '327px'}}>
                  {item?.url_record_1 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              key={item.id}
                              src={createAudioURL(
                                item?.url_record_1?.data,
                                item?.url_record_1?.type,
                              )}
                            />
                          )}
                          {item?.url_record_2 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              src={createAudioURL(
                                item?.url_record_2?.data,
                                item?.url_record_2?.type,
                              )}
                            />
                          )}
                          {item?.url_record_3 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              src={createAudioURL(
                                item?.url_record_3?.data,
                                item?.url_record_3?.type,
                              )}
                            />
                          )}
                          {item?.url_record_4 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              src={createAudioURL(
                                item?.url_record_4?.data,
                                item?.url_record_4?.type,
                              )}
                            />
                          )}
                          {item?.url_record_5 && (
                            <ReactH5AudioPlayer
                              autoPlayAfterSrcChange={false}
                              src={createAudioURL(
                                item?.url_record_5?.data,
                                item?.url_record_5?.type,
                              )}
                            />
                          )}
                  </ScheduleTd>
                </ScheduleTr>
              );
            })}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </div>
  );
};
