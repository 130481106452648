import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Main } from '../../components/Main/Main';
import { Outlet, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import {
  NavContainer,
  LinkButton,
} from '../ClaimStatistics/ClaimStatistics.styled';
import { claimsControlPanelText } from '../../services/componentText';

export const ClaimStatistics = () => {
  const { language } = useContext(UserContext);
  const location = useLocation();
  const activeTab = location?.pathname;

  return (
    <div>
      <SharedLayout />

      <Main>
        <NavContainer>
          <LinkButton
            to={`/claim-statistics/category`}
            className={
              activeTab === '/claim-statistics/category' ? 'active' : ''
            }
          >
            {claimsControlPanelText[language][0]?.claimCategoryStatistic}
          </LinkButton>

          <LinkButton
            to={`/claim-statistics/reason-return`}
            className={
              activeTab === '/claim-statistics/reason-return' ? 'active' : ''
            }
          >
            {claimsControlPanelText[language][0]?.reasonReturnStatistic}
          </LinkButton>

          <LinkButton
            to={`/claim-statistics/refund-status`}
            className={
              activeTab === '/claim-statistics/refund-status' ? 'active' : ''
            }
          >
            {claimsControlPanelText[language][0]?.refundStatusStatistic}
          </LinkButton>

          <LinkButton
            to={`/claim-statistics/warehouse-city`}
            className={
              activeTab === '/claim-statistics/warehouse-city' ? 'active' : ''
            }
          >
            {claimsControlPanelText[language][0]?.wirehouseCityStatistic}
          </LinkButton>

          <LinkButton
            to={`/claim-statistics/verification-status`}
            className={
              activeTab === '/claim-statistics/verification-status'
                ? 'active'
                : ''
            }
          >
            {claimsControlPanelText[language][0]?.verificationStatusStatistic}
          </LinkButton>

          <LinkButton
            to={`/claim-statistics/status`}
            className={activeTab === '/claim-statistics/status' ? 'active' : ''}
          >
            {claimsControlPanelText[language][0]?.warehouseStatusStatistic}
          </LinkButton>

          <LinkButton
            to={`/claim-statistics/client-configured`}
            className={
              activeTab === '/claim-statistics/client-configured'
                ? 'active'
                : ''
            }
          >
            {claimsControlPanelText[language][0]?.clientConfiguredStatistic}
          </LinkButton>

          <LinkButton
            to={`/claim-statistics/product`}
            className={
              activeTab === '/claim-statistics/product' ? 'active' : ''
            }
          >
            {claimsControlPanelText[language][0]?.productStatistic}
          </LinkButton>
        </NavContainer>
      </Main>
      <Outlet />
    </div>
  );
};
