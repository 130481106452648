import { UserContext } from "../../Context/UserContext";
import React, { useContext, useState, useRef, useEffect } from "react";
import { knowledgeBaseText } from "../../../services/componentText";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  SaveButton,
  ButtonContainer,
  DeleteButton,
  Input,
  AddedNewButton,
  NavContainer,
} from "./KnowledgeBaseItem.styled";
import { MyEditor } from "../../MyEditor/MyEditor";
import { toast } from "react-toastify";
import { Main } from "../../Main/Main";
import {
  updateKnowledgeBase,
  createKnowledgeBase,
  fetchKnowledgeBase,
  deleteKnowledgeBase,
} from "../../../api/knowledgeBase";

export const KnowledgeBaseItem = () => {
  const { language } = useContext(UserContext);
  const [initialValue, setInitialValue] = useState({
    id: "",
    title_ua: "",
    title_ru: "",
    description_ua: "",
    description_ru: "",
  });
  const [isPageRussian, setIsPageRussian] = useState(
    language === "russian" ? true : false
  );
  const location = useLocation();
  const navigate = useNavigate();

  const description_ru = useRef("");
  const description_ua = useRef("");
  const isCreateNewProduct = location?.pathname === "/knowledge-base/new";

  useEffect(() => {
    const fetchProductData = async () => {
      if (isCreateNewProduct) {
        return;
      }

      const productIdFromPath = location?.pathname?.replace(
        /^\/knowledge-base\/change\//,
        ""
      );

      const response = await fetchKnowledgeBase({
        id: productIdFromPath,
      });

      if (response?.data?.length > 0) {
        const fetchedProduct = response?.data;

        setInitialValue({
          ...fetchedProduct[0],
        });
        description_ru.current = fetchedProduct[0]?.description_ru || "";
        description_ua.current = fetchedProduct[0]?.description_ua || "";
      }
    };

    fetchProductData();
  }, [location, isCreateNewProduct]);

  const maxLength = 250;

  const handlePreSubmit = () => {
    const newProduct = {
      id: initialValue?.id || "",
      title_ua: initialValue?.title_ua || "",
      title_ru: initialValue?.title_ru || "",
      description_ua: description_ua.current || "",
      description_ru: description_ru.current || "",
    };

    handleSubmit(newProduct);
  };

  const handleSubmit = async (formData) => {
    if (isCreateNewProduct) {
      const response = await createKnowledgeBase(formData);

      if (response?.status === 201) {
        toast.success(knowledgeBaseText[language][0]?.successSave);
        return navigate("/knowledge-base/list");
      }
    }

    if (!isCreateNewProduct) {
      const response = await updateKnowledgeBase(formData);

      if (response?.status === 201) {
        toast.success(knowledgeBaseText[language][0]?.successUpdate);
        return navigate(`/knowledge-base/list/${initialValue?.id}`);
      }
    }
  };

  const handleCancelClick = () => {
    return navigate("/knowledge-base/list");
  };

  const handleDelete = async (id) => {
    const response = await deleteKnowledgeBase(id);

    if (response?.status === 200) {
      toast.success(knowledgeBaseText[language][0]?.successRemoved);
      return navigate("/knowledge-base/list");
    }
  };

  return (
    <div>
      <Main>
        <NavContainer>
          <AddedNewButton
            onClick={() => setIsPageRussian(false)}
            active={!isPageRussian}
            disabled={!isPageRussian}
          >
            {knowledgeBaseText[language][0]?.ukraineVersion}
          </AddedNewButton>
          <AddedNewButton
            onClick={() => setIsPageRussian(true)}
            active={isPageRussian}
            disabled={isPageRussian}
          >
            {knowledgeBaseText[language][0]?.russianVersion}
          </AddedNewButton>
        </NavContainer>

        {isPageRussian && (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                style={{ width: "70%", marginRight: "10px" }}
                value={initialValue?.title_ru}
                onChange={(e) => {
                  const updatedProduct = {
                    ...initialValue,
                    title_ru: e.target.value,
                  };
                  if (updatedProduct?.title_ru?.length <= maxLength) {
                    setInitialValue(updatedProduct);
                  }
                }}
                placeholder={
                  knowledgeBaseText[language][0]?.enterProductTitleRU
                }
                maxLength={maxLength}
                required
              />
              <p style={{ margin: "0" }}>
                {knowledgeBaseText[language][0]?.leftSymbols}{" "}
                {maxLength - initialValue?.title_ru?.length}/{maxLength}
              </p>
            </div>

            <div style={{ width: "100%", minHeight: "300px" }}>
              <MyEditor
                value={description_ru.current || ""}
                onChange={(newContent) => (description_ru.current = newContent)}
              />
            </div>
          </div>
        )}

        {!isPageRussian && (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                style={{ width: "70%", marginRight: "10px" }}
                value={initialValue?.title_ua}
                onChange={(e) => {
                  const updatedProduct = {
                    ...initialValue,
                    title_ua: e.target.value,
                  };
                  if (updatedProduct?.title_ua?.length <= maxLength) {
                    setInitialValue(updatedProduct);
                  }
                }}
                placeholder={
                  knowledgeBaseText[language][0]?.enterProductTitleUA
                }
                maxLength={maxLength}
                required
              />
              <p style={{ margin: "0" }}>
                {knowledgeBaseText[language][0]?.leftSymbols}{" "}
                {maxLength - initialValue?.title_ua?.length}/{maxLength}
              </p>
            </div>

            <div style={{ width: "100%", minHeight: "300px" }}>
              <MyEditor
                value={description_ua.current || ""}
                onChange={(newContent) => (description_ua.current = newContent)}
              />
            </div>
          </div>
        )}

        {isCreateNewProduct && (
          <ButtonContainer>
            <SaveButton onClick={() => handlePreSubmit()}>
              {knowledgeBaseText[language][0]?.save}
            </SaveButton>
            <DeleteButton onClick={() => handleCancelClick()}>
              {knowledgeBaseText[language][0]?.cancel}
            </DeleteButton>
          </ButtonContainer>
        )}
        {!isCreateNewProduct && (
          <ButtonContainer>
            <SaveButton onClick={() => handlePreSubmit()}>
              {knowledgeBaseText[language][0]?.update}
            </SaveButton>
            <DeleteButton
              onClick={() => {
                handleDelete(initialValue.id);
              }}
            >
              {knowledgeBaseText[language][0]?.delete}
            </DeleteButton>
          </ButtonContainer>
        )}
      </Main>
    </div>
  );
};
