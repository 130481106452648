import styled from '@emotion/styled';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

export const UsersTable = styled.table`
  margin: 1em 0;
  max-height: 100%;
  overflow: auto;
`;

export const UsersTr = styled.tr`
  border: 1px solid #d9e4e6;
  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const UsersTh = styled.th`
  display: none;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  display: table-cell;
  text-align: center;
`;

export const UsersTd = styled.td`
  display: block;
  word-wrap: break-word;
  padding: 5px;
  min-width: 50px;
  font-size: 16px;
  display: table-cell;
  text-align: center;
  border-right: #000;
  position: relative;
`;

export const BlockedButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 10px;
  text-decoration: none;
  background-color: #27ae60;
  color: #ffffff;
  font-size: 16px;
  width: 160px;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #48e084;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #48e084;
    padding-right: 0.625rem;
    background-color: green;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const BlockedButtonRed = styled(BlockedButton)`
  background-color: #e74c3c;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #ff6659;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #ff6659;
    padding-right: 0.625rem;
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const SearchContainer = styled.form`
  width: 100%;
  position: relative;
  display: flex;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 3px solid #3098d6;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;

  &:focus {
    color: #00b4cc;
  }
`;

export const SearchButton = styled.button`
  width: 40px;
  height: 36px;
  border: 1px solid #00b4cc;
  background: #3098d6;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
`;

export const IsBlockedSelect = styled.select`
  appearance: none;
  width: 150px;
  height: 25px;
  padding: 1px;
  padding-left: 5px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 18px;
  margin-left: 10px;

  cursor: pointer;
`;

export const IsBlockedOption = styled.option`
  appearance: none;
  width: 100%;
  height: 10px;
  padding-left: 5px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 18px;

  cursor: pointer;
`;

export const IsBlockedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const UserFaTimesCircle = styled(FaTimesCircle)`
cursor: pointer;
font-size: 24px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-weight: 700; 
`

export const UserFaMinusCircle = styled(FaCheckCircle)`
cursor: pointer;
font-size: 24px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-weight: 700; 
`