import { Main } from "../../Main/Main";
import {
  Table,
  Thead,
  TableContainer,
  Tr,
  HeadTr,
  Td,
  Th,
  Select,
  Option,
} from "./DisplayScriptCategory.styled";
import {
  fetchScriptCategory,
  updateScriptCategory,
} from "../../../api/script.js";
import { useQuery } from "react-query";
import React, { useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import { scriptText } from "../../../services/componentText";
import { toast } from "react-toastify";

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const DisplayScriptCategory = () => {
  const { currentUser, language } = useContext(UserContext);
  const { data, refetch } = useQuery(["fetchScriptCategory"], () =>
    fetchScriptCategory()
  );

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const handleSubmit = async ({ item, category_display }) => {
    const newData = {
      ...item,
      category_display: category_display,
    };

    const response = await updateScriptCategory(newData);

    if (response.status === 201) {
      toast.success(scriptText[language][0]?.successUpdate);
      return refetch();
    }
  };

  return (
    <div>
      {accessLevel && (
        <Main>
          <TableContainer>
            <Table>
              <Thead>
                <HeadTr>
                  <Th> {scriptText[language][0]?.number}</Th>
                  <Th style={{ width: "300px" }}>
                    {scriptText[language][0]?.categoryTitle}
                  </Th>
                  <Th style={{ width: "200px" }}>{scriptText[language][0]?.controlPanel}</Th>
                </HeadTr>
              </Thead>
              <tbody>
                {data ? (
                  <React.Fragment>
                    {data?.data?.map((item, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td style={{ width: "322px" }}>
                            {language === "russian"
                              ? item.title_ru
                              : item.title_ua}
                          </Td>
                          <Td style={{ width: "200px" }}>
                            <Select
                              value={item.category_display}
                              onChange={(e) =>
                                handleSubmit({
                                  item,
                                  category_display: e.target.value,
                                })
                              }
                            >
                              <Option key="1" value="1">
                                {"Відображати"}
                              </Option>
                              <Option key="2" value="2">
                                {"Не відображати"}
                              </Option>
                            </Select>
                          </Td>
                        </Tr>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <Tr>
                    <Td
                      colSpan={2}
                      style={{
                        width: "400px",
                        textAlign: "start",
                        paddingLeft: "50px",
                      }}
                    >
                      {scriptText[language][0]?.notDataOrError}
                    </Td>
                  </Tr>
                )}
              </tbody>
            </Table>
          </TableContainer>
        </Main>
      )}
    </div>
  );
};
