import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const ScheduleTable = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const ScheduleTr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ScheduleHeadTr = styled(ScheduleTr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const ScheduleTd = styled.td`
  width: 202px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color:  #ffffff;

  &:last-of-type {
    border-right: none;
  }
`;

export const ScheduleTh = styled.th`
  width: 180px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &:nth-of-type(2) {
      width: 400px;
  }

  :last-of-type {
    min-width: 520px;
  }
`;

export const FinesLink = styled.a`
  color: #000000;

  &:hover,
  &:focus {
    color: #3098d6;
  }
`;

export const SaveButton = styled(LoginFormButton)`
  min-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AddedNewButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;