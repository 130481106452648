export  const translateLabel = (label, locale) => {
    const labelsTranslation = {
      uk: {
        Today: 'Сьогодні',
        Yesterday: 'Вчора',
        'This Week': 'Поточний тиждень',
        'Last Week': 'Останній тиждень',
        'This Month': 'Поточний місяць',
        'Last Month': 'Останній місяць',
        'days up to today': 'днів до сьогодні',
        'days starting today': 'днів, починаючи від сьогодні',
      },
      ru: {
        Today: 'Сегодня',
        Yesterday: 'Вчера',
        'This Week': 'Текущая неделя',
        'Last Week': 'Прошлая неделя',
        'This Month': 'Текущий месяц',
        'Last Month': 'Прошлий месяц',
        'days up to today': 'дней до сегодняшнего дня',
        'days starting today': 'дней, начиная с сегодняшнего дня',
      },
    };

    return labelsTranslation[locale][label] || label;
  };