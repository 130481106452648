import styled from '@emotion/styled';

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const FinesListTable = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const FinesListTr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const FinesListHeadTr = styled(FinesListTr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const FinesListTd = styled.td`
  width: 252px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color: #ffffff;

  &:first-of-type {
    width: 550px;
  }
`;

export const FinesListTh = styled.th`
  width: 230px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &::nth-of-type(1),
  &::nth-of-type(2) {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  &:first-of-type {
    width: 530px;
  }
`;

