import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';
import { Form, Field } from 'formik';

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const Table = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const Tr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const HeadTr = styled(Tr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const Td = styled.td`
  width: 252px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color:  '#ffffff'};


  &:last-of-type {
    border-right: none;
  }
`;

export const Th = styled.th`
  width: 230px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &::nth-of-type(1),
  &::nth-of-type(2) {
    position: sticky;
    left: 0;
    z-index: 1;
  }
`;

export const SaveButton = styled(LoginFormButton)`
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const AddedNewAvailabilityButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const DeleteAvailabilityButton = styled(SaveButton)`
  background-color: #e74c3c;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const AddedAvailabilityContainer = styled.div`
display: flex;
align-items: flex-start;
justify-content: space-between;
padding: 10px;
flex-wrap: wrap;
width: 46%;
margin-bottom: 5px;
margin-right: 5px;
border: 2px solid #3098d6;
border-radius: 10px;
`;

export const AvailabilityText = styled.p`
  margin: 5px 0 0 0;
`;
export const AvailabilityNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchContainer = styled.form`
  width: 50%;
  position: relative;
  display: flex;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 3px solid #3098d6;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;

  &:focus {
    color: #00b4cc;
  }
`;

export const SearchButton = styled.button`
  width: 40px;
  height: 36px;
  border: 1px solid #00b4cc;
  background: #3098d6;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
`;

export const TextAvailability = styled.p`
  background-color: ${props => (props.checkStatus ? 'red' : 'green')};
  color: #ffffff;
  font-weight: 700;
  margin: 0;
`;

export const ModalContainer = styled.div`
  position: relative;
  min-width: 600px;
  height: 100%;
  margin: 40px;
`;

export const InfoForm = styled(Form)`
  align-items: center;
  flex-wrap: wrap;
`;

export const Input = styled(Field)`
  width: 40%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.17;
`;

export const Label = styled.label`
  width: 100%;
  text-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddedFMSelect = styled.select`
  appearance: none;
  width: 44%;
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.17;

  cursor: pointer;
`;

export const AddedFMOption = styled.option`
  appearance: none;
  width: 50%;
  height: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.17;

  cursor: pointer;
`;

export const ReportTextContainerForLabel = styled.div`
width: 50%;
`

export const VariationsContainer = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
margin-top: 10px;
justify-content: space-between;
`