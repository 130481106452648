import { Main } from '../../Main/Main';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  SaveButton,
  AddedNewFMButton,
  NavContainer,
} from './SeeChangesAllDepartments.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { fetchAllDepartmentReport } from '../../../api/report';
import { allDepartmentReportText } from '../../../services/componentText';
import { BarChart } from '../../BarChart/BarChart';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { Modal } from '../../Modal/Modal';

export const SeeChangesAllDepartments = () => {
      const { currentUser, language } = useContext(UserContext);
      const sortBy = 'date';
      const order = 'ASC';
      const [isModalDateOpen, setIsModalDateOpen] = useState(false);
      const currentDate = new Date();
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      const [period, setPeriod] = useState([
        {
          startDate: new Date(
            currentYear,
            currentMonth - 2,
            currentDate.getDate(),
          ),
          endDate: new Date(
            currentYear,
            currentMonth - 1,
            currentDate.getDate(),
          ),
          key: 'selection',
        },
      ]);
      const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
      const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
      const formattedStartDateRequest = format(
        period[0]?.startDate,
        'yyyy.MM.dd',
      );
      const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

      const { data: allDepartmentData, refetch } = useQuery(
        [
          'allDepartmentData',
          sortBy,
          order,
          formattedStartDateRequest,
          formattedEndDateRequest,
        ],
        () =>
          fetchAllDepartmentReport({
            sortBy,
            order,
            startDate: formattedStartDateRequest,
            endDate: formattedEndDateRequest,
          }),
      );

      const createNewDateForChart = value => {
        const label = 'Дані для діаграми';
        const data = {
          labels: allDepartmentData?.map(item =>
            formattedDateToDayMonthYear(item.date),
          ),
          datasets: [
            {
              label: 'Дані для діаграми',
              backgroundColor: 'rgba(75,192,192,0.2)',
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(75,192,192,0.4)',
              hoverBorderColor: 'rgba(75,192,192,1)',
              data: [],
              hoveredLabel: label,
            },
          ],
        };

        switch (value) {
          case 'operators_count_previous_day':
            data.datasets[0].data = allDepartmentData?.map(
              item => item.operators_count_previous_day,
            );
            break;
          case 'total_operators_worked_hours':
            data.datasets[0].data = allDepartmentData?.map(item =>
              item.total_operators_worked_hours,
            );
            break;
          case 'hours_on_KZ_UZ':
            data.datasets[0].data = allDepartmentData?.map(item =>
              item.hours_on_KZ_UZ,
            );
            break;
          case 'hours_on_incoming':
            data.datasets[0].data = allDepartmentData?.map(item =>
              item.hours_on_incoming,
            );
            break;
          case 'hours_on_failures':
            data.datasets[0].data = allDepartmentData?.map(item =>
              item.hours_on_failures,
            );
            break;
          case 'hours_on_downsale':
            data.datasets[0].data = allDepartmentData?.map(item =>
              item.hours_on_downsale,
            );
            break;
          case 'total_hours_in_Ukraine':
            data.datasets[0].data = allDepartmentData?.map(item =>
              item.total_hours_in_Ukraine,
            );
            break;
          case 'total_hours_in_Ukraine_with_extras':
            data.datasets[0].data = allDepartmentData?.map(item =>
                item.total_hours_in_Ukraine_with_extras,
            );
            break;
          case 'scheduled_working_hours':
            data.datasets[0].data = allDepartmentData?.map(item =>
              item.scheduled_working_hours
            );
            break;
          case 'real_not_ready_time':
            data.datasets[0].data = allDepartmentData?.map(item => item.real_not_ready_time
            );
            break;
          case 'hours_on_plan':
            data.datasets[0].data = allDepartmentData?.map(item => item.hours_on_plan
            );
            break;
          case 'plan_failure':
            data.datasets[0].data = allDepartmentData?.map(
              item => item.plan_failure,
            );
            break;
          case 'percentage_plan_failure':
            data.datasets[0].data = allDepartmentData?.map(
              item => item.percentage_plan_failure,
            );
            break;
          default:
            data.datasets[0].data = allDepartmentData?.map(
              item => item.operators_for_training,
            );
        }

        return data;
      };

      const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
        ...range,
        label: translateLabel(
          range.label,
          language === 'russian' ? 'ru' : 'uk',
        ),
      }));

      const customDefaultInputRanges = defaultInputRanges.map(range => ({
        ...range,
        label: translateLabel(
          range.label,
          language === 'russian' ? 'ru' : 'uk',
        ),
      }));

      const handleDate = () => {
        refetch();
        setIsModalDateOpen(false);
      };

      return (
        <Main>
          <NavContainer>
            <div
              style={{ marginRight: '10px' }}
            >{`${formattedStartDate} - ${formattedEndDate}`}</div>
            <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
              {allDepartmentReportText[language][0]?.choosePeriod}
            </AddedNewFMButton>
          </NavContainer>
          {isModalDateOpen && (
            <Modal closeModal={() => setIsModalDateOpen(false)}>
              <DateRangePicker
                onChange={item => setPeriod([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                locale={currentUser?.language === 'russian' ? ru : uk}
                staticRanges={customDefaultStaticRanges}
                inputRanges={customDefaultInputRanges}
                months={2}
                ranges={period}
                direction="horizontal"
                style={{ margin: '20px' }}
              />
              <SaveButton onClick={() => handleDate()}>
                {allDepartmentReportText[language][0]?.update}
              </SaveButton>
            </Modal>
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <BarChart
              title={
                allDepartmentReportText[language][0]?.numberOfEmployeesOnShift
              }
              data={createNewDateForChart('operators_count_previous_day')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]
                  ?.totalNumberOfHoursWorkedPerShift
              }
              data={createNewDateForChart('total_operators_worked_hours')}
            />
            <BarChart
              title={allDepartmentReportText[language][0]?.numberOfHoursOnKZ}
              data={createNewDateForChart('hours_on_KZ_UZ')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]?.numberOfHoursOnIncoming
              }
              data={createNewDateForChart('hours_on_incoming')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]?.numberOfHoursOnRefusals
              }
              data={createNewDateForChart('hours_on_failures')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]?.numberOfHoursOnDownsale
              }
              data={createNewDateForChart('hours_on_downsale')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]
                  ?.numberTotalOfHoursOnUkraine
              }
              data={createNewDateForChart('total_hours_in_Ukraine')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]
                  ?.numberShouldBeHoursOnUkraine
              }
              data={createNewDateForChart('total_hours_in_Ukraine_with_extras')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]
                  ?.numberOfWorkingHoursBeforeChangesForBarChart
              }
              data={createNewDateForChart('scheduled_working_hours')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]?.numberOfOvertimeHours
              }
              data={createNewDateForChart('hours_on_plan')}
            />
            <BarChart
              title={allDepartmentReportText[language][0]?.notFollowingPlan}
              data={createNewDateForChart('plan_failure')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]?.percentNotFollowingPlan
              }
              data={createNewDateForChart('percentage_plan_failure')}
            />
            <BarChart
              title={
                allDepartmentReportText[language][0]
                  ?.numberOfEmployeesOnEducation
              }
              data={createNewDateForChart('operators_for_training')}
            />
          </div>
        </Main>
      );
};
