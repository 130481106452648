import styled from '@emotion/styled';

export const BarChartMainContainer = styled.div`
  @media screen and (max-width: 1200px) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1200px) {
    &:nth-of-type(odd) {
      margin-right: 30px;
    }
  }
`;

export const BarChartContainer = styled.div`
  @media screen and (max-width: 1200px) {
    width: ${props => (props.isNavVisible ? '500px' : '700px')};
    height: ${props => (props.isNavVisible ? '250px' : '350px')};
  }

  @media screen and (min-width: 1200px) {
    width: ${props => (props.isNavVisible ? '700px' : '850px')};
    height: ${props => (props.isNavVisible ? '350px' : '425px')};
  }
`;

export const BarChartTitle = styled.h2`
  margin: 20px 0;
  text-align: center;
`;
