import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';
import { Link } from 'react-router-dom';

export const AddedSelect = styled.select`
  appearance: none;
  width: 300px;
  height: 30px;
  padding: 5px;
  background-color: #f5f7fa;
  border: 2px solid #3098d6;
  font-size: 16px;
  margin-left: 10px;
  border-radius: 10px;

  cursor: pointer;
`;

export const AddedOption = styled.option`
  appearance: none;
  width: 100%;
  height: 10px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 16px;

  cursor: pointer;
`;

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const Table = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const Tr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const HeadTr = styled(Tr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const Td = styled.td`
  width: 107px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color: ${props => props.backgroundColor || '#ffffff'};

  &:last-of-type {
    border-right: none;
  }
`;

export const Th = styled.th`
  width: 85px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const SaveButton = styled(LoginFormButton)`
  min-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AddedNewButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const IconButton = styled(Link)`
transition: background-color 0.2s;
display: flex;
align-items: center;
justify-content: center;
padding: 0.5rem 1rem;
text-decoration: none;
background-color: #27ae60;
color: #ffffff;
font-size: 16px;
width: 50%;

&:hover,
&:focus,
&.active {
 border-left: 0.375rem solid #48e084;
 padding-left: 0.625rem;
 border-right: 0.375rem solid #48e084;
 padding-right: 0.625rem;
 background-color: green;
 cursor: pointer;
 color: #ffffff;
}
`;

export const TrashButton = styled(IconButton)`
  background-color: #e74c3c;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #ff6659;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #ff6659;
    padding-right: 0.625rem;
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const IconLinkContainer = styled.div`
display: flex;
width: 100%;
`