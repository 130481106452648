import { Main } from '../../Main/Main';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  SaveButton,
  AddedNewFMButton,
  NavContainer,
  AddedSelect,
  AddedOption,
} from './SeeChangesWiretappingEverydayStatistics.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { fetchWiretappingEverydayStatistics } from '../../../api/wiretapping';
import { wiretappingCheckList } from '../../../services/componentText';
import { BarChart } from '../../BarChart/BarChart';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { Modal } from '../../Modal/Modal';
import { fetchUsers } from '../../../api/users';

const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);

export const SeeChangesWiretappingEverydayStatistics = () => {
  const { currentUser, language } = useContext(UserContext);
  const sortBy = 'date';
  const order = 'ASC';
  const [selectedEmployee, setSelectedEmployee] = useState(
    currentUser?.id_job_title === 4 ? currentUser?.full_name : '',
  );
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const { data: wiretappingEverydayStatisticsData, refetch } = useQuery(
    ['fetchWiretappingEverydayStatistics', selectedEmployee, sortBy, order],
    () =>
      fetchWiretappingEverydayStatistics({
        employee_create_full_name: selectedEmployee,
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const { data: users } = useQuery('allEmployees', fetchUsers);

  const createNewDateForChart = value => {
    const label = 'Дані для діаграми';
    const data = {
      labels: wiretappingEverydayStatisticsData?.map(item =>
        formattedDateToDayMonthYear(item.date),
      ),
      datasets: [
        {
          label: 'Дані для діаграми',
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: 'rgba(75,192,192,1)',
          data: [],
          hoveredLabel: label,
        },
      ],
    };

    switch (value) {
      case 'total_fine_amount':
        data.datasets[0].data = wiretappingEverydayStatisticsData?.map(
          item => item.total_fine_amount,
        );
        break;
      case 'total_order':
        data.datasets[0].data = wiretappingEverydayStatisticsData?.map(
          item => item.total_order,
        );
        break;
      case 'fines_status_id_count_four':
        data.datasets[0].data = wiretappingEverydayStatisticsData?.map(
          item => item.fines_status_id_count_four,
        );
        break;
      case 'fines_status_id_count_one':
        data.datasets[0].data = wiretappingEverydayStatisticsData?.map(
          item => item.fines_status_id_count_one,
        );
        break;
        case 'error_percent':
          data.datasets[0].data = wiretappingEverydayStatisticsData?.map(
            item => item.error_percent,
          );
          break;

      default:
        data.datasets[0].data = wiretappingEverydayStatisticsData?.map(
          item => item.fines_status_id_count_two,
        );
    }

    return data;
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };
  return (
    <Main>
      {accessLevel || currentUser?.access_level === access_level_d ? (
        <div>
          <NavContainer>
            <div
              style={{ marginRight: '10px' }}
            >{`${formattedStartDate} - ${formattedEndDate}`}</div>
            <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
              {wiretappingCheckList[language][0]?.choosePeriod}
            </AddedNewFMButton>
            {accessLevel && (
              <div style={{ marginLeft: '10px' }}>
                <label>
                  {wiretappingCheckList[language][0]?.chooseManagerOne}
                  <AddedSelect
                    value={selectedEmployee || ''}
                    onChange={e => setSelectedEmployee(e.target.value)}
                    style={{ backgroundColor: '#f5f7fa' }}
                  >
                    <AddedOption value="">
                      {wiretappingCheckList[language][0]?.chooseManagerTwo}
                    </AddedOption>
                    {users?.usersData?.map(
                      user =>
                        Number(user?.id_job_title) === 4 && (
                          <AddedOption
                            value={user?.full_name}
                            key={user?.full_name}
                          >
                            {user?.full_name}
                          </AddedOption>
                        ),
                    )}
                  </AddedSelect>
                </label>
              </div>
            )}
          </NavContainer>
          {isModalDateOpen && (
            <Modal closeModal={() => setIsModalDateOpen(false)}>
              <DateRangePicker
                onChange={item => setPeriod([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                locale={currentUser?.language === 'russian' ? ru : uk}
                staticRanges={customDefaultStaticRanges}
                inputRanges={customDefaultInputRanges}
                months={2}
                ranges={period}
                direction="horizontal"
                style={{ margin: '20px' }}
              />
              <SaveButton onClick={() => handleDate()}>
                {wiretappingCheckList[language][0]?.update}
              </SaveButton>
            </Modal>
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <BarChart
              title={wiretappingCheckList[language][0]?.finesAmount}
              data={createNewDateForChart('total_fine_amount')}
            />
            <BarChart
              title={wiretappingCheckList[language][0]?.totalWithoutSymbols}
              data={createNewDateForChart('total_order')}
            />
            <BarChart
              title={wiretappingCheckList[language][0]?.notFinesWithoutSymbols}
              data={createNewDateForChart('fines_status_id_count_four')}
            />
            <BarChart
              title={wiretappingCheckList[language][0]?.finesWithoutSymbols}
              data={createNewDateForChart('fines_status_id_count_two')}
            />
            <BarChart
              title={wiretappingCheckList[language][0]?.warningWithoutSymbols}
              data={createNewDateForChart('fines_status_id_count_one')}
            />
                        <BarChart
              title={wiretappingCheckList[language][0]?.errorPercentWithoutSymbols}
              data={createNewDateForChart('error_percent')}
            />
          </div>
        </div>
      ) : (
        <div>У вас немає доступу для перегляду сторінки</div>
      )}
    </Main>
  );
};
