import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

export const Item = styled.li`
  margin: 0;
  width: 100%;
  border: 2px solid #3098d6;
  border-radius: 10px;
  margin-bottom: 5px;
  display: flex;
`;

export const LinkContainer = styled.div`
  width: ${props => props.accessLevel ? '90%' : '100%'};
  transition: background-color 0.2s;

  &:hover,
  &:focus,
  &.active {
    background-color: #3098d6;
    border-radius: 0;
  }
`;

export const KnowledgeLink = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  padding-left: 10px;
  margin: -1px;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  transition: color 0.2s;

  &:hover,
  &:focus,
  &.active {
    cursor: pointer;
    color: #ffffff;
  }
`;

export const IconButton = styled(Link)`
  transition: background-color 0.2s;
   display: flex;
   align-items: center;
   justify-content: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #27ae60;
  color: #ffffff;
  font-size: 16px;
  width: 50%;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #48e084;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #48e084;
    padding-right: 0.625rem;
    background-color: green;
    cursor: pointer;
    color: #ffffff;
  }

  &:last-of-type {
    border-radius: 0 9px 9px 0;
  }

`;

export const TrashButton = styled(IconButton)`
  background-color: #e74c3c;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #ff6659;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #ff6659;
    padding-right: 0.625rem;
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const IconLinkContainer = styled.div`
display: flex;
width: 20%;
`

export const SearchInput = styled.input`
  width: 90%;
  border: 3px solid #3098d6;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;

  &:focus {
    color: #00b4cc;
  }
`;

export const SearchButton = styled.button`
  width: 60px;
  height: 36px;
  border: 1px solid #00b4cc;
  background: #3098d6;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, border 0.2s, color 0.2s;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }
`;

export const SearchContainer = styled.form`
  width: 70%;
  position: relative;
  display: flex;
`;
