import React, { useState } from 'react';
import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Outlet } from 'react-router-dom';
import { LinkOnPageNav } from '../../components/OnPageNav/LinkOnPageNav/LinkOnPageNav';
import { OnPageNavContainer } from '../../components/OnPageNav/OnPageNavContainer/OnPageNavContainer';
import { useContext } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { scheduleText } from '../../services/componentText';

export const Schedule = () => {
  const [activeTab, setActiveTab] = useState('genereal');
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);

  const { currentUser, language } = useContext(UserContext);
  return (
    <div>
      <SharedLayout />
      {(currentUser?.access_level === access_level_e ||
        currentUser?.access_level === access_level_f ||
        currentUser?.access_level === access_level_g) && (
        <OnPageNavContainer>
          <LinkOnPageNav
            to="genereal"
            onClick={() => setActiveTab('genereal')}
            className={activeTab === 'genereal' ? 'active' : ''}
          >
            {scheduleText[language][0]?.employeesSchedule}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="hourly"
            onClick={() => setActiveTab('hourly')}
            className={activeTab === 'hourly' ? 'active' : ''}
          >
            {scheduleText[language][0]?.scheduleOnHour}
          </LinkOnPageNav>

          <LinkOnPageNav
            to="number-of-working-hours"
            onClick={() => setActiveTab('number-of-working-hours')}
            className={activeTab === 'number-of-working-hours' ? 'active' : ''}
          >
            {scheduleText[language][0]?.workingHour}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="duty"
            onClick={() => setActiveTab('duty')}
            className={activeTab === 'duty' ? 'active' : ''}
          >
            {scheduleText[language][0]?.dutySchedule}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="hours-statistic"
            onClick={() => setActiveTab('hours-statistic')}
            className={activeTab === 'hours-statistic' ? 'active' : ''}
          >
            {scheduleText[language][0]?.hoursStatistic}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="me"
            onClick={() => setActiveTab('me')}
            className={activeTab === 'me' ? 'active' : ''}
          >
            {scheduleText[language][0]?.mySchedule}
          </LinkOnPageNav>
        </OnPageNavContainer>
      )}
            {(currentUser?.access_level === access_level_c) && (
        <OnPageNavContainer>
          <LinkOnPageNav
            to="me"
            onClick={() => setActiveTab('me')}
            className={activeTab === 'me' ? 'active' : ''}
          >
            {scheduleText[language][0]?.mySchedule}
          </LinkOnPageNav>
          
          <LinkOnPageNav
            to="duty"
            onClick={() => setActiveTab('duty')}
            className={activeTab === 'duty' ? 'active' : ''}
          >
            {scheduleText[language][0]?.dutySchedule}
          </LinkOnPageNav>

        </OnPageNavContainer>
      )}
      <Outlet />
    </div>
  );
};
