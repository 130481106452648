import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userText, fmText } from '../../../services/componentText';
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  AddedNewFMButton,
  AddedFMContainer,
  SaveButton,
  FMTextArea,
  ModalContainerFM,
  AddedFMInput,
  AddedFMSelect,
  AddedFMOption,
  ButtonContainer,
  DeleteFMButton,
  Thead,
  NavContainer,
  IsBlockedSelect,
  IsBlockedOption,
  LinkButton
} from './ManagerFM.styled';
import { UserContext } from '../../Context/UserContext';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { Modal } from '../../Modal/Modal';
import { formatDateToInput } from '../../../helpers/dateAndTime/formatDate';
import {
  fetchAllFM,
  createMutationFM,
  deleteMutationFM,
  updateMutationFM,
} from '../../../api/fm';
import { fetchUsers } from '../../../api/users';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

export const ManagerFM = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [fmDescribe, setFMDescribe] = useState('');
  const [isUpdateFM, setIsUpdateFM] = useState(false);
  const [fmId, setFmId] = useState();
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState('fm_date');
  const [order, setOrder] = useState('DESC');
  const [isBlocked, setIsBlocked] = useState(0);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');
  const maxLength = 255;

  const { data: fm, refetch } = useQuery(
    ['fmData', sortBy, order, isBlocked],
    () =>
      fetchAllFM({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        isBlocked,
      }),
  );

  const { data: users } = useQuery(["getAllEmployees"], () =>
  fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0"})
);

  const handleInputChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setFMDescribe(inputValue);
    }
  };

  const handleSubmitFM = async () => {
    const newFM = {
      employee_id: selectedEmployeeId,
      fm_date: selectedDate,
      fm_describe: fmDescribe,
    };

    const response = await createMutationFM(newFM);

    if (response?.status === 204) {
      resetValues();
      toast.success(`${fmText[language][0]?.successAdded}`);
      refetch();
    }
  };

  const handleAddNewFM = () => {
    resetValues();
    setIsModalOpen(true);
  };

  const handleUpdateClick = async (employeeId, fmId, fmDate, fmDescribe) => {
    setIsUpdateFM(true);
    setIsModalOpen(true);
    setSelectedEmployeeId(employeeId);
    setSelectedDate(formatDateToInput(fmDate));
    setFMDescribe(fmDescribe);
    setFmId(fmId);
  };

  const handleUpdateFM = async () => {
    const updateFM = {
      fm_date: selectedDate,
      fm_describe: fmDescribe,
    };

    const response = await updateMutationFM({ fmId, fmData: updateFM });
    if (response?.status === 200) {
      resetValues();
      toast.success(`${fmText[language][0]?.successUpdated}`);
      refetch();
    }
  };

  const handleDeleteFM = async () => {
    const response = await deleteMutationFM(fmId);
    if (response?.status === 200) {
      resetValues();
      toast.success(`${fmText[language][0]?.successDeleted}`);
      refetch();
    }
  };

  const resetValues = () => {
    setIsModalOpen(false);
    setSelectedEmployeeId();
    setSelectedDate('');
    setFMDescribe('');
    setFmId();
    setIsUpdateFM(false);
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <div>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {fmText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainerFM>
            <AddedFMContainer>
              <label>{fmText[language][0]?.chooseEmployeeOne}</label>
              <AddedFMSelect
                name="selectedUser"
                value={selectedEmployeeId}
                onChange={e => setSelectedEmployeeId(e.target.value)}
              >
                <AddedFMOption value="">
                  {fmText[language][0]?.chooseEmployeeTwo}
                </AddedFMOption>
                {users?.usersData?.map(user => 
                (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) &&  (
                  <AddedFMOption
                    key={user.id_employee}
                    value={user.id_employee}
                  >
                    {user.full_name}
                  </AddedFMOption>
                ))}
              </AddedFMSelect>
            </AddedFMContainer>

            <AddedFMContainer>
              <label>{fmText[language][0]?.chooseDate}</label>
              <AddedFMInput
                type="date"
                value={selectedDate}
                onChange={e => setSelectedDate(e.target.value)}
              />
            </AddedFMContainer>
            <AddedFMContainer>
              <label>{fmText[language][0]?.reasonDescribeFm}</label>
              <div>
                <FMTextArea
                  value={fmDescribe}
                  onChange={handleInputChange}
                  maxLength={maxLength}
                  required
                />
                <p>
                  {fmText[language][0]?.leftSymbols}
                  {maxLength - fmDescribe.length}/{maxLength}
                </p>
              </div>
            </AddedFMContainer>
            {!isUpdateFM && (
              <SaveButton onClick={handleSubmitFM}>
                {fmText[language][0]?.save}
              </SaveButton>
            )}
            {isUpdateFM && (
              <ButtonContainer>
                <SaveButton onClick={handleUpdateFM}>
                  {fmText[language][0]?.update}
                </SaveButton>
                <DeleteFMButton onClick={handleDeleteFM}>
                  {fmText[language][0]?.delete}
                </DeleteFMButton>
              </ButtonContainer>
            )}
          </ModalContainerFM>
        </Modal>
      )}
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
          {fmText[language][0]?.choosePeriod}
        </AddedNewFMButton>
        <AddedNewFMButton onClick={() => handleAddNewFM()}>
          {fmText[language][0]?.addNewFm}
        </AddedNewFMButton>
        <LinkButton to={`/fm/statistics`}>
            {fmText[language][0]?.statistics}
          </LinkButton>
        <IsBlockedSelect
          value={isBlocked}
          onChange={e => setIsBlocked(e.target.value)}
        >
          <IsBlockedOption key={0} value="0">
            {userText[language][0]?.notBlocked}
          </IsBlockedOption>
          <IsBlockedOption key={1} value="1">
            {userText[language][0]?.blocked}
          </IsBlockedOption>
        </IsBlockedSelect>
      </NavContainer>
      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh onClick={() => handleSortChange('fm_date')}>
                {fmText[language][0]?.date}
                {renderSortIcon('fm_date')}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange('full_name')}>
                {fmText[language][0]?.fullName}
                {renderSortIcon('full_name')}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange('fm_describe')}>
                {fmText[language][0]?.reasonFm}
                {renderSortIcon('fm_describe')}
              </ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {fm?.map(item => {
              return (
                <ScheduleTr key={item.fm_id} onClick={() =>
                  handleUpdateClick(
                    item.employee_id,
                    item.fm_id,
                    item.fm_date,
                    item.fm_describe,
                  )
                }>
                  <ScheduleTd>
                    {formattedDateToDayMonthYear(item.fm_date)}
                  </ScheduleTd>
                  <ScheduleTd>{item.full_name}</ScheduleTd>
                  <ScheduleTd>{item.fm_describe}</ScheduleTd>
                </ScheduleTr>
              );
            })}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </div>
  );
};
