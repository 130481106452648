export const getWeekRanges = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate();
  const weeks = [];

  let weekStart = 1;
  for (let i = 1; i <= daysInMonth; i++) {
    const dayOfWeek = new Date(year, month - 1, i).getDay();
    if (dayOfWeek === 0 || i === daysInMonth) {
      weeks.push({ start: weekStart, end: i });
      weekStart = i + 1;
    }
  }

  return weeks;
};
