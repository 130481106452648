import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchAllEverydayStatistics = async ({
  selectedDate,
  sortBy,
  order,
  email,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get(`/everyday-statistics`, {
      params: {
        sortBy,
        order,
        email,
        date: selectedDate,
        startDate,
        endDate,
      },
    });

    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchRating = async ({
  sortBy,
  order,
  email,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get(`/everyday-statistics/rating`, {
      params: {
        sortBy,
        order,
        email,
        startDate,
        endDate,
      },
    });

    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchRatingByPeriod = async ({
  sortBy,
  order,
  email,
  date,
}) => {
  try {
    const response = await makeRequestSQL.get(`/everyday-statistics/rating-by-period`, {
      params: {
        sortBy,
        order,
        email,
        date,
      },
    });

    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};


export const onDeleteByDate = async (selectedDate) => {
    try {
      const response = await makeRequestSQL.delete(
        `/everyday-statistics?date=${selectedDate}`,
      );

      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
};
  
export const onUpload = async formData => {
  try {
    const response = await makeRequestSQL.post(
      '/everyday-statistics',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};