import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  flex-wrap: wrap;
  width: 48%;
  margin-bottom: 5px;
  border: 2px solid #3098d6;
  border-radius: 10px;
`;


export const ClaimInput = styled.input`
  width: 40%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.17;
`;

export const ClaimLabel = styled.label`
  width: 100%;
  text-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
`;

export const SaveButton = styled(LoginFormButton)`
  min-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

export const DeleteButton = styled(SaveButton)`
  margin-left: 10px;
  background-color: #e74c3c;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #c0392b;
    color: #ffffff;
  }
`;