import * as Yup from 'yup';

export const LoginSchema = language => {
  const isRussian = language === 'russian';

  return Yup.object().shape({
    email: Yup.string()
      .required(
        isRussian
          ? 'Электронная почта обязательное поле'
          : 'Електронна пошта обовязкове поле',
      )
      .min(
        10,
        isRussian
          ? 'Электронная почта должна быть не менее 10 символов'
          : 'Електронна пошта повинна бути не менше 10 символів',
      )
      .max(
        63,
        isRussian
          ? 'Электронная почта должна быть не более 63 символов'
          : 'Електронна пошта повинна бути не більше 63 символів',
      )
      .email(
        isRussian
          ? 'Пожалуйста введите коректную Электронную почту'
          : 'Будь ласка введіть коректну Електронну пошту',
      )
      .matches(
        /^[a-zA-Z0-9_]+([.-]?[a-zA-Z0-9_]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
        isRussian
          ? 'Пожалуйста введите коректную Электронную почту'
          : 'Будь ласка введіть коректну Електронну пошту',
      ),
    password: Yup.string()
      .required(
        isRussian ? 'Пароль обязательное поле' : 'Пароль обовязкове поле',
      )
      .min(
        7,
        isRussian
          ? 'Пароль должен быть не менее 7 символов'
          : 'Пароль повинен бути не менше 7 символів',
      )
      .max(
        32,
        isRussian
          ? 'Пароль должен быть не более 32 символов'
          : 'Пароль повинен бути не більше 32 символів',
      )
      .matches(
        /^\S*$/,
        isRussian
          ? 'Пароль не должен содержать пробелов'
          : 'Пароль не повинен містити пробілів',
      )
      .matches(
        /[a-z]/,
        isRussian
          ? 'Пароль должен содержать хотя бы одну строчную английскую букву'
          : 'Пароль повинен містити хоча б одну малу англійську літеру',
      )
      .matches(
        /[A-Z]/,
        isRussian
          ? 'Пароль должен содержать хотя бы одну заглавную английскую букву'
          : 'Пароль повинен містити хоча б одну велику англійську літеру',
      )
      .matches(
        /^[a-zA-Z0-9!@#$%^&*()_+,-./:;<=>?@[\]^_`{|}~]+$/,
        isRussian
          ? 'Пароль может содержать только английские буквы и специальные символы.'
          : 'Пароль може містити лише англійські літери та спеціальні символи.',
      )
  });
};
