import { Main } from '../../Main/Main';
import { Modal } from '../../Modal/Modal';
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../Context/UserContext';
import {
  createEmployeeEfficiencyReport,
  fetchEmployeeEfficiencyReport,
  updateEmployeeEfficiencyReport,
  deleteEmployeeEfficiencyReport,
} from '../../../api/report';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  ModalContainer,
  InfoForm,
  Input,
  AddedReportContainer,
  DeleteReportButton,
  AddedNewReportButton,
  ButtonContainer,
  SaveButton,
  ReportTh,
  ReportTd,
  ReportTable,
  Thead,
  ReportLabel,
  ReportCommentContainer,
  AddedFMSelect,
  AddedFMOption,
  ReportTextContainerForLabel,
  NavContainer,
  TableValueContainer,
} from './EmployeesEfficiency.styled';
import { employeesEfficiencyText } from '../../../services/componentText';
import { Formik } from 'formik';
import { fetchUsers } from '../../../api/users';
import { formatDateToInput } from '../../../helpers/dateAndTime/formatDate';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { v4 as uuidv4 } from 'uuid';

export const EmployeesEfficiency = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [formData, setFormData] = useState({
    id: '',
    date: '',
    top_best_by_approve_percent: [],
    top_worst_by_approve_percent: [],
    top_best_by_upsells_percent: [],
    top_worst_by_upsells_percent: [],
    top_best_by_net_income: [],
    top_worst_by_net_income: [],
    top_best_by_approve_per_hour: [],
    top_worst_by_approve_per_hour: [],
    top_best_offer_by_upsells: [],
    top_worst_offer_by_upsells: [],
    top_best_offer_by_net_income: [],
    top_worst_offer_by_net_income: [],
  });
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const fieldsToParse = [
    'top_best_by_approve_percent',
    'top_worst_by_approve_percent',
    'top_best_by_upsells_percent',
    'top_worst_by_upsells_percent',
    'top_best_by_net_income',
    'top_worst_by_net_income',
    'top_best_by_approve_per_hour',
    'top_worst_by_approve_per_hour',
    'top_best_offer_by_upsells',
    'top_worst_offer_by_upsells',
    'top_best_offer_by_net_income',
    'top_worst_offer_by_net_income',
  ];

  const { data, refetch } = useQuery(
    [
      'employeeEfficiencyReport',
      formattedStartDateRequest,
      formattedEndDateRequest,
    ],
    () =>
      fetchEmployeeEfficiencyReport({
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const { data: users } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0" })
  );

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddItem = (fieldName, additionalFields) => {
    setFormData(prevData => {
      const newData = { ...prevData };
      newData[fieldName] = [
        ...newData[fieldName],
        { full_name: '', ...additionalFields },
      ];
      return newData;
    });
  };

  const handleArrayInputChange = (arrayName, index, field, value) => {
    const updatedArray = [...formData[arrayName]];
    updatedArray[index][field] = value;

    setFormData({
      ...formData,
      [arrayName]: updatedArray,
    });
  };

  const handleSubmit = async e => {
    if (isUpdate) {
      const response = await updateEmployeeEfficiencyReport(formData);
      if (response?.status === 204) {
        setIsModalOpen(false);
        setIsUpdate(false);
        resetValue();
        refetch();
        return toast.success(
          employeesEfficiencyText[language][0]?.successUpdate,
        );
      }
    }
    const response = await createEmployeeEfficiencyReport(formData);

    if (response?.status === 204) {
      setIsModalOpen(false);
      resetValue();
      refetch();
      return toast.success(employeesEfficiencyText[language][0]?.successSave);
    }
  };

  const resetValue = () => {
    setFormData({
      id: '',
      date: '',
      top_best_by_approve_percent: [],
      top_worst_by_approve_percent: [],
      top_best_by_upsells_percent: [],
      top_worst_by_upsells_percent: [],
      top_best_by_net_income: [],
      top_worst_by_net_income: [],
      top_best_by_approve_per_hour: [],
      top_worst_by_approve_per_hour: [],
      top_best_offer_by_upsells: [],
      top_worst_offer_by_upsells: [],
      top_best_offer_by_net_income: [],
      top_worst_offer_by_net_income: [],
    });
  };

  const handleNewReport = () => {
    setIsModalOpen(true);
    resetValue();
    setIsUpdate(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetValue();
  };

  const handleDelete = async () => {
    const response = await deleteEmployeeEfficiencyReport(selectedId);
    if (response.status === 200) {
      setIsUpdate(false);
      resetValue();
      setIsModalOpen(false);
      refetch();
      return toast.success(
        employeesEfficiencyText[language][0]?.successRemoved,
      );
    }
  };

  const parseField = fieldValue => {
    try {
      return JSON.parse(fieldValue);
    } catch (error) {
      return [];
    }
  };

  const handleClick = item => {
    const parseAndSetField = fieldName => {
      const fieldValue = item[fieldName];
      const parsedValue = parseField(fieldValue);
      setFormData(prevData => ({
        ...prevData,
        [fieldName]: [...parsedValue],
      }));
    };

    setIsUpdate(true);
    setIsModalOpen(true);
    setSelectedId(item?.id);
    setFormData({
      ...item,
      date: formatDateToInput(item.date),
    });

    fieldsToParse.forEach(field => parseAndSetField(field));
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  useEffect(() => {
    const addNewItemsIfNeeded = fieldName => {
      const requiredItemCount = 5;

      if (
        formData[fieldName] &&
        formData[fieldName].length < requiredItemCount
      ) {
        const newItemsCount = requiredItemCount - formData[fieldName].length;

        for (let i = 0; i < newItemsCount; i++) {
          let additionalFields = {};

          if (
            fieldName === 'top_best_by_approve_percent' ||
            fieldName === 'top_worst_by_approve_percent'
          ) {
            additionalFields = {
              approve_percent: '',
              approve_count: '',
            };
          } else if (
            fieldName === 'top_best_by_upsells_percent' ||
            fieldName === 'top_worst_by_upsells_percent'
          ) {
            additionalFields = {
              upsells_percent: '',
            };
          } else if (
            fieldName === 'top_best_by_net_income' ||
            fieldName === 'top_worst_by_net_income'
          ) {
            additionalFields = {
              net_income_value: '',
            };
          } else if (
            fieldName === 'top_best_by_approve_per_hour' ||
            fieldName === 'top_worst_by_approve_per_hour'
          ) {
            additionalFields = {
              aprove_per_hour_value: '',
            };
          } else if (
            fieldName === 'top_best_offer_by_upsells' ||
            fieldName === 'top_worst_offer_by_upsells'
          ) {
            additionalFields = {
              product_title: '',
              upsells_percent: '',
            };
          } else if (
            fieldName === 'top_best_offer_by_net_income' ||
            fieldName === 'top_worst_offer_by_net_income'
          ) {
            additionalFields = {
              product_title: '',
              net_income_value: '',
            };
          }

          handleAddItem(fieldName, additionalFields);
        }
      }
    };

    fieldsToParse.forEach(field => {
      addNewItemsIfNeeded(field);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const fieldStructures = {
    top_best_by_approve_percent: {
      columns: [
        {
          key: 'full_name',
        },
        { key: 'approve_percent' },
        { key: 'approve_count' },
      ],
    },
    top_worst_by_approve_percent: {
      columns: [
        {
          key: 'full_name',
        },
        { key: 'approve_percent' },
        { key: 'approve_count' },
      ],
    },
    top_best_by_upsells_percent: {
      columns: [
        {
          key: 'full_name',
        },
        { key: 'upsells_percent' },
      ],
    },
    top_worst_by_upsells_percent: {
      columns: [
        {
          key: 'full_name',
        },
        { key: 'upsells_percent' },
      ],
    },
    top_best_by_net_income: {
      columns: [
        {
          key: 'full_name',
        },
        { key: 'net_income_value' },
      ],
    },
    top_worst_by_net_income: {
      columns: [
        {
          key: 'full_name',
        },
        { key: 'net_income_value' },
      ],
    },
    top_best_by_approve_per_hour: {
      columns: [
        {
          key: 'full_name',
        },
        { key: 'aprove_per_hour_value' },
      ],
    },
    top_worst_by_approve_per_hour: {
      columns: [
        {
          key: 'full_name',
        },
        { key: 'aprove_per_hour_value' },
      ],
    },
    top_best_offer_by_upsells: {
      columns: [
        {
          key: 'product_title',
        },
        { key: 'upsells_percent' },
      ],
    },
    top_worst_offer_by_upsells: {
      columns: [
        {
          key: 'product_title',
        },
        { key: 'upsells_percent' },
      ],
    },
    top_best_offer_by_net_income: {
      columns: [
        {
          key: 'product_title',
        },
        { key: 'net_income_value' },
      ],
    },
    top_worst_offer_by_net_income: {
      columns: [
        {
          key: 'product_title',
        },
        { key: 'net_income_value' },
      ],
    },
  };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewReportButton onClick={() => setIsModalDateOpen(true)}>
          {employeesEfficiencyText[language][0]?.choosePeriod}
        </AddedNewReportButton>
        <AddedNewReportButton onClick={() => handleNewReport()}>
          {employeesEfficiencyText[language][0]?.addNewReport}
        </AddedNewReportButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {employeesEfficiencyText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainer>
            <Formik onSubmit={handleSubmit} initialValues={formData}>
              <InfoForm autoComplete="off">
                <ReportCommentContainer style={{ marginBottom: '5px' }}>
                  <ReportLabel>
                    <ReportTextContainerForLabel>
                      {employeesEfficiencyText[language][0]?.chooseDate}
                    </ReportTextContainerForLabel>
                    <Input
                      type="date"
                      name="date"
                      value={formData.date}
                      placeholder={
                        employeesEfficiencyText[language][0]?.chooseDate
                      }
                      onChange={e => handleInputChange(e)}
                    />
                  </ReportLabel>
                </ReportCommentContainer>

                {formData?.top_best_by_approve_percent?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.chooseTopBestEmployeeByApproval
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_by_approve_percent',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {
                              employeesEfficiencyText[language][0]
                                ?.chooseEmployeeTwo
                            }
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterApprovePercent
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`approve_percent_${index}`}
                          value={employee.approve_percent}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_by_approve_percent',
                              index,
                              'approve_percent',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterApprovePercent
                          }
                        />
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterTotalApprove
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`approve_count_${index}`}
                          value={employee.approve_count}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_by_approve_percent',
                              index,
                              'approve_count',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterTotalApprove
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {formData?.top_worst_by_approve_percent?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.chooseTopWorstEmployeeByApproval
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_by_approve_percent',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {
                              employeesEfficiencyText[language][0]
                                ?.chooseEmployeeTwo
                            }
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterApprovePercent
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`approve_percent_${index}`}
                          value={employee.approve_percent}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_by_approve_percent',
                              index,
                              'approve_percent',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterApprovePercent
                          }
                        />
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterTotalApprove
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`approve_count_${index}`}
                          value={employee.approve_count}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_by_approve_percent',
                              index,
                              'approve_count',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterTotalApprove
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {formData?.top_best_by_upsells_percent?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.chooseTopBestEmployeeByUpsells
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_by_upsells_percent',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {
                              employeesEfficiencyText[language][0]
                                ?.chooseEmployeeTwo
                            }
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterUpsellsPercent
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`upsells_percent_${index}`}
                          value={employee.upsells_percent}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_by_upsells_percent',
                              index,
                              'upsells_percent',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterUpsellsPercent
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {formData?.top_worst_by_upsells_percent?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.chooseTopWorstEmployeeByUpsells
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_by_upsells_percent',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {
                              employeesEfficiencyText[language][0]
                                ?.chooseEmployeeTwo
                            }
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterUpsellsPercent
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`upsells_percent_${index}`}
                          value={employee.upsells_percent}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_by_upsells_percent',
                              index,
                              'upsells_percent',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterUpsellsPercent
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {formData?.top_best_by_net_income?.map((employee, index) => (
                  <AddedReportContainer key={index}>
                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {
                          employeesEfficiencyText[language][0]
                            ?.chooseTopBestEmployeeByNetIncome
                        }
                      </ReportTextContainerForLabel>
                      <AddedFMSelect
                        name={`full_name_${index}`}
                        value={employee?.full_name}
                        onChange={e =>
                          handleArrayInputChange(
                            'top_best_by_net_income',
                            index,
                            'full_name',
                            e.target.value,
                          )
                        }
                      >
                        <AddedFMOption value="">
                          {
                            employeesEfficiencyText[language][0]
                              ?.chooseEmployeeTwo
                          }
                        </AddedFMOption>
                        {users?.usersData?.map(
                          user =>
                            (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                              <AddedFMOption
                                key={user.id_employee}
                                value={user.full_name}
                              >
                                {user.full_name}
                              </AddedFMOption>
                            ),
                        )}
                      </AddedFMSelect>
                    </ReportLabel>

                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {employeesEfficiencyText[language][0]?.enterNetIncome}
                      </ReportTextContainerForLabel>
                      <Input
                        type="number"
                        name={`net_income_value_${index}`}
                        value={employee.net_income_value}
                        onChange={e =>
                          handleArrayInputChange(
                            'top_best_by_net_income',
                            index,
                            'net_income_value',
                            e.target.value,
                          )
                        }
                        placeholder={
                          employeesEfficiencyText[language][0]?.enterNetIncome
                        }
                      />
                    </ReportLabel>
                  </AddedReportContainer>
                ))}

                {formData?.top_worst_by_net_income?.map((employee, index) => (
                  <AddedReportContainer key={index}>
                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {
                          employeesEfficiencyText[language][0]
                            ?.chooseTopWorstEmployeeByNetIncome
                        }
                      </ReportTextContainerForLabel>
                      <AddedFMSelect
                        name={`full_name_${index}`}
                        value={employee?.full_name}
                        onChange={e =>
                          handleArrayInputChange(
                            'top_worst_by_net_income',
                            index,
                            'full_name',
                            e.target.value,
                          )
                        }
                      >
                        <AddedFMOption value="">
                          {
                            employeesEfficiencyText[language][0]
                              ?.chooseEmployeeTwo
                          }
                        </AddedFMOption>
                        {users?.usersData?.map(
                          user =>
                            (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                              <AddedFMOption
                                key={user.id_employee}
                                value={user.full_name}
                              >
                                {user.full_name}
                              </AddedFMOption>
                            ),
                        )}
                      </AddedFMSelect>
                    </ReportLabel>

                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {employeesEfficiencyText[language][0]?.enterNetIncome}
                      </ReportTextContainerForLabel>
                      <Input
                        type="number"
                        name={`net_income_value_${index}`}
                        value={employee.net_income_value}
                        onChange={e =>
                          handleArrayInputChange(
                            'top_worst_by_net_income',
                            index,
                            'net_income_value',
                            e.target.value,
                          )
                        }
                        placeholder={
                          employeesEfficiencyText[language][0]?.enterNetIncome
                        }
                      />
                    </ReportLabel>
                  </AddedReportContainer>
                ))}

                {formData?.top_best_by_approve_per_hour?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.chooseTopBestEmployeeByApprovalPerHour
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_by_approve_per_hour',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {
                              employeesEfficiencyText[language][0]
                                ?.chooseEmployeeTwo
                            }
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterApprovalPerHour
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="text"
                          name={`net_income_value_${index}`}
                          value={employee.net_income_value}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_by_approve_per_hour',
                              index,
                              'aprove_per_hour_value',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterApprovalPerHour
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {formData?.top_worst_by_approve_per_hour?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.chooseTopWorstEmployeeByApprovalPerHour
                          }
                        </ReportTextContainerForLabel>
                        <AddedFMSelect
                          name={`full_name_${index}`}
                          value={employee?.full_name}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_by_approve_per_hour',
                              index,
                              'full_name',
                              e.target.value,
                            )
                          }
                        >
                          <AddedFMOption value="">
                            {
                              employeesEfficiencyText[language][0]
                                ?.chooseEmployeeTwo
                            }
                          </AddedFMOption>
                          {users?.usersData?.map(
                            user =>
                              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
                                <AddedFMOption
                                  key={user.id_employee}
                                  value={user.full_name}
                                >
                                  {user.full_name}
                                </AddedFMOption>
                              ),
                          )}
                        </AddedFMSelect>
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterApprovalPerHour
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="text"
                          name={`net_income_value_${index}`}
                          value={employee.net_income_value}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_by_approve_per_hour',
                              index,
                              'aprove_per_hour_value',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterApprovalPerHour
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {formData?.top_best_offer_by_upsells?.map((employee, index) => (
                  <AddedReportContainer key={index}>
                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {
                          employeesEfficiencyText[language][0]
                            ?.enterTopOferEmployeeByUpsells
                        }
                      </ReportTextContainerForLabel>
                      <Input
                        type="text"
                        name={`product_title_${index}`}
                        value={employee.product_title}
                        onChange={e =>
                          handleArrayInputChange(
                            'top_best_offer_by_upsells',
                            index,
                            'product_title',
                            e.target.value,
                          )
                        }
                        placeholder={
                          employeesEfficiencyText[language][0]
                            ?.enterTopOferEmployeeByUpsells
                        }
                      />
                    </ReportLabel>

                    <ReportLabel>
                      <ReportTextContainerForLabel>
                        {
                          employeesEfficiencyText[language][0]
                            ?.enterUpsellsPercent
                        }
                      </ReportTextContainerForLabel>
                      <Input
                        type="number"
                        name={`upsells_percent_${index}`}
                        value={employee.upsells_percent}
                        onChange={e =>
                          handleArrayInputChange(
                            'top_best_offer_by_upsells',
                            index,
                            'upsells_percent',
                            e.target.value,
                          )
                        }
                        placeholder={
                          employeesEfficiencyText[language][0]
                            ?.enterUpsellsPercent
                        }
                      />
                    </ReportLabel>
                  </AddedReportContainer>
                ))}

                {formData?.top_worst_offer_by_upsells?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterTopWorstOferByUpsells
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="text"
                          name={`product_title_${index}`}
                          value={employee.product_title}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_offer_by_upsells',
                              index,
                              'product_title',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterTopWorstOferByUpsells
                          }
                        />
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterUpsellsPercent
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`upsells_percent_${index}`}
                          value={employee.upsells_percent}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_offer_by_upsells',
                              index,
                              'upsells_percent',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterUpsellsPercent
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {formData?.top_best_offer_by_net_income?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterTopOferEmployeeByNetIncome
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="text"
                          name={`product_title_${index}`}
                          value={employee.product_title}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_offer_by_net_income',
                              index,
                              'product_title',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterTopOferEmployeeByNetIncome
                          }
                        />
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {employeesEfficiencyText[language][0]?.enterNetIncome}
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`unet_income_value_${index}`}
                          value={employee.net_income_value}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_best_offer_by_net_income',
                              index,
                              'net_income_value',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]?.enterNetIncome
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {formData?.top_worst_offer_by_net_income?.map(
                  (employee, index) => (
                    <AddedReportContainer key={index}>
                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {
                            employeesEfficiencyText[language][0]
                              ?.enterTopWorstOferByNetIncome
                          }
                        </ReportTextContainerForLabel>
                        <Input
                          type="text"
                          name={`product_title_${index}`}
                          value={employee.product_title}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_offer_by_net_income',
                              index,
                              'product_title',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]
                              ?.enterTopWorstOferByNetIncome
                          }
                        />
                      </ReportLabel>

                      <ReportLabel>
                        <ReportTextContainerForLabel>
                          {employeesEfficiencyText[language][0]?.enterNetIncome}
                        </ReportTextContainerForLabel>
                        <Input
                          type="number"
                          name={`net_income_value_${index}`}
                          value={employee.net_income_value}
                          onChange={e =>
                            handleArrayInputChange(
                              'top_worst_offer_by_net_income',
                              index,
                              'net_income_value',
                              e.target.value,
                            )
                          }
                          placeholder={
                            employeesEfficiencyText[language][0]?.enterNetIncome
                          }
                        />
                      </ReportLabel>
                    </AddedReportContainer>
                  ),
                )}

                {!isUpdate && (
                  <ButtonContainer>
                    <SaveButton type="submit">
                      {employeesEfficiencyText[language][0]?.save}
                    </SaveButton>
                    <DeleteReportButton
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {employeesEfficiencyText[language][0]?.cancel}
                    </DeleteReportButton>
                  </ButtonContainer>
                )}

                {isUpdate && (
                  <ButtonContainer>
                    <SaveButton type="submit">
                      {employeesEfficiencyText[language][0]?.update}
                    </SaveButton>
                    <DeleteReportButton
                      type="button"
                      onClick={() => handleDelete()}
                    >
                      {employeesEfficiencyText[language][0]?.delete}
                    </DeleteReportButton>
                  </ButtonContainer>
                )}
              </InfoForm>
            </Formik>
          </ModalContainer>
        </Modal>
      )}

      <ReportTable style={{ borderCollapse: 'collapse' }}>
        <Thead>
          <tr>
            <ReportTh rowSpan="2">
              {employeesEfficiencyText[language][0]?.date}
            </ReportTh>
            <ReportTh colSpan="3">
              {employeesEfficiencyText[language][0]?.topBestEmployeeByApproval}
            </ReportTh>
            <ReportTh colSpan="3" style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.topWorstEmployeeByApproval}
            </ReportTh>
            <ReportTh colSpan="2">
              {employeesEfficiencyText[language][0]?.topBestEmployeeByUpsells}
            </ReportTh>
            <ReportTh colSpan="2" style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.topWorstEmployeeByUpsells}
            </ReportTh>
            <ReportTh colSpan="2">
              {employeesEfficiencyText[language][0]?.topBestEmployeeByNetIncome}
            </ReportTh>
            <ReportTh colSpan="2" style={{ backgroundColor: '#e74c3c' }}>
              {
                employeesEfficiencyText[language][0]
                  ?.topWorstEmployeeByNetIncome
              }
            </ReportTh>
            <ReportTh colSpan="2">
              {
                employeesEfficiencyText[language][0]
                  ?.topBestEmployeeByApprovalPerHour
              }
            </ReportTh>
            <ReportTh colSpan="2" style={{ backgroundColor: '#e74c3c' }}>
              {
                employeesEfficiencyText[language][0]
                  ?.topWorstEmployeeByApprovalPerHour
              }
            </ReportTh>
            <ReportTh colSpan="2">
              {employeesEfficiencyText[language][0]?.topBestOferByUpsells}
            </ReportTh>
            <ReportTh colSpan="2" style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.topWorstOferByUpsells}
            </ReportTh>
            <ReportTh colSpan="2">
              {employeesEfficiencyText[language][0]?.topBestOferByNetIncome}
            </ReportTh>
            <ReportTh colSpan="2" style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.topWorstOferByNetIncome}
            </ReportTh>
          </tr>
          <tr>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.fullName}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.approvePercent}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.totalApprove}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.fullName}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.approvePercent}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.totalApprove}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.fullName}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.upsellsPercent}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.fullName}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.upsellsPercent}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.fullName}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.netIncome}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.fullName}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.netIncome}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.fullName}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.approvePerHour}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.fullName}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.approvePerHour}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.productTitle}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.upsellsPercent}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.productTitle}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.upsellsPercent}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.productTitle}
            </ReportTh>
            <ReportTh>
              {employeesEfficiencyText[language][0]?.netIncome}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.productTitle}
            </ReportTh>
            <ReportTh style={{ backgroundColor: '#e74c3c' }}>
              {employeesEfficiencyText[language][0]?.netIncome}
            </ReportTh>
          </tr>
        </Thead>
        <tbody>
          {data?.length > 0 ? (
            <React.Fragment>
              {data.map(item => {
                return (
                  <tr onClick={() => handleClick(item)} key={item.id}>
                    <ReportTd style={{ width: '90px' }}>
                      {formattedDateToDayMonthYear(item.date)}
                    </ReportTd>
                    {Object.keys(fieldStructures).map(fieldName => {
                      const fieldData = JSON?.parse(item[fieldName] || '[]');
                      const structure = fieldStructures[fieldName];
                      return structure.columns.map((column, columnIndex) => {
                        const key = uuidv4();
                        return (
                          <ReportTd key={key}>
                            {fieldData.map(value => {
                              const valueKey = uuidv4();
                              return (
                                <TableValueContainer key={valueKey}>
                                  {value[column.key]}
                                </TableValueContainer>
                              );
                            })}
                          </ReportTd>
                        );
                      });
                    })}
                  </tr>
                );
              })}
            </React.Fragment>
          ) : (
            <tr>
              <ReportTd
                colSpan={13}
                style={{
                  width: '2000px',
                  textAlign: 'start',
                  paddingLeft: '50px',
                }}
              >
                {employeesEfficiencyText[language][0]?.notDataOrError}
              </ReportTd>
            </tr>
          )}
        </tbody>
      </ReportTable>
    </Main>
  );
};
