export const getClientConfiguredBackgroundColor = (id) => {
    
    switch (id) {
        case 1:
            return '#27ae60';
        case 2:
            return '#e74c3c';
        case 3:
            return '#e74c3c'; 
        case 4:
            return 'darkred'; 
        default:
            return '#ffffff';
    }
  }