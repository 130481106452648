import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';
import { Form, Field } from 'formik';

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const ReportTable = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const ReportTr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const ReportHeadTr = styled(ReportTr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const ReportTd = styled.td`
  width: 107px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color: ${props => props.backgroundColor || '#ffffff'};
  cursor: pointer;

  &:last-of-type {
    border-right: none;
  }
`;

export const ReportTh = styled.th`
  width: 85px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const SaveButton = styled(LoginFormButton)`
  min-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

export const AddedNewReportButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const DeleteReportButton = styled(SaveButton)`
  margin-left: 10px;
  background-color: #e74c3c;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const AddedReportContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  flex-wrap: wrap;
  width: 46%;
  margin-bottom: 5px;
  border: 2px solid #3098d6;
  border-radius: 10px;
`;

export const ReportTextArea = styled(Field)`
  font-size: 16px;
  line-height: 1.17;
  width: 100%;
  height: 150px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  padding: 12px 16px;
  resize: none;
  background-color: transparent;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  &:focus {
    border-color: #5cbcf6;
  }

  &::placeholder {
    border-color: #5cbcf6;
    color: rgba(117, 117, 117, 0.5);
    font-size: $primarySize;
    line-height: 1.14;
    letter-spacing: 0.3;
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  max-width: 1400px;
  height: 100%;
  margin: 40px;
`;

export const InfoForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Input = styled(Field)`
  width: 40%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.17;
`;

export const ReportLabel = styled.label`
  width: 50%;
  text-wrap: wrap;
`;

export const ReportCommentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 2px solid #3098d6;
  padding: 10px;
  border-radius: 10px;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DataSelect = styled.select`
appearance: none;
width: 300px;
height: 30px;
padding: 5px;
background-color: #f5f7fa;
border: 2px solid #3098d6;
font-size: 16px;
margin-left: 10px;
border-radius: 10px;

cursor: pointer;
`;

export const DataOption = styled.option`
  width: 20%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
`;
