import styled from '@emotion/styled';

export const NavigationTopLeftBar = styled.div`
  margin-right: 0;
  margin-left: 0;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  adding: 1rem 0;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  height: 4.5rem;
  z-index: 999;
`;

export const NavigationLogoContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 0;
  padding-left: 10px;
`;

export const NavigationTopLeftButtonContainer = styled.div`
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  margin-left: auto;
  adding-right: 0;
  padding-left: 0;
`;

export const NavigationLeftHidden = styled.button`
  padding: 20px;
  background-color: transparent;
  border: none;
  color: #3098d6;
  font-size: 24px;
  cursor: pointer;
`;
