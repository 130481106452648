import styled from '@emotion/styled';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Table = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const Tr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const HeadTr = styled(Tr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const Td = styled.td`
  width: 252px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color: #ffffff;
  position: relative;

  &:first-of-type {
    width: 350px;
  }
`;

export const Th = styled.th`
  width: 230px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &::nth-of-type(1),
  &::nth-of-type(2) {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  &:first-of-type {
    width: 330px;
  }
`;

export const UserFaTimesCircle = styled(FaTimesCircle)`
cursor: pointer;
font-size: 24px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-weight: 700; 
`

export const UserFaMinusCircle = styled(FaCheckCircle)`
cursor: pointer;
font-size: 24px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-weight: 700; 
`