import { useState, useEffect } from "react";
import { Main } from "../../Main/Main";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { useQuery } from "react-query";
import { UserContext } from "../../Context/UserContext";
import "react-toastify/dist/ReactToastify.css";

import {
  ListContainer,
  Item,
  ScriptTemplateLink,
  LinkContainer,
  IconButton,
  TrashButton,
  IconLinkContainer,
  SearchInput,
  SearchButton,
  SearchContainer,
} from "./ScriptTemplateList.styled";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { productsText, scriptText } from "../../../services/componentText";
import { fetchScriptTemplate, deleteScriptTemplate } from "../../../api/script";
import { AiOutlineClear } from "react-icons/ai";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { LanguageSelector } from "../../LanguageSelector/LanguageSelector";

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const ScriptTemplateList = () => {
  const { currentUser, language } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [isRussianTitle, setIsRussianTitle] = useState(
    language === "russian" ? true : false
  );

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const { data: templatesData, refetch } = useQuery("templatesData", fetchScriptTemplate);

  const handleDelete = async (id) => {
    const response = await deleteScriptTemplate(id);

    if (response?.status === 200) {
      toast.success(productsText[language][0]?.successRemoved);
      return refetch();
    }
  };

  const filteredTemplates = templatesData?.data?.filter((item) => {
    const titleUaMatch = item.template_title_ua
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const titleRuMatch = item.template_title_ru
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    return titleUaMatch || titleRuMatch;
  });

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (filteredTemplates) {
      const totalItems = filteredTemplates.length;
      const calculatedTotalPages = Math.ceil(totalItems / pageLimit);
      setTotalPages(calculatedTotalPages);

      if (currentPage > calculatedTotalPages) {
        setCurrentPage(1);
      }
    }
  }, [filteredTemplates, pageLimit, currentPage]);

  const startIndex = (Number(currentPage) - 1) * Number(pageLimit);
  const endIndex = Number(startIndex) + Number(pageLimit);
  const paginatedTemplates = filteredTemplates?.slice(startIndex, endIndex);

  return (
    <div>
    <Main>
      {templatesData?.data?.length > 0 ? (
        <div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchContainer>
                <SearchInput
                  type="text"
                  placeholder={scriptText[language][0]?.searchTitle}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
                <SearchButton
                  type="button"
                  onClick={() => setSearchQuery('')}
                >
                  <AiOutlineClear size={26} />
                </SearchButton>
              </SearchContainer>
              <PageLimitSelector
                pageLimit={pageLimit}
                onChange={handlePageLimitChange}
              />

              <LanguageSelector
                language={isRussianTitle}
                onChange={value =>
                  setIsRussianTitle(value === 'true' ? true : false)
                }
              />
            </div>

            <ListContainer style={{userSelect: 'none'}}>
              {paginatedTemplates?.map(item => (
                <Item key={item.id}>
                  <LinkContainer accessLevel={accessLevel}>
                    <ScriptTemplateLink to={`/script-control-panel/change/${item.id}`}>
                      {isRussianTitle ? item.template_title_ru : item.template_title_ua}
                    </ScriptTemplateLink>
                  </LinkContainer>
                  {accessLevel && (
                    <IconLinkContainer>
                      <IconButton to={`/script-control-panel/change/${item.id}`}>
                        <FaEdit />
                      </IconButton>
                      <TrashButton onClick={() => handleDelete(item.id)}>
                        <FaTrash />
                      </TrashButton>
                    </IconLinkContainer>
                  )}
                </Item>
              ))}
            </ListContainer>

            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      ) : (
        <div>{productsText[language][0]?.notDataOrError}</div>
      )}
    </Main>
    <Outlet />
  </div>
  );
};
