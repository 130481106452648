import { Main } from "../../components/Main/Main";
import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import { useContext } from "react";
import { UserContext } from "../../components/Context/UserContext";
import { Outlet } from "react-router-dom";
import { ProductAvailabilityUser } from "../../components/ProductAvailability/ProductAvailabilityUser/ProductAvailabilityUser";
import { ManagerProductAvailability } from "../../components/ProductAvailability/ManagerProductAvailability/ManagerProductAvailability";

export const ProductAvailability = () => {
  const { currentUser } = useContext(UserContext);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
  const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);
  const access_level_a = Number(process.env.REACT_APP_A_LEVEL_ACCESS);
  const access_level_h = Number(process.env.REACT_APP_H_LEVEL_ACCESS);
  const access_level_k = Number(process.env.REACT_APP_K_LEVEL_ACCESS);

  return (
    <div>
      <SharedLayout />
      <Main>
        {(currentUser?.access_level === access_level_a ||
        currentUser?.access_level === access_level_f ||
        currentUser?.access_level === access_level_g ||
        currentUser?.access_level === access_level_k) && (
          <ManagerProductAvailability />
        )}
        {(currentUser?.access_level === access_level_d ||
        currentUser?.access_level === access_level_e ||
        currentUser?.access_level === access_level_c ||
        currentUser?.access_level === access_level_h) && (
          <ProductAvailabilityUser />
        )}
      </Main>
      <Outlet />
    </div>
  );
};
