import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchCallReport = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get('/reports/call-report', {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
      },
    });

    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteCallReport = async id => {
  try {
    const response = await makeRequestSQL.delete(
      `/reports/call-report?id=${id}`,
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createCallReport = async data => {
  try {
    const response = await makeRequestSQL.post('/reports/call-report', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateCallReport = async data => {
  try {
    const response = makeRequestSQL.put('/reports/call-report', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchAllDepartmentReport = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get(
      '/reports/all-department-report',
      {
        params: {
          sortBy,
          order,
          startDate,
          endDate,
        },
      },
    );

    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteAllDepartmentReport = async id => {
  try {
    const response = await makeRequestSQL.delete(
      `/reports/all-department-report?id=${id}`,
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createAllDepartmentReport = async data => {
  try {
    const response = await makeRequestSQL.post(
      '/reports/all-department-report',
      data,
    );
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateAllDepartmentReport = async data => {
  try {
    const response = makeRequestSQL.put('/reports/all-department-report', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOrderReport = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get('/reports/orders-report', {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
      },
    });

    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteOrderReport = async id => {
  try {
    const response = await makeRequestSQL.delete(
      `/reports/orders-report?id=${id}`,
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createOrderReport = async data => {
  try {
    const response = await makeRequestSQL.post('/reports/orders-report', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateOrderReport = async data => {
  try {
    const response = makeRequestSQL.put('/reports/orders-report', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createEmployeeReport = async data => {
  try {
    const response = await makeRequestSQL.post(
      '/reports/employee-report',
      data,
    );
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchEmployeeReport = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get('/reports/employee-report', {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
      },
    });

    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateEmployeeReport = async data => {
  try {
    const response = makeRequestSQL.put('/reports/employee-report', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteEmployeeReport = async id => {
  try {
    const response = await makeRequestSQL.delete(
      `/reports/employee-report?id=${id}`,
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchTotalCountEmployeeReport = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get('/reports/employee-report/total-count', {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
      },
    });

    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createEmployeeEfficiencyReport = async data => {
  try {
    const response = await makeRequestSQL.post(
      '/reports/employee-efficiency-report',
      data,
    );
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchEmployeeEfficiencyReport = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get(
      '/reports/employee-efficiency-report',
      {
        params: {
          sortBy,
          order,
          startDate,
          endDate,
        },
      },
    );

    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateEmployeeEfficiencyReport = async data => {
  try {
    const response = makeRequestSQL.put(
      '/reports/employee-efficiency-report',
      data,
    );
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteEmployeeEfficiencyReport = async id => {
  try {
    const response = await makeRequestSQL.delete(
      `/reports/employee-efficiency-report?id=${id}`,
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchTotalCountEmployeeEfficiencyReport = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get(
      '/reports/employee-efficiency-report/total-count',
      {
        params: {
          sortBy,
          order,
          startDate,
          endDate,
        },
      },
    );

    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};