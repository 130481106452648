import { Main } from '../../Main/Main';
import { Modal } from '../../Modal/Modal';
import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from 'react-query';
import {
  ModalContainer,
  InfoForm,
  Input,
  ReportTextArea,
  AddedReportContainer,
  DeleteReportButton,
  AddedNewReportButton,
  ButtonContainer,
  SaveButton,
  ReportTh,
  ReportTd,
  ReportHeadTr,
  ReportTr,
  ReportTable,
  TableContainer,
  Thead,
  ReportLabel,
  ReportCommentContainer,
  NavContainer,
} from './AllDepartments.styled';
import {
  createAllDepartmentReport,
  updateAllDepartmentReport,
  fetchAllDepartmentReport,
  deleteAllDepartmentReport,
} from '../../../api/report';
import { allDepartmentReportText } from '../../../services/componentText';
import { Formik } from 'formik';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { formatDateToInput } from '../../../helpers/dateAndTime/formatDate';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

export const AllDepartments = () => {
   const { currentUser, language } = useContext(UserContext);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [sortBy, setSortBy] = useState('date');
   const [order, setOrder] = useState('DESC');
   const [isUpdate, setIsUpdate] = useState(false);
   const [selectedId, setSelectedId] = useState();
   const [allDepartmentReportInitialState, setCallReportInitialState] =
     useState({
       id: '',
       date: '',
       operators_count_previous_day: '',
       total_operators_worked_hours: '',
       hours_on_KZ_UZ: '',
       hours_on_incoming: '',
       hours_on_failures: '',
       hours_on_downsale: '',
       total_hours_in_Ukraine: '',
       total_hours_in_Ukraine_with_extras: '',
       scheduled_working_hours: '',
       hours_on_plan: '',
       plan_failure: '',
       percentage_plan_failure: '',
       operators_for_training: '',
       report_comment: '',
     });
    const [isModalDateOpen, setIsModalDateOpen] = useState(false);
    const currentDate = new Date();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const [period, setPeriod] = useState([
      {
        startDate: new Date(
          currentYear,
          currentMonth - 2,
          currentDate.getDate(),
        ),
        endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
        key: 'selection',
      },
    ]);
    const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
    const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
    const formattedStartDateRequest = format(
      period[0]?.startDate,
      'yyyy.MM.dd',
    );
    const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

   const { data, refetch } = useQuery(
     ['allDepartmentReport', sortBy, order],
     () =>
       fetchAllDepartmentReport({
         sortBy,
         order,
         startDate: formattedStartDateRequest,
         endDate: formattedEndDateRequest,
       }),
   );

   const handleSortChange = field => {
     if (sortBy === field) {
       setOrder(order === 'ASC' ? 'DESC' : 'ASC');
       return refetch();
     }

     setSortBy(field);
     return refetch();
   };

   const handleSubmit = async values => {
     if (isUpdate) {
       const response = await updateAllDepartmentReport(values);

       if (response?.status === 204) {
         setIsModalOpen(false);
         setIsUpdate(false);
         resetValue();
         refetch();
         return toast.success(
           allDepartmentReportText[language][0]?.successUpdate,
         );
       }
     }

     const response = await createAllDepartmentReport(values);

     if (response?.status === 204) {
       setIsModalOpen(false);
       resetValue();
       refetch();
       return toast.success(allDepartmentReportText[language][0]?.successSave);
     }
   };

   const resetValue = () => {
     setCallReportInitialState({
       id: '',
       date: '',
       operators_count_previous_day: '',
       total_operators_worked_hours: '',
       hours_on_KZ_UZ: '',
       hours_on_incoming: '',
       hours_on_failures: '',
       hours_on_downsale: '',
       total_hours_in_Ukraine: '',
       total_hours_in_Ukraine_with_extras: '',
       scheduled_working_hours: '',
       hours_on_plan: '',
       plan_failure: '',
       percentage_plan_failure: '',
       operators_for_training: '',
       report_comment: '',
     });
   };

   const handleCancel = () => {
     setIsModalOpen(false);
     resetValue();
   };

   const handleDelete = async () => {
     const response = await deleteAllDepartmentReport(selectedId);

     if (response.status === 200) {
       setIsUpdate(false);
       resetValue();
       setIsModalOpen(false);
       refetch();
       return toast.success(
         allDepartmentReportText[language][0]?.successRemoved,
       );
     }
   };

   const renderSortIcon = field => {
     if (sortBy === field) {
       return order === 'ASC' ? '▲' : '▼';
     }
     return '';
   };

   const handleClick = item => {
     setIsUpdate(true);
     setIsModalOpen(true);
     setSelectedId(item?.id);
     setCallReportInitialState({ ...item, date: formatDateToInput(item.date) });
   };

   const handleNewReport = () => {
     setIsModalOpen(true);
     resetValue();
     setIsUpdate(false);
  };
  
    const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const customDefaultInputRanges = defaultInputRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const handleDate = () => {
      refetch();
      setIsModalDateOpen(false);
    };

   return (
     <Main>
       <NavContainer>
         <div
           style={{ marginRight: '10px' }}
         >{`${formattedStartDate} - ${formattedEndDate}`}</div>
         <AddedNewReportButton onClick={() => setIsModalDateOpen(true)}>
           {allDepartmentReportText[language][0]?.choosePeriod}
         </AddedNewReportButton>
         <AddedNewReportButton onClick={() => handleNewReport()}>
           {allDepartmentReportText[language][0]?.addNewReport}
         </AddedNewReportButton>
       </NavContainer>
       {isModalDateOpen && (
         <Modal closeModal={() => setIsModalDateOpen(false)}>
           <DateRangePicker
             onChange={item => setPeriod([item.selection])}
             showSelectionPreview={true}
             moveRangeOnFirstSelection={false}
             locale={currentUser?.language === 'russian' ? ru : uk}
             staticRanges={customDefaultStaticRanges}
             inputRanges={customDefaultInputRanges}
             months={2}
             ranges={period}
             direction="horizontal"
             style={{ margin: '20px' }}
           />
           <SaveButton onClick={() => handleDate()}>
             {allDepartmentReportText[language][0]?.update}
           </SaveButton>
         </Modal>
       )}
       {isModalOpen && (
         <Modal closeModal={() => setIsModalOpen(false)}>
           <ModalContainer>
             <Formik
               initialValues={allDepartmentReportInitialState}
               onSubmit={handleSubmit}
             >
               <InfoForm autoComplete="off">
                 <AddedReportContainer>
                   <ReportLabel>
                     {allDepartmentReportText[language][0]?.chooseDate}
                   </ReportLabel>
                   <Input
                     type="date"
                     name="date"
                     placeholder={
                       allDepartmentReportText[language][0]?.chooseDate
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfEmployeesOnShift
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="operators_count_previous_day"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfEmployeesOnShift
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterTotalNumberOfHoursWorkedPerShift
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="total_operators_worked_hours"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterTotalNumberOfHoursWorkedPerShift
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfHoursOnKZ
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="hours_on_KZ_UZ"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfHoursOnKZ
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfHoursOnIncoming
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="hours_on_incoming"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfHoursOnIncoming
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfHoursOnRefusals
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="hours_on_failures"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfHoursOnRefusals
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfHoursOnDownsale
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="hours_on_downsale"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfHoursOnDownsale
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterTotalNumberOfHoursOnUkraine
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="total_hours_in_Ukraine"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterTotalNumberOfHoursOnUkraine
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterShouldBeHoursOnUkraine
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="total_hours_in_Ukraine_with_extras"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterShouldBeHoursOnUkraine
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfWorkingHoursBeforeChanges
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="scheduled_working_hours"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfWorkingHoursBeforeChanges
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfOvertimeHours
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="hours_on_plan"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfOvertimeHours
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNotFollowingPlan
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="plan_failure"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNotFollowingPlan
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterPercentNotFollowingPlan
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="percentage_plan_failure"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterPercentNotFollowingPlan
                     }
                   />
                 </AddedReportContainer>

                 <AddedReportContainer>
                   <ReportLabel>
                     {
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfEmployeesOnEducation
                     }
                   </ReportLabel>
                   <Input
                     type="number"
                     name="operators_for_training"
                     placeholder={
                       allDepartmentReportText[language][0]
                         ?.enterNumberOfEmployeesOnEducation
                     }
                   />
                 </AddedReportContainer>

                 <ReportCommentContainer>
                   <ReportLabel>
                     {allDepartmentReportText[language][0]?.enterReportComment}
                   </ReportLabel>
                   <ReportTextArea
                     type="number"
                     name="report_comment"
                     as="textarea"
                     placeholder={
                       allDepartmentReportText[language][0]?.enterReportComment
                     }
                   />
                 </ReportCommentContainer>
                 {!isUpdate && (
                   <ButtonContainer>
                     <SaveButton type="submit">
                       {allDepartmentReportText[language][0]?.save}
                     </SaveButton>
                     <DeleteReportButton
                       type="button"
                       onClick={() => handleCancel()}
                     >
                       {allDepartmentReportText[language][0]?.cancel}
                     </DeleteReportButton>
                   </ButtonContainer>
                 )}
                 {isUpdate && (
                   <ButtonContainer>
                     <SaveButton type="submit">
                       {allDepartmentReportText[language][0]?.update}
                     </SaveButton>
                     <DeleteReportButton
                       type="button"
                       onClick={() => handleDelete()}
                     >
                       {allDepartmentReportText[language][0]?.delete}
                     </DeleteReportButton>
                   </ButtonContainer>
                 )}
               </InfoForm>
             </Formik>
           </ModalContainer>
         </Modal>
       )}
       <TableContainer>
         <ReportTable>
           <Thead>
             <ReportHeadTr>
               <ReportTh onClick={() => handleSortChange('date')}>
                 {allDepartmentReportText[language][0]?.date}{' '}
                 {renderSortIcon('date')}
               </ReportTh>
               <ReportTh
                 onClick={() =>
                   handleSortChange('operators_count_previous_day')
                 }
               >
                 {
                   allDepartmentReportText[language][0]
                     ?.numberOfEmployeesOnShift
                 }{' '}
                 {renderSortIcon('operators_count_previous_day')}
               </ReportTh>
               <ReportTh
                 onClick={() =>
                   handleSortChange('total_operators_worked_hours')
                 }
               >
                 {
                   allDepartmentReportText[language][0]
                     ?.totalNumberOfHoursWorkedPerShift
                 }{' '}
                 {renderSortIcon('total_operators_worked_hours')}
               </ReportTh>
               <ReportTh onClick={() => handleSortChange('hours_on_KZ_UZ')}>
                 {allDepartmentReportText[language][0]?.numberOfHoursOnKZ}{' '}
                 {renderSortIcon('hours_on_KZ_UZ')}
               </ReportTh>
               <ReportTh onClick={() => handleSortChange('hours_on_incoming')}>
                 {allDepartmentReportText[language][0]?.numberOfHoursOnIncoming}{' '}
                 {renderSortIcon('hours_on_incoming')}
               </ReportTh>
               <ReportTh onClick={() => handleSortChange('hours_on_failures')}>
                 {allDepartmentReportText[language][0]?.numberOfHoursOnRefusals}{' '}
                 {renderSortIcon('hours_on_failures')}
               </ReportTh>
               <ReportTh onClick={() => handleSortChange('hours_on_downsale')}>
                 {allDepartmentReportText[language][0]?.numberOfHoursOnDownsale}{' '}
                 {renderSortIcon('hours_on_downsale')}
               </ReportTh>
               <ReportTh
                 onClick={() => handleSortChange('total_hours_in_Ukraine')}
               >
                 {
                   allDepartmentReportText[language][0]
                     ?.numberTotalOfHoursOnUkraine
                 }{' '}
                 {renderSortIcon('total_hours_in_Ukraine')}
               </ReportTh>
               <ReportTh
                 onClick={() =>
                   handleSortChange('total_hours_in_Ukraine_with_extras')
                 }
               >
                 {
                   allDepartmentReportText[language][0]
                     ?.numberShouldBeHoursOnUkraine
                 }{' '}
                 {renderSortIcon('total_hours_in_Ukraine_with_extras')}
               </ReportTh>
               <ReportTh
                 onClick={() => handleSortChange('scheduled_working_hours')}
               >
                 {
                   allDepartmentReportText[language][0]
                     ?.numberOfWorkingHoursBeforeChanges
                 }{' '}
                 {renderSortIcon('scheduled_working_hours')}
               </ReportTh>
               <ReportTh onClick={() => handleSortChange('hours_on_plan')}>
                 {allDepartmentReportText[language][0]?.numberOfOvertimeHours}{' '}
                 {renderSortIcon('hours_on_plan')}
               </ReportTh>
               <ReportTh onClick={() => handleSortChange('plan_failure')}>
                 {allDepartmentReportText[language][0]?.notFollowingPlan}{' '}
                 {renderSortIcon('plan_failure')}
               </ReportTh>
               <ReportTh
                 onClick={() => handleSortChange('percentage_plan_failure')}
               >
                 {allDepartmentReportText[language][0]?.percentNotFollowingPlan}{' '}
                 {renderSortIcon('percentage_plan_failure')}
               </ReportTh>
               <ReportTh
                 onClick={() => handleSortChange('operators_for_training')}
               >
                 {
                   allDepartmentReportText[language][0]
                     ?.numberOfEmployeesOnEducation
                 }{' '}
                 {renderSortIcon('operators_for_training')}
               </ReportTh>
               <ReportTh onClick={() => handleSortChange('report_comment')}>
                 {allDepartmentReportText[language][0]?.reportComment}{' '}
                 {renderSortIcon('report_comment')}
               </ReportTh>
             </ReportHeadTr>
           </Thead>
           <tbody>
             {data?.length > 0 ? (
               <React.Fragment>
                 {data?.map(item => {
                   return (
                     <ReportTr key={item.id} onClick={() => handleClick(item)}>
                       <ReportTd>
                         {formattedDateToDayMonthYear(item.date)}
                       </ReportTd>
                       <ReportTd>{item.operators_count_previous_day}</ReportTd>
                       <ReportTd>{item.total_operators_worked_hours}</ReportTd>
                       <ReportTd>{item.hours_on_KZ_UZ}</ReportTd>
                       <ReportTd>{item.hours_on_incoming}</ReportTd>
                       <ReportTd>{item.hours_on_failures}</ReportTd>
                       <ReportTd>{item.hours_on_downsale}</ReportTd>
                       <ReportTd>{item.total_hours_in_Ukraine}</ReportTd>
                       <ReportTd>
                         {item.total_hours_in_Ukraine_with_extras}
                       </ReportTd>
                       <ReportTd>{item.scheduled_working_hours}</ReportTd>
                       <ReportTd>{item.hours_on_plan}</ReportTd>
                       <ReportTd>{item.plan_failure}</ReportTd>
                       <ReportTd>{item.percentage_plan_failure} %</ReportTd>
                       <ReportTd>{item.operators_for_training}</ReportTd>
                       <ReportTd>{item.report_comment}</ReportTd>
                     </ReportTr>
                   );
                 })}
               </React.Fragment>
             ) : (
               <ReportTr>
                 <ReportTd
                   colSpan={13}
                   style={{
                     width: '2000px',
                     textAlign: 'start',
                     paddingLeft: '50px',
                   }}
                 >
                   {allDepartmentReportText[language][0]?.notDataOrError}
                 </ReportTd>
               </ReportTr>
             )}
           </tbody>
         </ReportTable>
       </TableContainer>
     </Main>
   );
};
