import {
  Thead,
  Table,
  Tr,
  HeadTr,
  Td,
  Th,
  UserFaMinusCircle,
  UserFaTimesCircle,
} from "./OnlineUsers.styled";
import { onlineUsersText } from "../../services/componentText";
import { UserContext } from "../..//components/Context/UserContext";
import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import { Main } from "../../components/Main/Main";
import { fetchOnlineUsers } from "../../api/users";

export const OnlineUsers = () => {
  const { language } = useContext(UserContext);

  const {data} = useQuery('onlineUsers', fetchOnlineUsers)

  const getIcon = value => {
    if (value === 1) {
      return <UserFaMinusCircle fill="green" />;
    }  else {
      return <UserFaTimesCircle fill="red" />;
    }
  };

  return (
    <div>
      <SharedLayout />
      <Main>
          <Table>
            <Thead>
              <HeadTr>
                <Th>{onlineUsersText[language][0]?.fullName}</Th>
                <Th>{onlineUsersText[language][0]?.status}</Th>
                <Th>{onlineUsersText[language][0]?.lastSeen}</Th>
              </HeadTr>
            </Thead>
            <tbody>
              {data?.map((item) => {
                let formattedDateTime;

                if (item?.last_online) {
                  const options = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZone: "UTC",
                  };

                  formattedDateTime = new Date(
                    item?.last_online
                  ).toLocaleString(options);
                } else {
                  formattedDateTime = "";
                }

                return (
                  <Tr key={item?.id_employee}>
                    <Td>{item?.full_name}</Td>
                    <Td>
                      {getIcon(item?.is_online)}
                    </Td>
                    <Td>{formattedDateTime}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
      </Main>
    </div>
  );
};
