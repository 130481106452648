import { Main } from '../../Main/Main';
import { Modal } from '../../Modal/Modal';
import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from 'react-query';
import {
  ModalContainer,
  InfoForm,
  Input,
  ReportTextArea,
  AddedReportContainer,
  DeleteReportButton,
  AddedNewReportButton,
  ButtonContainer,
  SaveButton,
  ReportTh,
  ReportTd,
  ReportHeadTr,
  ReportTr,
  ReportTable,
  TableContainer,
  Thead,
  ReportLabel,
  ReportCommentContainer,
  NavContainer,
} from './OrderReport.styled';
import {
  createOrderReport,
  updateOrderReport,
  fetchOrderReport,
  deleteOrderReport,
} from '../../../api/report';
import { orderReportText } from '../../../services/componentText';
import { Formik } from 'formik';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { formatDateToInput } from '../../../helpers/dateAndTime/formatDate';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

export const OrderReport = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState('date');
  const [order, setOrder] = useState('DESC');
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedId, setSelectedId] = useState();
    const currentDate = new Date();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const [period, setPeriod] = useState([
      {
        startDate: new Date(
          currentYear,
          currentMonth - 2,
          currentDate.getDate(),
        ),
        endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
        key: 'selection',
      },
    ]);
    const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
    const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
    const formattedStartDateRequest = format(
      period[0]?.startDate,
      'yyyy.MM.dd',
    );
    const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');
  const [orderReportInitialState, setCallReportInitialState] = useState({
    id: '',
    date: '',
    number_of_leads_by_creation: '',
    number_of_leads_by_approval: '',
    percent_of_orders_not_processed_on_time: '',
    number_of_approve_by_creation: '',
    number_of_approve_by_approval: '',
    number_of_proccessing_by_creation: '',
    number_of_proccessing_by_approval: '',
    number_of_trash_by_creation: '',
    number_of_trash_by_approval: '',
    number_of_refusals_by_creation: '',
    number_of_refusals_by_approval: '',
    number_of_orders_accepted_per_hour_by_creation: '',
    number_of_orders_accepted_per_hour_by_approval: '',
    percent_of_approval_by_offers: '',
    percent_of_approval_by_offers_without_trash: '',
    percent_of_upsells: '',
    percent_of_approve_by_employee_by_creation: '',
    percent_of_approve_by_employee_without_trash_by_creation: '',
    percent_of_refusals_without_trash: '',
    percent_of_processing_without_trash: '',
    percent_of_trash: '',
    percent_of_free_delivery: '',
    average_check_by_approval: '',
    average_increase_in_check: '',
    report_comment: '',
  });

  const { data, refetch } = useQuery(['orderReport', sortBy, order], () =>
    fetchOrderReport({
      sortBy,
      order,
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    }),
  );

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const handleSubmit = async values => {
    if (isUpdate) {
      const response = await updateOrderReport(values);

      if (response?.status === 204) {
        setIsModalOpen(false);
        setIsUpdate(false);
        resetValue();
        refetch();
        return toast.success(orderReportText[language][0]?.successUpdate);
      }
    }

    const response = await createOrderReport(values);

    if (response?.status === 204) {
      setIsModalOpen(false);
      resetValue();
      refetch();
      return toast.success(orderReportText[language][0]?.successSave);
    }
  };

  const resetValue = () => {
    setCallReportInitialState({
      id: '',
      date: '',
      number_of_leads_by_creation: '',
      number_of_leads_by_approval: '',
      percent_of_orders_not_processed_on_time: '',
      number_of_approve_by_creation: '',
      number_of_approve_by_approval: '',
      number_of_proccessing_by_creation: '',
      number_of_proccessing_by_approval: '',
      number_of_trash_by_creation: '',
      number_of_trash_by_approval: '',
      number_of_refusals_by_creation: '',
      number_of_refusals_by_approval: '',
      number_of_orders_accepted_per_hour_by_creation: '',
      number_of_orders_accepted_per_hour_by_approval: '',
      percent_of_approval_by_offers: '',
      percent_of_approval_by_offers_without_trash: '',
      percent_of_upsells: '',
      percent_of_approve_by_employee_by_creation: '',
      percent_of_approve_by_employee_without_trash_by_creation: '',
      percent_of_refusals_without_trash: '',
      percent_of_processing_without_trash: '',
      percent_of_trash: '',
      percent_of_free_delivery: '',
      average_check_by_approval: '',
      average_increase_in_check: '',
      report_comment: '',
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetValue();
  };

  const handleDelete = async () => {
    const response = await deleteOrderReport(selectedId);

    if (response.status === 200) {
      setIsUpdate(false);
      resetValue();
      setIsModalOpen(false);
      refetch();
      return toast.success(orderReportText[language][0]?.successRemoved);
    }
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const handleClick = item => {
    setIsUpdate(true);
    setIsModalOpen(true);
    setSelectedId(item?.id);
    setCallReportInitialState({ ...item, date: formatDateToInput(item.date) });
  };

  const handleNewReport = () => {
    setIsModalOpen(true);
    resetValue();
    setIsUpdate(false);
  };

    const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const customDefaultInputRanges = defaultInputRanges.map(range => ({
      ...range,
      label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
    }));

    const handleDate = () => {
      refetch();
      setIsModalDateOpen(false);
    };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewReportButton onClick={() => setIsModalDateOpen(true)}>
          {orderReportText[language][0]?.choosePeriod}
        </AddedNewReportButton>
        <AddedNewReportButton onClick={() => handleNewReport()}>
          {orderReportText[language][0]?.addNewReport}
        </AddedNewReportButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {orderReportText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainer>
            <Formik
              initialValues={orderReportInitialState}
              onSubmit={handleSubmit}
            >
              <InfoForm autoComplete="off">
                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.chooseDate}
                  </ReportLabel>
                  <Input
                    type="date"
                    name="date"
                    placeholder={orderReportText[language][0]?.chooseDate}
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterNumberOfLeadsByCreation}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_leads_by_creation"
                    placeholder={
                      orderReportText[language][0]?.enterNumberOfLeadsByCreation
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterNumberOfLeadsByApproval}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_leads_by_approval"
                    placeholder={
                      orderReportText[language][0]?.enterNumberOfLeadsByApproval
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterPercentOfordersNotProcessedOnTime
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_orders_not_processed_on_time"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterPercentOfordersNotProcessedOnTime
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterNumberOfApproveByCreation
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_approve_by_creation"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterNumberOfApproveByCreation
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterNumberOfApproveByApproval
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_approve_by_approval"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterNumberOfApproveByApproval
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterNumberOfProcessingByCreation
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_proccessing_by_creation"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterNumberOfProcessingByCreation
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterNumberOfProcessingByApproval
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_proccessing_by_approval"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterNumberOfProcessingByApproval
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterNumberOfTrashByCreation}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_trash_by_creation"
                    placeholder={
                      orderReportText[language][0]?.enterNumberOfTrashByCreation
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterNumberOfTrashByApproval}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_trash_by_approval"
                    placeholder={
                      orderReportText[language][0]?.enterNumberOfTrashByApproval
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterNumberOfRefusalsByCreation
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_refusals_by_creation"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterNumberOfRefusalsByCreation
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterNumberOfRefusalsByApproval
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_refusals_by_approval"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterNumberOfRefusalsByApproval
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterNumberOfOrdersAcceptedPerHourByCreation
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_orders_accepted_per_hour_by_creation"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterNumberOfOrdersAcceptedPerHourByCreation
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterNumberOfOrdersAcceptedPerHourByApproval
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="number_of_orders_accepted_per_hour_by_approval"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterNumberOfOrdersAcceptedPerHourByApproval
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterPercentOfApprovalByOffers
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_approval_by_offers"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterPercentOfApprovalByOffers
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterPercentOfApprovalByOffersWithoutTrash
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_approval_by_offers_without_trash"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterPercentOfApprovalByOffersWithoutTrash
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterPercentOfUpsells}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_upsells"
                    placeholder={
                      orderReportText[language][0]?.enterPercentOfUpsells
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterPercentOfApproveByEmployeeByCreation
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_approve_by_employee_by_creation"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterPercentOfApproveByEmployeeByCreation
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterPercentOfApproveByEmployeeWithoutTrashByCreation
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_approve_by_employee_without_trash_by_creation"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterPercentOfApproveByEmployeeWithoutTrashByCreation
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterPercentOfRefusalsWithoutTrash
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_refusals_without_trash"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterPercentOfRefusalsWithoutTrash
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {
                      orderReportText[language][0]
                        ?.enterPercentOfProcessingWithoutTrash
                    }
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_processing_without_trash"
                    placeholder={
                      orderReportText[language][0]
                        ?.enterPercentOfProcessingWithoutTrash
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterPercentOfTrash}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_trash"
                    placeholder={
                      orderReportText[language][0]?.enterPercentOfTrash
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterPercentOfFreeDelivery}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="percent_of_free_delivery"
                    placeholder={
                      orderReportText[language][0]?.enterPercentOfFreeDelivery
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterAverageCheckByApproval}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="average_check_by_approval"
                    placeholder={
                      orderReportText[language][0]?.enterAverageCheckByApproval
                    }
                  />
                </AddedReportContainer>

                <AddedReportContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterAverageIncreaseInCheck}
                  </ReportLabel>
                  <Input
                    type="number"
                    name="average_increase_in_check"
                    placeholder={
                      orderReportText[language][0]?.enterAverageIncreaseInCheck
                    }
                  />
                </AddedReportContainer>

                <ReportCommentContainer>
                  <ReportLabel>
                    {orderReportText[language][0]?.enterReportComment}
                  </ReportLabel>
                  <ReportTextArea
                    type="number"
                    name="report_comment"
                    as="textarea"
                    placeholder={
                      orderReportText[language][0]?.enterReportComment
                    }
                  />
                </ReportCommentContainer>
                {!isUpdate && (
                  <ButtonContainer>
                    <SaveButton type="submit">
                      {orderReportText[language][0]?.save}
                    </SaveButton>
                    <DeleteReportButton
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {orderReportText[language][0]?.cancel}
                    </DeleteReportButton>
                  </ButtonContainer>
                )}
                {isUpdate && (
                  <ButtonContainer>
                    <SaveButton type="submit">
                      {orderReportText[language][0]?.update}
                    </SaveButton>
                    <DeleteReportButton
                      type="button"
                      onClick={() => handleDelete()}
                    >
                      {orderReportText[language][0]?.delete}
                    </DeleteReportButton>
                  </ButtonContainer>
                )}
              </InfoForm>
            </Formik>
          </ModalContainer>
        </Modal>
      )}
      <TableContainer>
        <ReportTable>
          <Thead>
            <ReportHeadTr>
              <ReportTh onClick={() => handleSortChange('date')}>
                {orderReportText[language][0]?.date} {renderSortIcon('date')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_leads_by_creation')}
              >
                {orderReportText[language][0]?.numberOfLeadsByCreation}{' '}
                {renderSortIcon('number_of_leads_by_creation')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_leads_by_approval')}
              >
                {orderReportText[language][0]?.numberOfLeadsByApproval}{' '}
                {renderSortIcon('number_of_leads_by_approval')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('percent_of_orders_not_processed_on_time')
                }
              >
                {
                  orderReportText[language][0]
                    ?.percentOfOrdersNotProcessedOnTime
                }{' '}
                {renderSortIcon('percent_of_orders_not_processed_on_time')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('number_of_approve_by_creation')
                }
              >
                {orderReportText[language][0]?.numberOfApproveByCreation}{' '}
                {renderSortIcon('number_of_approve_by_creation')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('number_of_approve_by_approval')
                }
              >
                {orderReportText[language][0]?.numberOfApproveByApproval}{' '}
                {renderSortIcon('number_of_approve_by_approval')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('number_of_proccessing_by_creation')
                }
              >
                {orderReportText[language][0]?.numberOfProcessingByCreation}{' '}
                {renderSortIcon('number_of_proccessing_by_creation')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('number_of_proccessing_by_approval')
                }
              >
                {orderReportText[language][0]?.numberOfProcessingByApproval}{' '}
                {renderSortIcon('number_of_proccessing_by_approval')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_trash_by_creation')}
              >
                {orderReportText[language][0]?.numberOfTrashByCreation}{' '}
                {renderSortIcon('number_of_trash_by_creation')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('number_of_trash_by_approval')}
              >
                {orderReportText[language][0]?.numberOfTrashByApproval}{' '}
                {renderSortIcon('number_of_trash_by_approval')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('number_of_refusals_by_creation')
                }
              >
                {orderReportText[language][0]?.numberOfRefusalsByCreation}{' '}
                {renderSortIcon('number_of_refusals_by_creation')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('number_of_refusals_by_approval')
                }
              >
                {orderReportText[language][0]?.numberOfRefusalsByApproval}{' '}
                {renderSortIcon('number_of_refusals_by_approval')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange(
                    'number_of_orders_accepted_per_hour_by_creation',
                  )
                }
              >
                {
                  orderReportText[language][0]
                    ?.numberOfOrdersAcceptedPerHourByCreation
                }{' '}
                {renderSortIcon(
                  'number_of_orders_accepted_per_hour_by_creation',
                )}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange(
                    'number_of_orders_accepted_per_hour_by_approval',
                  )
                }
              >
                {
                  orderReportText[language][0]
                    ?.numberOfOrdersAcceptedPerHourByApproval
                }{' '}
                {renderSortIcon(
                  'number_of_orders_accepted_per_hour_by_approval',
                )}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('percent_of_approval_by_offers')
                }
              >
                {orderReportText[language][0]?.percentOfApprovalByOffers}{' '}
                {renderSortIcon('percent_of_approval_by_offers')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange(
                    'percent_of_approval_by_offers_without_trash',
                  )
                }
              >
                {
                  orderReportText[language][0]
                    ?.percentOfApprovalByOffersWithoutTrash
                }{' '}
                {renderSortIcon('percent_of_approval_by_offers_without_trash')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('percent_of_upsells')}>
                {orderReportText[language][0]?.percentOfUpsells}{' '}
                {renderSortIcon('percent_of_upsells')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('percent_of_approve_by_employee_by_creation')
                }
              >
                {
                  orderReportText[language][0]
                    ?.percentOfApproveByEmployeeByCreation
                }{' '}
                {renderSortIcon('percent_of_approve_by_employee_by_creation')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange(
                    'percent_of_approve_by_employee_without_trash_by_creation',
                  )
                }
              >
                {
                  orderReportText[language][0]
                    ?.percentOfApproveByEmployeeWithoutTrashByCreation
                }{' '}
                {renderSortIcon(
                  'percent_of_approve_by_employee_without_trash_by_creation',
                )}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('percent_of_refusals_without_trash')
                }
              >
                {orderReportText[language][0]?.percentOfRefusalsWithoutTrash}{' '}
                {renderSortIcon('percent_of_refusals_without_trash')}
              </ReportTh>
              <ReportTh
                onClick={() =>
                  handleSortChange('percent_of_processing_without_trash')
                }
              >
                {orderReportText[language][0]?.percentOfProcessingWithoutTrash}{' '}
                {renderSortIcon('percent_of_processing_without_trash')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('percent_of_trash')}>
                {orderReportText[language][0]?.percentOfTrash}{' '}
                {renderSortIcon('percent_of_trash')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('percent_of_free_delivery')}
              >
                {orderReportText[language][0]?.percentOfFreeDelivery}{' '}
                {renderSortIcon('percent_of_free_delivery')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('average_check_by_approval')}
              >
                {orderReportText[language][0]?.averageCheckByApproval}{' '}
                {renderSortIcon('average_check_by_approval')}
              </ReportTh>
              <ReportTh
                onClick={() => handleSortChange('average_increase_in_check')}
              >
                {orderReportText[language][0]?.averageIncreaseInCheck}{' '}
                {renderSortIcon('average_increase_in_check')}
              </ReportTh>
              <ReportTh onClick={() => handleSortChange('report_comment')}>
                {orderReportText[language][0]?.reportComment}{' '}
                {renderSortIcon('report_comment')}
              </ReportTh>
            </ReportHeadTr>
          </Thead>
          <tbody>
            {data?.length > 0 ? (
              <React.Fragment>
                {data?.map(item => {
                  return (
                    <ReportTr key={item.id} onClick={() => handleClick(item)}>
                      <ReportTd>
                        {formattedDateToDayMonthYear(item.date)}
                      </ReportTd>
                      <ReportTd>{item.number_of_leads_by_creation}</ReportTd>
                      <ReportTd>{item.number_of_leads_by_approval}</ReportTd>
                      <ReportTd>
                        {item.percent_of_orders_not_processed_on_time} %
                      </ReportTd>
                      <ReportTd>{item.number_of_approve_by_creation}</ReportTd>
                      <ReportTd>{item.number_of_approve_by_approval}</ReportTd>
                      <ReportTd>
                        {item.number_of_proccessing_by_creation}
                      </ReportTd>
                      <ReportTd>
                        {item.number_of_proccessing_by_approval}
                      </ReportTd>
                      <ReportTd>{item.number_of_trash_by_creation}</ReportTd>
                      <ReportTd>{item.number_of_trash_by_approval}</ReportTd>
                      <ReportTd>{item.number_of_refusals_by_creation}</ReportTd>
                      <ReportTd>{item.number_of_refusals_by_approval}</ReportTd>
                      <ReportTd>
                        {item.number_of_orders_accepted_per_hour_by_creation} %
                      </ReportTd>
                      <ReportTd>
                        {item.number_of_orders_accepted_per_hour_by_approval}
                      </ReportTd>
                      <ReportTd>
                        {item.percent_of_approval_by_offers} %
                      </ReportTd>
                      <ReportTd>
                        {item.percent_of_approval_by_offers_without_trash} %
                      </ReportTd>
                      <ReportTd>{item.percent_of_upsells} %</ReportTd>
                      <ReportTd>
                        {item.percent_of_approve_by_employee_by_creation} %
                      </ReportTd>
                      <ReportTd>
                        {
                          item.percent_of_approve_by_employee_without_trash_by_creation
                        }{' '}
                        %
                      </ReportTd>
                      <ReportTd>
                        {item.percent_of_refusals_without_trash} %
                      </ReportTd>
                      <ReportTd>
                        {item.percent_of_processing_without_trash} %
                      </ReportTd>
                      <ReportTd>{item.percent_of_trash} %</ReportTd>
                      <ReportTd>{item.percent_of_free_delivery} %</ReportTd>
                      <ReportTd>{item.average_check_by_approval}</ReportTd>
                      <ReportTd>{item.average_increase_in_check}</ReportTd>
                      <ReportTd>{item.report_comment}</ReportTd>
                    </ReportTr>
                  );
                })}
              </React.Fragment>
            ) : (
              <ReportTr>
                <ReportTd
                  colSpan={13}
                  style={{
                    width: '2000px',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {orderReportText[language][0]?.notDataOrError}
                </ReportTd>
              </ReportTr>
            )}
          </tbody>
        </ReportTable>
      </TableContainer>
    </Main>
  );
};
