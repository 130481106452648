import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const fetchOrderStatus = async () => {
  try {
    const response = await makeRequestSQL.get('/wiretapping/order-statuses');
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createCheckList = async data => {
  try {
    const response = await makeRequestSQL.post('/wiretapping/check-list', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchCheckList = async ({
  sortBy = 'createdAt',
  order = 'DESC',
  startDate,
  endDate,
  employee_create_full_name,
  employee_full_name,
  searchQuery,
  currentPage ,
  pageLimit ,
  order_status_id,
  fines_status_id,
  only_fines_for_employee,
  id
}) => {
  try {
    const response = await makeRequestSQL.get('/wiretapping/checklist', {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
        employee_create_full_name,
        employee_full_name,
        search: searchQuery,
        page: currentPage,
        limit: pageLimit,
        order_status_id,
        fines_status_id,
        only_fines_for_employee,
        id
      },
    });

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateCheckList = async data => {
  try {
    const response = await makeRequestSQL.put('/wiretapping/check-list', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchWiretappingStatistics = async ({searchBy, sortBy, order, startDate, endDate}) => {
  try {
    const response = await makeRequestSQL.get('/wiretapping/statistics', {
      params: {
        searchBy, sortBy, order, startDate, endDate
      }
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchWiretappingEverydayStatistics = async ({employee_create_full_name, sortBy, order, startDate, endDate}) => {
  try {
    const response = await makeRequestSQL.get('/wiretapping/everyday-statistics', {
      params: {
        employee_create_full_name, sortBy, order, startDate, endDate
      }
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchWiretappingCategoryStatistics = async ({ sortBy, order, startDate, endDate}) => {
  try {
    const response = await makeRequestSQL.get('/wiretapping/category-statistics', {
      params: {
  sortBy, order, startDate, endDate
      }
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
