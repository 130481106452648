import styled from '@emotion/styled';

export const Button = styled.button`
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  font-size: 0.8203125rem;
  line-height: 1.625;
  border-radius: 10rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #f5f7fa;
  background-color: #5cbcf6;
  border-color: #5cbcf6;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  display: block;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    background-color: #0b6aa2;
    border: 2px solid #5cbcf6;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const LoginFormButton = styled(Button)`
  width: 100%;
`;
