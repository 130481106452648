import { MainContainer } from './Main.styled';
import PropTypes from 'prop-types';
import { NavContext } from '../Context/NavContext'
import { useContext } from 'react';

export const Main = ({ children }) => {
const { isNavVisible } = useContext(NavContext);
    return (
      <MainContainer
        isNavVisible={isNavVisible}
      >
        {children}
      </MainContainer>
    );
};

 Main.defaultProps = {
   children: null,
};
 
 Main.propTypes = {
   children: PropTypes.node,
 };