import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchKnowledgeBase = async ({
  sortBy,
  order,
  searchQuery,
  currentPage,
  pageLimit,
  id
}) => {
  try {
    const response = await makeRequestSQL.get('/knowledge-base/', {
      params: {
        sortBy,
        order,
        search: searchQuery,
        page: currentPage,
        limit: pageLimit,
        id
      },
    });
    const decryptedData = await  decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateKnowledgeBase = async data => {
  try {
    const response = await makeRequestSQL.put('/knowledge-base/', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createKnowledgeBase = async data => {
  try {
    const response = await makeRequestSQL.post('/knowledge-base/', data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteKnowledgeBase= async id => {
  try {
    const response = await makeRequestSQL.delete('/knowledge-base/', {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
