import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Main } from '../../components/Main/Main';
import { useContext, useState } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { SeeChangesEverydayStatistics } from '../../components/EverydayStatistics/SeeChangesEverydayStatistics/SeeChangesEverydayStatistics';
import { SeeChangesWiretappingEverydayStatistics } from '../../components/Wiretapping/WiretappingEverydayStatistics/SeeChangesWiretappingEverydayStatistics';
import { SeeChangesClaimsReport } from '../../components/ClaimsReport/SeeChangesClaimsReport/SeeChangesClaimsReport';
import { Outlet } from 'react-router-dom';

export const Home = () => {
  const { currentUser, language } = useContext(UserContext);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
  const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);
  const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);
  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  return (
    <div>
      <SharedLayout />
      {currentUser?.access_level === access_level_c && (
        <SeeChangesEverydayStatistics />
      )}
      {currentUser?.access_level === access_level_d && (
        <SeeChangesWiretappingEverydayStatistics />
      )}
      {currentUser?.access_level === access_level_i && (
        <SeeChangesClaimsReport />
      )}
      {currentUser?.access_level !== access_level_c && currentUser?.access_level !== access_level_d && currentUser?.access_level !== access_level_i &&(
        <Main>Нажаль поки що тут нічого немає</Main>
      )}
      <Outlet />
    </div>
  );
};
