export const getGradientColor = (current, required) => {
  let percentage = current / required;

  let red, green, blue;

  // 0-60%: Червоний до Жовтого
  if (percentage <= 0.6) {
    red = 255;
    green = Math.round(255 * (percentage / 0.6));
    blue = 0;
  }
  // 60%-100%: Жовтий до Зеленого
  else if (percentage <= 1) {
    red = Math.round(255 * (1 - (percentage - 0.6) / 0.4));
    green = 255;
    blue = 0;
  }
  // 100%-200%: Зелений до Синього
  else {
    percentage = Math.min(percentage, 2) - 1;
    red = 0;
    green = Math.round(255 * (1 - percentage));
    blue = Math.round(255 * percentage);
  }

  return `rgb(${red}, ${green}, ${blue})`;
}
