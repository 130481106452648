import React, { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import { salaryText } from '../../../services/componentText';
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  Thead,
} from './UserSalary.styled';

import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { fetchSalaryByEmail } from '../../../api/salary';

export const UserSalary = () => {
  const { language } = useContext(UserContext);

  const { data: salaries } = useQuery(['salariesData'], () =>
  fetchSalaryByEmail(),
  );

  return (
    <div>
      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh>{salaryText[language][0]?.date}</ScheduleTh>
              <ScheduleTh>{salaryText[language][0]?.hoursInVoip}</ScheduleTh>
              <ScheduleTh>{salaryText[language][0]?.bid}</ScheduleTh>
              <ScheduleTh>{salaryText[language][0]?.oneExtraSalary}</ScheduleTh>
              <ScheduleTh>
                {salaryText[language][0]?.totalSalaryForExtra}
              </ScheduleTh>
              <ScheduleTh>{salaryText[language][0]?.prize}</ScheduleTh>
              <ScheduleTh>{salaryText[language][0]?.fines}</ScheduleTh>
              <ScheduleTh>{salaryText[language][0]?.finesComment}</ScheduleTh>
              <ScheduleTh>
                {salaryText[language][0]?.paymentForGoods}
              </ScheduleTh>
              <ScheduleTh>{salaryText[language][0]?.toPayOff}</ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {salaries?.length > 0 ? (
              <React.Fragment>
                {salaries?.map(salary => {
                  return (
                    <ScheduleTr key={salary.salary_id}>
                      <ScheduleTd>
                        {formattedDateToDayMonthYear(salary.salary_date)}
                      </ScheduleTd>
                      <ScheduleTd>{salary.working_hours}</ScheduleTd>
                      <ScheduleTd>
                        {salary.salary_bid ? salary.salary_bid : 0} грн
                      </ScheduleTd>
                      <ScheduleTd>
                        {salary.salary_in_hour ? salary.salary_in_hour : 0} грн
                      </ScheduleTd>
                      <ScheduleTd>
                        {salary.total_salary_for_extra
                          ? salary.total_salary_for_extra
                          : 0}{' '}
                        грн
                      </ScheduleTd>
                      <ScheduleTd>
                        {salary.prize ? salary.prize : 0} грн
                      </ScheduleTd>
                      <ScheduleTd>
                        {salary.fines ? salary.fines : 0} грн
                      </ScheduleTd>
                      <ScheduleTd>{salary.fines_comment}</ScheduleTd>
                      <ScheduleTd>
                        {salary.payment_for_goods
                          ? salary.payment_for_goods
                          : 0}{' '}
                        грн
                      </ScheduleTd>
                      <ScheduleTd>{salary.to_pay_off} грн</ScheduleTd>
                    </ScheduleTr>
                  );
                })}
              </React.Fragment>
            ) : (
              <ScheduleTr>
                <ScheduleTd colSpan={9} style={{width: '600px'}}>
                  {salaryText[language][0]?.notDataOrError}
                </ScheduleTd>
              </ScheduleTr>
            )}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </div>
  );
};
