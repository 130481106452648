import { SubMenuList, SubMenuItem, NavigationLink } from "./SubMenu.styled";
import { navbarText } from "../../services/componentText";
import { useContext } from "react";
import { UserContext } from "../Context/UserContext";
const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);
const access_level_a = Number(process.env.REACT_APP_A_LEVEL_ACCESS);
const access_level_b = Number(process.env.REACT_APP_B_LEVEL_ACCESS);
const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);
const access_level_h = Number(process.env.REACT_APP_H_LEVEL_ACCESS);
const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);
const access_level_j = Number(process.env.REACT_APP_J_LEVEL_ACCESS);
const access_level_k = Number(process.env.REACT_APP_K_LEVEL_ACCESS);

export const SubMenuOrganizational = () => {
  const { language, currentUser } = useContext(UserContext);
  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  return (
    <SubMenuList>
      <SubMenuItem>
        {(access_level_c === currentUser?.access_level ||
          currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_d ||
          currentUser?.access_level === access_level_i) && (
          <NavigationLink
            to={
              currentUser?.access_level === access_level_e ||
              currentUser?.access_level === access_level_f ||
              currentUser?.access_level === access_level_g ||
              currentUser?.access_level === access_level_h
                ? "/schedule/genereal"
                : "/schedule/me"
            }
          >
            {navbarText[language][0]?.schedule}
          </NavigationLink>
        )}
        {(currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g) && (
            <div>
          <NavigationLink to="/users/users-list">
            {navbarText[language][0]?.users}
          </NavigationLink>
          <NavigationLink to="/users-online">
            {navbarText[language][0]?.usersOnline}
          </NavigationLink>
          </div>
        )}

        {(access_level_c === currentUser?.access_level ||
          currentUser?.access_level === access_level_a ||
          currentUser?.access_level === access_level_b ||
          currentUser?.access_level === access_level_d ||
          currentUser?.access_level === access_level_i) && (
          <NavigationLink to={"/users/current-user"}>
            {navbarText[language][0]?.users}
          </NavigationLink>
        )}
        {(access_level_c === currentUser?.access_level ||
          currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ) && (
          <div>
            <NavigationLink to="/salary">
              {navbarText[language][0]?.salary}
            </NavigationLink>
            <NavigationLink to="/fm">Форс мажори</NavigationLink>
            <NavigationLink to="/fines">
              {navbarText[language][0]?.fines}
            </NavigationLink>
            <NavigationLink to="/find-fail">
              {navbarText[language][0]?.navbarText}
            </NavigationLink>
            <NavigationLink
              to={
                accessLevel
                  ? "/everyday-statistics/users"
                  : "/everyday-statistics/me"
              }
            >
              {navbarText[language][0]?.everydayStatistics}
            </NavigationLink>
            <NavigationLink to="/rating">
              {navbarText[language][0]?.rating}
            </NavigationLink>
            <NavigationLink to="/rating-by-period">
              {navbarText[language][0]?.ratingByPeriod}
            </NavigationLink>
            {/*  <NavigationLink to="/news">
          {navbarText[language][0]?.news}
        </NavigationLink>
        <NavigationLink to="/notice">
          {navbarText[language][0]?.notices}
        </NavigationLink>  */}
          </div>
        )}
        {currentUser?.access_level === access_level_k && (
          <div>
          <NavigationLink  to={"/schedule/me"}
          >
            {navbarText[language][0]?.schedule}
          </NavigationLink>
          <NavigationLink to={"/users/current-user"}>
            {navbarText[language][0]?.users}
          </NavigationLink>
            <NavigationLink to="/fm">Форс мажори</NavigationLink>
            <NavigationLink to="/fines">
              {navbarText[language][0]?.fines}
            </NavigationLink>
            <NavigationLink to="/find-fail">
              {navbarText[language][0]?.navbarText}
            </NavigationLink>
            <NavigationLink
              to={"/everyday-statistics/me"}
            >
              {navbarText[language][0]?.everydayStatistics}
            </NavigationLink>

          </div>
        )}
      </SubMenuItem>
    </SubMenuList>
  );
};

export const SubMenuProducts = () => {
  const { language, currentUser } = useContext(UserContext);
  return (
    <SubMenuList>
      <SubMenuItem>
        {(currentUser?.access_level === access_level_c ||
          currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g || currentUser?.access_level === access_level_j || currentUser?.access_level === access_level_i || currentUser?.access_level === access_level_k) && (
          <div>
            <NavigationLink to="/products/product-list">
              {navbarText[language][0]?.products}
            </NavigationLink>
            <NavigationLink to="/script/list">
              {navbarText[language][0]?.scripts}
            </NavigationLink>
          </div>
        )}
        {currentUser?.access_level === access_level_a && (
          <NavigationLink to="/products/product-list">
            {navbarText[language][0]?.products}
          </NavigationLink>
        )}
        {(currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g) && (
          <div>
            <NavigationLink to="/script-control-panel/list">
              {navbarText[language][0]?.scriptsTemplate}
            </NavigationLink>
          </div>
        )}
        {(currentUser?.access_level === access_level_c ||
          currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_d ||
          currentUser?.access_level === access_level_a ||
          currentUser?.access_level === access_level_h || currentUser?.access_level === access_level_k) && (
          <NavigationLink to="/products-availability">
            {navbarText[language][0]?.productsAvailability}
          </NavigationLink>
        )}
      </SubMenuItem>
    </SubMenuList>
  );
};

export const SubMenuKnowledges = () => {
  const { language, currentUser } = useContext(UserContext);
  return (
    <SubMenuList>
      <SubMenuItem>
        {(currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_c || currentUser?.access_level === access_level_k)  && ![86, 94, 127, 128]?.includes(Number(currentUser?.id_employee))&& (
          <NavigationLink to="/knowledge-base/list">
            {navbarText[language][0]?.knowledgebase}
          </NavigationLink>
        )}
        {/* <NavigationLink to="/education">
          {navbarText[language][0]?.education}
        </NavigationLink> */}
      </SubMenuItem>
    </SubMenuList>
  );
};

export const SubMenuWiretapping = () => {
  const { language, currentUser } = useContext(UserContext);

  return (
    <SubMenuList>
      <SubMenuItem>
        {(currentUser?.access_level === access_level_c ||
          currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_d || currentUser?.access_level === access_level_k) && (
          <div>
            <NavigationLink to="/fines-list">
              {navbarText[language][0]?.finesList}
            </NavigationLink>
          </div>
        )}
        {(currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_d) && (
          <div>
            <NavigationLink to="/wiretapping/check-list">
              {navbarText[language][0]?.checklist}
            </NavigationLink>
            <NavigationLink to="/wiretapping-statistics/fines">
              {navbarText[language][0]?.statistics}
            </NavigationLink>
          </div>
        )}
      </SubMenuItem>
    </SubMenuList>
  );
};

export const SubMenuReport = () => {
  const { language, currentUser } = useContext(UserContext);

  return (
    <SubMenuList>
      <SubMenuItem>
        {(currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_e) && (
          <div>
            <NavigationLink to="/call-report/reports">
              {navbarText[language][0]?.callReport}
            </NavigationLink>
            <NavigationLink to="/all-departments-report/reports">
              {navbarText[language][0]?.allDepartmentReport}
            </NavigationLink>
            <NavigationLink to="/employee-report/reports">
              {navbarText[language][0]?.employeeReport}
            </NavigationLink>
            <NavigationLink to="/employee-efficiency-report/reports">
              {navbarText[language][0]?.employeeEfficiencyReport}
            </NavigationLink>
            <NavigationLink to="/order-report/reports">
              {navbarText[language][0]?.orderReport}
            </NavigationLink>
          </div>
        )}
      </SubMenuItem>
    </SubMenuList>
  );
};

export const SubMenuClaims = () => {
  const { language, currentUser } = useContext(UserContext);

  return (
    <SubMenuList>
      <SubMenuItem>
        {(currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_e) && (
          <div>
            <NavigationLink to="/claims-control-panel/category">
              {navbarText[language][0]?.claimsControlPanel}
            </NavigationLink>
            <NavigationLink to="/claim-statistics/category">
              {navbarText[language][0]?.statistics}
            </NavigationLink>
          </div>
        )}
        {(currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_a ||
          currentUser?.access_level === access_level_i) && (
          <div>
            <NavigationLink to="/claims/claim-list">
              {navbarText[language][0]?.claimsList}
            </NavigationLink>
          </div>
        )}
        {(currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_i) && (
          <div>
            <NavigationLink to="/claims-report/report-list">
              {navbarText[language][0]?.everydayStatistics}
            </NavigationLink>
          </div>
        )}
      </SubMenuItem>
    </SubMenuList>
  );
};
