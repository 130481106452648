import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { LinkOnPageNav } from '../../components/OnPageNav/LinkOnPageNav/LinkOnPageNav';
import { OnPageNavContainer } from '../../components/OnPageNav/OnPageNavContainer/OnPageNavContainer';
import { UserContext } from '../../components/Context/UserContext';
import { userText } from '../../services/componentText';
import { useContext } from 'react';

export const Users = () => {
  const [activeTab, setActiveTab] = useState('users-list');
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const { currentUser, language } = useContext(UserContext);

  return (
    <div>
      <SharedLayout />
      {(currentUser?.access_level === access_level_e ||
        currentUser?.access_level === access_level_f ||
        currentUser?.access_level === access_level_g) && (
        <OnPageNavContainer>
          <LinkOnPageNav
            to="users-list"
            onClick={() => setActiveTab('users-list')}
            className={activeTab === 'users-list' ? 'active' : ''}
          >
            {userText[language][0]?.userList}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="create-user"
            onClick={() => setActiveTab('create-user')}
            className={activeTab === 'create-user' ? 'active' : ''}
          >
            {userText[language][0]?.createNewUser}
          </LinkOnPageNav>
          <LinkOnPageNav
            to="current-user"
            onClick={() => setActiveTab('current-user')}
            className={activeTab === 'current-user' ? 'active' : ''}
          >
            {userText[language][0]?.currentUser}
          </LinkOnPageNav>
        </OnPageNavContainer>
      )}
      <Outlet />
    </div>
  );
};
