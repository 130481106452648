import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  flex-wrap: wrap;
  width: 46%;
  margin-bottom: 5px;
  border: 2px solid #3098d6;
  border-radius: 10px;
`;


export const CheckListInput = styled.input`
  width: 40%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.17;
`;

export const CheckListLabel = styled.label`
  width: 100%;
  text-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
`;

export const AddedCheckListSelect = styled.select`
  appearance: none;
  width: 44%;
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 18px;
  line-height: 1.17;
  text-align: center;

  cursor: pointer;
  background-color: ${props => {
    if (props?.backgroundcolor !== null && (Number(props?.backgroundcolor) === 1)) {
      return '#28a745';
    } 
    else if (props?.backgroundcolor === null || props?.backgroundcolor === 'Без змін'|| props?.backgroundcolor === 'Без изменений') {
      return '#ffc107';
    }
    if (props?.backgroundcolor !== null && (Number(props?.backgroundcolor) === 0)) {
      return '#dc3545'; 
    }
  else {
      return '#f5f7fa'; 
    }
  }};

  color: ${props => {
    const backgroundColorValue = Number(props?.backgroundcolor);

    if (backgroundColorValue === 1 || backgroundColorValue === 0) {
      return '#ffffff';
    } else {
      return '#000000';
    }
  }};
`;

export const AddedCheckListSelectSmall = styled(AddedCheckListSelect)`
width: 120px;
`

export const AddedCheckListOption = styled.option`
  appearance: none;
  width: 50%;
  height: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 18px;
  line-height: 1.17;

  cursor: pointer;
  color: ${props => {
    const backgroundColorValue = Number(props?.backgroundColor);

    if (backgroundColorValue === 1 || backgroundColorValue === 0) {
      return '#ffffff';
    } else {
      return '#000000';
    }
  }};
`;

export const CheckListCommentContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  border: 2px solid #3098d6;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
`;

export const CheckListTextArea = styled.textarea`
  font-size: 16px;
  line-height: 1.17;
  width: 100%;
  height: 150px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  padding: 12px 16px;
  resize: none;
  background-color: transparent;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  &:focus {
    border-color: #5cbcf6;
  }

  &::placeholder {
    border-color: #5cbcf6;
    color: rgba(117, 117, 117, 0.5);
    font-size: $primarySize;
    line-height: 1.14;
    letter-spacing: 0.3;
  }
`;

export const SaveButton = styled(LoginFormButton)`
  min-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

export const DeleteReportButton = styled(SaveButton)`
  margin-left: 10px;
  background-color: #e74c3c;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const UploadFileContainer = styled.div`
position: relative;
width: 400px;
height: 200px;
marginRight: 20px;
marginBottom: 20px;
overflow: hidden;
border: 2px solid #5cbcf6;
border-radius: 10px;
`

export const CheckListImage = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
`

export const UploadAudioFileContainer = styled.div`
position: relative;
width: 100%;
height: 100%;
marginRight: 20px;
marginBottom: 20px;
overflow: hidden;
border: 2px solid #5cbcf6;
border-radius: 10px;
`

export const FaudioIconContainer = styled.div`
width: 20%;
position: relative;
height: 100px;
border-right: 2px solid #5cbcf6;
border-radius: 10px;
`

export const AudioText = styled.p`
margin: 0;
text-align: center;
font-size: 18px;
line-height: 1.17;
font-weight: 700;
`

export const UploadAudioTitle = styled.h2`
margin: 10px 0;
text-align: center;
`

export const CheckListFinesStatusContainer = styled.div`
  width: 40%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.17;
`;

export const CheckListFinesCategoryContainer = styled.div`
font-size: 16px;
line-height: 1.17;
width: 100%;
height: 150px;
border: 1px solid rgba(33, 33, 33, 0.2);
box-sizing: border-box;
border-radius: 15px;
outline: none;
padding: 12px 16px;
resize: none;
background-color: transparent;
transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;