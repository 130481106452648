import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Main } from '../../components/Main/Main';

export const NotFound = () => {
  return (
    <div>
      <SharedLayout />
      <Main>Вибачте, ми не змогли знайти цю сторінку :(</Main>
    </div>
  );
};
