import styled from "@emotion/styled";

export let IconButton = styled.button`
  margin: 0 0 0 10px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  color: #f5f7fa;
  font: inherit;
  background-color: #5cbcf6;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus,
  &.active {
    background-color: #0b6aa2;
    border: 2px solid #5cbcf6;
  }
`;