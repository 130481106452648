import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchFinesList = async () => {
  try {
    const response = await makeRequestSQL.get("/fines-amount/");
    
    const decryptedData = await  decryptMiddleware(response?.data);
    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createFinesAmount = async (data) => {
  try {
    const response = await makeRequestSQL.post("/fines-amount/", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateFinesAmount = async (data) => {
  try {
    const response = await makeRequestSQL.put("/fines-amount/", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteFinesAmount = async (id) => {
  try {
    const response = await makeRequestSQL.delete("/fines-amount/", {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
