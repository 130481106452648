import { WiretappingTd } from './WiretappingAllStatisticsWithoutJSON.styled';
import { wiretappingCheckList } from '../../../services/componentText';
import { UserContext } from '../../Context/UserContext';
import React, { useContext } from 'react';

export const WiretappingField = ({ trueCount, falseCount, orderCount }) => {
  const {language } = useContext(UserContext);

  return (
    <WiretappingTd>
      <span>
      {wiretappingCheckList[language][0]?.announced}
        {trueCount}
      </span>
      <br />
      <span>
        {wiretappingCheckList[language][0]?.notAnonced}
        {falseCount}
      </span>
      <br />
      <span style={{ fontWeight: '700' }}>
        {wiretappingCheckList[language][0]?.percent}
        {((falseCount / orderCount) * 100).toFixed(2)}%
      </span>
      <br />
    </WiretappingTd>
  );
};
