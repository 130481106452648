import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Main } from '../../components/Main/Main';
import { Outlet, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { NavContainer, AddedNewButton } from './ClaimsReport.styled';
import { claimsControlPanelText } from '../../services/componentText';

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);

export const ClaimsReport = () => {
  const { currentUser, language } = useContext(UserContext);
  const location = useLocation();
  const activeTab = location?.pathname;

  const isMainProducts =
    location.pathname === '/claims-report' ||
    location.pathname === '/claims-report/report-list' ||
    location.pathname === '/claims-report/see-changes';

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  return (
    <div>
      <SharedLayout />
      {(accessLevel || currentUser?.access_level === access_level_i) && (
        <Main>
          {isMainProducts && (
            <NavContainer>
              <AddedNewButton
                to="/claims-report/new-report"
                className={
                  activeTab === '/claims-report/new-report' ? 'active' : ''
                }
              >
                {claimsControlPanelText[language][0]?.addNewReport}
              </AddedNewButton>
              <AddedNewButton
                to="/claims-report/report-list"
                className={
                  activeTab === '/claims-report/report-list' ? 'active' : ''
                }
              >
                {claimsControlPanelText[language][0]?.everydayStatistics}
              </AddedNewButton>
              <AddedNewButton
                to="/claims-report/see-changes"
                className={
                  activeTab === '/claims-report/see-changes' ? 'active' : ''
                }
              >
                {claimsControlPanelText[language][0]?.seeChanges}
              </AddedNewButton>
            </NavContainer>
          )}
        </Main>
      )}
      <Outlet />
    </div>
  );
};
