import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;


export const AddedNewButton = styled(Link)`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;