import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Outlet } from 'react-router-dom';
import { NavContainer, LinkButton } from './ScriptsTemplate.styled';
import { UserContext } from '../../components/Context/UserContext';
import React, { useContext, useState} from 'react';
import { scriptText } from '../../services/componentText';
import { Main } from '../../components/Main/Main';
import { useLocation } from 'react-router-dom';

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const ScriptsTemplate = () => {
    const { currentUser, language } = useContext(UserContext);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('/script-control-panel/list');
  
    const isAddNewProductPage = location.pathname === '/script-control-panel/new';
  
    const isMainProducts = location.pathname === '/script-control-panel' || location.pathname === '/script-control-panel/list';
  
    const accessLevel =
      currentUser?.access_level === access_level_g ||
      currentUser?.access_level === access_level_f ||
      currentUser?.access_level === access_level_e ;

      return (
        <div>
          <SharedLayout />
          {isMainProducts && (
                  <Main>
                  {accessLevel && (
                    <NavContainer>
                    {isAddNewProductPage ? null : (
                      <LinkButton to="/script-control-panel/new">
                        {scriptText[language][0]?.addNewTemplate}
                      </LinkButton>
                    )}
                      <LinkButton to="/script-control-panel/list"
                      onClick={() => setActiveTab('/script-control-panel/list')}
                      className={activeTab === '/script-control-panel/list' ? 'active' : ''}>
                        {scriptText[language][0]?.templateList}
                      </LinkButton>

                      <LinkButton to="/script-control-panel/change-category"
                      onClick={() => setActiveTab('/script-control-panel/change-category')}
                      className={activeTab === '/script-control-panel/change-category' ? 'active' : ''}>
                        {scriptText[language][0]?.categoryDisplay}
                      </LinkButton>

                    </NavContainer>
                  )}
                </Main>
          )}
          <Outlet />
        </div>
      );
  };
  