import React, { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import {
  AddedFMSelect,
  AddedFMOption,
  AddedFMContainer,
  SaveButton,
  AddedNewFMButton,
  NavContainer,
} from "./SeeChangesEverydayStatistics.styled";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { Main } from "../../Main/Main";
import { fetchAllEverydayStatistics } from "../../../api/everydayStatistics";
import { everydayStatisticsText } from "../../../services/componentText";
import { fetchUsers } from "../../../api/users";
import { BarChart } from "../../BarChart/BarChart";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk, ru } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/reactDateRange/translateLabel";
import { Modal } from "../../Modal/Modal";

export const SeeChangesEverydayStatistics = () => {
  const { currentUser, language } = useContext(UserContext);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;
  const sortBy = "date";
  const order = "ASC";
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: "selection",
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data: users } = useQuery(["getAllEmployees"], () =>
    fetchUsers({
      sortBy: "full_name",
      order: "ASC",
      isBlocked: "0",
    })
  );

  const { data: everydayStatisticsData, refetch } = useQuery(
    ["everydayStatistics", selectedEmployee],
    () =>
      fetchAllEverydayStatistics({
        email: accessLevel ? selectedEmployee : currentUser?.email,
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      })
  );

  const createNewDateForChart = (value) => {
    const label = "Дані для діаграми";
    const data = {
      labels: everydayStatisticsData?.statisticsData?.map((item) =>
        formattedDateToDayMonthYear(item.date)
      ),
      datasets: [
        {
          label: "Дані для діаграми",
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.4)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: [],
          hoveredLabel: label,
        },
      ],
    };

    switch (value) {
      case "approval_percentage":
        data.datasets[0].data = everydayStatisticsData?.statisticsData?.map(
          (item) => item.approval_percentage
        );
        break;
      case "in_processing_percentage":
        data.datasets[0].data = everydayStatisticsData?.statisticsData?.map(
          (item) => item.in_processing_percentage
        );
        break;
      case "rejected_percentage":
        data.datasets[0].data = everydayStatisticsData?.statisticsData?.map(
          (item) => item.rejected_percentage
        );
        break;
      case "trash_percentage":
        data.datasets[0].data = everydayStatisticsData?.statisticsData?.map(
          (item) => item.trash_percentage
        );
        break;
      case "netto_income":
        data.datasets[0].data = everydayStatisticsData?.statisticsData?.map(
          (item) => item.netto_income
        );
        break;
      case "check_by_approv":
        data.datasets[0].data = everydayStatisticsData?.statisticsData?.map(
          (item) => item.check_by_approv
        );
        break;
      default:
        data.datasets[0].data = everydayStatisticsData?.statisticsData?.map(
          (item) => item.total_order
        );
    }

    return data;
  };

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, language === "russian" ? "ru" : "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, language === "russian" ? "ru" : "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
          {everydayStatisticsText[language][0]?.choosePeriod}
        </AddedNewFMButton>
        {accessLevel && (
          <AddedFMContainer style={{ marginLeft: "20px" }}>
            <label>
              {everydayStatisticsText[language][0]?.chooseEmployeeOne}
            </label>
            <AddedFMSelect
              name="selectedUser"
              value={selectedEmployee?.full_name}
              onChange={(e) => setSelectedEmployee(e.target.value)}
            >
              <AddedFMOption value="">
                {everydayStatisticsText[language][0]?.chooseEmployeeTwo}
              </AddedFMOption>
              {users?.usersData?.map(user => (
              (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) && (
              <AddedFMOption key={user.id_employee} value={user?.email}>
                {user.full_name}
              </AddedFMOption>
              )
            ))}
            </AddedFMSelect>
          </AddedFMContainer>
        )}
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === "russian" ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <SaveButton onClick={() => handleDate()}>
            {everydayStatisticsText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <BarChart
          title={"Відсоток апруву"}
          data={createNewDateForChart("approval_percentage")}
        />
        <BarChart
          title={"Відсоток в обробці"}
          data={createNewDateForChart("in_processing_percentage")}
        />
        <BarChart
          title={"Відсоток відмін"}
          data={createNewDateForChart("rejected_percentage")}
        />
        <BarChart
          title={"Відсоток трешу"}
          data={createNewDateForChart("trash_percentage")}
        />
        <BarChart
          title={"Відсоток маржі"}
          data={createNewDateForChart("netto_income")}
        />
        <BarChart
          title={"Чек по апруву"}
          data={createNewDateForChart("check_by_approv")}
        />
      </div>
    </Main>
  );
};
