import { toast } from 'react-toastify';

export const mergeData = (dataOne, dataTwo) => {
try {
      let merged = { ...dataOne };

      for (const key in dataTwo) {
        if (merged[key] && typeof merged[key] === 'object') {
          for (const subKey in dataTwo[key]) {
            if (merged[key][subKey]) {
              merged[key][subKey] += dataTwo[key][subKey];
            } else {
              merged[key][subKey] = dataTwo[key][subKey];
            }
          }
        } else {
          merged[key] = dataTwo[key];
        }
      }

      return merged;
} catch (error) {
    toast.error('Error on mergeData');
}
};
