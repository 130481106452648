import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Main } from '../../Main/Main';
import { UserContext } from '../../Context/UserContext';
import { formatDate } from '../../../helpers/dateAndTime/formatDate';
import { ClipLoader } from 'react-spinners';
import {
  UsersTable,
  UsersTr,
  UsersTh,
  UsersTd,
  BlockedButton,
  BlockedButtonRed,
  SearchContainer,
  SearchInput,
  SearchButton,
  IsBlockedSelect,
  IsBlockedOption,
  IsBlockedContainer,
  UserFaMinusCircle,
  UserFaTimesCircle
} from './UserList.styled';
import { AiOutlineSearch } from 'react-icons/ai';
import { Modal } from '../../Modal/Modal';
import { OneUser } from '../OneUser/OneUser';
import { userText } from '../../../services/componentText';
import { Pagination } from '../../Pagination/Pagination';
import { PageLimitSelector } from '../../PageLimitSelector/PageLimitSelector';
import { fetchUsers, updateUser } from '../../../api/users';

export const UserList = () => {
  const { language, currentUser } = useContext(UserContext);
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const [inputValue, setInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('id_employee');
  const [order, setOrder] = useState('ASC');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isBlocked, setIsBlocked] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(1);

  const { data, error, isLoading, isSuccess, refetch} = useQuery(["getAllEmployees", {searchQuery,
    sortBy,
    order,
    isBlocked,
    currentPage,
    pageLimit},], () =>
  fetchUsers({
    search: searchQuery,
    sortBy,
    order,
    isBlocked,
    page: currentPage,
    limit: pageLimit,
  })
);


  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleSearch = event => {
    event?.preventDefault();
    setSearchQuery(inputValue);
    refetch();
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleBlockUser = async (userId, isBlocked, event) => {
    event.stopPropagation();
    const data = {isBlocked: !isBlocked}

    const response = await updateUser({userId, data})
    if (response.status === 200) {
      refetch();
    }
  };
  const handleSortChange = field => {
    setSortBy(field);
    setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    refetch();
  };
  
  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const handleUserClick = user => {
    setSelectedUser(user);
  };

    useEffect(() => {
      if (data) {
        setTotalPages(data?.totalPages);
      }
    }, [data, pageLimit]);
  
      const handlePageChange = selectedPage => {
        setCurrentPage(selectedPage);
      };

      const handlePageLimitChange = newPageLimit => {
        setPageLimit(newPageLimit);
        setCurrentPage(1);
      };

      const getIcon = value => {
        if (value) {
          return <UserFaMinusCircle fill="green" />;
        }  else {
          return <UserFaTimesCircle fill="red" />;
        }
      };

  return (
    <div>
      {(currentUser?.access_level === access_level_e ||
        currentUser?.access_level === access_level_f ||
        currentUser?.access_level === access_level_g) && (
        <div>
          {selectedUser && (
            <Modal
              closeModal={() => setSelectedUser(null)}
              style={{ width: '300px' }}
            >
              <OneUser userData={selectedUser} />
            </Modal>
          )}
          <Main>
            {isLoading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'top',
                  height: '5vh',
                }}
              >
                <ClipLoader color="#3098d6" loading={isLoading} size={30} />
              </div>
            )}
            <SearchContainer onSubmit={handleSearch}>
              <SearchInput
                type="text"
                placeholder={userText[language][0]?.search}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
              />
              <SearchButton type="submit" onClick={handleSearch}>
                <AiOutlineSearch size="24px" />
              </SearchButton>
            </SearchContainer>
            <IsBlockedContainer>
              <label>{userText[language][0]?.chooseEmployeeStatus} </label>
              <IsBlockedSelect
                value={isBlocked}
                onChange={e => setIsBlocked(e.target.value)}
              >
                <IsBlockedOption key={0} value="0">
                  {userText[language][0]?.notBlocked}
                </IsBlockedOption>
                <IsBlockedOption key={1} value="1">
                  {userText[language][0]?.blocked}
                </IsBlockedOption>
              </IsBlockedSelect>
              <PageLimitSelector
                pageLimit={pageLimit}
                onChange={handlePageLimitChange}
              />
            </IsBlockedContainer>
            <UsersTable border="1">
              <thead>
                <UsersTr>
                  <UsersTh onClick={() => handleSortChange('id_employee')}>
                    № {renderSortIcon('id_employee')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('email')}>
                    {userText[language][0]?.userListEmail}{' '}
                    {renderSortIcon('email')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('full_name')}>
                    {userText[language][0]?.userListFullName}{' '}
                    {renderSortIcon('full_name')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('phone_number')}>
                    {userText[language][0]?.phoneNumber}
                    {renderSortIcon('phone_number')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('telegram')}>
                    {userText[language][0]?.userListTelegram}
                    {renderSortIcon('telegram')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('skype')}>
                    {userText[language][0]?.skype} {renderSortIcon('skype')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('id_job_title')}>
                    {userText[language][0]?.userListJobTitle}
                    {renderSortIcon('id_job_title')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('id_groupe')}>
                    {userText[language][0]?.groupe}{' '}
                    {renderSortIcon('id_groupe')}
                  </UsersTh>
                  <UsersTh>{userText[language][0]?.card} </UsersTh>
                  <UsersTh onClick={() => handleSortChange('chat_id')}>
                    {userText[language][0]?.botIntegration}
                    {renderSortIcon('chat_id')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('country')}>
                    {userText[language][0]?.country} {renderSortIcon('country')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('city')}>
                    {userText[language][0]?.city} {renderSortIcon('city')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('address')}>
                    {userText[language][0]?.address} {renderSortIcon('address')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('birthday')}>
                    {userText[language][0]?.birthday}{' '}
                    {renderSortIcon('birthday')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('language')}>
                    {userText[language][0]?.language}{' '}
                    {renderSortIcon('language')}
                  </UsersTh>
                  <UsersTh onClick={() => handleSortChange('isBlocked')}>
                    {userText[language][0]?.status}
                    {renderSortIcon('isBlocked')}
                  </UsersTh>
                </UsersTr>
              </thead>
              {error && <div>{userText[language][0]?.usersListError}</div>}
              {isSuccess && (!data || data?.usersData?.length === 0) ? (
                <div>{userText[language][0]?.usersListNotFound}</div>
              ) : (
                <tbody>
                  {data?.usersData?.map(user => (
                    <UsersTr
                      key={user?.id_employee}
                      onClick={() => handleUserClick(user)}
                    >
                      <UsersTd>{user.id_employee}</UsersTd>
                      <UsersTd>{user.email}</UsersTd>
                      <UsersTd>{user.full_name}</UsersTd>
                      <UsersTd>{user.phone_number}</UsersTd>
                      <UsersTd>{user.telegram}</UsersTd>
                      <UsersTd>{user.skype}</UsersTd>
                      <UsersTd>
                        {language === 'russian'
                          ? user.job_title_ru
                          : user.job_title_ua}
                      </UsersTd>
                      <UsersTd>
                        {language === 'russian'
                          ? user.groupe_name_ru
                          : user.groupe_name_ua}
                      </UsersTd>
                      <UsersTd>{user.card} </UsersTd>
                      <UsersTd>{getIcon(user.chat_id)} </UsersTd>
                      <UsersTd>{user.country}</UsersTd>
                      <UsersTd>{user.city}</UsersTd>
                      <UsersTd>{user.address}</UsersTd>
                      <UsersTd>{formatDate(user.birthday)}</UsersTd>
                      <UsersTd>{user.language}</UsersTd>
                      {currentUser.access_level > user?.access_level && (
                        <UsersTd>
                          {user.isBlocked ? (
                            <BlockedButton
                              onClick={event =>
                                handleBlockUser(
                                  user.id_employee,
                                  user.isBlocked,
                                  event,
                                )
                              }
                            >
                              {userText[language][0]?.unblock}
                            </BlockedButton>
                          ) : (
                            <BlockedButtonRed
                              onClick={event =>
                                handleBlockUser(
                                  user.id_employee,
                                  user.isBlocked,
                                  event,
                                )
                              }
                            >
                              {userText[language][0]?.block}
                            </BlockedButtonRed>
                          )}
                        </UsersTd>
                      )}
                    </UsersTr>
                  ))}
                </tbody>
              )}
              {!isLoading && !isSuccess && (
                <div>{userText[language][0]?.usersListLoadingError}</div>
              )}
            </UsersTable>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Main>
        </div>
      )}
      {currentUser?.access_level < 5 && (
        <Main>{userText[language][0]?.notFound}</Main>
      )}
    </div>
  );
};
