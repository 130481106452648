import { Main } from '../../Main/Main';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  SaveButton,
  AddedNewFMButton,
  NavContainer,
} from './SeeChangesOrderReport.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { fetchOrderReport } from '../../../api/report';
import { orderReportText } from '../../../services/componentText';
import { BarChart } from '../../BarChart/BarChart';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { Modal } from '../../Modal/Modal';

export const SeeChangesOrderReport = () => {
  const { currentUser, language } = useContext(UserContext);
  const sortBy = 'date';
  const order = 'ASC';
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data: orderReportData, refetch } = useQuery(
    [
      'orderReportData',
      sortBy,
      order,
      formattedStartDateRequest,
      formattedEndDateRequest,
    ],
    () =>
      fetchOrderReport({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const createNewDateForChart = value => {
    const label = 'Дані для діаграми';
    const data = {
      labels: orderReportData?.map(item =>
        formattedDateToDayMonthYear(item.date),
      ),
      datasets: [
        {
          label: 'Дані для діаграми',
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: 'rgba(75,192,192,1)',
          data: [],
          hoveredLabel: label,
        },
      ],
    };

    switch (value) {
      case 'number_of_leads_by_creation':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_leads_by_creation,
        );
        break;
      case 'number_of_leads_by_approval':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_leads_by_approval,
        );
        break;
      case 'percent_of_orders_not_processed_on_time':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_orders_not_processed_on_time,
        );
        break;
      case 'number_of_approve_by_creation':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_approve_by_creation,
        );
        break;
      case 'number_of_approve_by_approval':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_approve_by_approval,
        );
        break;
      case 'number_of_proccessing_by_creation':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_proccessing_by_creation,
        );
        break;
      case 'number_of_proccessing_by_approval':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_proccessing_by_approval,
        );
        break;
      case 'number_of_trash_by_creation':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_trash_by_approval,
        );
        break;
      case 'number_of_refusals_by_creation':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_refusals_by_approval,
        );
        break;
      case 'number_of_orders_accepted_per_hour_by_creation':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_orders_accepted_per_hour_by_creation,
        );
        break;
      case 'number_of_orders_accepted_per_hour_by_approval':
        data.datasets[0].data = orderReportData?.map(
          item => item.number_of_orders_accepted_per_hour_by_approval,
        );
        break;
      case 'percent_of_approval_by_offers':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_approval_by_offers,
        );
        break;
      case 'percent_of_approval_by_offers_without_trash':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_approval_by_offers_without_trash,
        );
        break;
      case 'percent_of_upsells':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_upsells,
        );
        break;
      case 'percent_of_approve_by_employee_by_creation':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_approve_by_employee_by_creation,
        );
        break;
      case 'percent_of_approve_by_employee_without_trash_by_creation':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_approve_by_employee_without_trash_by_creation,
        );
        break;
      case 'percent_of_refusals_without_trash':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_refusals_without_trash,
        );
        break;
      case 'percent_of_processing_without_trash':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_processing_without_trash,
        );
        break;

      case 'percent_of_trash':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_trash,
        );
        break;
      case 'percent_of_free_delivery':
        data.datasets[0].data = orderReportData?.map(
          item => item.percent_of_free_delivery,
        );
        break;
      case 'average_check_by_approval':
        data.datasets[0].data = orderReportData?.map(
          item => item.average_check_by_approval,
        );
        break;
      default:
        data.datasets[0].data = orderReportData?.map(
          item => item.average_increase_in_check,
        );
    }

    return data;
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
          {orderReportText[language][0]?.choosePeriod}
        </AddedNewFMButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {orderReportText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <BarChart
          title={orderReportText[language][0]?.numberOfLeadsByCreation}
          data={createNewDateForChart('number_of_leads_by_creation')}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfLeadsByApproval}
          data={createNewDateForChart('number_of_leads_by_approval')}
        />
        <BarChart
          title={
            orderReportText[language][0]?.percentOfOrdersNotProcessedOnTime
          }
          data={createNewDateForChart(
            'percent_of_orders_not_processed_on_time',
          )}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfApproveByCreation}
          data={createNewDateForChart('number_of_approve_by_creation')}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfApproveByApproval}
          data={createNewDateForChart('number_of_approve_by_approval')}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfProcessingByCreation}
          data={createNewDateForChart('number_of_proccessing_by_creation')}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfProcessingByApproval}
          data={createNewDateForChart('number_of_proccessing_by_approval')}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfTrashByCreation}
          data={createNewDateForChart('number_of_trash_by_creation')}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfTrashByApproval}
          data={createNewDateForChart('number_of_trash_by_approval')}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfRefusalsByCreation}
          data={createNewDateForChart('number_of_refusals_by_creation')}
        />
        <BarChart
          title={orderReportText[language][0]?.numberOfRefusalsByApproval}
          data={createNewDateForChart('number_of_refusals_by_approval')}
        />
        <BarChart
          title={
            orderReportText[language][0]
              ?.numberOfOrdersAcceptedPerHourByCreation
          }
          data={createNewDateForChart(
            'number_of_orders_accepted_per_hour_by_creation',
          )}
        />
        <BarChart
          title={
            orderReportText[language][0]
              ?.numberOfOrdersAcceptedPerHourByApproval
          }
          data={createNewDateForChart(
            'number_of_orders_accepted_per_hour_by_approval',
          )}
        />
        <BarChart
          title={orderReportText[language][0]?.percentOfApprovalByOffers}
          data={createNewDateForChart('percent_of_approval_by_offers')}
        />
        <BarChart
          title={
            orderReportText[language][0]?.percentOfApprovalByOffersWithoutTrash
          }
          data={createNewDateForChart(
            'percent_of_approval_by_offers_without_trash',
          )}
        />
        <BarChart
          title={orderReportText[language][0]?.percentOfUpsells}
          data={createNewDateForChart('percent_of_upsells')}
        />
        <BarChart
          title={
            orderReportText[language][0]?.percentOfApproveByEmployeeByCreation
          }
          data={createNewDateForChart(
            'percent_of_approve_by_employee_by_creation',
          )}
        />
        <BarChart
          title={
            orderReportText[language][0]
              ?.percentOfApproveByEmployeeWithoutTrashByCreation
          }
          data={createNewDateForChart(
            'percent_of_approve_by_employee_without_trash_by_creation',
          )}
        />
        <BarChart
          title={orderReportText[language][0]?.percentOfRefusalsWithoutTrash}
          data={createNewDateForChart('percent_of_refusals_without_trash')}
        />
        <BarChart
          title={orderReportText[language][0]?.percentOfProcessingWithoutTrash}
          data={createNewDateForChart('percent_of_processing_without_trash')}
        />
        <BarChart
          title={orderReportText[language][0]?.percentOfTrash}
          data={createNewDateForChart('percent_of_trash')}
        />
        <BarChart
          title={orderReportText[language][0]?.percentOfFreeDelivery}
          data={createNewDateForChart('percent_of_free_delivery')}
        />
        <BarChart
          title={orderReportText[language][0]?.averageCheckByApproval}
          data={createNewDateForChart('average_check_by_approval')}
        />
        <BarChart
          title={orderReportText[language][0]?.averageIncreaseInCheck}
          data={createNewDateForChart('average_increase_in_check')}
        />
      </div>
    </Main>
  );
};
