import { Link } from 'react-router-dom';
import styled from '@emotion/styled';


export const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
  color: #3098d6;
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 15px;
  text-transform: uppercase;

  :hover {
    color: #0b6aa2;
  }
`;