
export const calculateHoursDifference =  (start, end) => {
  try {
      const [startHour, startMinute] = start.split(':').map(Number);
      const [endHour, endMinute] = end.split(':').map(Number);

      let hourDifference = endHour - startHour;
      let minuteDifference = endMinute - startMinute;

      if (minuteDifference < 0) {
        hourDifference -= 1;
        minuteDifference += 60;
    }
    
    const numberTime = hourDifference + minuteDifference / 60;

      return numberTime;
  } catch (error) {
    console.log('error on calculateHoursDifference');
  }
}
