import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';
import { Form, Field } from 'formik';

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const Table = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const Tr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }
`;

export const HeadTr = styled(Tr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const Td = styled.td`
  width: 252px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color:  '#ffffff'};


  &:last-of-type {
    border-right: none;
  }
`;

export const Th = styled.th`
  width: 230px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &::nth-of-type(1),
  &::nth-of-type(2) {
    position: sticky;
    left: 0;
    z-index: 1;
  }
`;

export const AvailabilityText = styled.p`
  margin: 5px 0 0 0;
`;
export const AvailabilityNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchContainer = styled.form`
  width: 50%;
  position: relative;
  display: flex;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 3px solid #3098d6;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;

  &:focus {
    color: #00b4cc;
  }
`;

export const SearchButton = styled.button`
  width: 40px;
  height: 36px;
  border: 1px solid #00b4cc;
  background: #3098d6;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
`;

export const TextAvailability = styled.p`
  background-color: ${props => (props.checkStatus ? 'red' : 'green')};
  color: #ffffff;
  font-weight: 700;
  margin: 0;
`;