import React, { useContext } from 'react';
import { UserContext } from '../Context/UserContext';
import {
  LanguageSelect,
  LanguageOption,
  LanguageContainer,
} from './LanguageSelector.styled';

export const LanguageSelector = ({ language, onChange }) => {
  return (
    <LanguageContainer>
      <LanguageSelect
        id="language"
        value={language}
        onChange={e => onChange(e.target.value)}
      >
        <LanguageOption key="RU" value={true}>
          RU
        </LanguageOption>

        <LanguageOption key="UA" value={false}>
          UA
        </LanguageOption>
      </LanguageSelect>
    </LanguageContainer>
  );
};
