import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Main } from '../../components/Main/Main';
import { Outlet, useLocation  } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { NavContainer, AddedNewButton } from '../Product/Product.styled';
import { scriptText } from '../../services/componentText';

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);
const access_level_a = Number(process.env.REACT_APP_A_LEVEL_ACCESS);
const access_level_k = Number(process.env.REACT_APP_K_LEVEL_ACCESS);

export const Script = () => {
    const { currentUser, language } = useContext(UserContext);
    const location = useLocation();
  
    const isAddNewProductPage = location.pathname === '/script/new';
  
    const isMainProducts = location.pathname === '/script' || location.pathname === '/script/list';
  
    const accessLevel =
      currentUser?.access_level === access_level_g ||
      currentUser?.access_level === access_level_f ||
      currentUser?.access_level === access_level_e || 
      currentUser?.access_level === access_level_i || 
      currentUser?.access_level === access_level_a ||
      currentUser?.access_level === access_level_k;

      return (
        <div>
          <SharedLayout />
          {isMainProducts && (
                  <Main>
                  {accessLevel && (
                    <NavContainer>
                    {isAddNewProductPage ? null : (
                      <AddedNewButton to="/script/new">
                        {scriptText[language][0]?.addNewTemplate}
                      </AddedNewButton>
                    )}
                    </NavContainer>
                  )}
                </Main>
          )}
          <Outlet />
        </div>
      );
}