import { Main } from '../../Main/Main';
import { fetchWiretappingEverydayStatistics } from '../../../api/wiretapping';
import { useQuery } from 'react-query';
import React, { useState, useContext } from 'react';
import {
  AddedSelect,
  AddedOption,
  Thead,
  TableContainer,
  WiretappingTable,
  WiretappingTr,
  WiretappingHeadTr,
  WiretappingTd,
  WiretappingTh,
  SaveButton,
  AddedNewButton,
  NavContainer,
} from './WiretappingEverydayStatistics.styled';
import { wiretappingCheckList } from '../../../services/componentText';
import { UserContext } from '../../Context/UserContext';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from '../../Modal/Modal';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { fetchUsers } from '../../../api/users';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';

const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);

export const WiretappingEverydayStatistics = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [order, setOrder] = useState('DESC');
  const [selectedEmployee, setSelectedEmployee] = useState(
    currentUser?.id_job_title === 4 ? currentUser?.full_name : '',
  );
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data, refetch } = useQuery(
    ['fetchWiretappingEverydayStatistics', selectedEmployee, sortBy, order],
    () =>
      fetchWiretappingEverydayStatistics({
        employee_create_full_name: selectedEmployee,
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

    const { data: usersData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0"})
  );

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      {accessLevel || currentUser?.access_level === access_level_d ? (
        <div>
          <NavContainer>
            <div
              style={{ marginRight: '10px' }}
            >{`${formattedStartDate} - ${formattedEndDate}`}</div>
            <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
              {wiretappingCheckList[language][0]?.choosePeriod}
            </AddedNewButton>
            {accessLevel && (
              <div style={{ marginLeft: '10px' }}>
                <label>
                  {wiretappingCheckList[language][0]?.chooseManagerOne}
                  <AddedSelect
                    value={selectedEmployee || ''}
                    onChange={e => setSelectedEmployee(e.target.value)}
                    style={{ backgroundColor: '#f5f7fa' }}
                  >
                    <AddedOption value="">
                      {wiretappingCheckList[language][0]?.chooseManagerTwo}
                    </AddedOption>
                    {usersData?.usersData?.map(
                      user =>
                        Number(user?.id_job_title) === 4 && (
                          <AddedOption
                            value={user?.full_name}
                            key={user?.full_name}
                          >
                            {user?.full_name}
                          </AddedOption>
                        ),
                    )}
                  </AddedSelect>
                </label>
              </div>
            )}
          </NavContainer>
          {isModalDateOpen && (
            <Modal closeModal={() => setIsModalDateOpen(false)}>
              <DateRangePicker
                onChange={item => setPeriod([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                locale={language === 'russian' ? ru : uk}
                staticRanges={customDefaultStaticRanges}
                inputRanges={customDefaultInputRanges}
                months={2}
                ranges={period}
                direction="horizontal"
                style={{ margin: '20px' }}
              />
              <SaveButton onClick={() => handleDate()}>
                {wiretappingCheckList[language][0]?.update}
              </SaveButton>
            </Modal>
          )}
          <TableContainer>
            <WiretappingTable>
              <Thead>
                <WiretappingHeadTr>
                  <WiretappingTh onClick={() => handleSortChange('date')}>
                    {wiretappingCheckList[language][0]?.createdDate}
                    {renderSortIcon('date')}
                  </WiretappingTh>
                  <WiretappingTh
                    onClick={() =>
                      handleSortChange('employee_create_full_name')
                    }
                  >
                    {wiretappingCheckList[language][0]?.createdFullName}
                    {renderSortIcon('employee_create_full_name')}
                  </WiretappingTh>
                  <WiretappingTh
                    onClick={() => handleSortChange('total_fine_amount')}
                  >
                    {wiretappingCheckList[language][0]?.finesAmount}
                    {renderSortIcon('total_fine_amount')}
                  </WiretappingTh>
                  <WiretappingTh
                    onClick={() => handleSortChange('total_order')}
                  >
                    {wiretappingCheckList[language][0]?.totalWithoutSymbols}
                    {renderSortIcon('total_order')}
                  </WiretappingTh>
                  <WiretappingTh
                    onClick={() =>
                      handleSortChange('fines_status_id_count_four')
                    }
                  >
                    {wiretappingCheckList[language][0]?.notFinesWithoutSymbols}
                    {renderSortIcon('fines_status_id_count_four')}
                  </WiretappingTh>
                  <WiretappingTh
                    onClick={() =>
                      handleSortChange('fines_status_id_count_two')
                    }
                  >
                    {wiretappingCheckList[language][0]?.finesWithoutSymbols}
                    {renderSortIcon('fines_status_id_count_two')}
                  </WiretappingTh>
                  <WiretappingTh
                    onClick={() =>
                      handleSortChange('fines_status_id_count_one')
                    }
                  >
                    {wiretappingCheckList[language][0]?.warningWithoutSymbols}
                    {renderSortIcon('fines_status_id_count_one')}
                  </WiretappingTh>
                  <WiretappingTh
                    onClick={() =>
                      handleSortChange('error_percent')
                    }
                  >
                    {wiretappingCheckList[language][0]?.errorPercentWithoutSymbols}
                    {renderSortIcon('error_percent')}
                  </WiretappingTh>
                  <WiretappingTh
                    onClick={() =>
                      handleSortChange('work_duration')
                    }
                  >
                    {wiretappingCheckList[language][0]?.oneOrderWorkDuration}
                    {renderSortIcon('work_duration')}
                  </WiretappingTh>
                </WiretappingHeadTr>
              </Thead>
              <tbody>
                {data?.length > 0 ? (
                  <React.Fragment>
                    {data?.map(item => {
                      const key = uuidv4();

                      return (
                        <WiretappingTr key={key}>
                          <WiretappingTd>
                            {formattedDateToDayMonthYear(item.date)}
                          </WiretappingTd>
                          <WiretappingTd>
                            {item.employee_create_full_name}
                          </WiretappingTd>
                          <WiretappingTd>
                            {item.total_fine_amount} грн
                          </WiretappingTd>
                          <WiretappingTd>{item.total_order}</WiretappingTd>
                          <WiretappingTd>
                            {item.fines_status_id_count_four}
                          </WiretappingTd>
                          <WiretappingTd>
                            {item.fines_status_id_count_two}
                          </WiretappingTd>
                          <WiretappingTd>
                            {item.fines_status_id_count_one}
                          </WiretappingTd>
                          <WiretappingTd>
                            {' '}
                            {item.error_percent}
                            %
                          </WiretappingTd>
                          <WiretappingTd>{item.work_duration}</WiretappingTd>
                        </WiretappingTr>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <WiretappingTr>
                    <WiretappingTd
                      colSpan={13}
                      style={{
                        width: '2000px',
                        textAlign: 'start',
                        paddingLeft: '50px',
                      }}
                    >
                      {wiretappingCheckList[language][0]?.notDataOrError}
                    </WiretappingTd>
                  </WiretappingTr>
                )}
              </tbody>
            </WiretappingTable>
          </TableContainer>
        </div>
      ) : (
        <div>У вас немає доступу для перегляду сторінки</div>
      )}
    </Main>
  );
};
