import {
  Thead,
  TableContainer,
  FinesListTable,
  FinesListTr,
  FinesListHeadTr,
  FinesListTd,
  FinesListTh,
} from './FinesListUsers.styled';
import { finesListText } from '../../../services/componentText';
import { UserContext } from '../../Context/UserContext';
import { fetchFinesList } from '../../../api/finesList';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';

export const FinesListUsers = () => {
  const { language } = useContext(UserContext);
  const { data } = useQuery('finesAmount', fetchFinesList);

  return (
    <TableContainer>
      <FinesListTable>
        <Thead>
          <FinesListHeadTr>
            <FinesListTh>{finesListText[language][0]?.finesReason}</FinesListTh>
            <FinesListTh>{finesListText[language][0]?.fineAmount}</FinesListTh>
          </FinesListHeadTr>
        </Thead>
        <tbody>
          {data?.map(item => (
            <FinesListTr key={item?.id}>
              {language === 'russian' ? (
                <FinesListTd>{item?.fine_title_ru}</FinesListTd>
              ) : (
                <FinesListTd>{item?.fine_title_ua}</FinesListTd>
              )}
              <FinesListTd>{item?.fine_amount} грн</FinesListTd>
            </FinesListTr>
          ))}
        </tbody>
      </FinesListTable>
    </TableContainer>
  );
};
