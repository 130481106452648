import { Main } from '../../Main/Main';
import React, { useContext, useState,  useEffect } from 'react';
import { toast } from 'react-toastify';
import { UserContext } from '../../Context/UserContext';
import {
    fetchClaimsReport,
    deleteClaimsReport,
    updateClaimsReport,
    createClaimsReport,
} from '../../../api/claims';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  ListContainer,
  ItemContainer,
  ClaimInput,
  ClaimLabel,
  SaveButton,
  ButtonContainer,
  DeleteButton,
} from './ClaimsReportItem.styled';
import { claimsControlPanelText } from '../../../services/componentText';

export const ClaimsReportItem = ( ) => {
    const { currentUser, language } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
      id: '',
      employee_id: currentUser?.id_employee,
      order_processed: null,
      consultations: null,
      claim_accepted: null,
      return_issued: null,
      completed_exchanges: null,
      problem_customer: null,
    });
  
    const isCreateNewClaim = location?.pathname === '/claims-report/new-report';

    useEffect(() => {
        const fetchClaim = async () => {
          if (isCreateNewClaim) {
            return;
          }
    
          const claimIdFromPath = location?.pathname?.replace(
            /^\/claims-report\/change-report\//,
            '',
          );
    
          const response = await fetchClaimsReport({ id: claimIdFromPath });
          if (response?.length > 0) {
            setInitialValues(response[0]);
          }
        };
        fetchClaim();
      }, [location, isCreateNewClaim]);

      const handleSubmit = async () => {
        if (isCreateNewClaim) {
          const response = await createClaimsReport(initialValues);
    
          if (response?.status === 201) {
            toast.success(claimsControlPanelText[language][0]?.successSave);
            return navigate('/claims-report/report-list');
          }
        }
    
        if (!isCreateNewClaim) {
          const response = await updateClaimsReport(initialValues);
    
          if (response?.status === 201) {
            toast.success(claimsControlPanelText[language][0]?.successUpdate);
            return navigate('/claims-report/report-list');
          }
        }
      };
    
      const handleCancelClick = () => {
        return navigate('/claims-report/report-list');
      };
    
      const handleDelete = async () => {
        const response = await deleteClaimsReport(initialValues.id);
    
        if (response.status === 200) {
          toast.success(claimsControlPanelText[language][0]?.successRemoved);
          return navigate('/claims-report/report-list');
        }
      };

return <Main>
    <ListContainer>
    <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.enterOrderProcessed}

            <ClaimInput
              type="number"
              placeholder={
                claimsControlPanelText[language][0]?.enterOrderProcessed
              }
              value={initialValues?.order_processed || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  order_processed: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
    </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.enterСonsultations}

            <ClaimInput
              type="number"
              placeholder={
                claimsControlPanelText[language][0]?.enterСonsultations
              }
              value={initialValues?.consultations || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  consultations: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.enterClaimAccepted}

            <ClaimInput
              type="number"
              placeholder={
                claimsControlPanelText[language][0]?.enterClaimAccepted
              }
              value={initialValues?.claim_accepted || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  claim_accepted: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.enterReturnIssued}

            <ClaimInput
              type="number"
              placeholder={
                claimsControlPanelText[language][0]?.enterReturnIssued
              }
              value={initialValues?.return_issued || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  return_issued: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.enterCompletedExchanges}

            <ClaimInput
              type="number"
              placeholder={
                claimsControlPanelText[language][0]?.enterCompletedExchanges
              }
              value={initialValues?.completed_exchanges || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  completed_exchanges: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
        </ItemContainer>

        <ItemContainer>
          <ClaimLabel>
            {claimsControlPanelText[language][0]?.enterProblemCustomer}

            <ClaimInput
              type="number"
              placeholder={
                claimsControlPanelText[language][0]?.enterProblemCustomer
              }
              value={initialValues?.problem_customer || ''}
              onChange={e => {
                const updatedInitialValue = {
                  ...initialValues,
                  problem_customer: e.target.value,
                };
                setInitialValues(updatedInitialValue);
              }}
            />
          </ClaimLabel>
        </ItemContainer>
        {isCreateNewClaim && (
        <ButtonContainer>
          <SaveButton onClick={() => handleSubmit(initialValues)}>
            {claimsControlPanelText[language][0]?.save}
          </SaveButton>
          <DeleteButton onClick={() => handleCancelClick()}>
            {claimsControlPanelText[language][0]?.cancel}
          </DeleteButton>
        </ButtonContainer>
      )}
      {!isCreateNewClaim && (
        <ButtonContainer>
          <SaveButton onClick={() => handleSubmit(initialValues)}>
            {claimsControlPanelText[language][0]?.update}
          </SaveButton>
          <DeleteButton onClick={() => handleDelete()}>
            {claimsControlPanelText[language][0]?.delete}
          </DeleteButton>
        </ButtonContainer>
      )}
    </ListContainer>
</Main>
}