import styled from '@emotion/styled';
import { LoginFormButton } from '../../Buttons/Buttons.styled';

export const ModalContainerFM = styled.div`
  width: 700px;
  min-height: 440px;
`;

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableContainer = styled.div`
  height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
`;

export const ScheduleTable = styled.table`
  margin: 1em 0;
  overflow-x: auto;
  background-color: #ffffff;
`;

export const ScheduleTr = styled.tr`
  display: flex;
  vertical-align: top;
  border: 1px solid #d9e4e6;

  &:nth-of-type(odd) {
    background-color: #eaf3f3;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ScheduleHeadTr = styled(ScheduleTr)`
  display: flex;
  flex-direction: row;
  &:nth-of-type(odd) {
    background-color: transparent;
    border: none;
  }
`;

export const ScheduleTd = styled.td`
  width: 252px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  min-height: 30px;
  border: 1px solid #d9e4e6;
  background-color:  '#ffffff'};


  &:last-of-type {
    border-right: none;
  }
`;

export const ScheduleTh = styled.th`
  width: 230px;
  border: 1px solid #fff;
  background-color: #3098d6;
  color: #ffffff;
  padding: 1em;
  text-align: center;

  &::nth-of-type(1),
  &::nth-of-type(2) {
    position: sticky;
    left: 0;
    z-index: 1;
  }
`;

export const SaveButton = styled(LoginFormButton)`
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.2s;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AddedNewFMButton = styled.button`
  transition: background-color 0.2s;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #3098d6;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    border-left: 0.375rem solid #5cbcf6;
    padding-left: 0.625rem;
    border-right: 0.375rem solid #5cbcf6;
    padding-right: 0.625rem;
    background-color: #0b6aa2;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const DeleteFMButton = styled(SaveButton)`
  background-color: #e74c3c;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const AddedFMContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  &:first-of-type {
    margin-top: 20px;
  }
`;

export const FMTextArea = styled.textarea`
  font-size: 20px;
  line-height: 1.17;
  width: 400px;
  height: 150px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: 12px 16px;
  resize: none;
  background-color: transparent;
  transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  &:focus {
    border-color: #5cbcf6;
  }

  &::placeholder {
    border-color: #5cbcf6;
    color: rgba(117, 117, 117, 0.5);
    font-size: $primarySize;
    line-height: 1.14;
    letter-spacing: 0.3;
  }
`;

export const AddedFMInput = styled.input`
  appearance: none;
  width: 370px;
  height: 10px;
  padding: 12px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 20px;

  cursor: pointer;
`;

export const AddedFMSelect = styled.select`
  appearance: none;
  width: 397px;
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 20px;

  cursor: pointer;
`;

export const AddedFMOption = styled.option`
  appearance: none;
  width: 100%;
  height: 10px;
  padding: 5px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
  font-size: 20px;

  cursor: pointer;
`;

export const FinesText = styled.p`
  margin: 5px 0 0 0;
`;

export const FinesNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  width: 100%;
  text-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
`;

export const UploadFileContainer = styled.div`
position: relative;
width: 400px;
height: 200px;
marginRight: 20px;
marginBottom: 20px;
overflow: hidden;
border: 2px solid #5cbcf6;
border-radius: 10px;
`

export const Image = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
`