import { Main } from '../../Main/Main';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  SaveButton,
  AddedNewFMButton,
  NavContainer,
} from './SeeChangesEmployeeReport.styled';
import { formattedDateToDayMonthYear } from '../../../helpers/dateAndTime/formattedDateToDayMonthYear';
import { fetchEmployeeReport } from '../../../api/report';
import { employeeReportText } from '../../../services/componentText';
import { BarChart } from '../../BarChart/BarChart';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';
import { Modal } from '../../Modal/Modal';

export const SeeChangesEmployeeReport = () => {
  const { currentUser, language } = useContext(UserContext);
  const sortBy = 'date';
  const order = 'ASC';
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');

  const { data: employeeReportData, refetch } = useQuery(
    [
      'employeeReportData',
      sortBy,
      order,
      formattedStartDateRequest,
      formattedEndDateRequest,
    ],
    () =>
      fetchEmployeeReport({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const createNewDateForChart = value => {
    const label = 'Дані для діаграми';
    const data = {
      labels: employeeReportData?.map(item =>
        formattedDateToDayMonthYear(item.date),
      ),
      datasets: [
        {
          label: 'Дані для діаграми',
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: 'rgba(75,192,192,1)',
          data: [],
          hoveredLabel: label,
        },
      ],
    };

    switch (value) {
      case 'net_income_by_approve':
        data.datasets[0].data = employeeReportData?.map(
          item => item.net_income_by_approve,
        );
        break;
      case 'number_of_interview':
        data.datasets[0].data = employeeReportData?.map(
          item => item.number_of_interview,
        );
        break;
      case 'number_of_new_employee':
        data.datasets[0].data = employeeReportData?.map(
          item => item.number_of_new_employee,
        );
        break;
      case 'number_of_corrected_orders':
        data.datasets[0].data = employeeReportData?.map(
          item => item.number_of_corrected_orders,
        );
        break;
      case 'number_of_claims_drawn_up':
        data.datasets[0].data = employeeReportData?.map(
          item => item.number_of_claims_drawn_up,
        );
        break;
      case 'number_of_order_out_of_stock':
        data.datasets[0].data = employeeReportData?.map(
          item => item.number_of_order_out_of_stock,
        );
        break;
      default:
        data.datasets[0].data = employeeReportData?.map(
          item => item.number_of_wrong_number,
        );
    }

    return data;
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
          {employeeReportText[language][0]?.choosePeriod}
        </AddedNewFMButton>
      </NavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {employeeReportText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <BarChart
          title={employeeReportText[language][0]?.netIncomeByApproveBy}
          data={createNewDateForChart('net_income_by_approve')}
        />
        <BarChart
          title={employeeReportText[language][0]?.numberOfInterview}
          data={createNewDateForChart('number_of_interview')}
        />
        <BarChart
          title={employeeReportText[language][0]?.numberOfNewEmployee}
          data={createNewDateForChart('number_of_new_employee')}
        />
        <BarChart
          title={employeeReportText[language][0]?.numberOfCorrectedOrders}
          data={createNewDateForChart('number_of_corrected_orders')}
        />
        <BarChart
          title={employeeReportText[language][0]?.numberOfClaimsDrawnUp}
          data={createNewDateForChart('number_of_claims_drawn_up')}
        />
        <BarChart
          title={employeeReportText[language][0]?.chartNumberOfOrderOutOfStock}
          data={createNewDateForChart('number_of_order_out_of_stock')}
        />
        <BarChart
          title={employeeReportText[language][0]?.chartNumberOfWrongNumber}
          data={createNewDateForChart('number_of_wrong_number')}
        />
      </div>
    </Main>
  );
};
