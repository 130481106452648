import { SharedLayout } from '../../components/SharedLayout/SharedLayout';
import { Main } from '../../components/Main/Main';
import { Outlet, useLocation  } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../components/Context/UserContext';
import { NavContainer, AddedNewButton } from './KnowledgeBase.styled';
import { knowledgeBaseText } from '../../services/componentText';

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const KnowledgeBase = () => {
  const { currentUser, language } = useContext(UserContext);
  const location = useLocation();

  const isAddNewProductPage = location.pathname === '/knowledge-base/new';

  const isMainProducts = location.pathname === '/knowledge-base' || location.pathname === '/knowledge-base/list';

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  return (
    <div>
      <SharedLayout />
      {isMainProducts && (
              <Main>
              {accessLevel && (
                <NavContainer>
                {isAddNewProductPage ? null : (
                  <AddedNewButton to="/knowledge-base/new">
                    {knowledgeBaseText[language][0]?.addNewProduct}
                  </AddedNewButton>
                )}
                </NavContainer>
              )}
            </Main>
      )}
      <Outlet />
    </div>
  );
};

