import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const fetchAllFM = async ({ sortBy, order, startDate, endDate, isBlocked, id}) => {
    try {
      const response = await makeRequestSQL.get('/fm/', {
        params: {
             sortBy, order, startDate, endDate, isBlocked, id
        }
      });
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData?.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const createMutationFM = async fmData => {
    try {
      const response = await makeRequestSQL.post('/fm', fmData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

 export const deleteMutationFM = async fmId => {
    try {
      const response = await makeRequestSQL.delete(`/fm/`, {
        params: {
          id: fmId,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateMutationFM = async ({fmId, fmData}) => {
    try {
      const response = await makeRequestSQL.put(`/fm/${fmId}`, fmData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchCountFM = async ({ sortBy, order, startDate, endDate, isBlocked}) => {
    try {
      const response = await makeRequestSQL.get('/fm/fm-count', {
        params: {
             sortBy, order, startDate, endDate, isBlocked
        }
      });
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };