import { makeRequestSQL } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptMiddleware } from '../services/decryptMiddleware';

export const createClaims = async data => {
    try {
      const response = await makeRequestSQL.post('/claims/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchClaims = async ({
    id,
    sortBy,
    order,
    startDate,
    endDate,
    employee_id,
    warehouse_city_id,
    claims_category_id,
    client_configured_id,
    verificatioт_status_id,
    claims_status_id,
    refund_status_id,
    reason_return_id,
    search,
    page,
    limit,
  }) => {
    try {
      const response = await makeRequestSQL.get('/claims/', {
        params: {
          id,
          sortBy,
          order,
          startDate,
          endDate,
          employee_id,
          warehouse_city_id,
          claims_category_id,
          client_configured_id,
          verificatioт_status_id,
          claims_status_id,
          refund_status_id,
          reason_return_id,
          search,
          page,
          limit,
        }
      });

      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const deleteClaims = async id => {
    try {
      const response = await makeRequestSQL.delete(`/claims/`, {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateClaims = async data => {
    try {
      const response = await makeRequestSQL.put('/claims/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const createClaimsReport = async data => {
    try {
      const response = await makeRequestSQL.post('/claims/report', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchClaimsReport = async ({
    id,
    sortBy,
    order,
    startDate,
    endDate,
    employee_id,
  }) => {
    try {
      const response = await makeRequestSQL.get('/claims/report', {
        params: {
          id,
          sortBy,
          order,
          startDate,
          endDate,
          employee_id
        }
      });

      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const deleteClaimsReport = async id => {
    try {
      const response = await makeRequestSQL.delete(`/claims/report`, {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const updateClaimsReport = async data => {
    try {
      const response = await makeRequestSQL.put('/claims/report', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchClaimsStatistics = async ({
    sortBy,
    order,
    startDate,
    endDate,
  }) => {
    try {
      const response = await makeRequestSQL.get('/claims/statistics', {
        params: {
          startDate,
          endDate,
        }
      });
      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };

  export const fetchClaimsProductStatistics = async ({
    sortBy,
    order,
    startDate,
    endDate,
  }) => {
    try {
      const response = await makeRequestSQL.get('/claims/product-statistics', {
        params: {
          sortBy,
          order,
          startDate,
          endDate,
        }
      });

      const decryptedData = await  decryptMiddleware(response?.data);
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };