import axios from 'axios';

export const makeRequestSQL = axios.create({
  baseURL: 'https://www.ogomart-service.space/api',
  withCredentials: true,
});

// export const makeRequestSQL = axios.create({
//   baseURL: 'http://localhost:3002/api',
//   withCredentials: true,
// });
 
