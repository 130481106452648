import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  AddedFMContainer,
  Thead,
  NavigationButton,
  AddedFMSelect,
  AddedFMOption,
} from "./Rating.style";
import { UserContext } from "../../Context/UserContext";
import {
  everydayStatisticsText,
  salaryText,
} from "../../../services/componentText";
import {
  getGradientColorForApprove,
  getGradientColorForNettoIncome,
  getGradientColorForAdditionalApprovalPercentage,
} from "../../../helpers/gradient/getGradientColorForEverydayStatistics";
import { Main } from "../../Main/Main";
import { fetchRatingByPeriod } from "../../../api/everydayStatistics";
import { SharedLayout } from "../../SharedLayout/SharedLayout";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { v4 as uuidv4 } from 'uuid';


export const RatingByPeriod = () => {
  const { language } = useContext(UserContext);
  const [sortBy, setSortBy] = useState("totalScore");
  const [order, setOrder] = useState("DESC");
  const [selectedDate, setSelectedDate] = useState("");

  const { data, refetch } = useQuery(
    ["fetchRatingByPeriod", sortBy, order, selectedDate],
    () =>
      fetchRatingByPeriod({
        date: selectedDate,
        sortBy,
        order,
      })
  );

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  useEffect(() => {
    if (!selectedDate && data?.uniqueSalaryDates[0] !== undefined) {
      setSelectedDate(data?.uniqueSalaryDates[0]);
    }
  }, [selectedDate, data]);

  return (
    <div>
      <SharedLayout />
      <Main>
        <div>
          <AddedFMContainer>
            <NavigationButton>
              <AddedFMSelect
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              >
                <AddedFMOption value="" disabled>
                  {salaryText[language][0]?.chooseDate}
                </AddedFMOption>
                {data !== undefined &&
                  data?.uniqueSalaryDates?.map((date) => (
                    <AddedFMOption key={date} value={date}>
                      {formattedDateToDayMonthYear(date)}
                    </AddedFMOption>
                  ))}
              </AddedFMSelect>
            </NavigationButton>
          </AddedFMContainer>
        </div>

        <TableContainer>
          <ScheduleTable>
            <Thead>
              <ScheduleHeadTr>
                <ScheduleTh style={{ width: "30px" }}>№</ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("full_name")}>
                  {everydayStatisticsText[language][0]?.fullName}{" "}
                  {renderSortIcon("full_name")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("totalScore")}>
                  {everydayStatisticsText[language][0]?.totalScore}{" "}
                  {renderSortIcon("totalScore")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("working_hours")}>
                  {everydayStatisticsText[language][0]?.totalHour}{" "}
                  {renderSortIcon("working_hours")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("working_hours_score")}
                >
                  {everydayStatisticsText[language][0]?.totalHourScore}{" "}
                  {renderSortIcon("working_hours_score")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("rejected_order")}>
                  {everydayStatisticsText[language][0]?.rejected}{" "}
                  {renderSortIcon("rejected_order")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("rejected_order_score")}
                >
                  {everydayStatisticsText[language][0]?.rejectedScore}{" "}
                  {renderSortIcon("rejected_order_score")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("approve_by_hour")}>
                  {everydayStatisticsText[language][0]?.approveByHour}{" "}
                  {renderSortIcon("approve_by_hour")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("approve_by_hour_score")}
                >
                  {everydayStatisticsText[language][0]?.approveByHourScore}{" "}
                  {renderSortIcon("approve_by_hour_score")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("total_order")}>
                  {everydayStatisticsText[language][0]?.totalOrder}{" "}
                  {renderSortIcon("total_order")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("total_order_score")}
                >
                  {everydayStatisticsText[language][0]?.totalOrderScore}{" "}
                  {renderSortIcon("total_order_score")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("total_approve")}>
                  {everydayStatisticsText[language][0]?.approve}{" "}
                  {renderSortIcon("total_approve")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("total_approve_score")}
                >
                  {everydayStatisticsText[language][0]?.approveScore}{" "}
                  {renderSortIcon("total_approve_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("additional_approval")}
                >
                  {everydayStatisticsText[language][0]?.additionalApproval}{" "}
                  {renderSortIcon("additional_approval")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("additional_approval_score")}
                >
                  {everydayStatisticsText[language][0]?.additionalApprovalScore}{" "}
                  {renderSortIcon("additional_approval_score")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("ransomed")}>
                  {everydayStatisticsText[language][0]?.ransomed}{" "}
                  {renderSortIcon("ransomed")}
                </ScheduleTh>
                <ScheduleTh onClick={() => handleSortChange("ransomed_score")}>
                  {everydayStatisticsText[language][0]?.ransomedScore}{" "}
                  {renderSortIcon("ransomed_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("approval_percentage")}
                >
                  {everydayStatisticsText[language][0]?.approvalPercentage}{" "}
                  {renderSortIcon("approval_percentage")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("approval_percentage_score")}
                >
                  {everydayStatisticsText[language][0]?.approvalPercentageScore}{" "}
                  {renderSortIcon("approval_percentage_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() =>
                    handleSortChange("approval_percentage_without_trash")
                  }
                >
                  {
                    everydayStatisticsText[language][0]
                      ?.approvalPercentageWithoutTrash
                  }{" "}
                  {renderSortIcon("approval_percentage_without_trash")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() =>
                    handleSortChange("approval_percentage_without_trash_score")
                  }
                >
                  {
                    everydayStatisticsText[language][0]
                      ?.approvalPercentageWithoutTrashScore
                  }{" "}
                  {renderSortIcon("approval_percentage_without_trash_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() =>
                    handleSortChange("additions_persentage_by_repurchases")
                  }
                >
                  {
                    everydayStatisticsText[language][0]
                      ?.additionalApprovalPercentage2
                  }{" "}
                  {renderSortIcon("additions_persentage_by_repurchases")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() =>
                    handleSortChange(
                      "additions_persentage_by_repurchases_score"
                    )
                  }
                >
                  {
                    everydayStatisticsText[language][0]
                      ?.additionalApprovalPercentageScore2
                  }{" "}
                  {renderSortIcon("additions_persentage_by_repurchases_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("ransomed_percentage")}
                >
                  {everydayStatisticsText[language][0]?.ransomedPercentage}{" "}
                  {renderSortIcon("ransomed_percentage")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("ransomed_percentage_score")}
                >
                  {everydayStatisticsText[language][0]?.ransomedPercentageScore}{" "}
                  {renderSortIcon("ransomed_percentage_score")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() => handleSortChange("profit_by_repurchases")}
                >
                  {everydayStatisticsText[language][0]?.profitByRepurchases}{" "}
                  {renderSortIcon("profit_by_repurchases")}
                </ScheduleTh>
                <ScheduleTh
                  onClick={() =>
                    handleSortChange("profit_by_repurchases_score")
                  }
                >
                  {everydayStatisticsText[language][0]?.nettoIncomeScore}{" "}
                  {renderSortIcon("profit_by_repurchases_score")}
                </ScheduleTh>
              </ScheduleHeadTr>
            </Thead>
            <tbody>
              {data?.salaries?.length > 0 ? (
                <React.Fragment>
                  {data?.salaries?.map((item, index) => {
                    const key = uuidv4();
                    const approvalBgColor = getGradientColorForApprove(
                      item.approval_percentage
                    );

                    const approvalPercentageWithoutTrash =
                      getGradientColorForApprove(
                        item.approval_percentage_without_trash
                      );
                    const ransomedPercentage = getGradientColorForApprove(
                      item.ransomed_percentage
                    );

                    const nettoIncomeBgColor = getGradientColorForNettoIncome(
                      item.profit_by_repurchases
                    );

                    const additionalApprovalPercentageBgColor =
                      getGradientColorForAdditionalApprovalPercentage(
                        item.additions_persentage_by_repurchases
                      );

                    return (
                      <ScheduleTr key={key}>
                        <ScheduleTd style={{ width: "52px" }}>
                          {index + 1}
                        </ScheduleTd>
                        <ScheduleTd>{item.full_name}</ScheduleTd>
                        <ScheduleTd>{item.totalScore}</ScheduleTd>
                        <ScheduleTd>{item.working_hours}</ScheduleTd>
                        <ScheduleTd>{item.working_hours_score}</ScheduleTd>
                        <ScheduleTd>{item.rejected_order}</ScheduleTd>
                        <ScheduleTd>{item.rejected_order_score}</ScheduleTd>
                        <ScheduleTd>{item.approve_by_hour}</ScheduleTd>
                        <ScheduleTd>{item.approve_by_hour_score}</ScheduleTd>
                        <ScheduleTd>{item.total_leads}</ScheduleTd>
                        <ScheduleTd>{item.total_leads_score}</ScheduleTd>
                        <ScheduleTd>{item.total_approve}</ScheduleTd>
                        <ScheduleTd>{item.total_approve_score}</ScheduleTd>
                        <ScheduleTd>{item.additional_approval}</ScheduleTd>
                        <ScheduleTd>
                          {item.additional_approval_score}
                        </ScheduleTd>
                        <ScheduleTd>{item.ransomed}</ScheduleTd>
                        <ScheduleTd>{item.ransomed_score}</ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: approvalBgColor,
                          }}
                        >
                          {item.approval_percentage}%
                        </ScheduleTd>
                        <ScheduleTd>
                          {item.approval_percentage_score}
                        </ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: approvalPercentageWithoutTrash,
                          }}
                        >
                          {item.approval_percentage_without_trash}%
                        </ScheduleTd>
                        <ScheduleTd>
                          {item.approval_percentage_without_trash_score}
                        </ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor:
                              additionalApprovalPercentageBgColor,
                          }}
                        >
                          {item.additions_persentage_by_repurchases}
                        </ScheduleTd>
                        <ScheduleTd>
                          {item.additions_persentage_by_repurchases_score}
                        </ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: ransomedPercentage,
                          }}
                        >
                          {item.ransomed_percentage}
                        </ScheduleTd>
                        <ScheduleTd>
                          {item.ransomed_percentage_score}
                        </ScheduleTd>
                        <ScheduleTd
                          style={{
                            backgroundColor: nettoIncomeBgColor,
                          }}
                        >
                          {item.profit_by_repurchases}
                        </ScheduleTd>
                        <ScheduleTd>
                          {item.profit_by_repurchases_score}
                        </ScheduleTd>
                      </ScheduleTr>
                    );
                  })}
                </React.Fragment>
              ) : (
                <ScheduleTr>
                  <ScheduleTd colSpan={9} style={{ width: "600px" }}>
                    {everydayStatisticsText[language][0]?.notDataOrError}
                  </ScheduleTd>
                </ScheduleTr>
              )}
            </tbody>
          </ScheduleTable>
        </TableContainer>
      </Main>
    </div>
  );
};
