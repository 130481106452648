export const getVarificationBackgroundColor = id => {

  if (id === 12 && id !== null) {
    return '#fcb045';
  }

  if (id >= 0 && id <= 11 && id !== null) {
    return '#27ae60';
  } else {
    return '#ffffff';
  }
};
