export const getRefundStatusBackgroundColor = id => {

  if (id === 1 && id !== null) {
    return '#fcb045';
  }

  if (id === 2 && id !== null) {
    return '#27ae60';
  }

  if ((id === 3 && id !== null) || (id === 4 && id !== null)) {
    return '#e74c3c';
  } else {
    return '#ffffff';
  }
};
