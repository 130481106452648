import { Main } from '../../Main/Main';
import {
  fetchWiretappingStatistics,
  fetchOrderStatus,
} from '../../../api/wiretapping';
import { useQuery } from 'react-query';
import React, { useState, useContext } from 'react';
import {
  AddedSelect,
  AddedOption,
  Thead,
  TableContainer,
  WiretappingTable,
  WiretappingTr,
  WiretappingHeadTr,
  WiretappingTd,
  WiretappingTh,
  SaveButton,
  AddedNewButton,
  NavContainer
} from './WiretappingAllStatisticsWithoutJSON.styled';
import { wiretappingCheckList } from '../../../services/componentText';
import { UserContext } from '../../Context/UserContext';
import { v4 as uuidv4 } from 'uuid';
import { WiretappingField } from './WiretappingField';
import { Modal } from '../../Modal/Modal';
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import { uk, ru } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { translateLabel } from '../../../helpers/reactDateRange/translateLabel';

export const WiretappingAllStatisticsWithoutJSON = () => {
  const { language } = useContext(UserContext);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [searchBy, setSearchBy] = useState('employee_create_full_name');
  const [sortBy, setSortBy] = useState(searchBy);
  const [order, setOrder] = useState('DESC');
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: 'selection',
    },
  ]);
  const formattedStartDate = format(period[0]?.startDate, 'dd.MM.yyyy');
  const formattedEndDate = format(period[0]?.endDate, 'dd.MM.yyyy');
  const formattedStartDateRequest = format(period[0]?.startDate, 'yyyy.MM.dd');
  const formattedEndDateRequest = format(period[0]?.endDate, 'yyyy.MM.dd');
  const category = [
    {
      id: 1,
      value: 'employee_create_full_name',
      category_title_ru: 'По менеджерам',
      category_title_ua: 'По менеджерам',
    },
    {
      id: 2,
      value: 'employee_full_name',
      category_title_ru: 'По операторам',
      category_title_ua: 'По операторам',
    },
    {
      id: 3,
      value: 'status_id',
      category_title_ru: 'По статусам',
      category_title_ua: 'По статусам',
    },
    {
      id: 4,
      value: 'product_title',
      category_title_ru: 'По офферам',
      category_title_ua: 'По оферам',
    },
  ];

  const fields = [
    'is_greeting',
    'is_identifying_needs',
    'is_presentation',
    'is_offer_of_kits',
    'is_offering_an_improved_version',
    'is_crosssail_offer',
    'is_the_client_immediately_agreed',
    'is_handling_objections',
    'is_undercharacterization',
    'are_characteristics_stated_correctly',
    'is_address_verification',
    'is_address_correct',
    'is_np_department_verification',
    'is_np_department_correct',
    'is_order_list_verification',
    'is_order_list_correct',
    'is_total_order_amount_verification',
    'is_total_order_amount_correct',
    'is_plus_delivery_amount',
    'is_order_approved_by_client',
    'is_motivation_for_ransom',
    'is_hundred_percent_cashback',
    'is_order_status_correct',
    'is_call_time_exceeded',
    'is_third_party_verification',
    'is_calling_back_third_party',
    'is_conversation_reset',
    'is_try_call_back',
    'is_operator_handle_objection_to_save',
    'is_full_name_verification',
    'is_full_name_correct',
    'is_third_party_correct',
    'is_operator_speak_correctly',
  ];

  const { data, refetch } = useQuery(
    ['fetchWiretappingStatistics', searchBy, sortBy, order],
    () =>
      fetchWiretappingStatistics({
        searchBy,
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      }),
  );

  const { data: orderStatusData } = useQuery('orderStatus', fetchOrderStatus);

  const handleSortChange = field => {
    if (sortBy === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = field => {
    if (sortBy === field) {
      return order === 'ASC' ? '▲' : '▼';
    }
    return '';
  };

  const customDefaultStaticRanges = defaultStaticRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const customDefaultInputRanges = defaultInputRanges.map(range => ({
    ...range,
    label: translateLabel(range.label, language === 'russian' ? 'ru' : 'uk'),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
            <NavContainer>
        <div
          style={{ marginRight: '10px' }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewButton onClick={() => setIsModalDateOpen(true)}>
          {wiretappingCheckList[language][0]?.choosePeriod}
        </AddedNewButton>
        <div style={{marginLeft: '10px'}}>
          <label>{wiretappingCheckList[language][0]?.chooseFilter}</label>
          <AddedSelect
            value={searchBy || 'employee_create_full_name'}
            onChange={e => setSearchBy(e.target.value)}
            style={{ backgroundColor: '#f5f7fa' }}
          >
            {category?.map(item => (
              <AddedOption value={item?.value} key={item?.id}>
                {language === 'russian'
                  ? item?.category_title_ru
                  : item?.category_title_ua}
              </AddedOption>
            ))}
          </AddedSelect>
        </div>
      </NavContainer>
            {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={item => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={language === 'russian' ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: '20px' }}
          />
          <SaveButton onClick={() => handleDate()}>
            {wiretappingCheckList[language][0]?.update}
          </SaveButton>
        </Modal>
      )}

      <TableContainer>
        <WiretappingTable>
          <Thead>
            <WiretappingHeadTr>
              {searchBy === 'employee_create_full_name' && (
                <WiretappingTh
                  onClick={() => handleSortChange('employee_create_full_name')}
                >
                  {wiretappingCheckList[language][0]?.createdFullName}
                  {renderSortIcon('employee_create_full_name')}
                </WiretappingTh>
              )}
              {searchBy === 'employee_full_name' && (
                <WiretappingTh
                  onClick={() => handleSortChange('employee_full_name')}
                >
                  {wiretappingCheckList[language][0]?.fullName}
                  {renderSortIcon('employee_full_name')}
                </WiretappingTh>
              )}
              {searchBy === 'status_id' && (
                <WiretappingTh onClick={() => handleSortChange('status_id')}>
                  {wiretappingCheckList[language][0]?.statusOrder}
                  {renderSortIcon('status_id')}
                </WiretappingTh>
              )}
              {searchBy === 'product_title' && (
                <WiretappingTh
                  onClick={() => handleSortChange('product_title')}
                >
                  {wiretappingCheckList[language][0]?.productTitle}
                  {renderSortIcon('product_title')}
                </WiretappingTh>
              )}
              <WiretappingTh
                onClick={() => handleSortChange('order_count')}
                style={{ width: '178px' }}
              >
                {wiretappingCheckList[language][0]?.orderStatistics}
                {renderSortIcon('order_count')}
              </WiretappingTh>
              <WiretappingTh
                onClick={() => handleSortChange('total_fine_amount')}
              >
                {wiretappingCheckList[language][0]?.finesAmount}
                {renderSortIcon('total_fine_amount')}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.greetting}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.identifyingNeeds}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.presentation}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.offerOfKits}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.offeringAnImprovedVersion}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.crossailOffer}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.theClientImmediatelyAgreed}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.handlingObjections}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.undercharacterization}
              </WiretappingTh>
              <WiretappingTh>
                {
                  wiretappingCheckList[language][0]
                    ?.characteristicsStatedCorrectly
                }
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.fullNameVerification}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.fullNameCorrect}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.addressVerification}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.addressCorrect}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.npDepartmentVerification}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.npDepartmentCorrect}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.orderListVerification}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.orderListCorrect}
              </WiretappingTh>
              <WiretappingTh>
                {
                  wiretappingCheckList[language][0]
                    ?.totalOrderAmountVerification
                }
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.totalOrderAmountCorrect}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.plusDeliveryAmount}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.orderApprovedByClient}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.motivationForRansom}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.hundredPercentCashback}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.orderStatusCorrect}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.callTimeExceeded}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.thirdPartyVerification}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.callingBackThirdParty}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.conversationReset}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.tryCallBack}
              </WiretappingTh>
              <WiretappingTh>
                {
                  wiretappingCheckList[language][0]
                    ?.operatorHandleObjectionToSave
                }
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.thirdPartyCorrect}
              </WiretappingTh>
              <WiretappingTh>
                {wiretappingCheckList[language][0]?.operatorSpeakCorrectly}
              </WiretappingTh>
            </WiretappingHeadTr>
          </Thead>
          <tbody>
            {data?.length > 0 ? (
              <React.Fragment>
                {data?.map(item => {
                  const key = uuidv4();
                  return (
                    <WiretappingTr key={key}>
                      {searchBy === 'employee_create_full_name' && (
                        <WiretappingTd>
                          {item.employee_create_full_name}
                        </WiretappingTd>
                      )}
                      {searchBy === 'employee_full_name' && (
                        <WiretappingTd>{item.employee_full_name}</WiretappingTd>
                      )}
                      {searchBy === 'status_id' && (
                        <WiretappingTd>
                          {orderStatusData?.map(status => {
                            if (
                              Number(status?.id) === Number(item?.status_id)
                            ) {
                              return language === 'russian'
                                ? status?.status_title_ru
                                : status?.status_title_ua;
                            }
                            return '';
                          })}
                        </WiretappingTd>
                      )}
                      {searchBy === 'product_title' && (
                        <WiretappingTd>{item.product_title}</WiretappingTd>
                      )}
                      <WiretappingTd style={{ width: '200px' }}>
                        <span>
                          {wiretappingCheckList[language][0]?.total}
                          {item.order_count}
                        </span>
                        <br />
                        <span>
                          {wiretappingCheckList[language][0]?.notFines}
                          {item.fines_status_id_count_four}
                        </span>
                        <br />
                        <span>
                          {wiretappingCheckList[language][0]?.warning}
                          {item.fines_status_id_count_two}
                        </span>
                        <br />
                        <span>
                          {wiretappingCheckList[language][0]?.fines}
                          {item.fines_status_id_count_one}
                        </span>
                        <br />
                        <span style={{ fontWeight: '700' }}>
                          {wiretappingCheckList[language][0]?.errorPercent}
                          {(
                            ((item.fines_status_id_count_one +
                              item.fines_status_id_count_two) /
                              item.order_count) *
                            100
                          ).toFixed(2)}
                          %
                        </span>
                        <br />
                      </WiretappingTd>
                      <WiretappingTd>
                        {item.total_fine_amount} грн
                      </WiretappingTd>
                      {fields &&
                        fields?.map(field => (
                          <WiretappingField
                            key={field}
                            label={wiretappingCheckList[language][0]?.[field]}
                            trueCount={item[`${field}_count_true`]}
                            falseCount={item[`${field}_count_false`]}
                            orderCount={item.order_count}
                          />
                        ))}
                    </WiretappingTr>
                  );
                })}
              </React.Fragment>
            ) : (
              <WiretappingTr>
                <WiretappingTd
                  colSpan={13}
                  style={{
                    width: '2000px',
                    textAlign: 'start',
                    paddingLeft: '50px',
                  }}
                >
                  {wiretappingCheckList[language][0]?.notDataOrError}
                </WiretappingTd>
              </WiretappingTr>
            )}
          </tbody>
        </WiretappingTable>
      </TableContainer>
    </Main>
  );
};
