import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { finesText } from "../../../services/componentText";
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  AddedNewFMButton,
  AddedFMContainer,
  SaveButton,
  FMTextArea,
  ModalContainerFM,
  AddedFMInput,
  AddedFMSelect,
  AddedFMOption,
  ButtonContainer,
  DeleteFMButton,
  Thead,
  FinesText,
  FinesNavContainer,
  Label,
  UploadFileContainer,
  Image,
} from "./FinesManager.styled";
import { UserContext } from "../../Context/UserContext";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { Modal } from "../../Modal/Modal";
import { formatDateToInput } from "../../../helpers/dateAndTime/formatDate";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk, ru } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/reactDateRange/translateLabel";
import { AiOutlinePlus } from "react-icons/ai";
import {
  fetchAllFines,
  createMutationFines,
  deleteMutationFines,
  updateMutationFines,
} from "../../../api/fines";
import { fetchUsers } from "../../../api/users";

const createImageURL = (imageData) => {
  const binaryData = new Uint8Array(imageData?.data);
  const blob = new Blob([binaryData], { type: `image/${imageData?.type}` });
  return URL.createObjectURL(blob);
};

export const FinesManager = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isImageModalOpen, setIsImageOpenModal] = useState(false);
  const [isUpdateFines, setIsUpdateFines] = useState(false);
  const [initialValues, setInitialValues] = useState({
    employee_id: "",
    fines_id: "",
    fines_date: "",
    fines_price: "",
    fines_describe: "",
    fines_url_image_1: null,
  });
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: "selection",
    },
  ]);
  const [selectedImage, setSelectedImage] = useState(null);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data: fines, refetch } = useQuery(["finesData"], () =>
    fetchAllFines({
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    })
  );

  const { data: users } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0"})
  );

  let maxFinesColumns = 0;
  fines?.forEach((employee) => {
    if (employee?.fines_list?.length > maxFinesColumns) {
      maxFinesColumns = employee?.fines_list?.length;
    }
  });

  const handleSubmitFines = async () => {
    const formData = new FormData();

    Object.entries(initialValues)?.forEach(([key, value]) => {
      if (key === "fines_url_image_1") {
        if (value instanceof FileList) {
          for (let i = 0; i < value?.length; i++) {
            formData.append(key, value[i]);
          }
        } else if (value instanceof File) {
          formData.append(key, value);
        }
      } else {
        formData.append(key, value);
      }
    });

    if (isUpdateFines) {
      const response = await updateMutationFines({
        id: initialValues?.fines_id,
        finesData: formData,
      });
      if (response?.status === 200) {
        toast.success(finesText[language][0]?.successUpdated);
        refetch();
        return resetValues();
      }
    }

    if (!isUpdateFines) {
      const response = await createMutationFines(formData);

      if (response?.status === 204) {
        resetValues();
        toast.success(finesText[language][0]?.successAdded);
        return refetch();
      }
    }
  };

  const handleAddNewFines = () => {
    resetValues();
    setIsModalOpen(true);
  };

  const handleUpdateClick = async (
    employeeId,
    finesId,
    finesDate,
    finesPrice,
    finesDescribe,
    finesFirstImage
  ) => {
    setIsUpdateFines(true);
    setIsModalOpen(true);

    const updateFines = {
      employee_id: employeeId,
      fines_id: finesId,
      fines_date: formatDateToInput(finesDate),
      fines_price: finesPrice,
      fines_describe: finesDescribe,
      fines_url_image_1: finesFirstImage,
    };

    setInitialValues(updateFines);
    const getImageURL = finesFirstImage
      ? createImageURL(finesFirstImage)
      : null;
    setSelectedImage(getImageURL);
  };

  const handleDeleteFines = async () => {
    const response = await deleteMutationFines(initialValues?.fines_id);
    if (response?.status === 200) {
      resetValues();
      toast.success(finesText[language][0]?.successDeleted);
      refetch();
    }
  };

  const resetValues = () => {
    const updateFines = {
      employee_id: "",
      fines_id: "",
      fines_date: "",
      fines_price: "",
      fines_describe: "",
      fines_url_image_1: null,
    };
    setIsModalOpen(false);
    setInitialValues(updateFines);
    setIsUpdateFines(false);
    setSelectedImage("");
  };

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, language === "russian" ? "ru" : "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, language === "russian" ? "ru" : "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const handleImageClick = (image) => {
    if (image) {
      setIsImageOpenModal(true);
      return setSelectedImage(image);
    }

    if (selectedImage) {
      return document.getElementById("fines_url_image_1").click();
    }
  };

  const handleImageFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      const updatedInitialValues = {
        ...initialValues,
        fines_url_image_1: file,
      };
      setInitialValues(updatedInitialValues);

      reader.onload = (readerEvent) => {
        setSelectedImage(readerEvent.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      {isImageModalOpen && (
        <Modal closeModal={() => setIsImageOpenModal(false)}>
          <div style={{ width: "95%" }}>
            <img
              src={createImageURL(selectedImage)}
              alt="Скріншот"
              width="100%"
              height="100%"
            />
          </div>
        </Modal>
      )}
      <FinesNavContainer>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <AddedNewFMButton onClick={() => setIsModalDateOpen(true)}>
          {finesText[language][0]?.choosePeriod}
        </AddedNewFMButton>
        <AddedNewFMButton onClick={() => handleAddNewFines()}>
          {finesText[language][0]?.createNewFines}
        </AddedNewFMButton>
      </FinesNavContainer>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={currentUser?.language === "russian" ? ru : uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <SaveButton onClick={() => handleDate()}>
            {finesText[language][0]?.update}
          </SaveButton>
        </Modal>
      )}
      {isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainerFM>
            <AddedFMContainer>
              <label>{finesText[language][0]?.chooseEmployeeOne}</label>
              <AddedFMSelect
                value={initialValues?.employee_id}
                onChange={(e) => {
                  const updatedInitialValues = {
                    ...initialValues,
                    employee_id: e.target.value,
                  };
                  setInitialValues(updatedInitialValues);
                }}
              >
                <AddedFMOption value="">
                  {finesText[language][0]?.chooseEmployeeTwo}
                </AddedFMOption>
                {users?.usersData?.map(user => 
                (Number(user?.id_job_title) === 3 || Number(user?.id_job_title) === 11) &&  (
                  <AddedFMOption
                    key={user.id_employee}
                    value={user.id_employee}
                  >
                    {user.full_name}
                  </AddedFMOption>
                ))}
              </AddedFMSelect>
            </AddedFMContainer>

            <AddedFMContainer>
              <label>{finesText[language][0]?.chooseDate}</label>
              <AddedFMInput
                type="date"
                value={initialValues?.fines_date}
                onChange={(e) => {
                  const updatedInitialValues = {
                    ...initialValues,
                    fines_date: e.target.value,
                  };
                  setInitialValues(updatedInitialValues);
                }}
              />
            </AddedFMContainer>

            <AddedFMContainer>
              <label>{finesText[language][0]?.enterFinesPrice}</label>
              <AddedFMInput
                type="number"
                value={initialValues?.fines_price}
                placeholder="Введіть сумму штрафа"
                onChange={(e) => {
                  const updatedInitialValues = {
                    ...initialValues,
                    fines_price: e.target.value,
                  };
                  setInitialValues(updatedInitialValues);
                }}
              />
            </AddedFMContainer>

            <AddedFMContainer>
              <label>{finesText[language][0]?.reasonDescribeFines}</label>
              <div>
                <FMTextArea
                  value={initialValues?.fines_describe}
                  onChange={(e) => {
                    const updatedInitialValues = {
                      ...initialValues,
                      fines_describe: e.target.value,
                    };
                    setInitialValues(updatedInitialValues);
                  }}
                  required
                />
              </div>
            </AddedFMContainer>
            <AddedFMContainer>
              <Label>{finesText[language][0]?.addScreenshot}</Label>
              <UploadFileContainer onClick={() => handleImageClick}>
                {!initialValues?.fines_url_image_1 && (
                  <AiOutlinePlus
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    size={100}
                    fill="#5cbcf6"
                  />
                )}
                {selectedImage && (
                  <Image src={selectedImage} alt="Selected Screenshot" />
                )}
                <input
                  id="fines_url_image_1"
                  type="file"
                  name="fines_url_image_1"
                  accept="image/*"
                  onChange={handleImageFileChange}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    opacity: 0,
                    cursor: "pointer",
                  }}
                />
              </UploadFileContainer>
            </AddedFMContainer>
            {!isUpdateFines && (
              <SaveButton onClick={handleSubmitFines}>
                {finesText[language][0]?.save}
              </SaveButton>
            )}
            {isUpdateFines && (
              <ButtonContainer>
                <SaveButton onClick={handleSubmitFines}>
                  {finesText[language][0]?.update}
                </SaveButton>
                <DeleteFMButton onClick={handleDeleteFines}>
                  {finesText[language][0]?.delete}
                </DeleteFMButton>
              </ButtonContainer>
            )}
          </ModalContainerFM>
        </Modal>
      )}
      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh>{finesText[language][0]?.fullName}</ScheduleTh>
              {Array?.from({ length: maxFinesColumns })?.map((_, index) => (
                <ScheduleTh key={index}>{index + 1} - Штраф</ScheduleTh>
              ))}
              <ScheduleTh>
                {finesText[language][0]?.totalPriceForPeriod}
              </ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {fines ? (
              <React.Fragment>
                {fines?.map((employee) => {
                  const missingCells =
                    maxFinesColumns - employee?.fines_list?.length;
                  const totalFinesForPeriod =
                    employee?.fines_list?.reduce(
                      (acc, oneFines) => acc + parseFloat(oneFines.fines_price),
                      0
                    ) || 0;

                  return (
                    <ScheduleTr key={employee.user_id}>
                      <ScheduleTd>{employee.full_name}</ScheduleTd>
                      {employee?.fines_list?.map((oneFines) => (
                        <ScheduleTd key={oneFines.fines_id}>
                          <div
                            onClick={() =>
                              handleUpdateClick(
                                employee.user_id,
                                oneFines.fines_id,
                                oneFines.fines_date,
                                oneFines.fines_price,
                                oneFines.fines_describe,
                                oneFines.fines_url_image_1
                              )
                            }
                          >
                            <FinesText>
                              {formattedDateToDayMonthYear(oneFines.fines_date)}{" "}
                            </FinesText>
                            <FinesText>{oneFines.fines_describe}</FinesText>
                            <FinesText>
                              {finesText[language][0]?.oneFinesPrice} -{" "}
                              {oneFines.fines_price} грн
                            </FinesText>
                          </div>
                          {oneFines?.fines_url_image_1?.data &&
                            oneFines?.fines_url_image_1?.type && (
                              <img
                                src={createImageURL(oneFines.fines_url_image_1)}
                                alt="Скріншот"
                                width="100px"
                                height="100px"
                                onClick={() =>
                                  handleImageClick(oneFines.fines_url_image_1)
                                }
                              />
                            )}
                        </ScheduleTd>
                      ))}
                      {Array.from({ length: missingCells }).map((_, index) => (
                        <ScheduleTd key={`placeholder-${index}`} />
                      ))}
                      <ScheduleTd>
                        <FinesText>{totalFinesForPeriod} грн</FinesText>
                      </ScheduleTd>
                    </ScheduleTr>
                  );
                })}
              </React.Fragment>
            ) : (
              <ScheduleTr>
                <ScheduleTd
                  colSpan={5}
                  style={{
                    width: "2000px",
                    textAlign: "start",
                    paddingLeft: "50px",
                  }}
                >
                  {finesText[language][0]?.notDataOrError}
                </ScheduleTd>
              </ScheduleTr>
            )}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </div>
  );
};
