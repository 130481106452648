import { Main } from '../../Main/Main';
import React, { Modal } from '../../Modal/Modal';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../Context/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import {
  deleteMutationDutyEmployee,
  createMutationDutyEmployee,
  fetchDutyEmployee,
  fetchDutyGroupe,
  createMutationDutyGroupe,
  updateDutyGroupe,
  deleteMutationDutyGroupe,
  fetchDutySchedule,
  saveSchedule,
  updateSchedule,
  deleteSchedule,
} from '../../../api/duty';
import 'react-toastify/dist/ReactToastify.css';
import {
  ModalContainerDuty,
  Thead,
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleHeadTr,
  ScheduleTd,
  ScheduleTh,
  SaveButton,
  ButtonContainer,
  DeleteDutyButton,
  AddedDutyContainer,
  AddedDutyInput,
  AddedDutySelect,
  AddedDutyOption,
  ModalContainerDutyGroupe,
  DutyNavigationButton,
  DeleteDutyIconButton,
  DutyScheduleNavContainer,
  ChooseDataContainer,
  DataOption,
  DataSelect,
  CopyNavContainer,
  AddedTimeContainer,
  AddedTimeInput,
  ModalContainerDutySchedule,
} from './DutySchedule.styled';
import { useQuery } from 'react-query';
import { weekDays, dutyText, scheduleText } from '../../../services/componentText';
import { FaTimes } from 'react-icons/fa';
import {
  formatDateSchedule,
  formatDateToInput,
} from '../../../helpers/dateAndTime/formatDate';
import { MdPublishedWithChanges } from 'react-icons/md';
import { IconButton } from '../../Buttons/IconButton.styled';
import { fetchUsers } from '../../../api/users';

export const DutySchedule = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCreateGroupe, setIsModalOpenCreateGroupe] = useState(false);
  const [isModalOpenAddEmployee, setIsModalOpenAddEmployee] = useState(false);
  const [isModalOpenUpdateGroupe, setIsModalOpenUpdateGroupe] = useState(false);
  const [isModalOpenAddChangeSchedule, setIsModalOpenAddChangeSchedule] =
    useState(false);
  const [groupeNameUA, setGroupeNameUA] = useState('');
  const [groupeNameRU, setGroupeNameRU] = useState('');
  const [selectedDutyGroupeId, setSelectedDutyGroupeId] = useState();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [formattedDutySchedule, setFormattedDutySchedule] = useState({});
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [timeSlots, setTimeSlots] = useState([{ start: '', end: '' }]);
  const [filteredDays, setFilteredDays] = useState(daysInMonth);
  const [selectedStartDate, setSelectedStartDate] = useState(1);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [time, setTime] = useState('');
  const [selectedScheduleID, setSelectedScheduleID] = useState(null);
  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1)?.getDay();
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_c = Number(process.env.REACT_APP_C_LEVEL_ACCESS);

  const { data: dutyGroupe, refetch: refetchDutyGroupe } = useQuery(
    'dutyGroupe',
    fetchDutyGroupe,
  );

  const { data: users } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "full_name", order: "ASC", isBlocked: "0", jobTitle: '3' })
  );

  const { data: dutyEmployee, refetch: refetchDutyEmployee } = useQuery(
    'dutyEmployee',
    fetchDutyEmployee,
  );

  const { data: dutySchedule, refetch: refetchDutySchedule } = useQuery(
    ['dutySchedule', currentYear, currentMonth],
    () => fetchDutySchedule(currentYear, currentMonth),
  );
  useEffect(() => {
    if (dutySchedule) {
      setFormattedDutySchedule(modifyDutySchedule(dutySchedule));
    }
  }, [dutySchedule]);

  useEffect(() => {
    const days = new Date(currentYear, currentMonth, 0).getDate();
    const daysArray = [...Array(days).keys()].map(day => day + 1);
    setDaysInMonth(daysArray);
    setFilteredDays(daysArray);
  }, [currentMonth, currentYear]);

  const modifyDutySchedule = data => {
    const formattedData = {};

    for (const [groupId, groupData] of Object.entries(data || {})) {
      if (!formattedData[groupId]) {
        formattedData[groupId] = {
          groupNameRU: groupData?.groupNameRU,
          groupNameUA: groupData?.groupNameUA,
          employees: {},
        };
      }

      for (const employeeData of groupData.employees) {
        if (!formattedData[groupId].employees[employeeData.employee_id]) {
          formattedData[groupId].employees[employeeData.employee_id] = {
            full_name: employeeData.full_name,
            days: {},
          };
        }

        for (const schedule of employeeData.schedule) {
          const date = new Date(schedule.day);
          const dayNumber = date.getDate();

          if (
            !formattedData[groupId]?.employees[employeeData.employee_id]?.days[
              dayNumber
            ]
          ) {
            formattedData[groupId].employees[employeeData.employee_id].days[
              dayNumber
            ] = [];
          }

          formattedData[groupId]?.employees[employeeData?.employee_id]?.days[
            dayNumber
          ]?.push({
            id_schedule: schedule.id_schedule,
            start: schedule?.start_time
              ? schedule?.start_time?.slice(0, 5)
              : '',
            end: schedule?.end_time ? schedule?.end_time?.slice(0, 5) : '',
            schedule_category_id: schedule?.id_categorie,
          });
        }
      }
    }

    return formattedData;
  };

  const handleSubmitDutyGroupe = async () => {
    const newGroupe = {
      groupe_name_RU: groupeNameRU,
      groupe_name_UA: groupeNameUA,
    };

    const response = await createMutationDutyGroupe(newGroupe);

    if (response?.status === 204) {
      toast.success(dutyText[language][0]?.successCreateGroupe);
      setGroupeNameRU('');
      setGroupeNameUA('');
      setIsModalOpenCreateGroupe(false);
      refetchDutyGroupe();
      refetchDutyEmployee();
    }
  };

  const hadleClickDutyEmployee = async id => {
    setSelectedDutyGroupeId(id);
    setIsModalOpenAddEmployee(true);
  };

  const hadleSubmitDutyEmployee = async () => {
    const newEmployee = {
      employee_id: selectedEmployeeId,
      id_duty_groupe: selectedDutyGroupeId,
    };

    const response = await createMutationDutyEmployee(newEmployee);

    if (response?.status === 204) {
      toast.success(dutyText[language][0]?.successAddedEmployeeToGroupe);
      setSelectedEmployeeId();
      setSelectedDutyGroupeId();
      refetchDutyEmployee();
      setIsModalOpenAddEmployee(false);
    }
  };

  const handleClickGroupe = groupe => {
    setSelectedDutyGroupeId(groupe?.id_groupe);
    setGroupeNameUA(groupe?.groupe_name_UA);
    setGroupeNameRU(groupe?.groupe_name_RU);
    setIsModalOpenUpdateGroupe(true);
  };

  const handleUpdateDutyGroupe = async () => {
    const groupeData = {
      id_groupe: selectedDutyGroupeId,
      groupe_name_RU: groupeNameRU,
      groupe_name_UA: groupeNameUA,
    };
    const response = await updateDutyGroupe(groupeData);

    if (response?.status === 204) {
      toast.success(dutyText[language][0]?.successEditGroupeTitle);
      setSelectedDutyGroupeId();
      setGroupeNameRU('');
      setGroupeNameUA('');
      setIsModalOpenUpdateGroupe(false);
      refetchDutyGroupe();
      refetchDutyEmployee();
    }
  };

  const handleDeleteDutyGroupe = async id_groupe => {
    const response = await deleteMutationDutyGroupe(id_groupe);

    if (response?.status === 204) {
      toast.success(dutyText[language][0]?.successRemovedGroupe);
      setIsModalOpenUpdateGroupe(false);
      refetchDutyGroupe();
      refetchDutyEmployee();
    }
  };

  const changeMonth = direction => {
    if (direction === 1 && currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(prev => prev + 1);
    } else if (direction === -1 && currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(prev => prev - 1);
    } else {
      setCurrentMonth(prev => prev + direction);
    }
  };

  const handleApplyCustomSchedule = () => {
    if (selectedStartDate && selectedEndDate) {
      const newFilteredDays = daysInMonth.filter(
        day => day >= selectedStartDate && day <= selectedEndDate,
      );
      setFilteredDays(newFilteredDays);
    } else {
      setFilteredDays(daysInMonth);
    }
  };

  const updateTimeSlot = (index, field, value) => {
    const updatedSlots = [...timeSlots];

    updatedSlots[index][field] = value;
    setTimeSlots(updatedSlots);
  };

  const prepareDataForServer = () => {
    const results = [];
    const categoryId = 9;

    if (!categoryId) {
      toast.error(
        `${scheduleText[language][0]?.categoryIdMissing} ${selectedDay}`,
      );
      return results;
    }

    timeSlots.forEach(slot => {
      const startTime = slot?.start?.trim() || null;
      const endTime = slot?.end?.trim() || null;

      if (categoryId <= 10 && (!startTime || !endTime)) {
        toast.error(
          language === 'russian'
            ? `Для дня ${selectedDay} с идентификатором категории ${categoryId} необходимы поля start_time и end_time.`
            : `Для дня ${selectedDay} з ідентифікатором категорії ${categoryId} необхідні поля start_time та end_time.`,
        );
        return;
      }

      results.push({
        employee_id: selectedEmployeeId,
        day: formatDateSchedule(currentYear, currentMonth, selectedDay),
        start_time: startTime,
        end_time: endTime,
        id_categorie: categoryId,
        id_status: 2,
      });
    });

    return results;
  };

  const handleDayClick = (day, employeeId, groupId) => {
    setSelectedDay(day);
    setSelectedEmployeeId(employeeId);
    const employeeSchedule =
      formattedDutySchedule[groupId].employees[employeeId].days[day];

    if (employeeSchedule) {
      setTimeSlots(employeeSchedule);
    } else {
      setTimeSlots([{ start: '', end: '' }]);
    }

    setSelectedScheduleID(
      formattedDutySchedule[employeeId]?.schedules[day]?.id_schedule,
    );

    setIsModalOpenAddChangeSchedule(true);
  };

  const updateSingleSlotToServer = async (slot, index) => {
    const dataForServer = prepareDataForSingleSlot(slot);
    const response = await updateSchedule(dataForServer);

    if (response?.status === 204) {
      setIsModalOpenAddChangeSchedule(false);
      refetchDutySchedule(currentYear, currentMonth);
    }
  };

  const prepareDataForSingleSlot = slot => {
    return {
      employee_id: selectedEmployeeId,
      id_schedule: slot.id || selectedScheduleID,
      start_time: slot?.start.trim(),
      end_time: slot?.end.trim(),
      id_categorie: 9,
      id_status: 2,
      day: selectedDay,
    };
  };

  const handleSave = async () => {
    const fullScheduleData = await prepareDataForServer();

    const response = await saveSchedule(fullScheduleData);

    if (response?.status === 204) {
      toast.success(scheduleText[language][0]?.successResponseDataBase);
      refetchDutySchedule(currentYear, currentMonth);
      setIsModalOpenAddChangeSchedule(false);
    }
  };

  const areTimeSlotsValid = () => {
    return timeSlots.every(slot => slot.start < slot.end);
  };

  const addTimeSlot = () => {
    setTimeSlots(prev => [...prev, { start: '', end: '' }]);
  };

  const handleConfirmDelete = async () => {
    const formatDate = await formatDateToInput(
      `${currentYear}-${currentMonth}-${selectedDay}`,
    );
    const response = await deleteSchedule(selectedEmployeeId, formatDate);

    if (response.status === 200) {
      toast.success(scheduleText[language][0]?.successDelete);
      setIsModalOpenAddChangeSchedule(false);
      refetchDutySchedule(currentYear, currentMonth);
    }
  };

  useEffect(() => {
    setTime(`02:00`);
  }, []);

  return (
    <div>
      <Main>
        {(currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g) && (
          <div>
            <DutyScheduleNavContainer>
              <DutyNavigationButton onClick={() => setIsModalOpen(true)}>
                {dutyText[language][0]?.editDutGroupe}
              </DutyNavigationButton>
              <DutyNavigationButton onClick={() => changeMonth(-1)}>
                {scheduleText[language][0]?.prevMonth}
              </DutyNavigationButton>
              <DutyNavigationButton onClick={() => changeMonth(1)}>
                {scheduleText[language][0]?.nextMonth}
              </DutyNavigationButton>
              <CopyNavContainer>
                <ChooseDataContainer>
                  <span>{scheduleText[language][0]?.dateStartPeriod} </span>
                  <DataSelect
                    value={selectedStartDate || ''}
                    onChange={e => setSelectedStartDate(Number(e.target.value))}
                  >
                    <DataOption value="">
                      {scheduleText[language][0]?.chooseDate}
                    </DataOption>
                    {daysInMonth.map(day => (
                      <DataOption key={day} value={day}>
                      {day >= 10 ? day : `0${day}`}.
                      {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                      </DataOption>
                    ))}
                  </DataSelect>
                </ChooseDataContainer>

                <ChooseDataContainer>
                  <span>{scheduleText[language][0]?.dateEndPeriod} </span>
                  <DataSelect
                    value={selectedEndDate || ''}
                    onChange={e => setSelectedEndDate(Number(e.target.value))}
                  >
                    <DataOption value="">
                      {scheduleText[language][0]?.chooseDate}
                    </DataOption>
                    {daysInMonth.map(day => (
                      <DataOption key={day} value={day}>
                      {day >= 10 ? day : `0${day}`}.
                      {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                      </DataOption>
                    ))}
                  </DataSelect>
                </ChooseDataContainer>

                <DutyNavigationButton onClick={handleApplyCustomSchedule}>
                  {scheduleText[language][0]?.changeSchedule}
                </DutyNavigationButton>
              </CopyNavContainer>
            </DutyScheduleNavContainer>
            {isModalOpen && (
              <Modal closeModal={() => setIsModalOpen(false)}>
                <ModalContainerDuty>
                  <DutyNavigationButton
                    onClick={() => setIsModalOpenCreateGroupe(true)}
                  >
                    {dutyText[language][0]?.addGroupe}
                  </DutyNavigationButton>
                  <div style={{ display: 'flex' }}>
                    {dutyGroupe &&
                      dutyGroupe?.map(groupe => (
                        <ScheduleTable
                          key={groupe?.id_groupe}
                          style={{ marginLeft: '10px' }}
                        >
                          <Thead>
                            <ScheduleHeadTr>
                              <ScheduleTh
                                onClick={() => handleClickGroupe(groupe)}
                              >
                                {language === 'russian'
                                  ? groupe?.groupe_name_RU
                                  : groupe?.groupe_name_UA}
                              </ScheduleTh>
                            </ScheduleHeadTr>
                          </Thead>
                          <tbody>
                            {dutyEmployee &&
                              dutyEmployee?.map(
                                employee =>
                                  employee?.id_duty_groupe ===
                                    groupe?.id_groupe && (
                                    <ScheduleTr key={employee?.employee_id}>
                                      <ScheduleTd>
                                        {employee?.full_name}{' '}
                                        <DeleteDutyIconButton
                                          onClick={() =>
                                            deleteMutationDutyEmployee(
                                              employee?.employee_id,
                                              refetchDutyEmployee,
                                            )
                                          }
                                        >
                                          <FaTimes />
                                        </DeleteDutyIconButton>
                                      </ScheduleTd>
                                    </ScheduleTr>
                                  ),
                              )}
                            <ScheduleTr>
                              <ScheduleTd
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <DutyNavigationButton
                                  onClick={() =>
                                    hadleClickDutyEmployee(groupe?.id_groupe)
                                  }
                                >
                                  {dutyText[language][0]?.addEmployee}
                                </DutyNavigationButton>
                              </ScheduleTd>
                            </ScheduleTr>
                          </tbody>
                        </ScheduleTable>
                      ))}
                  </div>
                </ModalContainerDuty>
              </Modal>
            )}
            {isModalOpenCreateGroupe && (
              <Modal closeModal={() => setIsModalOpenCreateGroupe(false)}>
                <ModalContainerDutyGroupe>
                  <AddedDutyContainer>
                    <label>{dutyText[language][0]?.groupeTitleUA}</label>
                    <AddedDutyInput
                      type="text"
                      value={groupeNameUA}
                      onChange={e => setGroupeNameUA(e.target.value)}
                    />
                  </AddedDutyContainer>
                  <AddedDutyContainer>
                    <label>{dutyText[language][0]?.groupeTitleRU}</label>
                    <AddedDutyInput
                      type="text"
                      value={groupeNameRU}
                      onChange={e => setGroupeNameRU(e.target.value)}
                    />
                  </AddedDutyContainer>
                  <SaveButton onClick={handleSubmitDutyGroupe}>
                    {dutyText[language][0]?.save}
                  </SaveButton>
                </ModalContainerDutyGroupe>
              </Modal>
            )}
            {isModalOpenAddEmployee && (
              <Modal closeModal={() => setIsModalOpenAddEmployee(false)}>
                <ModalContainerDutyGroupe>
                  <AddedDutyContainer>
                    <label>{dutyText[language][0]?.chooseEmployeeOne}</label>
                    <AddedDutySelect
                      name="selectedUser"
                      value={selectedEmployeeId}
                      onChange={e => setSelectedEmployeeId(e.target.value)}
                    >
                      <AddedDutyOption value="">
                        {dutyText[language][0]?.chooseEmployeeTwo}
                      </AddedDutyOption>
                      {users?.usersData?.map(user => (
                        <AddedDutyOption
                          key={user.id_employee}
                          value={user.id_employee}
                        >
                          {user.full_name}
                        </AddedDutyOption>
                      ))}
                    </AddedDutySelect>
                  </AddedDutyContainer>
                  <SaveButton onClick={hadleSubmitDutyEmployee}>
                    {dutyText[language][0]?.addToGroupe}
                  </SaveButton>
                </ModalContainerDutyGroupe>
              </Modal>
            )}
            {isModalOpenUpdateGroupe && (
              <Modal closeModal={() => setIsModalOpenUpdateGroupe(false)}>
                <ModalContainerDutyGroupe>
                  <AddedDutyContainer>
                    <label>{dutyText[language][0]?.groupeTitleUA}</label>
                    <AddedDutyInput
                      type="text"
                      value={groupeNameUA}
                      onChange={e => setGroupeNameUA(e.target.value)}
                    />
                  </AddedDutyContainer>
                  <AddedDutyContainer>
                    <label>{dutyText[language][0]?.groupeTitleRU}</label>
                    <AddedDutyInput
                      type="text"
                      value={groupeNameRU}
                      onChange={e => setGroupeNameRU(e.target.value)}
                    />
                  </AddedDutyContainer>
                  <ButtonContainer>
                    <SaveButton onClick={() => handleUpdateDutyGroupe()}>
                      {dutyText[language][0]?.saveChanges}
                    </SaveButton>
                    <DeleteDutyButton
                      onClick={() =>
                        handleDeleteDutyGroupe(selectedDutyGroupeId)
                      }
                    >
                      {dutyText[language][0]?.remove}
                    </DeleteDutyButton>
                  </ButtonContainer>
                </ModalContainerDutyGroupe>
              </Modal>
            )}
            {isModalOpenAddChangeSchedule && (
              <Modal closeModal={() => setIsModalOpenAddChangeSchedule(false)}>
                <ModalContainerDutySchedule>
                  <h2 style={{ textAlign: 'center' }}>
                    День:                       {selectedDay >= 10 ? selectedDay : `0${selectedDay}`}.
                      {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                  </h2>
                  {timeSlots &&
                    timeSlots?.map((slot, index) => (
                      <div key={index}>
                        <AddedTimeContainer>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <AddedTimeInput
                              type="time"
                              value={slot.start ? slot.start : time}
                              step="300"
                              onChange={e =>
                                updateTimeSlot(index, 'start', e.target.value)
                              }
                            />
                            -
                            <AddedTimeInput
                              type="time"
                              value={slot.end ? slot.end : time}
                              step="300"
                              onChange={e =>
                                updateTimeSlot(index, 'end', e.target.value)
                              }
                            />
                            {slot?.start && slot?.end && (
                              <div>
                                <IconButton
                                  onClick={() =>
                                    updateSingleSlotToServer(slot, index)
                                  }
                                  style={{ marginBottom: '10px' }}
                                >
                                  <MdPublishedWithChanges size="28px" />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </AddedTimeContainer>
                      </div>
                    ))}
                  <SaveButton
                    onClick={addTimeSlot}
                    disabled={timeSlots?.some(slot => !slot.start || !slot.end)}
                  >
                    {scheduleText[language][0]?.addSlot}
                  </SaveButton>
                  <SaveButton
                    onClick={() => handleSave()}
                    disabled={!areTimeSlotsValid()}
                    style={{ marginTop: '10px' }}
                  >
                    {scheduleText[language][0]?.save}
                  </SaveButton>
                  <SaveButton
                    onClick={() => handleConfirmDelete()}
                    style={{ marginTop: '10px' }}
                  >
                    {scheduleText[language][0]?.deleteSchedule}
                  </SaveButton>
                </ModalContainerDutySchedule>
              </Modal>
            )}
            <h1>
              {scheduleText[language][0]?.scheduleForMonth}{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}-
              {currentYear}
            </h1>
            <TableContainer>
              {Object.entries(formattedDutySchedule).map(
                ([groupId, groupData]) => (
                  <div key={groupId}>
                    <ScheduleTable style={{ marginBottom: '50px' }}>
                      <Thead>
                        <ScheduleHeadTr>
                          <ScheduleTh>
                            {language === 'russian'
                              ? groupData.groupNameRU
                              : groupData.groupNameUA}
                          </ScheduleTh>
                          {filteredDays?.map((day, idx) => {
                  const adjustedIdx = (idx + firstDayOfMonth - 1) % 7;
                  return (
                    <ScheduleTh key={idx}>
                      {
                        weekDays[language][0][
                          Object.keys(weekDays[language][0])[adjustedIdx]
                        ]
                      }
                      <br />
                      {day >= 10 ? day : `0${day}`}.
                      {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                    </ScheduleTh>
                  );
                })}
                        </ScheduleHeadTr>
                      </Thead>
                      <tbody>
                        {Object.entries(groupData.employees).map(
                          ([employeeId, employeeData]) => (
                            <ScheduleTr key={employeeId}>
                              <ScheduleTd>{employeeData.full_name}</ScheduleTd>
                              {filteredDays.map(day => (
                                <ScheduleTd
                                  key={day}
                                  onClick={
                                    day
                                      ? () =>
                                          handleDayClick(
                                            day,
                                            employeeId,
                                            groupId,
                                          )
                                      : null
                                  }
                                >
                                  {employeeData.days[day] ? (
                                    employeeData.days[day].map(
                                      (schedule, index) => {
                                        return (
                                          <div key={index}>
                                            {schedule.start} - {schedule.end}
                                          </div>
                                        );
                                      },
                                    )
                                  ) : (
                                    <div>-</div>
                                  )}
                                </ScheduleTd>
                              ))}
                            </ScheduleTr>
                          ),
                        )}
                      </tbody>
                    </ScheduleTable>
                  </div>
                ),
              )}
            </TableContainer>
          </div>
        )}
        {currentUser?.access_level === access_level_c && (
          <div style={{ display: 'flex' }}>
            {dutyGroupe &&
              dutyGroupe?.map(groupe => (
                <ScheduleTable
                  key={groupe?.id_groupe}
                  style={{ marginLeft: '10px' }}
                >
                  <Thead>
                    <ScheduleHeadTr>
                      <ScheduleTh onClick={() => handleClickGroupe(groupe)}>
                        {language === 'russian'
                          ? groupe?.groupe_name_RU
                          : groupe?.groupe_name_UA}
                      </ScheduleTh>
                    </ScheduleHeadTr>
                  </Thead>
                  <tbody>
                    {dutyEmployee &&
                      dutyEmployee?.map(
                        employee =>
                          employee?.id_duty_groupe === groupe?.id_groupe && (
                            <ScheduleTr key={employee?.employee_id}>
                              <ScheduleTd>{employee?.full_name} </ScheduleTd>
                            </ScheduleTr>
                          ),
                      )}
                  </tbody>
                </ScheduleTable>
              ))}
          </div>
        )}
      </Main>
      <ToastContainer autoClose={4000} />
    </div>
  );
};
