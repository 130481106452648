import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "react-query";
import {
  TableContainer,
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  AddedNewFMButton,
  AddedFMContainer,
  SaveButton,
  AddedFMInput,
  AddedFMSelect,
  AddedFMOption,
  ButtonContainer,
  DeleteFMButton,
  Thead,
  FileContainer,
  ModalContainer,
  NavigationButton,
  ModalConteiner,
  FMTextArea,
  AddedInput,
} from "./ManagerSalary.styled";
import { UserContext } from "../../Context/UserContext";
import { Modal } from "../../Modal/Modal";
import { salaryText } from "../../../services/componentText";
import { AiOutlinePlus, AiFillFileExcel } from "react-icons/ai";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import {
  fetchAllSalaries,
  updateSalary,
  onDeleteByDate,
  uploadFile,
} from "../../../api/salary";

export const ManagerSalary = () => {
  const { currentUser, language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState("to_pay_off");
  const [order, setOrder] = useState("DESC");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSalaryData, setSelectedSalaryData] = useState({
    salary_id: "",
    salary_bid: "",
    working_hours: "",
    salary_in_hour: "",
    total_salary_for_extra: "",
    prize: "",
    fines: "",
    payment_for_goods: "",
    to_pay_off: "",
    fines_comment: "",
  });
  const maxLength = 250;
  const accessLevel = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0]?.name);
  };

  const { data: salaries, refetch } = useQuery(
    ["salariesData", { selectedDate, sortBy, order }],
    () => fetchAllSalaries({ date: selectedDate, sortBy, order})
  );

  const onUpload = async () => {
    const formData = new FormData();
    formData.append("salary", file);

    const response = await uploadFile(formData);
    if (response.status === 204) {
      setIsModalOpen(false);
      toast.success(`${salaryText[language][0]?.successUpload}`);
      refetch();
    }
  };

  useEffect(() => {
    if (!selectedDate && salaries?.uniqueSalaryDates[0] !== undefined) {
      setSelectedDate(salaries?.uniqueSalaryDates[0]);
    }
  }, [selectedDate, salaries]);

  const handleClick = (data) => {
    setIsUpdateModalOpen(true);
    setSelectedSalaryData({
      salary_id: data.salary_id || "",
      salary_bid: data.salary_bid || 0,
      working_hours: data.working_hours || 0,
      salary_in_hour: data.salary_in_hour || 0,
      total_salary_for_extra: data.total_salary_for_extra || 0,
      prize: data.prize || 0,
      fines: data.fines || 0,
      payment_for_goods: data.payment_for_goods || 0,
      to_pay_off: data.to_pay_off || 0,
      fines_comment: data.fines_comment || "",
    });
  };

  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue?.length <= maxLength) {
      setSelectedSalaryData((prevData) => ({
        ...prevData,
        fines_comment: inputValue,
      }));
    }
  };

  const handleUpdate = async (data) => {
    const response = await updateSalary(data);

    if (response.status === 204) {
      setIsUpdateModalOpen(false);
      toast.success(`${salaryText[language][0]?.successUpdate}`);
      refetch();
    }
  };

  const handleDelete = async () => {
    const response = await onDeleteByDate(selectedDate);

    if (response?.status === 204) {
      toast.success(`${salaryText[language][0]?.successDelete}`);
      refetch();
    }
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  return (
    <div>
      {isUpdateModalOpen && (
        <Modal closeModal={() => setIsUpdateModalOpen(false)}>
          <ModalConteiner>
            <AddedFMContainer>
              <label>{salaryText[language][0]?.hoursInVoip}:</label>
              <AddedInput
                type="number"
                value={selectedSalaryData.working_hours}
                placeholder={salaryText[language][0]?.hoursInVoip}
                onChange={(e) =>
                  setSelectedSalaryData((prevData) => ({
                    ...prevData,
                    working_hours: e.target.value,
                  }))
                }
              />
            </AddedFMContainer>
            <AddedFMContainer>
              <label>{salaryText[language][0]?.bid}:</label>
              <AddedInput
                type="number"
                value={selectedSalaryData.salary_bid}
                placeholder={salaryText[language][0]?.bid}
                onChange={(e) =>
                  setSelectedSalaryData((prevData) => ({
                    ...prevData,
                    salary_bid: e.target.value,
                  }))
                }
              />
            </AddedFMContainer>
            <AddedFMContainer>
              <label>{salaryText[language][0]?.oneExtraSalary}:</label>
              <AddedInput
                type="number"
                value={selectedSalaryData.salary_in_hour}
                placeholder={salaryText[language][0]?.oneExtraSalary}
                onChange={(e) =>
                  setSelectedSalaryData((prevData) => ({
                    ...prevData,
                    salary_in_hour: e.target.value,
                  }))
                }
              />
            </AddedFMContainer>
            <AddedFMContainer>
              <label>{salaryText[language][0]?.totalSalaryForExtra}:</label>
              <AddedInput
                type="number"
                value={selectedSalaryData.total_salary_for_extra}
                placeholder={salaryText[language][0]?.totalSalaryForExtra}
                onChange={(e) =>
                  setSelectedSalaryData((prevData) => ({
                    ...prevData,
                    total_salary_for_extra: e.target.value,
                  }))
                }
              />
            </AddedFMContainer>
            <AddedFMContainer>
              <label>{salaryText[language][0]?.prize}:</label>
              <AddedInput
                type="number"
                value={selectedSalaryData.prize}
                placeholder={salaryText[language][0]?.prize}
                onChange={(e) =>
                  setSelectedSalaryData((prevData) => ({
                    ...prevData,
                    prize: e.target.value,
                  }))
                }
              />
            </AddedFMContainer>
            <AddedFMContainer>
              <label>{salaryText[language][0]?.fines}:</label>
              <AddedInput
                type="number"
                value={selectedSalaryData.fines}
                placeholder={salaryText[language][0]?.fines}
                onChange={(e) =>
                  setSelectedSalaryData((prevData) => ({
                    ...prevData,
                    fines: e.target.value,
                  }))
                }
              />
            </AddedFMContainer>
            <AddedFMContainer>
              <label>{salaryText[language][0]?.toPayOff}:</label>
              <AddedInput
                type="number"
                value={selectedSalaryData.to_pay_off}
                placeholder={salaryText[language][0]?.toPayOff}
                onChange={(e) =>
                  setSelectedSalaryData((prevData) => ({
                    ...prevData,
                    to_pay_off: e.target.value,
                  }))
                }
              />
            </AddedFMContainer>
            <AddedFMContainer>
              <label>{salaryText[language][0]?.finesComment}:</label>
              <div>
                <FMTextArea
                  type="text"
                  value={selectedSalaryData.fines_comment}
                  placeholder={salaryText[language][0]?.finesComment}
                  onChange={handleCommentChange}
                  maxLength={maxLength}
                />
                <p>
                  {salaryText[language][0]?.leftSymbols}{" "}
                  {maxLength - selectedSalaryData.fines_comment?.length}/
                  {maxLength}
                </p>
              </div>
            </AddedFMContainer>
            <ButtonContainer>
              <SaveButton onClick={() => handleUpdate(selectedSalaryData)}>
                {salaryText[language][0]?.update}
              </SaveButton>
              <DeleteFMButton onClick={() => setIsUpdateModalOpen(false)}>
                {salaryText[language][0]?.decline}
              </DeleteFMButton>
            </ButtonContainer>
          </ModalConteiner>
        </Modal>
      )}
      {currentUser?.access_level === accessLevel && isModalOpen && (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <ModalContainer>
            {!fileName && (
              <AiOutlinePlus
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                size="100"
              />
            )}
            {fileName && (
              <FileContainer>
                <AiFillFileExcel
                  size="100"
                  color="green"
                  style={{ marginRight: "10px" }}
                />
                {fileName}
              </FileContainer>
            )}
            <AddedFMInput
              type="file"
              accept=".xlsx, .xls"
              onChange={onFileChange}
            />
          </ModalContainer>
          <ButtonContainer>
            <SaveButton onClick={onUpload}>
              {salaryText[language][0]?.upload}
            </SaveButton>
            {fileName && (
              <DeleteFMButton
                onClick={() => {
                  setFile(null);
                  setFileName(null);
                }}
              >
                {salaryText[language][0]?.cancel}
              </DeleteFMButton>
            )}
          </ButtonContainer>
        </Modal>
      )}
      {currentUser?.access_level === accessLevel && (
        <div>
          <AddedNewFMButton onClick={() => setIsModalOpen(true)}>
            {salaryText[language][0]?.addNewTable}
          </AddedNewFMButton>
          <AddedFMContainer>
            <NavigationButton>
              <AddedFMSelect
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              >
                <AddedFMOption value="" disabled>
                  {salaryText[language][0]?.chooseDate}
                </AddedFMOption>
                {salaries !== undefined &&
                  salaries?.uniqueSalaryDates?.map((date) => (
                    <AddedFMOption key={date} value={date}>
                      {formattedDateToDayMonthYear(date)}
                    </AddedFMOption>
                  ))}
              </AddedFMSelect>

              <ButtonContainer style={{ marginLeft: "20px" }}>
                {/* <SaveButton onClick={fetchAllSalaries}>
                  {salaryText[language][0]?.chooseThisDate}
                </SaveButton> */}
                {currentUser?.access_level === accessLevel && (
                  <DeleteFMButton
                    onClick={handleDelete}
                    style={{ width: "350px" }}
                  >
                    {salaryText[language][0]?.chooseRemoveThisDate}
                  </DeleteFMButton>
                )}
              </ButtonContainer>
            </NavigationButton>
          </AddedFMContainer>
        </div>
      )}
      <TableContainer>
        <ScheduleTable>
          <Thead>
            <ScheduleHeadTr>
              <ScheduleTh onClick={() => handleSortChange("full_name")}>
                {salaryText[language][0]?.fullName}{" "}
                {renderSortIcon("full_name")}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange("working_hours")}>
                {salaryText[language][0]?.hoursInVoip}{" "}
                {renderSortIcon("working_hours")}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange("salary_bid")}>
                {salaryText[language][0]?.bid} {renderSortIcon("salary_bid")}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange("salary_in_hour")}>
                {salaryText[language][0]?.oneExtraSalary}{" "}
                {renderSortIcon("salary_in_hour")}
              </ScheduleTh>
              <ScheduleTh
                onClick={() => handleSortChange("total_salary_for_extra")}
              >
                {salaryText[language][0]?.totalSalaryForExtra}
                {renderSortIcon("total_salary_for_extra")}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange("prize")}>
                {salaryText[language][0]?.prize} {renderSortIcon("prize")}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange("fines")}>
                {salaryText[language][0]?.fines} {renderSortIcon("fines")}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange("fines_comment")}>
                {salaryText[language][0]?.finesComment}{" "}
                {renderSortIcon("fines_comment")}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange("payment_for_goods")}>
                {salaryText[language][0]?.paymentForGoods}
                {renderSortIcon("payment_for_goods")}
              </ScheduleTh>
              <ScheduleTh onClick={() => handleSortChange("to_pay_off")}>
                {salaryText[language][0]?.toPayOff}
                {renderSortIcon("to_pay_off")}
              </ScheduleTh>
            </ScheduleHeadTr>
          </Thead>
          <tbody>
            {salaries?.salaries?.length > 0 ? (
              <React.Fragment>
                {salaries?.salaries?.map((salary) => {
                  return (
                    <ScheduleTr
                      key={salary.salary_id}
                      onClick={() => handleClick(salary)}
                    >
                      <ScheduleTd>{salary.full_name}</ScheduleTd>
                      <ScheduleTd>{salary.working_hours}</ScheduleTd>
                      <ScheduleTd>
                        {salary.salary_bid ? salary.salary_bid : 0} грн
                      </ScheduleTd>
                      <ScheduleTd>
                        {salary.salary_in_hour ? salary.salary_in_hour : 0} грн
                      </ScheduleTd>
                      <ScheduleTd>
                        {salary.total_salary_for_extra
                          ? salary.total_salary_for_extra
                          : 0}{" "}
                        грн
                      </ScheduleTd>
                      <ScheduleTd>
                        {salary.prize ? salary.prize : 0} грн
                      </ScheduleTd>
                      <ScheduleTd>
                        {salary.fines ? salary.fines : 0} грн
                      </ScheduleTd>
                      <ScheduleTd>{salary.fines_comment}</ScheduleTd>
                      <ScheduleTd>
                        {salary.payment_for_goods
                          ? salary.payment_for_goods
                          : 0}{" "}
                        грн
                      </ScheduleTd>
                      <ScheduleTd>{salary.to_pay_off} грн</ScheduleTd>
                    </ScheduleTr>
                  );
                })}
              </React.Fragment>
            ) : (
              <ScheduleTr>
                <ScheduleTd colSpan={9} style={{ width: "600px" }}>
                  {salaryText[language][0]?.notDataOrError}
                </ScheduleTd>
              </ScheduleTr>
            )}
          </tbody>
        </ScheduleTable>
      </TableContainer>
    </div>
  );
};
